const defaultStyles = {
  '& .MuiOutlinedInput-root': {
    gap: '4px',
    width: '100%',
    margin: '20px 0 0',
    padding: '5px',
    borderRadius: 4,
    border: '1px solid #d1d1d9',
  },
  '& .MuiInputLabel-root': {
    transform: 'none!important',
    fontWeight: '600',
    fontSize: '12px',
    lineHeight: '14px',
  },
  '& .MuiOutlinedInput-input': {
    height: 'calc(44px - 12px)!important',
    border: 'none',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '20px',
    backgroundColor: 'transparent',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    display: 'none',
  },
  '& .MuiChip-root': {
    flexDirection: 'row-reverse',
    height: '32px',
    margin: 0,
    padding: '0 10px',
    background: '#F0EFFF',
    borderRadius: '4px',
    gap: 0,
    color: '#544DA9',
  },
  '& .MuiSvgIcon-root ': {
    color: '#544DA9',
    width: '15px',
    height: '15px',
  },
};

export const MuiAutocompleteLight = {
  styleOverrides: {
    root: {
      ...defaultStyles,
    },
  },
};

export const MuiAutocompleteDark = {
  styleOverrides: {
    root: {
      ...defaultStyles,
      '& .MuiChip-root': {
        flexDirection: 'row-reverse',
        height: '32px',
        margin: 0,
        padding: '0 10px',
        background: '#877cff',
        borderRadius: '4px',
        gap: 0,
        color: '#fff',
      },
      '& .MuiSvgIcon-root ': {
        color: '#fff',
        width: '15px',
        height: '15px',
      },
    },
  },
};
