import { parseISO } from 'date-fns';
import { useNavigate } from 'react-router-dom';

import { FullScreenDialog } from '@components/FullScreenDialog/FullScreenDialog';
import { KrakenListItem } from '@components/KrakenListItem/KrakenListItem';
import { KrakenNavBar } from '@components/KrakenNavBar/KrakenNavBar';
import { dateWithTimeFormat } from '@src/constants/constants';
import { LinkEnum } from '@src/types/types';
import { useTypedSelector } from '@src/utils';
import {
  GetApiFindingsHumanByHidApiArg,
  GetApiFindingsHumanByHidApiResponse,
  KrakenModelsFindingHelpersRisk,
  useGetApiFindingsHumanByHidQuery,
} from '@store/services/query.generated';
import { getDate } from '@utils/dateUtils';
import { useActions } from '@utils/hooks/useActions';

import s from './FBUFindings.module.sass';

const list = [
  'Name',
  'Finding',
  'Type',
  'Status',
  'Component',
  'Last Edit Date',
];

const navStyle = { cursor: 'pointer' };

export const FBUFindings = () => {
  const { updateFBU } = useActions();
  const navigate = useNavigate();

  const {
    humanId,
    projectId,
    showPopup,
    risk: helpersRisk,
  } = useTypedSelector((state) => state.findingsByUserReducer);
  let data: undefined | GetApiFindingsHumanByHidApiResponse;

  if (humanId && projectId && helpersRisk && showPopup) {
    const payload: GetApiFindingsHumanByHidApiArg = {
      hid: humanId,
      risk: helpersRisk as KrakenModelsFindingHelpersRisk,
      projectId,
    };
    ({ data } = useGetApiFindingsHumanByHidQuery(payload));
  }

  const closePopup = () => {
    updateFBU({ field: 'showPopup', value: false });
    updateFBU({ field: 'projectId', value: '' });
  };

  const onClick = (id: string | undefined) => {
    id
      && navigate(
        LinkEnum.EDIT_FINDING.replace(':pid', projectId).replace(':fid', id),
        { state: { linkTemplate: LinkEnum.EDIT_FINDING } },
      );
  };

  return (
    <FullScreenDialog
      onClose={closePopup}
      open={showPopup}
      title="Findings by Project"
    >
      <div className={s.wrapper}>
        <KrakenNavBar list={list} gridTemplateColumns="repeat(6, 1fr)" />
        {data?.map(
          ({
            finding_id,
            editorName,
            summary,
            type,
            statusName,
            component,
            lastEditDate,
          }) => (
            <KrakenListItem
              fid={finding_id}
              key={finding_id}
              classname={s.listItem}
              handler={onClick}
              style={navStyle}
            >
              <div>{editorName}</div>
              <div>{summary}</div>
              <div>{type}</div>
              <div>{statusName}</div>
              <div>{component}</div>
              <div>
                {lastEditDate
                  ? getDate(
                      dateWithTimeFormat,
                      new Date(parseISO(lastEditDate)),
                    )
                  : ''}
              </div>
            </KrakenListItem>
          ),
        )}
      </div>
    </FullScreenDialog>
  );
};
