import { UseFormWatch } from 'react-hook-form';

import { CheckList } from '@components/CheckList/CheckList';

import { IFindingTemplateFields } from '../types';

import { useFTChecklist } from './useFTChecklist';

interface IProps {
  watch: UseFormWatch<IFindingTemplateFields>;
}

export const FTChecklist = ({ watch }: IProps) => {
  const links = useFTChecklist(watch);

  return <CheckList asideLinks={links} />;
};
