import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { MenuItem, SelectChangeEvent, Skeleton } from '@mui/material';

import { KrakenSelect } from '@components/KrakenSelect/KrakenSelect';
import { retracted } from '@pages/Project/Findings/AddEditFinding/constants';
import {
  GetApiFindingsProjectByPidFallbackApiResponse,
  useDeleteApiActivitiesByAidFindingAndFidMutation,
  useGetApiActivitiesByAidFindingsQuery,
  useGetApiActivitiesByAidQuery,
  useGetApiFindingsProjectByPidFallbackQuery,
  usePutApiActivitiesByAidFindingAndFidMutation,
} from '@store/services/query.generated';
import { useActions } from '@utils/hooks/useActions';

const skeletonStyle = { flexGrow: 1, height: 44, marginTop: '20px' };

export const ActivityFindings = () => {
  const [finding, setFinding] = useState<string[]>([]);
  const [findings, setFindings] = useState<GetApiFindingsProjectByPidFallbackApiResponse>([]);
  const { pid, aid } = useParams();
  const { updateActivityFields } = useActions();

  const { data: activity, isLoading: activityIsLoading } = useGetApiActivitiesByAidQuery({ aid: aid! }, { skip: !aid });
  const { data: allFindings = [], isLoading } = useGetApiFindingsProjectByPidFallbackQuery({ pid: pid! }, { skip: !pid });
  const { data: fids, isLoading: fidIsLoading } = useGetApiActivitiesByAidFindingsQuery({ aid: aid! }, { skip: !aid });
  const [putFinding] = usePutApiActivitiesByAidFindingAndFidMutation();
  const [deleteFinding] = useDeleteApiActivitiesByAidFindingAndFidMutation();

  const onChange = ({ target: { value } }: SelectChangeEvent) => {
    const newFid = (value as any).find((x: string) => !finding.includes(x));
    const fidToBeDeleted = finding.find((x) => !value.includes(x));

    setFinding(value as unknown as string[]);
    updateActivityFields({ field: 'findings', value });

    aid && fidToBeDeleted && deleteFinding({ aid, fid: fidToBeDeleted });
    aid && newFid && putFinding({ aid, fid: newFid });
  };

  useEffect(() => {
    if (allFindings.length) {
      const formattedData = allFindings.filter(
        ({ finding_status_id, component_id }) => {
          const isRetracted = finding_status_id?.toUpperCase() === retracted;
          const theSameComponentId = component_id === activity?.PcId;

          return !isRetracted && theSameComponentId;
        },
      );

      setFindings(formattedData);
    }
  }, [allFindings, activity]);

  useEffect(() => {
    if (fids?.length) {
      setFinding(fids);
    }
  }, [fids]);

  return (
    <>
      {(isLoading || fidIsLoading || activityIsLoading) && !findings.length && (
        <Skeleton animation="wave" variant="rectangular" sx={skeletonStyle} />
      )}
      {findings.length ? (
        <KrakenSelect
          label="Findings"
          value={finding}
          onChange={onChange}
          multiple
        >
          {findings.map(({ finding_id, summary }) => (
            <MenuItem key={finding_id} value={finding_id}>
              {summary}
            </MenuItem>
          ))}
        </KrakenSelect>
      ) : null}
    </>
  );
};
