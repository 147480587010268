import { BrowserRouter as Router } from 'react-router-dom';
import { AuthenticatedTemplate } from '@azure/msal-react';

import { KrakenNotificationProvider } from '@components/KrakenNotificationProvider';
import { KrakenRoutes } from '@src/KrakenRoutes';
import BaseLayout from '@src/layouts/Base/Base';

export const Authenticated = () => (
  <AuthenticatedTemplate>
    <KrakenNotificationProvider>
      <Router>
        <BaseLayout>
          <KrakenRoutes />
        </BaseLayout>
      </Router>
    </KrakenNotificationProvider>
  </AuthenticatedTemplate>
);
