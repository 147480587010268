import { KrakenTable } from '@components/KrakenTable/KrakenTable';
import { GetApiActivityTemplatesApiResponse } from '@store/services/query.generated';

import { useATList } from './useATList';

interface IProps {
  templates: GetApiActivityTemplatesApiResponse;
}

export const ATList = ({ templates }: IProps) => {
  const { columns, rowTemplate } = useATList();

  return (
    <KrakenTable
      tableData={templates}
      columns={columns}
      gridTemplateColumns="repeat(3, 1fr)"
      rowTemplate={rowTemplate}
    />
  );
};
