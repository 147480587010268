import { Controller } from 'react-hook-form';
import { Control } from 'react-hook-form/dist/types';

import { Datepicker } from '@components/Datepicker/Datepicker';
import { kebabCase } from '@utils/kebabCase';

import { ESRLinks, IStatusReportFields } from '../types';

interface IProps {
  control: Control<IStatusReportFields>;
}

export const SRReportDate = ({ control }: IProps) => (
  <Controller
    control={control}
    name="ReportDate"
    render={({ field: { value, onChange } }) => (
      <Datepicker
        id={kebabCase(ESRLinks.REPORT_DATE)}
        label="Report date*"
        startDate={value}
        onChange={onChange}
      />
    )}
  />
);
