import { loginRequest } from '@src/config/authConfig';
import { msalInstance } from '@src/index';
import { showError } from '@store/reducers/errorSlice';
import { updateTokenData } from '@store/reducers/msalSlice';
import { store } from '@store/store';
import { isValidToken } from '@utils/isValidToken';

export const getToken = async () => {
  let idToken = null;

  const responseHandler = (response: any) => {
    const now: any = new Date()?.getTime();
    const ttl = response.extExpiresOn
      ? response.extExpiresOn.getTime() - now
      : 0;

    store.dispatch(updateTokenData(response));

    if (ttl > 0) {
      idToken = response.idToken;
      sessionStorage.setItem('tokensTime', response.extExpiresOn);
      localStorage.setItem('idToken', response.idToken);
    }
  };

  if (isValidToken() && localStorage.getItem('idToken')) {
    return localStorage.getItem('idToken');
  }

  await msalInstance
    .acquireTokenSilent({
      ...loginRequest,
      account: msalInstance.getAllAccounts()[0],
    })
    .then(responseHandler)
    .catch(() => {
      msalInstance
        .acquireTokenPopup({
          ...loginRequest,
          account: msalInstance.getAllAccounts()[0],
        })
        .then(responseHandler, (err) => {
          store.dispatch(showError(err));
        });
    });

  return idToken;
};
