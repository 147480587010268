import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { KrakenListItem } from '@components/KrakenListItem/KrakenListItem';
import { IColumn } from '@components/KrakenTable/type';
import { dateWithTimeFormat } from '@src/constants/constants';
import { LinkEnum } from '@src/types/types';
import { KrakenBackendApiModelsActivityTemplatesApiActivityTemplateAssociated } from '@store/services/query.generated';
import { getDate } from '@utils/dateUtils';

import s from './ATList.module.sass';

const columns: IColumn<KrakenBackendApiModelsActivityTemplatesApiActivityTemplateAssociated>[] = [
    {
      headerName: 'Subject',
      field: 'Subject',
      sortable: true,
    },
    {
      headerName: 'Description',
      field: 'Description',
      sortable: true,
    },
    {
      headerName: 'LastModified',
      field: 'ModifiedDate',
      sortable: true,
      sortType: 'date',
    },
  ];

export const useATList = () => {
  const navigate = useNavigate();

  const onClick = (id: string | undefined) => {
    id
      && navigate(LinkEnum.EDIT_ACTIVITY_TEMPLATE.replace(':atid', id), {
        state: { linkTemplate: LinkEnum.EDIT_ACTIVITY_TEMPLATE },
      });
  };

  const rowTemplate = useCallback(
    ({
 Id, Subject, Description, ModifiedDate,
}) => (
  <KrakenListItem
    key={Id}
    fid={Id}
    classname={s.listWrapper}
    handler={onClick}
  >
    <div>{Subject}</div>
    <div>{Description}</div>
    <div>
      {ModifiedDate
            ? getDate(dateWithTimeFormat, new Date(ModifiedDate))
            : ''}
    </div>
  </KrakenListItem>
    ),
    [],
  );

  return {
    columns,
    rowTemplate,
  };
};
