import { LinkEnum } from '@src/types/types';

export interface ISubmenu {
  name: string;
  link: LinkEnum;
  iconName: string;
}

export interface IRoutes {
  to: string;
  iconName: string;
  itemName: string;
}

export const routes: IRoutes[] = [
  {
    to: LinkEnum.DASHBOARD,
    iconName: 'dashboard',
    itemName: 'Dashboard',
  },
  {
    to: LinkEnum.PROJECTS,
    iconName: 'folder',
    itemName: 'Projects',
  },
  {
    to: LinkEnum.COMPANIES,
    iconName: 'todo',
    itemName: 'Admin',
  },
];
