import { Controller } from 'react-hook-form';
import { Control } from 'react-hook-form/dist/types';

import { KrakenTextArea } from '@components/KrakenTextArea/KrakenTextArea';
import { kebabCase } from '@utils/kebabCase';
import { textAreaHandler } from '@utils/textAreaHandler';

import { EAddEditComponentLinks, IComponentFields } from '../types';

const placeholder = `How successfully do they address concerns discussed in the threat analysis (TA)? -OR- How successfully do they avoid vulnerabilities and protect each attack surface discussed in the TA? Where are the weaknesses in their attack surface?

•  What are the patterns of strengths and/or weaknesses, if any, that emerge from the set of findings against this component? 
•  Are there any systemic problems? 
•  Are there any problems specific to a certain area within the component?
•  Do not include recommendations in this section.`;

interface IProps {
  control: Control<IComponentFields>;
}

export const ComponentObservations = ({ control }: IProps) => (
  <Controller
    control={control}
    name="Observations"
    render={({ field: { value, onChange } }) => (
      <KrakenTextArea
        id={kebabCase(EAddEditComponentLinks.OBSERVATIONS)}
        title="Observations"
        value={value || ''}
        onChange={(val) => textAreaHandler(val, onChange)}
        placeholder={placeholder}
        height={180}
        hideToolbar
      />
    )}
  />
);
