import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IError {
  visible: boolean;
  message: string;
  title?: string;
  is401?: boolean;
}

const initialState: IError = {
  visible: false,
  message: '',
  title: '',
  is401: false,
};

export interface IShowError {
  message: string;
  title?: string;
  is401?: boolean;
}

const errorSlice = createSlice({
  name: 'error',
  initialState,
  reducers: {
    showError(
      state: IError,
      {
        payload: { message, title = '', is401 = false },
      }: PayloadAction<IShowError>,
    ) {
      state.visible = true;
      state.message = message;
      state.title = title;
      state.is401 = is401;
    },
    hideError: () => initialState,
  },
});

const { reducer, actions } = errorSlice;

export default reducer;
export const { showError, hideError } = actions;
