import { ChangeEvent, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button } from '@mui/material';

import s from '@pages/Project/Activities/AddEditActivity/ActivityChecklist/ActivityChecklist.module.sass';
import {
  useGetApiActivitiesByAidQuery,
  usePostApiActivitiesByAidChecklistMutation,
} from '@store/services/query.generated';

export const useActivityChecklist = () => {
  const { aid } = useParams();
  const [showPopup, setShowPopup] = useState(false);
  const [input, setInput] = useState('');

  const [addChecklist] = usePostApiActivitiesByAidChecklistMutation();
  const { data: activity } = useGetApiActivitiesByAidQuery({ aid: aid! });

  const onAddChecklist = () => {
    setShowPopup(true);
  };

  const onOk = () => {
    addChecklist({
      aid: aid!,
      body: input,
    }).finally(() => {
      setShowPopup(false);
      setInput('');
    });
  };

  const onInput = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
    setInput(value);
  };

  const onClose = () => {
    setShowPopup(false);
    setInput('');
  };

  const footer = (
    <footer className={s.footer}>
      <Button size="small" color="secondary" onClick={onClose}>
        Cancel
      </Button>
      <Button size="small" color="primary" onClick={onOk} disabled={!input}>
        Ok
      </Button>
    </footer>
  );

  return {
    onAddChecklist,
    checklist: activity?.Checklist || [],
    showPopup,
    footer,
    input,
    onInput,
    onClose,
  };
};
