import { Navigate, Route, Routes } from 'react-router-dom';

import { ActivityTemplates } from '@pages/Admin/ActivityTemplates/ActivityTemplates';
import { AddEditActivityTemplate } from '@pages/Admin/ActivityTemplates/AddEditActivityTemplate/AddEditActivityTemplate';
import { Admin } from '@pages/Admin/Admin';
import { Archive } from '@pages/Admin/Archive/Archive';
import { ArchiveProject } from '@pages/Admin/Archive/ArchiveProject/ArchiveProject';
import { AddEditCompany } from '@pages/Admin/Companies/AddEditCompanies/AddEditCompanies';
import { Companies } from '@pages/Admin/Companies/Companies';
import { AddEditFindingTemplate } from '@pages/Admin/FindingTemplate/AddEditFindingTemplate/AddEditFindingTemplate';
import { FindingTemplate } from '@pages/Admin/FindingTemplate/FindingTemplate';
import { StereotypesTemplate } from '@pages/Admin/StereotypesTemplate';
import { AddEditStereotypeTemplate } from '@pages/Admin/StereotypesTemplate/AddEditStereotypeTemplate';
import { Dashboard } from '@pages/Dashboard/Dashboard';
import { Activities } from '@pages/Project/Activities/Activities';
import { AddEditActivity } from '@pages/Project/Activities/AddEditActivity/AddEditActivity';
import { AddEditComponent } from '@pages/Project/Components/AddEditComponent/AddEditComponent';
import { Components } from '@pages/Project/Components/Components';
import { AddEditFinding } from '@pages/Project/Findings/AddEditFinding/AddEditFinding';
import { Findings } from '@pages/Project/Findings/Findings';
import { Project } from '@pages/Project/Project';
import { Reporting } from '@pages/Project/Reporting/Reporting';
import { Users } from '@pages/Project/Users/Users';
import { AddEditProject } from '@pages/Projects/AddEditProject/AddEditProject';
import Projects from '@pages/Projects/Projects';
import { ReAuthPage } from '@pages/ReAuthPage/ReAuthPage';
import { ExecutiveSummary } from '@pages/Reporting/ExecutiveSummary/ExecutiveSummary';
import { FinalReport } from '@pages/Reporting/GeneralFinalReport/FinalReport';
import { StatusReport } from '@pages/Reporting/StatusReport/StatusReport';
import { Integrations } from '@pages/Settings/Integrations/Integrations';
import { Notifications } from '@pages/Settings/Notifications/Notifications';
import { LinkEnum } from '@src/types/types';

export const KrakenRoutes = () => (
  <Routes>
    <Route path={LinkEnum.RE_AUTH} element={<ReAuthPage />} />
    <Route path={LinkEnum.MAIN_PAGE} element={<Dashboard />} />
    <Route
      path={LinkEnum.DASHBOARD}
      element={<Navigate to={LinkEnum.MAIN_PAGE} replace />}
    />
    <Route path={LinkEnum.PROJECTS} element={<Projects />} />
    <Route path={LinkEnum.ADD_PROJECT} element={<AddEditProject />} />
    <Route path={LinkEnum.EDIT_PROJECT} element={<AddEditProject />} />
    <Route path={LinkEnum.PROJECTS} element={<Projects />} />
    <Route path={LinkEnum.PROJECT} element={<Project />}>
      <Route path={LinkEnum.PROGRESS} element={<>Progress</>} />
      <Route path={LinkEnum.FINDINGS} element={<Findings />} />
      <Route path={LinkEnum.ADD_FINDING} element={<AddEditFinding />} />
      <Route path={LinkEnum.EDIT_FINDING} element={<AddEditFinding />} />
      <Route path={LinkEnum.ACTIVITIES} element={<Activities />} />
      <Route path={LinkEnum.ADD_ACTIVITY} element={<AddEditActivity />} />
      <Route path={LinkEnum.EDIT_ACTIVITY} element={<AddEditActivity />} />
      <Route path={LinkEnum.COMPONENTS} element={<Components />} />
      <Route path={LinkEnum.EDIT_COMPONENT} element={<AddEditComponent />} />
      <Route path={LinkEnum.USERS} element={<Users />} />
      <Route path={LinkEnum.REPORTING} element={<Reporting />} />
      <Route path={LinkEnum.FINAL_REPORT} element={<FinalReport />} />
      <Route path={LinkEnum.EXECUTIVE_SUMMARY} element={<ExecutiveSummary />} />
      <Route path={LinkEnum.STATUS_REPORT} element={<StatusReport />} />
    </Route>
    <Route path={LinkEnum.ADMIN} element={<Admin />}>
      <Route path={LinkEnum.ARCHIVE} element={<Archive />} />
      <Route path={LinkEnum.ARCHIVE_PROJECT} element={<ArchiveProject />} />
      <Route path={LinkEnum.COMPANIES} element={<Companies />} />
      <Route path={LinkEnum.ADD_COMPANY} element={<AddEditCompany />} />
      <Route path={LinkEnum.EDIT_COMPANY} element={<AddEditCompany />} />
      <Route path={LinkEnum.FINDING_TEMPLATE} element={<FindingTemplate />} />
      <Route
        path={LinkEnum.ACTIVITY_TEMPLATE}
        element={<ActivityTemplates />}
      />
      <Route
        path={LinkEnum.ADD_FINDING_TEMPLATE}
        element={<AddEditFindingTemplate />}
      />
      <Route
        path={LinkEnum.EDIT_FINDING_TEMPLATE}
        element={<AddEditFindingTemplate />}
      />
      <Route
        path={LinkEnum.ADD_ACTIVITY_TEMPLATE}
        element={<AddEditActivityTemplate />}
      />
      <Route
        path={LinkEnum.EDIT_ACTIVITY_TEMPLATE}
        element={<AddEditActivityTemplate />}
      />
      <Route
        path={LinkEnum.STEREOTYPE_TEMPLATE}
        element={<StereotypesTemplate />}
      />
      <Route
        path={LinkEnum.ADD_STEREOTYPE_TEMPLATE}
        element={<AddEditStereotypeTemplate />}
      />
      <Route
        path={LinkEnum.EDIT_STEREOTYPE_TEMPLATE}
        element={<AddEditStereotypeTemplate />}
      />
    </Route>
    <Route path={LinkEnum.NOTIFICATIONS} element={<Notifications />} />
    <Route path={LinkEnum.INTEGRATIONS} element={<Integrations />} />
  </Routes>
);
