import { Controller } from 'react-hook-form';
import { Control } from 'react-hook-form/dist/types';

import { KrakenTextArea } from '@components/KrakenTextArea/KrakenTextArea';
import { kebabCase } from '@utils/kebabCase';
import { textAreaHandler } from '@utils/textAreaHandler';

import { EAddEditActivityLinks, IActivityFields } from '../types';

const methodologyPlaceholder = `A high-level description (in full sentences) of the tools and methods the consultant used to evaluate the security of the component/system.
Point of view: State in the imperative (i.e., second person, implied subject).
Sentence template (use only those aspects that apply): [Use method(s) and/or tool(s)] to check [their thing] for [high-level description of checklist]. Then, manually validate tool-generated findings.`;

interface IProps {
  control: Control<IActivityFields>;
}

export const ActivityMethodology = ({ control }: IProps) => (
  <Controller
    control={control}
    name="Description"
    render={({ field: { value, onChange } }) => (
      <KrakenTextArea
        id={kebabCase(EAddEditActivityLinks.METHODOLOGY)}
        title="Methodology*"
        value={value || ''}
        onChange={(val) => textAreaHandler(val, onChange)}
        placeholder={methodologyPlaceholder}
        style={{ height: '100%' }}
        height={180}
        hideToolbar
      />
    )}
  />
);
