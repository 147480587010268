export interface ICord {
  mouseX: number;
  mouseY: number;
}

export enum LinkEnum {
  MAIN_PAGE = '/',
  RE_AUTH = '/re-auth',
  DASHBOARD = '/dashboard',
  PROJECTS = '/projects',
  PROJECT = '/projects/:pid/',
  NOTIFICATIONS = '/settings/notifications',
  INTEGRATIONS = '/settings/integrations',
  ADD_PROJECT = '/add-project',
  EDIT_PROJECT = '/edit-project/:pid',
  PROGRESS = '/projects/:pid/progress',
  FINDINGS = '/projects/:pid/findings',
  ADD_FINDING = '/projects/:pid/findings/add-finding',
  EDIT_FINDING = '/projects/:pid/findings/edit-finding/:fid',
  ACTIVITIES = '/projects/:pid/activities',
  ADD_ACTIVITY = '/projects/:pid/activities/add-activity',
  EDIT_ACTIVITY = '/projects/:pid/activities/edit-activity/:aid',
  COMPONENTS = '/projects/:pid/components',
  EDIT_COMPONENT = '/projects/:pid/components/edit-components/:cid',
  USERS = '/projects/:pid/users',
  REPORTING = '/projects/:pid/reporting',
  FINAL_REPORT = '/projects/:pid/reporting/final-report',
  EXECUTIVE_SUMMARY = '/projects/:pid/executive-summary',
  STATUS_REPORT = '/projects/:pid/reporting/status-report',
  ADMIN = '/admin',
  COMPANIES = '/admin/companies',
  ADD_COMPANY = '/admin/companies/add-company',
  EDIT_COMPANY = '/admin/companies/edit-company/:cid',
  FINDING_TEMPLATE = '/admin/finding-templates',
  ADD_FINDING_TEMPLATE = '/admin/finding-templates/add-finding-template',
  EDIT_FINDING_TEMPLATE = '/admin/finding-templates/edit-finding-template/:vid',
  ACTIVITY_TEMPLATE = '/admin/activity-templates',
  ADD_ACTIVITY_TEMPLATE = '/admin/activity-templates/add-activity-template',
  EDIT_ACTIVITY_TEMPLATE = '/admin/activity-templates/edit-activity-template/:atid',
  ARCHIVE = '/admin/archive',
  ARCHIVE_PROJECT = '/admin/archive/deleted-data/:pid',
  STEREOTYPE_TEMPLATE = '/admin/stereotype-templates',
  ADD_STEREOTYPE_TEMPLATE = '/admin/stereotype-templates/add-stereotype-template',
  EDIT_STEREOTYPE_TEMPLATE = '/admin/stereotype-templates/edit-stereotype-template/:sid',
}

export enum EChecklistBtn {
  INCOMPLETE = 'Incomplete',
  TESTED = 'Tested',
  N_A = 'N/A',
}

export enum EReviewOutcome {
  OK,
  NEEDS_WORK,
  BUG_OF_YEAR,
}

export enum EReviewType {
  PEER,
  TECH_LEAD,
  PROJECT_MANAGER,
  EDITORIAL,
  OVERSIGHT,
}

export enum EReviewRoles {
  SECURITY_ADVISOR = 'FFE1D0CA-AC7F-4F85-8076-1445F288A8E6',
  TECHNICAL_REVIEWER = 'F132C21F-D7B1-405B-AC9D-65CAE56C1E3D',
  EXECUTIVE_SPONSOR = 'B7F0C796-1F40-45E4-811C-96D8C04997EF',
  CONSULTANT = '7B450336-2570-4E9C-A985-97CEA99A1D63',
  TECHNICAL_LEAD = '28E98FFF-642B-405D-BF3F-B1E872E25C73',
  PROJECT_MANAGER = 'FA7A32CD-03A9-47E7-8A5A-EA487015A079',
}
