import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { EditsHistory } from '@components/EditsHistory/EditsHistory';
import {
  GetApiReviewablesEditHistoryProjectByPidApiResponse,
  useGetApiReviewablesEditHistoryProjectByPidQuery,
} from '@store/services/query.generated';

export const ESHistory = () => {
  const [history, setHistory] = useState<GetApiReviewablesEditHistoryProjectByPidApiResponse>([]);
  const { pid } = useParams();

  const { data = [] } = useGetApiReviewablesEditHistoryProjectByPidQuery(
    {
      pid: pid!,
    },
    { skip: !pid },
  );

  useEffect(() => {
    if (data?.length) {
      const arr = data.filter(({ referent }) => referent === pid);
      arr?.length && setHistory(arr);
    }
  }, [data]);

  return history.length ? <EditsHistory data={history} /> : null;
};
