import { Controller } from 'react-hook-form';
import { Control } from 'react-hook-form/dist/types';
import { useParams } from 'react-router-dom';
import { FormControl, InputLabel } from '@mui/material';

import { DragAndDrop } from '@components/DragAndDrop/DragAndDrop';
import { ICompanyFields } from '@pages/Admin/Companies/AddEditCompanies/type';

import styles from '../AddCompany.module.sass';

interface IProps {
  control: Control<ICompanyFields>;
}

export const CompanyLogo = ({ control }: IProps) => {
  const { cid } = useParams();
  return (
    <Controller
      control={control}
      name="logo"
      render={({ field: { value, onChange } }) => (
        <FormControl fullWidth className={styles.addLogo}>
          <InputLabel
            shrink
            id="status-text-label"
            className={styles.addLogoLabel}
          >
            {`${cid ? 'Update' : 'Add '} logo`}
          </InputLabel>
          <DragAndDrop handler={onChange} value={value} />
        </FormControl>
      )}
    />
  );
};
