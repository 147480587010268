import { showError } from '@store/reducers/errorSlice';
import { updateConnectionState } from '@store/reducers/signalRSlice';
import { store } from '@store/store';

import { notificationQueue } from './constants';
import { notifications } from './notifications';

export const connect = async () => {
  try {
    await notifications.start();

    store.dispatch(updateConnectionState(true));
    window.console.log('=== SignalR is now connected ===');

    const recursion = () => {
      if (!notificationQueue.length) {
        return;
      }

      const fn = notificationQueue.shift();

      typeof fn === 'function' && fn();

      recursion();
    };

    notificationQueue.length && recursion();
  } catch (err: any) {
    store.dispatch(showError(err));
  }
};
