import { useEffect, useState } from 'react';
import { UseFormWatch } from 'react-hook-form/dist/types/form';
import { useParams } from 'react-router-dom';

import { ICheckListLink } from '@components/CheckList/CheckList';
import {
  EAddProjectLinks,
  IProjectFields,
} from '@pages/Projects/AddEditProject/types';
import { isValidDate } from '@utils/dateUtils';

export const useProjectCheckList = (watch: UseFormWatch<IProjectFields>) => {
  const { pid } = useParams();
  const linksState: ICheckListLink = {
    [EAddProjectLinks.PROJECT_NAME]: false,
    [EAddProjectLinks.COMPANY]: false,
    ...(!pid && { [EAddProjectLinks.COMPONENT]: false }),
    [EAddProjectLinks.PERIOD]: false,
    [EAddProjectLinks.STATUS_TEXT]: false,
  };
  const [links, setLinks] = useState(linksState);

  useEffect(() => {
    const subscription = watch((value) => {
      setLinks({
        [EAddProjectLinks.PROJECT_NAME]: Boolean(
          value.prName && value.prName.length > 2,
        ),
        [EAddProjectLinks.COMPANY]: Boolean(value.coID),
        ...(!pid && {
          [EAddProjectLinks.COMPONENT]: Boolean(
            value.component && value.component.length > 2,
          ),
        }),
        [EAddProjectLinks.PERIOD]: Boolean(
          'date' in value
            && value.date?.length
            && isValidDate(value.date[0] as Date)
            && isValidDate(value.date[1] as Date),
        ),
        [EAddProjectLinks.STATUS_TEXT]: Boolean(value.notes),
      });
    });
    return () => subscription.unsubscribe();
  }, [watch, pid]);

  return links;
};
