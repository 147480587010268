import { useState } from 'react';
import { Controller } from 'react-hook-form';
import { Control } from 'react-hook-form/dist/types';
import { Checkbox, Collapse, FormControlLabel } from '@mui/material';

import { KrakenTextArea } from '@components/KrakenTextArea/KrakenTextArea';
import { textAreaHandler } from '@utils/textAreaHandler';

import { IFinalReportFields } from '../types';

import s from '../FinalReport.module.sass';

interface IProps {
  control: Control<IFinalReportFields>;
}

export const FRAppendixC = ({ control }: IProps) => {
  const [checked, setChecked] = useState(false);

  const handler = (value: boolean, onChange: (...event: any[]) => void) => {
    setChecked(!value);
    onChange(!value);
  };

  return (
    <div className={s.mb24}>
      <Controller
        control={control}
        name="AddAppendixC"
        render={({ field: { value, onChange } }) => (
          <FormControlLabel
            control={(
              <Checkbox
                checked={value || false}
                onClick={() => handler(value, onChange)}
              />
            )}
            label="Add Appendix C"
            sx={{
              '[data-theme="dark"] &': {
                color: '#fff',
              },
            }}
          />
        )}
      />
      <Collapse in={checked}>
        <Controller
          control={control}
          name="AppendixC"
          render={({ field: { value, onChange } }) => (
            <KrakenTextArea
              title="Appendix C Content*"
              value={value || ''}
              onChange={(val) => textAreaHandler(val, onChange)}
              height={180}
              hideToolbar
            />
          )}
        />
      </Collapse>
    </div>
  );
};
