import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr';

import { BASE_URL } from '@src/constants/environment';

const build = new HubConnectionBuilder();

const withUrl = build.withUrl(`${BASE_URL}/hubs/notifications`, {
  accessTokenFactory: () => localStorage.getItem('idToken') || '',
});

const withAutomaticReconnect = withUrl.withAutomaticReconnect({
  nextRetryDelayInMilliseconds: (retryContext: {
    previousRetryCount: number;
  }) => [1000, 5000, 25000][retryContext.previousRetryCount] ?? 450000,
});

const configureLogging = withAutomaticReconnect.configureLogging(LogLevel.None);

export const notifications = configureLogging.build();
