import { useMemo } from 'react';
import { UseFormWatch } from 'react-hook-form/dist/types/form';
import { useParams } from 'react-router-dom';

import { BreadCrumbs } from '@components/BreadCrumbs/BreadCrumbs';
import { LinkEnum } from '@src/types/types';

import { IFindingFields } from '../types';

interface IProps {
  watch: UseFormWatch<IFindingFields>;
}

export const FindingBreadCrumbs = ({ watch }: IProps) => {
  const { pid } = useParams();
  const findingName = watch('name');

  const crumbs = useMemo(
    () => [
      ['All projects', LinkEnum.PROJECTS],
      ['Findings', LinkEnum.FINDINGS.replace(':pid', pid!)],
    ],
    [pid],
  );

  return <BreadCrumbs crumbs={crumbs} currentPage={findingName} />;
};
