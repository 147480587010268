import { KrakenModelsDtoCweCweItemResult } from '@store/services/query.generated';

export enum EAddEditFindingLinks {
  FINDING_TEMPLATE = 'Finding Template',
  NAME = 'Name',
  ACTIVITIES = 'Activities',
  COMPONENT = 'Component',
  IMPACT = 'Impact',
  DIFFICULTY = 'Difficulty',
  CVSS = 'CVSS',
  DESCRIPTION = 'Description',
  OBSERVATION = 'Observation',
  RECOMMENDATION = 'Recommendation',
  INTERNAL_NOTES = 'Internal Notes',
}

export enum EStatusList {
  UNDER_INVESTIGATION = 'Under Investigation',
  VULNERABLE = 'Vulnerable',
  ACCEPTED = 'Accepted',
  MITIGATED = 'Mitigated (Partial Fix)',
  REMEDIED = 'Remedied (Fixed)',
  RETRACTED = 'Retracted',
}

export enum ESeverityList {
  INFO = 'Info',
  LOW = 'Low',
  MEDIUM = 'Medium',
  HIGH = 'High',
  CRITICAL = 'Critical',
}

export interface IOnInUse {
  connectionsCount: number;
  connectionRole: string;
  editorName: string;
}

export interface IControlRequested {
  requesterName: string;
  requesterConnectionId: string;
}

export interface IFindingFields {
  templateId: string | null;
  name: string | null;
  componentId: string | null;
  associatedActivities: string[] | null;
  impact: string | null;
  impactRationale: string | null;
  difficulty: string | null;
  difficultyRationale: string | null;
  statusId: string | null;
  awsCategory: string | null;
  overriddenSeverity: number | null;
  residualImpact: string | null;
  residualDifficulty: string | null;
  cwe: KrakenModelsDtoCweCweItemResult | number | null;
  mitigation: string | null;
  cvss: string | null;
  description: string | null;
  observation: string | null;
  recommendation: string | null;
  location: string | null;
  reference: string | null;
  notes: string | null;
  isAWS: boolean;
  validCVSS: boolean;
}
