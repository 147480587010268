import { useState } from 'react';
import { Button } from '@mui/material';

import { Footer } from '@components/Footer/Footer';
import { AddEditUser } from '@pages/Project/Users/AddEditUser';

export const UsersFooter = () => {
  const [showPopup, setShowPopup] = useState(false);
  const onCLick = () => setShowPopup(true);
  const onClose = () => setShowPopup(false);

  return (
    <Footer>
      <Button size="small" onClick={onCLick}>
        Add User
      </Button>
      {showPopup && <AddEditUser visible={showPopup} onClose={onClose} />}
    </Footer>
  );
};
