import { useState } from 'react';

import { dates } from '@utils/dateUtils';

import { KrakenTableSort } from '../KrakenTableSort/KrakenTableSort';
import { ESort, IColumn } from '../type';

import s from '../KrakenTable.module.sass';

interface IProps<T> {
  data: T[];
  columns: IColumn<T>[];
  setTableData?: (data: T[]) => void;
  gridTemplateColumns: string;
}

export function KrakenTableHead<T>({
  data,
  columns,
  setTableData,
  gridTemplateColumns,
}: IProps<T>) {
  const [checkedLabel, setCheckedLabel] = useState('');

  const onSort = (sort: ESort, column: IColumn<T>) => {
    const comparator = (x: any, y: any) => {
      if (column?.comparator) {
        return column.comparator(x, y);
      }

      const key: any = sort === ESort.NORMAL ? 'sortIndex' : column.field;

      if (!x[key] || !y[key]) {
        return -1;
      }

      if (column?.sortType === 'date') {
        if (sort === ESort.ASK) return dates.compare(x[key], y[key]);
        if (sort === ESort.DESC) return dates.compare(y[key], x[key]);
      }

      switch (sort) {
        case ESort.DESC:
          if (
            x[key].toString().toLowerCase() > y[key].toString().toLowerCase()
          ) {
            return -1;
          }
          if (
            x[key].toString().toLowerCase() < y[key].toString().toLowerCase()
          ) {
            return 1;
          }
          return 0;
        case ESort.ASK:
        default:
          if (
            x[key].toString().toLowerCase() < y[key].toString().toLowerCase()
          ) {
            return -1;
          }
          if (
            x[key].toString().toLowerCase() > y[key].toString().toLowerCase()
          ) {
            return 1;
          }
          return 0;
      }
    };

    setCheckedLabel(column.headerName);

    setTableData && setTableData([...data!].sort(comparator));
  };

  return (
    <div className={s.krakenTableHead} style={{ gridTemplateColumns }}>
      {columns.map((column) => (
        <div key={column.headerName}>
          {column.sortable && column.field ? (
            <KrakenTableSort<T>
              column={column}
              onSort={onSort}
              outerChecked={column.headerName !== checkedLabel}
            />
          ) : (
            column.headerName
          )}
        </div>
      ))}
    </div>
  );
}
