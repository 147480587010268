import {
 useCallback, useEffect, useRef, useState,
} from 'react';
import { shallowEqual } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Button } from '@mui/material';

import { useTypedSelector } from '@src/utils';
import { TFindingNotificationType } from '@store/reducers/addEditFindingSlice';
import { useActions } from '@utils/hooks/useActions';
import {
  AnswerControlRequest,
  SendControlRequest,
  timeToAnswerForEditor,
} from '@utils/signalR';

import s from './FindingNotification.module.sass';

const askForControl = ':user: editing finding right now';
const askControlIsImpossible = 'Control have been requested by somebody else';
const accepted = ':user: accepted your request';
const declined = 'User declined  your request';
const requesting = ':user: is requesting control';

export const FindingNotification = () => {
  const [text, setText] = useState('');
  const [seconds, setSeconds] = useState(timeToAnswerForEditor);
  const { fid } = useParams();
  const { resetFindingNotification, setFindingNotificationType } = useActions();
  const myInterval = useRef<any>();
  const typeRef = useRef<TFindingNotificationType>();

  const { findingNotificationType, notificationName, requesterConnectionId } = useTypedSelector((state) => state.addEditFindingReducer, shallowEqual);

  const ask = () => {
    SendControlRequest(`finding_edit_${fid}`);
    setFindingNotificationType('waiting');
  };
  const decline = useCallback(() => {
    requesterConnectionId && AnswerControlRequest(requesterConnectionId, false);
    resetFindingNotification();
  }, [requesterConnectionId]);
  const accept = useCallback(() => {
    requesterConnectionId && AnswerControlRequest(requesterConnectionId, true);
    resetFindingNotification();
  }, [requesterConnectionId]);

  useEffect(() => {
    typeRef.current = findingNotificationType;
    if (findingNotificationType === 'askForControl') {
      setText(askForControl.replace(':user:', notificationName || 'User'));
    } else if (findingNotificationType === 'requesting') {
      let sec = timeToAnswerForEditor;

      setText(requesting.replace(':user:', notificationName || 'User'));
      myInterval.current = setInterval(() => {
        if (sec <= 1 || typeRef.current !== 'requesting') {
          clearInterval(myInterval.current);
          setFindingNotificationType('askForControl');
        }
        sec -= 1;
      }, 1000);
    } else if (findingNotificationType === 'accepted') {
      setText(accepted);
    } else if (findingNotificationType === 'declined') {
      setText(declined);
    } else if (findingNotificationType === 'askControlBlocked') {
      setText(askControlIsImpossible);
    } else if (findingNotificationType === 'waiting') {
      let sec = timeToAnswerForEditor;

      myInterval.current = setInterval(() => {
        if (sec <= 1 || typeRef.current !== 'waiting') {
          clearInterval(myInterval.current);
        }
        setSeconds(--sec);
      }, 1000);
    }
  }, [findingNotificationType, notificationName]);

  useEffect(
    () => () => {
      clearInterval(myInterval.current);
    },
    [],
  );

  return (
    <div className={s.wrapper}>
      {findingNotificationType === 'waiting' ? (
        <div>{`waiting ${seconds}sec`}</div>
      ) : (
        <div>{text}</div>
      )}
      {findingNotificationType === 'askForControl' && (
        <Button size="small" onClick={ask}>
          Ask For Control
        </Button>
      )}
      {findingNotificationType === 'requesting' && (
        <div className={s.btnWrapper}>
          <Button size="small" color="error" onClick={decline}>
            Decline
          </Button>
          <Button size="small" onClick={accept}>
            Accept
          </Button>
        </div>
      )}
    </div>
  );
};
