import { Link } from 'react-router-dom';
import { Button } from '@mui/material';

import { Footer } from '@components/Footer/Footer';
import Icon from '@components/Icon/Icon';
import { LinkEnum } from '@src/types/types';

import { useProjectFooter } from './useProjectFooter';

interface IProps {
  readyToSubmit: boolean;
}

export const ProjectFooter = ({ readyToSubmit }: IProps) => {
  const {
 pid, isAdmin, active, onDelete, onDeactivate, onActivate,
} = useProjectFooter();

  return (
    <Footer>
      <Button
        component={Link}
        color="secondary"
        size="small"
        to={LinkEnum.PROJECTS}
      >
        Cancel
      </Button>
      <Button
        color="primary"
        size="small"
        type="submit"
        startIcon={<Icon iconName="save" />}
        disabled={!readyToSubmit}
      >
        Save project
      </Button>
      {pid && active && (
        <Button color="error" size="small" onClick={onDeactivate}>
          Deactivate
        </Button>
      )}
      {pid && !active && (
        <Button color="primary" size="small" onClick={onActivate}>
          Activate
        </Button>
      )}
      {isAdmin && pid && (
        <Button color="error" size="small" onClick={onDelete}>
          Delete
        </Button>
      )}
    </Footer>
  );
};
