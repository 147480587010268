import { KrakenLayout } from '@src/layouts/KrakenLayout/KrakenLayout';
import { DownloadFile } from '@src/popups/DownloadPDF/DownloadFile';

import { SRAddressAndContactInfo } from './SRAdressAndContactInfo';
import { SRAppendixA } from './SRAppendixA';
import { SRBackground } from './SRBackground';
import { SRChecklist } from './SRChecklist';
import { SRClassificationFont } from './SRClassificationFont';
import { SRCurrentStatus } from './SRCurrentStatus';
import { SRFooter } from './SRFooter';
import { SRHeaderFooterFont } from './SRHeaderFooterFont';
import { SRMainFont } from './SRMainFont';
import { SRProjectState } from './SRProjectState';
import { SRReportDate } from './SRReportDate';
import { SRStakeholders } from './SRStakeholders';
import { SRStatusDate } from './SRStatusDate';
import { useStatusReport } from './useStatusReport';

import s from './StatusReport.module.sass';

export const StatusReport = () => {
  const {
    control,
    visible,
    watch,
    handleSubmit,
    onSubmit,
    isLoading,
    fonts,
    fontsIsLoading,
  } = useStatusReport();

  return (
    <KrakenLayout
      checkList={<SRChecklist watch={watch} />}
      footer={<SRFooter />}
      onSubmit={handleSubmit(onSubmit)}
      isLoading={isLoading}
    >
      <div className={s.wrapper}>
        <div className={s.twoColumns}>
          <SRStatusDate control={control} />
          <SRReportDate control={control} />
        </div>
        <SRAddressAndContactInfo control={control} />
        <SRStakeholders control={control} />
        <SRBackground control={control} />
        <SRCurrentStatus control={control} />
        <SRProjectState control={control} />
        <SRAppendixA control={control} />
        <SRMainFont
          fonts={fonts}
          control={control}
          fontsIsLoading={fontsIsLoading}
        />
        <SRHeaderFooterFont
          fonts={fonts}
          control={control}
          fontsIsLoading={fontsIsLoading}
        />
        <SRClassificationFont
          fonts={fonts}
          control={control}
          fontsIsLoading={fontsIsLoading}
        />
        {visible && <DownloadFile />}
      </div>
    </KrakenLayout>
  );
};
