import { Controller } from 'react-hook-form';
import { Control } from 'react-hook-form/dist/types';

import { KrakenTextArea } from '@components/KrakenTextArea/KrakenTextArea';
import { kebabCase } from '@utils/kebabCase';
import { textAreaHandler } from '@utils/textAreaHandler';

import { EAddEditActivityLinks, IActivityFields } from '../types';

const observationsPlaceholder = `Prefer third person (e.g., The system is … ). First-person plural may be used, but only when necessary." Tense will vary depending on what you’re describing. 
What to communicate: 
•  How do they address the concerns outlined in the Methodology and checklist items? -OR- How do they protect against or mitigate vulnerability types associated with this activity?
•  Did we identify any vulnerabilities in this activity? If so, what are the weaknesses in their system?
•  What are the patterns (strengths and/or weaknesses), if any, that emerge from the set of findings against this activity?
•  Do not include recommendations.

Sentence templates:
•\tAll findings, no strengths: [The system] has [vulnerability/trend] in [component/method/part of the system]
•\tNo findings: [The system] securely achieves [activity name/aspect] by means of [summary of whatever they did right].
•\tSome findings, some strengths: [The system] securely achieves [activity name/aspect] by means of [summary of whatever they did right]. However, the system also has [vulnerability/trend] in [component/method/part of the system].
•\tIf the test was impossible, use this field to describe why.`;

interface IProps {
  control: Control<IActivityFields>;
}

export const ActivityObservations = ({ control }: IProps) => (
  <Controller
    control={control}
    name="Disposition"
    render={({ field: { value, onChange } }) => (
      <KrakenTextArea
        id={kebabCase(EAddEditActivityLinks.OBSERVATIONS)}
        title="Observations"
        value={value || ''}
        onChange={(val) => textAreaHandler(val, onChange)}
        placeholder={observationsPlaceholder}
        height={180}
        hideToolbar
      />
    )}
  />
);
