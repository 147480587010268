export enum EFTLinks {
  NAME = 'Name',
  TITLE = 'Title',
  IMPACT = 'Impact',
  DIFFICULTY = 'Difficulty',
  DESCRIPTION = 'Description',
  OBSERVATION = 'Observation',
  RECOMMENDATION = 'Recommendation',
  REFERENCE = 'Reference',
  NOTES_TO_PENTESTER = 'Notes to Pentester',
}

export interface IFindingTemplateFields {
  Name: string | null;
  Description: string | null;
  Reference: string | null;
  Recommendation: string | null;
  Observation: string | null;
  Title: string | null;
  Notes: string | null;
  Impact: string | null;
  ImpactRationale: string | null;
  Difficulty: string | null;
  DifficultyRationale: string | null;
  ShippableRecommendation: boolean;
}
