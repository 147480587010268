import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { EReviewRoles, EReviewType } from '@src/types/types';
import {
  GetApiProjectsByPidRolesApiResponse,
  useGetApiProjectsByPidRolesQuery,
} from '@store/services/query.generated';

const getReviewType = (roles: GetApiProjectsByPidRolesApiResponse) => (roles.some(
    (role) => role.RoleId?.toUpperCase() === EReviewRoles.TECHNICAL_LEAD,
  )
    ? EReviewType.TECH_LEAD
    : roles.some(
        (role) => role.RoleId?.toUpperCase() === EReviewRoles.TECHNICAL_REVIEWER,
      )
    ? EReviewType.EDITORIAL
    : roles.some(
        (role) => role.RoleId?.toUpperCase() === EReviewRoles.PROJECT_MANAGER,
      )
    ? EReviewType.PROJECT_MANAGER
    : EReviewType.PEER);

export const useIsReviewType = () => {
  const { pid } = useParams();
  const [reviewType, setReviewType] = useState(EReviewType.PEER);

  const { data: roles } = useGetApiProjectsByPidRolesQuery(
    { pid: pid! },
    { skip: !pid },
  );

  useEffect(() => {
    if (roles) {
      setReviewType(getReviewType(roles));
    }
  }, [roles]);

  return reviewType;
};
