import { Controller } from 'react-hook-form';
import { Control } from 'react-hook-form/dist/types';

import { Input } from '@components/Input/Input';
import {
  EAddProjectLinks,
  IProjectFields,
} from '@pages/Projects/AddEditProject/types';
import { kebabCase } from '@utils/kebabCase';

interface IProps {
  control: Control<IProjectFields>;
}

export const ProjectComponent = ({ control }: IProps) => (
  <Controller
    control={control}
    name="component"
    render={({ field: { value, onChange, ref } }) => (
      <Input
        id={kebabCase(EAddProjectLinks.COMPONENT)}
        label="Component name*"
        value={value}
        inputRef={ref}
        onChange={onChange}
      />
    )}
  />
);
