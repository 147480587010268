import { Controller } from 'react-hook-form';
import { Control } from 'react-hook-form/dist/types';
import { Checkbox, FormControlLabel } from '@mui/material';

import { IFinalReportFields } from '../types';

interface IProps {
  control: Control<IFinalReportFields>;
}

const styles = {
  sx: {
    '[data-theme="dark"] &': {
      color: '#fff',
    },
  },
};

export const FRAddPolicyProcedure = ({ control }: IProps) => (
  <Controller
    control={control}
    name="AddPolicyProcedure"
    render={({ field: { value, onChange } }) => (
      <FormControlLabel
        control={
          <Checkbox checked={value || false} onClick={() => onChange(!value)} />
        }
        label="Add Policy & Procedure Review"
        sx={styles.sx}
      />
    )}
  />
);
