import { useEffect, useState } from 'react';
import { LinearProgress } from '@mui/material';

import { KrakenDialog } from '@components/KrakenDialog/KrakenDialog';
import { fetchOptions } from '@src/constants/constants';
import { BASE_URL } from '@src/constants/environment';
import { useTypedSelector } from '@src/utils';
import { downloadFile } from '@utils/downloadFile';
import { useActions } from '@utils/hooks/useActions';

import IMG from './img/downloading_img.png';

import s from './DownloadPDF.module.sass';

export const DownloadFile = () => {
  const [extension, setExtension] = useState('');
  const { hideDownloadFilePopup, showError } = useActions();

  const {
 visible, method, payload, uri, fileName,
} = useTypedSelector(
    (state) => state.downloadFileReducer,
  );

  const onClose = () => hideDownloadFilePopup();

  useEffect(() => {
    if (fileName) {
      const ext = fileName.substring(fileName.lastIndexOf('.') + 1);
      setExtension(ext || fileName);
    }
  }, [fileName]);

  useEffect(() => {
    (async () => {
      const options: any = {
        method,
        ...(payload ? { body: JSON.stringify(payload) } : {}),
        ...fetchOptions,
      };

      options.headers['Content-Type'] = 'application/json';

      fetch(BASE_URL + uri, options)
        .then((response: any) => {
          if (!response.ok) throw new Error('!response.ok');

          return response && response.blob();
        })
        .then((blob) => {
          if (Object.prototype.toString.call(blob) !== '[object Blob]') throw new Error('blob must be');
          blob && downloadFile(blob, fileName);
        })
        .catch(showError)
        .finally(() => onClose());
    })();
  }, []);

  return (
    <KrakenDialog onClose={onClose} visible={visible} maxWidth="xs">
      <div className={s.wrapper}>
        <img src={IMG} alt={`Converting to ${extension.toUpperCase()}...`} />
        <h3 className={s.title}>
          {`Converting to ${extension.toUpperCase()}...`}
        </h3>
        <p>Keep page open to convert report faster</p>
        <LinearProgress sx={{ width: 270 }} />
      </div>
    </KrakenDialog>
  );
};
