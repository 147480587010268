import { useTheme } from '@mui/material';

import { KrakenBackendApiModelsActivitiesApiActivityChecklistItem } from '@store/services/query.generated';

interface IProps {
  checklist:
    | KrakenBackendApiModelsActivitiesApiActivityChecklistItem[]
    | null
    | undefined;
}

export const ActivityChecklist = ({ checklist }: IProps) => {
  const {
    palette: { mode },
  } = useTheme();
  const checked = checklist?.filter(
      ({ status }) => status === 'NotAvailable' || status === 'Tested',
    ).length || 0;
  const fill = checked === checklist?.length;
  const lightBackground = !checklist?.length || !fill ? '#F8F8F8' : '#E8FBF5';
  const lightColor = !checklist?.length || !fill ? '#717786' : '#12B17E';
  const darkBackground = !checklist?.length || !fill ? '#3c414f' : '#2E313B';
  const darkColor = !checklist?.length || !fill ? '#727b91' : '#717786';

  const styles = {
    box: {
      display: 'flex',
      width: '88px',
      height: '50px',
      justifyContent: 'center',
      alignItems: 'center',
      fontWeight: 700,
      fontSize: '14px',
      background: mode === 'light' ? lightBackground : darkBackground,
      color: mode === 'light' ? lightColor : darkColor,
      borderRadius: '4px',
    },
  };

  return (
    <div style={styles.box}>{`${checked} of ${checklist?.length || 0}`}</div>
  );
};
