import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { LinearProgress } from '@mui/material';

import { EmptyList } from '@components/EmptyList/EmptyList';
import { AddEditFinding } from '@pages/Project/Findings/AddEditFinding/AddEditFinding';
import { LinkEnum } from '@src/types/types';
import {
  useGetApiActivitiesProjectByPidQuery,
  useGetApiComponentsByPidQuery,
  useGetApiFindingsProjectByPidFallbackQuery,
} from '@store/services/query.generated';
import { usePage } from '@utils/hooks/usePage';

import { FindingList } from './FindingList/FindingList';
import { FindingsFooter } from './FindingsFooter/FindingsFooter';

const pages = ['add-finding', 'edit-finding'];

export const Findings = () => {
  const { page } = usePage(pages);
  const [readyToAdd, setReadyToAdd] = useState(false);
  const [tooltipText, setTooltipText] = useState('');
  const { pid } = useParams();
  const navigate = useNavigate();

  const { data = [], isLoading } = useGetApiFindingsProjectByPidFallbackQuery({
    pid: pid!,
  });
  const { data: components } = useGetApiComponentsByPidQuery({ pid: pid! });
  const { data: activities } = useGetApiActivitiesProjectByPidQuery({
    pid: pid!,
  });

  useEffect(() => {
    if (components && components.length && activities && activities.length) {
      setReadyToAdd(true);
    } else {
      setReadyToAdd(false);
      if (!components && !activities) {
        setTooltipText('Add the activities and components first');
      } else if (!activities) {
        setTooltipText('Add the activities first');
      } else if (!components) {
        setTooltipText('Add the components first');
      }
    }
  }, [components, activities]);

  return page ? (
    <AddEditFinding />
  ) : (
    <div>
      {isLoading && <LinearProgress />}
      {!isLoading && !data.length && (
        <EmptyList
          handler={() => navigate(LinkEnum.ADD_FINDING.replace(':pid', pid!), {
              state: { linkTemplate: LinkEnum.ADD_FINDING },
            })}
          listName="finding"
          disabled={!readyToAdd}
          tooltip={tooltipText}
        />
      )}
      {!!data.length && (
        <>
          <FindingList findings={data} />
          <FindingsFooter disabled={!readyToAdd} tooltip={tooltipText} />
        </>
      )}
    </div>
  );
};
