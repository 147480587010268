import { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';

import { IStereotypeTemplateFields } from '@pages/Admin/StereotypesTemplate/AddEditStereotypeTemplate/types';
import { yupRequiredString } from '@src/constants/constants';
import { LinkEnum } from '@src/types/types';
import {
  useGetApiStereotypesBySidQuery,
  usePostApiStereotypesMutation,
  usePutApiStereotypesBySidMutation,
} from '@store/services/query.generated';

const schema = yup.object().shape({
  Area: yupRequiredString,
  SurfaceName: yupRequiredString,
  Description: yupRequiredString,
});
export const useStereotypeTemplate = () => {
  const [readyToSubmit, setReadyToSubmit] = useState(false);
  const { sid } = useParams();
  const navigate = useNavigate();
  const {
    control,
    watch,
    handleSubmit,
    reset,
    formState: { isDirty, isValid },
    setFocus,
  } = useForm<IStereotypeTemplateFields>({
    mode: 'onChange',
    resolver: yupResolver(schema),
  });

  const { data: stereotypeTemplate, isLoading: isSTLoading } = useGetApiStereotypesBySidQuery({ sid: sid! }, { skip: !sid });
  const [postStereotype, { isLoading: isPostLoading }] = usePostApiStereotypesMutation();
  const [putStereotype, { isLoading: isPutLoading }] = usePutApiStereotypesBySidMutation();

  const onSubmit: SubmitHandler<IStereotypeTemplateFields> = (data) => {
    const krakenBackendApiModelsStereotypesApiStereotype = {
      Area: data.Area,
      SurfaceName: data.SurfaceName,
      Description: data.Description,
      ThreatAnalysis: data.ThreatAnalysis,
    };

    if (sid) {
      putStereotype({
        sid,
        krakenBackendApiModelsStereotypesApiStereotype,
      });
    } else {
      postStereotype({
        krakenBackendApiModelsStereotypesApiStereotype,
      }).then((resp: any) => {
        resp?.data
          ? navigate(
              LinkEnum.EDIT_STEREOTYPE_TEMPLATE.replace(':sid', resp?.data),
            )
          : navigate(LinkEnum.STEREOTYPE_TEMPLATE);
      });
    }
  };

  useEffect(() => {
    setFocus('SurfaceName');
  }, []);

  useEffect(() => {
    if (stereotypeTemplate) {
      reset({
        Area: stereotypeTemplate.Area || null,
        SurfaceName: stereotypeTemplate.SurfaceName || null,
        Description: stereotypeTemplate.Description || null,
        ThreatAnalysis: stereotypeTemplate.ThreatAnalysis || null,
      });
    }
  }, [stereotypeTemplate]);

  useEffect(() => {
    setReadyToSubmit(isDirty && isValid);
  }, [isDirty, isValid]);

  return {
    sid,
    control,
    watch,
    handleSubmit,
    readyToSubmit,
    onSubmit,
    isLoading: isSTLoading || isPostLoading || isPutLoading,
  };
};
