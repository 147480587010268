import { Controller } from 'react-hook-form';
import { Control } from 'react-hook-form/dist/types';

import { Datepicker } from '@components/Datepicker/Datepicker';

import { IProjectFields } from '../types';

interface IProps {
  control: Control<IProjectFields>;
}

const getValue = (
  dates: (Date | null) | [Date | null, Date | null],
  type: string,
) => {
  if (Array.isArray(dates)) {
    const [start, end] = dates;

    return type === 'startDate' ? start : end;
  }

  return null;
};

export const ProjectDate = ({ control }: IProps) => (
  <Controller
    control={control}
    name="date"
    render={({ field: { value, onChange } }) => (
      <Datepicker
        label="Choose start & end date*"
        startDate={getValue(value, 'startDate')}
        endDate={getValue(value, 'endDate')}
        selectsRange
        onChange={onChange}
      />
    )}
  />
);
