import { parseISO } from 'date-fns';
import { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';

import {
  yupBoolean,
  yupRequiredDateRange,
  yupRequiredString,
  yupString,
} from '@src/constants/constants';
import { AWS_ID } from '@src/constants/environment';
import { LinkEnum } from '@src/types/types';
import { useTypedSelector } from '@src/utils';
import { endpoints } from '@store/services/customApiEndpoints';
import {
  useGetApiProjectsByPidQuery,
  usePostApiComponentsByPidMutation,
  usePostApiProjectsMutation,
  usePutApiProjectsByPidMutation,
} from '@store/services/query.generated';
import { b64toBlob } from '@utils/b64toBlob';
import { blobToBase64 } from '@utils/blobToBase64';
import { isValidDate, toLocalDate } from '@utils/dateUtils';
import { useActions } from '@utils/hooks/useActions';

import { IProjectFields } from './types';

const requiredSchema: any = {
  prName: yupRequiredString,
  coID: yupRequiredString,
  date: yupRequiredDateRange,
  external_project_link: yupString,
  regression: yupBoolean,
  rating_justifications: yupBoolean,
  severity_override: yupBoolean,
  google_restricted_scope: yupBoolean,
  testproject: yupBoolean,
  isMasa: yupBoolean,
  notes: yupString,
  logo: yupString,
};

export const useAddEditProject = () => {
  const [readyToSubmit, setReadyToSubmit] = useState(false);
  const { pid } = useParams();
  !pid && (requiredSchema.component = yupRequiredString);
  const schema = yup.object().shape(requiredSchema);
  const [isAWS, setIsAWS] = useState(false);
  const navigate = useNavigate();
  const {
    control,
    reset,
    handleSubmit,
    formState: {
 isDirty, isValid, dirtyFields, isSubmitted,
},
    setFocus,
    watch,
    setValue,
    getValues,
  } = useForm<IProjectFields>({
    mode: 'onChange',
    resolver: yupResolver(schema),
  });
  const companyId = watch('coID');
  const { resetAddProject, setCompaniesPopup, setConfirmationPopup } = useActions();

  const { showConfirmationPopup, showCompaniesPopup } = useTypedSelector(
    (state) => state.addProjectReducer,
  );

  const { data: project } = useGetApiProjectsByPidQuery(
    { pid: pid! },
    { skip: !pid },
  );
  const [createProject] = usePostApiProjectsMutation();
  const [updateProject] = usePutApiProjectsByPidMutation();
  const [postComponent] = usePostApiComponentsByPidMutation();

  const onSubmit: SubmitHandler<IProjectFields> = async ({
    coID,
    prName,
    external_project_link,
    notes,
    logo,
    regression,
    rating_justifications,
    severity_override,
    google_restricted_scope,
    testproject,
    isMasa,
    date,
    component,
  }) => {
    const krakenModelsDtoProjectDto: any = {
      active: true,
      coID,
      prName,
      mavenlinkID: null,
      ...(external_project_link ? { external_project_link } : {}),
      ...(notes ? { notes } : {}),
      ...(regression ? { regression } : {}),
      ...(rating_justifications ? { rating_justifications } : {}),
      ...(severity_override ? { severity_override } : {}),
      ...(google_restricted_scope ? { google_restricted_scope } : {}),
      ...(testproject ? { testproject } : {}),
      ...(isMasa ? { isMasa } : {}),
    };

    const [startDate, endDate] = date;

    if (startDate && isValidDate(startDate as Date)) {
      krakenModelsDtoProjectDto.startDate = toLocalDate(startDate as Date);
    }
    if (endDate && isValidDate(endDate as Date)) {
      krakenModelsDtoProjectDto.endDate = toLocalDate(endDate as Date);
    }

    if (pid) {
      updateProject({ pid, krakenModelsDtoProjectDto });
      if ('logo' in dirtyFields) {
        const blobLogo: Blob = await b64toBlob(logo);

        await endpoints.project.patchLogo(pid, blobLogo);
      }
    } else {
      createProject({
        krakenModelsDtoProjectDto,
      }).then(async (result: any) => {
        if (result?.data) {
          try {
            if (component) {
              postComponent({
                pid: result.data,
                krakenBackendApiModelsProjectComponentsApiProjectComponent: {
                  name: component,
                },
              });
            }
            if (logo) {
              const blobLogo: Blob = await b64toBlob(logo);

              await endpoints.project.patchLogo(result?.data, blobLogo);
            }
          } finally {
            navigate(LinkEnum.EDIT_PROJECT.replace(':pid', result.data));
          }
        }
      });
    }
  };

  const closeCompaniesPopup = () => setCompaniesPopup(false);

  const hideConfirmationPopup = () => {
    setConfirmationPopup(false);
  };

  useEffect(() => {
    setIsAWS(companyId?.toLowerCase() === AWS_ID.toLowerCase());
  }, [companyId]);

  useEffect(() => {
    if (isAWS) {
      setValue('severity_override', false);
      setValue('rating_justifications', true);
    }
  }, [isAWS]);

  useEffect(() => {
    if (project) {
      reset({
        prName: project.prName || '',
        coID: project.coID || '',
        date: [
          parseISO(project?.startDate as string) || null,
          parseISO(project?.endDate as string) || null,
        ],
        external_project_link: project.external_project_link || '',
        regression: project.regression || false,
        rating_justifications: isAWS || project.rating_justifications || false,
        severity_override: isAWS ? false : project.severity_override || false,
        google_restricted_scope: project.google_restricted_scope || false,
        testproject: project.testproject || false,
        notes: project.notes || '',
      });
    }
  }, [project]);

  useEffect(() => {
    if (pid) {
      endpoints.project.getLogo(pid).then(async (result) => {
        const logoBase64: any = await blobToBase64(await result.blob());

        reset({
          ...getValues(),
          logo: logoBase64,
        });
      });
    }
  }, [pid, isSubmitted]);

  useEffect(() => {
    setReadyToSubmit(isDirty && isValid);
  }, [isDirty, isValid]);

  useEffect(() => {
    setFocus('prName');
  }, [setFocus]);

  useEffect(
    () => () => {
      resetAddProject();
    },
    [],
  );

  return {
    control,
    handleSubmit,
    onSubmit,
    readyToSubmit,
    hideConfirmationPopup,
    closeCompaniesPopup,
    showConfirmationPopup,
    showCompaniesPopup,
    watch,
    isAWS,
  };
};
