// @ts-ignore
// eslint-disable-next-line import/extensions
import Cvss from './cvss/cvss.js';
import { ICvssMetric } from './types';

const deepCopy = (object: any) => JSON.parse(JSON.stringify(object));

export const vectorString = 'CVSS:3.1/AV:X/AC:X/PR:X/UI:X/S:X/C:X/I:X/A:X/E:X/RL:X/RC:X/CR:X/IR:X/AR:X/MAV:X/MAC:X/MPR:X/MUI:X/MS:X/MC:X/MI:X/MA:X';

const baseScore = Object.freeze({
  metricName: 'Base Score',
  metrics: [
    {
      title: 'Attack Vector (AV)',
      buttons: [
        {
          buttonName: 'Network (N)',
          parent: 'AV',
          code: 'N',
          checked: false,
        },
        {
          buttonName: 'Adjacent (A)',
          parent: 'AV',
          code: 'A',
          checked: false,
        },
        {
          buttonName: 'Local (L)',
          parent: 'AV',
          code: 'L',
          checked: false,
        },
        {
          buttonName: 'Physical (P)',
          parent: 'AV',
          code: 'P',
          checked: false,
        },
      ],
      metricCode: 'AV',
    },
    {
      title: 'Attack Complexity (AC)',
      buttons: [
        {
          buttonName: 'Low (L)',
          parent: 'AC',
          code: 'L',
          checked: false,
        },
        {
          buttonName: 'High (H)',
          parent: 'AC',
          code: 'H',
          checked: false,
        },
      ],
      metricCode: 'AC',
    },
    {
      title: 'Privileges Required (PR)',
      buttons: [
        {
          buttonName: 'None (N)',
          parent: 'PR',
          code: 'N',
          checked: false,
        },
        {
          buttonName: 'Low (L)',
          parent: 'PR',
          code: 'L',
          checked: false,
        },
        {
          buttonName: 'High (H)',
          parent: 'PR',
          code: 'H',
          checked: false,
        },
      ],
      metricCode: 'PR',
    },
    {
      title: 'User Interaction (UI)',
      buttons: [
        {
          buttonName: 'None (N)',
          parent: 'UI',
          code: 'N',
          checked: false,
        },
        {
          buttonName: 'Required (R)',
          parent: 'UI',
          code: 'R',
          checked: false,
        },
      ],
      metricCode: 'UI',
    },
    {
      title: 'Scope (S)',
      buttons: [
        {
          buttonName: 'Unchanged (U)',
          parent: 'S',
          code: 'U',
          checked: false,
        },
        {
          buttonName: 'Changed (C)',
          parent: 'S',
          code: 'C',
          checked: false,
        },
      ],
      metricCode: 'S',
    },
    {
      title: 'Confidentiality (C)',
      buttons: [
        {
          buttonName: 'None (N)',
          parent: 'C',
          code: 'N',
          checked: false,
        },
        {
          buttonName: 'Low (L)',
          parent: 'C',
          code: 'L',
          checked: false,
        },
        {
          buttonName: 'High (H)',
          parent: 'C',
          code: 'H',
          checked: false,
        },
      ],
      metricCode: 'C',
    },
    {
      title: 'Integrity (I)',
      buttons: [
        {
          buttonName: 'None (N)',
          parent: 'I',
          code: 'N',
          checked: false,
        },
        {
          buttonName: 'Low (L)',
          parent: 'I',
          code: 'L',
          checked: false,
        },
        {
          buttonName: 'High (H)',
          parent: 'I',
          code: 'H',
          checked: false,
        },
      ],
      metricCode: 'I',
    },
    {
      title: 'Availability (A)',
      buttons: [
        {
          buttonName: 'None (N)',
          parent: 'A',
          code: 'N',
          checked: false,
        },
        {
          buttonName: 'Low (L)',
          parent: 'A',
          code: 'L',
          checked: false,
        },
        {
          buttonName: 'High (H)',
          parent: 'A',
          code: 'H',
          checked: false,
        },
      ],
      metricCode: 'A',
    },
  ],
  score: 0,
});
const temporalScore = Object.freeze({
  metricName: 'Temporal Score',
  metrics: [
    {
      title: 'Exploit Code Maturity (E)',
      buttons: [
        {
          buttonName: 'Not Defined (X)',
          parent: 'E',
          code: 'X',
          checked: false,
        },
        {
          buttonName: 'Unproven (U)',
          parent: 'E',
          code: 'U',
          checked: false,
        },
        {
          buttonName: 'Proof-of-Concept (P)',
          parent: 'E',
          code: 'P',
          checked: false,
        },
        {
          buttonName: 'Functional (F)',
          parent: 'E',
          code: 'F',
          checked: false,
        },
        {
          buttonName: 'High (H)',
          parent: 'E',
          code: 'H',
          checked: false,
        },
      ],
      metricCode: 'E',
    },
    {
      title: 'Remediation Level (RL)',
      buttons: [
        {
          buttonName: 'Not Defined (X)',
          parent: 'RL',
          code: 'X',
          checked: false,
        },
        {
          buttonName: 'Official Fix (O)',
          parent: 'RL',
          code: 'O',
          checked: false,
        },
        {
          buttonName: 'Temporary Fix (T)',
          parent: 'RL',
          code: 'T',
          checked: false,
        },
        {
          buttonName: 'Workaround (W)',
          parent: 'RL',
          code: 'W',
          checked: false,
        },
        {
          buttonName: 'Unavailable (U)',
          parent: 'RL',
          code: 'U',
          checked: false,
        },
      ],
      metricCode: 'RL',
    },
    {
      title: 'Report Confidence (RC)',
      buttons: [
        {
          buttonName: 'Not Defined (X)',
          parent: 'RC',
          code: 'X',
          checked: false,
        },
        {
          buttonName: 'Unknown (U)',
          parent: 'RC',
          code: 'U',
          checked: false,
        },
        {
          buttonName: 'Reasonable (R)',
          parent: 'RC',
          code: 'R',
          checked: false,
        },
        {
          buttonName: 'Confirmed (C)',
          parent: 'RC',
          code: 'C',
          checked: false,
        },
      ],
      metricCode: 'RC',
    },
  ],
  score: 0,
});
const environmentalScore = Object.freeze({
  metricName: 'Environmental Score',
  metrics: [
    {
      title: 'Confidentiality Requirement (CR)',
      buttons: [
        {
          buttonName: 'Not Defined (X)',
          parent: 'CR',
          code: 'X',
          checked: false,
        },
        {
          buttonName: 'Low (L)',
          parent: 'CR',
          code: 'L',
          checked: false,
        },
        {
          buttonName: 'Medium (M)',
          parent: 'CR',
          code: 'M',
          checked: false,
        },
        {
          buttonName: 'High (H)',
          parent: 'CR',
          code: 'H',
          checked: false,
        },
      ],
      metricCode: 'CR',
    },
    {
      title: 'Integrity Requirement (IR)',
      buttons: [
        {
          buttonName: 'Not Defined (X)',
          parent: 'IR',
          code: 'X',
          checked: false,
        },
        {
          buttonName: 'Low (L)',
          parent: 'IR',
          code: 'L',
          checked: false,
        },
        {
          buttonName: 'Medium (M)',
          parent: 'IR',
          code: 'M',
          checked: false,
        },
        {
          buttonName: 'High (H)',
          parent: 'IR',
          code: 'H',
          checked: false,
        },
      ],
      metricCode: 'IR',
    },
    {
      title: 'Availability Requirement (AR)',
      buttons: [
        {
          buttonName: 'Not Defined (X)',
          parent: 'AR',
          code: 'X',
          checked: false,
        },
        {
          buttonName: 'Low (L)',
          parent: 'AR',
          code: 'L',
          checked: false,
        },
        {
          buttonName: 'Medium (M)',
          parent: 'AR',
          code: 'M',
          checked: false,
        },
        {
          buttonName: 'High (H)',
          parent: 'AR',
          code: 'H',
          checked: false,
        },
      ],
      metricCode: 'AR',
    },
    {
      title: 'Modified Attack Vector (MAV)',
      buttons: [
        {
          buttonName: 'Not Defined (X)',
          parent: 'MAV',
          code: 'X',
          checked: false,
        },
        {
          buttonName: 'Network',
          parent: 'MAV',
          code: 'N',
          checked: false,
        },
        {
          buttonName: 'Adjacent Network',
          parent: 'MAV',
          code: 'A',
          checked: false,
        },
        {
          buttonName: 'Local',
          parent: 'MAV',
          code: 'L',
          checked: false,
        },
        {
          buttonName: 'Physical',
          parent: 'MAV',
          code: 'P',
          checked: false,
        },
      ],
      metricCode: 'MAV',
    },
    {
      title: 'Modified Attack Complexity (MAC)',
      buttons: [
        {
          buttonName: 'Not Defined (X)',
          parent: 'MAC',
          code: 'X',
          checked: false,
        },
        {
          buttonName: 'Low',
          parent: 'MAC',
          code: 'L',
          checked: false,
        },
        {
          buttonName: 'High',
          parent: 'MAC',
          code: 'H',
          checked: false,
        },
      ],
      metricCode: 'MAC',
    },
    {
      title: 'Modified Privileges Required (MPR)',
      buttons: [
        {
          buttonName: 'Not Defined (X)',
          parent: 'MPR',
          code: 'X',
          checked: false,
        },
        {
          buttonName: 'None',
          parent: 'MAC',
          code: 'N',
          checked: false,
        },
        {
          buttonName: 'Low',
          parent: 'MAC',
          code: 'L',
          checked: false,
        },
        {
          buttonName: 'High',
          parent: 'MAC',
          code: 'H',
          checked: false,
        },
      ],
      metricCode: 'MPR',
    },
    {
      title: 'Modified User Interaction (MUI)',
      buttons: [
        {
          buttonName: 'Not Defined (X)',
          parent: 'MUI',
          code: 'X',
          checked: false,
        },
        {
          buttonName: 'None',
          parent: 'MUI',
          code: 'N',
          checked: false,
        },
        {
          buttonName: 'Required',
          parent: 'MUI',
          code: 'R',
          checked: false,
        },
      ],
      metricCode: 'MUI',
    },
    {
      title: 'Modified Scope (MS)',
      buttons: [
        {
          buttonName: 'Not Defined (X)',
          parent: 'MS',
          code: 'X',
          checked: false,
        },
        {
          buttonName: 'Unchanged',
          parent: 'MS',
          code: 'U',
          checked: false,
        },
        {
          buttonName: 'Changed',
          parent: 'MS',
          code: 'C',
          checked: false,
        },
      ],
      metricCode: 'MS',
    },
    {
      title: 'Modified Confidentiality (MC)',
      buttons: [
        {
          buttonName: 'Not Defined (X)',
          parent: 'MC',
          code: 'X',
          checked: false,
        },
        {
          buttonName: 'None',
          parent: 'MC',
          code: 'N',
          checked: false,
        },
        {
          buttonName: 'Low',
          parent: 'MC',
          code: 'L',
          checked: false,
        },
        {
          buttonName: 'High',
          parent: 'MC',
          code: 'H',
          checked: false,
        },
      ],
      metricCode: 'MC',
    },
    {
      title: 'Modified Integrity (MI)',
      buttons: [
        {
          buttonName: 'Not Defined (X)',
          parent: 'MI',
          code: 'X',
          checked: false,
        },
        {
          buttonName: 'None',
          parent: 'MI',
          code: 'N',
          checked: false,
        },
        {
          buttonName: 'Low',
          parent: 'MI',
          code: 'L',
          checked: false,
        },
        {
          buttonName: 'High',
          parent: 'MI',
          code: 'H',
          checked: false,
        },
      ],
      metricCode: 'MI',
    },
    {
      title: 'Modified Availability (MA)',
      buttons: [
        {
          buttonName: 'Not Defined (X)',
          parent: 'MA',
          code: 'X',
          checked: false,
        },
        {
          buttonName: 'None',
          parent: 'MA',
          code: 'N',
          checked: false,
        },
        {
          buttonName: 'Low',
          parent: 'MA',
          code: 'L',
          checked: false,
        },
        {
          buttonName: 'High',
          parent: 'MA',
          code: 'H',
          checked: false,
        },
      ],
      metricCode: 'MA',
    },
  ],
  score: 0,
});

export const templateCvss: Readonly<ICvssMetric[]> = [
  baseScore,
  temporalScore,
  environmentalScore,
];

export const cvssTemplate = (
  cvss: string | null | undefined = vectorString,
) => {
  const cloneTemplateCvss = deepCopy(templateCvss) as ICvssMetric[];

  if (cvss) {
    const c = new Cvss(cvss);
    const vectorArray = cvss.split('/');

    vectorArray.splice(0, 1);

    const filteredVector = vectorArray
      .map((item) => item.split(':'))
      .filter((item) => item[1] !== 'X');

    cloneTemplateCvss[0].score = c.getBaseScore();
    cloneTemplateCvss[1].score = c.getTemporalScore();
    cloneTemplateCvss[2].score = c.getEnvironmentalScore();

    cloneTemplateCvss.forEach((item) => {
      item.metrics.forEach(({ metricCode, buttons }) => {
        filteredVector.forEach(([parent, code]) => {
          if (metricCode === parent) {
            buttons.forEach((btn) => {
              btn.checked = btn.code === code;
            });
          }
        });
      });
    });
  }

  return cloneTemplateCvss;
};
