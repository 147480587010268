import { IPublicClientApplication } from '@azure/msal-browser';
import { InteractionRequiredAuthError } from '@azure/msal-common';

import { loginRequest } from '@src/config/authConfig';
import { handleLogout } from '@src/utils';

export const ifNotAuthenticated = async (
  instance: IPublicClientApplication,
) => {
  try {
    const response = await instance.ssoSilent(loginRequest);

    instance.setActiveAccount(response.account);
  } catch (err) {
    if (err instanceof InteractionRequiredAuthError) {
      await instance.loginRedirect(loginRequest);
    } else {
      handleLogout(instance);
    }
  }
};
