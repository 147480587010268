import { Controller } from 'react-hook-form';
import { Control } from 'react-hook-form/dist/types';
import { FormControl, useTheme } from '@mui/material';

import { KrakenTextArea } from '@components/KrakenTextArea/KrakenTextArea';
import { RadioButtons } from '@components/RadioButtons/RadioButtons';
import { textAreaHandler } from '@utils/textAreaHandler';

import { difficultyList, impactList } from '../constants';
import { IFindingFields } from '../types';

interface IProps {
  control: Control<IFindingFields>;
}

const styles: any = {
  wrapper: { display: 'flex', gap: '8px' },
};

export const FindingMitigated = ({ control }: IProps) => {
  const {
    palette: { mode },
  } = useTheme();
  styles.header = { color: mode === 'light' ? '#000' : '#9ba1aa' };

  return (
    <>
      <div style={styles.wrapper}>
        <FormControl variant="standard" fullWidth>
          <h3 style={styles.header}>Residual Impact*</h3>
          <Controller
            control={control}
            name="residualImpact"
            render={({ field: { value, onChange } }) => (
              <RadioButtons
                list={Object.keys(impactList)}
                checked={value || 'Info'}
                onChange={(e) => onChange(e.target.value)}
              />
            )}
          />
        </FormControl>
        <FormControl variant="standard" fullWidth>
          <h3 style={styles.header}>Residual Difficulty*</h3>
          <Controller
            control={control}
            name="residualDifficulty"
            render={({ field: { value, onChange } }) => (
              <RadioButtons
                list={Object.keys(difficultyList)}
                checked={value || 'Simple'}
                onChange={(e) => onChange(e.target.value)}
              />
            )}
          />
        </FormControl>
      </div>
      <Controller
        control={control}
        name="mitigation"
        render={({ field: { value, onChange } }) => (
          <KrakenTextArea
            title="Mitigation*"
            value={value || ''}
            onChange={(val) => textAreaHandler(val, onChange)}
            placeholder="How did the customer mitigate the issue?"
            height={180}
            hideToolbar
          />
        )}
      />
    </>
  );
};
