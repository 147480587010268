import { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';

import { yupRequiredString } from '@src/constants/constants';
import { LinkEnum } from '@src/types/types';
import {
  useGetApiActivityTemplatesByAtidQuery,
  usePostApiActivityTemplatesMutation,
  usePutApiActivityTemplatesByAtidMutation,
} from '@store/services/query.generated';

import { IActivityTemplateFields } from './types';

const schema = yup.object().shape({
  Subject: yupRequiredString,
});

export const useAddEditActivity = () => {
  const [readyToSubmit, setReadyToSubmit] = useState(false);
  const { atid } = useParams();
  const {
    control,
    watch,
    handleSubmit,
    reset,
    formState: { isDirty, isValid },
    setFocus,
  } = useForm<IActivityTemplateFields>({
    mode: 'onChange',
    resolver: yupResolver(schema),
  });
  const navigate = useNavigate();

  const { data: activityTemplate, isLoading: templateIsLoading } = useGetApiActivityTemplatesByAtidQuery({ atid: atid! }, { skip: !atid });
  const [postActivityTemplates] = usePostApiActivityTemplatesMutation();
  const [putActivityTemplates] = usePutApiActivityTemplatesByAtidMutation();

  const onSubmit: SubmitHandler<IActivityTemplateFields> = (data) => {
    const payload = {
      Subject: data.Subject,
      Description: data.Description,
      Scope: data.Scope,
      Notes: data.Notes,
    };

    if (atid) {
      putActivityTemplates({
        atid,
        krakenBackendApiModelsActivityTemplatesApiActivityTemplate: payload,
      });
    } else {
      postActivityTemplates({
        krakenBackendApiModelsActivityTemplatesApiActivityTemplateWithChecklist:
          payload,
      }).then((resp: any) => {
        resp?.data
          && navigate(LinkEnum.EDIT_ACTIVITY_TEMPLATE.replace(':atid', resp.data));
      });
    }
  };

  useEffect(() => {
    setFocus('Subject');
  }, []);

  useEffect(() => {
    if (activityTemplate) {
      reset({
        Subject: activityTemplate.Subject,
        Description: activityTemplate.Description,
        Scope: activityTemplate.Scope,
        Notes: activityTemplate.Notes,
      });
    }
  }, [activityTemplate]);

  useEffect(() => {
    setReadyToSubmit(isDirty && isValid);
  }, [isDirty, isValid]);

  return {
    atid,
    control,
    onSubmit,
    isLoading: templateIsLoading,
    watch,
    handleSubmit,
    readyToSubmit,
  };
};
