import { useNavigate, useParams } from 'react-router-dom';
import { Button, Tooltip } from '@mui/material';

import { Footer } from '@components/Footer/Footer';
import { LinkEnum } from '@src/types/types';

interface IProps {
  disabled: boolean;
  tooltip: string;
}

export const FindingsFooter = ({ disabled, tooltip }: IProps) => {
  const { pid } = useParams();
  const navigate = useNavigate();

  const onClick = () => navigate(LinkEnum.ADD_FINDING.replace(':pid', pid!), {
      state: { linkTemplate: LinkEnum.ADD_FINDING },
    });

  return (
    <Footer>
      {!disabled ? (
        <Button color="primary" size="small" onClick={onClick}>
          Add finding
        </Button>
      ) : (
        <Tooltip title={tooltip}>
          <Button
            color="warning"
            size="small"
            disabled
            component="div"
            sx={{
              pointerEvents: 'auto !important',
            }}
            onClick={undefined}
          >
            Add finding
          </Button>
        </Tooltip>
      )}
    </Footer>
  );
};
