import IMG from './img/bubble-gum-downloading.png';

import s from '../EmptyList/EmptyList.module.sass';

export const KrakenNothingFound = () => (
  <div className={s.emptyWrapper}>
    <img src={IMG} alt="Nothing found" />
    <span className={s.title}>Nothing found</span>
    <p>You don’t have any deleted elements.</p>
  </div>
);
