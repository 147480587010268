import { Link } from 'react-router-dom';
import { Button, Tooltip } from '@mui/material';

import NO_PROJECT from '@src/assets/image/handy-startup-business-idea.gif';
import { LinkEnum } from '@src/types/types';

import styles from './EmptyList.module.sass';

interface IProps {
  listName: string;
  to?: string | LinkEnum;
  handler?: () => void;
  disabled?: boolean;
  tooltip?: string;
}

export const EmptyList = ({
  to,
  handler,
  listName,
  disabled = false,
  tooltip = '',
}: IProps) => (
  <div className={styles.emptyWrapper}>
    <img src={NO_PROJECT} alt={`no ${listName.toLowerCase()}`} />
    <span className={styles.title}>{`Add new ${listName.toLowerCase()}`}</span>
    <p>
      {`You don’t have any active ${listName.toLowerCase()} here. Let’s`}
      <br />
      start and create new
    </p>
    {disabled ? (
      <Tooltip title={tooltip}>
        <Button
          color="warning"
          size="small"
          disabled
          component="div"
          sx={{
            pointerEvents: 'auto !important',
          }}
          onClick={undefined}
        >
          Add finding
        </Button>
      </Tooltip>
    ) : (
      <Button
        color="primary"
        size="small"
        {...(to
          ? {
              component: Link,
              to,
            }
          : {})}
        {...(handler
          ? {
              onClick: handler,
            }
          : {})}
      >
        {`Add new ${listName.toLowerCase()}`}
      </Button>
    )}
  </div>
);
