import { format, parseISO } from 'date-fns';
import { enUS } from 'date-fns/locale';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useGetApiProjectsByPidQuery } from '@store/services/query.generated';

const observationsText = `- WHAT WERE THE RESULTS OF OUR OBJECTIVES? DID WE ACHIEVE THEM? IF NOT, WHY WERE THEY SECURE? WHAT IS THEIR LEVEL OF EXPOSURE TODAY?

- MAJOR POSITIVE AND NEGATIVE TRENDS (VULNERABILITY TREND ANALYSIS)

- SUMMARY OF CRITICAL- AND HIGH-SEVERITY FINDINGS (OR LOWER, IF CRITICAL OR HIGH DO NOT EXIST.)

- HOW DOES COMPANY ALIGN WITH ITS PEERS?

- (OPTIONAL - SHOULD BE FORMATTED IN TABLE) BULLETED LIST OF STRENGTHS AND WEAKNESSES`;

const recommendationsText = `- PROVIDE HIGH-LEVEL ACTIONABLE RECOMMENDATIONS FOR ANY OF THE MAJOR TRENDS

- SDL TEMPLATED LANGUAGE (IF NEEDED)

- ONGOING THREAT MODELING AND ANNUAL SECURITY REVIEWS WHEN NEW MAJOR FUNCTIONALITY IS INTRODUCED

- REMAINING VULNERABILITIES THAT NEED TO BE ADDRESSED (STACK RANKED)

- SOURCE CODE REVIEW / WHITE-BOX ACTIVITIES (IF BLACK BOX)`;

export const useWritingPrompts = () => {
  const [scopePrefix, setScopePrefix] = useState('');
  const [scopePostfix, setScopePostfix] = useState('');
  const [objectiveText, setObjectiveText] = useState('');
  const { pid } = useParams();

  const { data: project } = useGetApiProjectsByPidQuery(
    { pid: pid! },
    { skip: !pid },
  );

  useEffect(() => {
    if (project) {
      const {
 coName, prName, startDate, endDate,
} = project;

      const start = format(parseISO(startDate as string), 'LLLL dd', {
        locale: enUS,
      });
      const end = format(parseISO(endDate as string), 'LLLL dd', {
        locale: enUS,
      });

      setScopePrefix(
        `${coName} engaged Leviathan Security to perform a time-bound security assessment of`,
      );
      setScopePostfix(
        `We performed this assessment from ${start} until ${end}`,
      );
      setObjectiveText(`Our objectives were to review ${prName} to OBJECTIVES DISCUSSED IN PRESALES "EXECUTIVE QUESTIONS"/SOW/EXTERNAL ALIGNMENT. Testing was informed through
            A (Black-Box). the development team providing a quick explanation on the functionality and use cases of the service. We were provided with test credentials to access the server where testing was performed. The advantage of this approach is that it simulates a real-world adversary to some extent. However, this methodology provides a lower level of confidence around holistic security coverage, as real-world adversaries will often have unlimited time; this is colloquially known as black-box methodology.
            B (White-Box). highly detailed discussions with the project team on the application's design, security controls, and other implementation details. We were provided with source code, application design diagrams, a set of test credentials for each application role, previous threat models or security reviews, and other resources to give us the greatest advantage in identifying major weaknesses within the service; this is colloquially known as white-box methodology.
            C (Gray-Box). a detailed discussion with the project team around application functionality, its technology stack, and some of the security considerations. We were provided with design diagrams, a set of test credentials for each application role, and other documentation that provides our consultants with higher understanding of the service to reduce knowledge ramp-up; this is colloquially known as gray-box methodology.`);
    }
  }, [project]);

  return {
    scopePrefix,
    scopePostfix,
    observationsText,
    recommendationsText,
    objectiveText,
  };
};
