import { useNavigate } from 'react-router-dom';
import { LinearProgress } from '@mui/material';

import { EmptyList } from '@components/EmptyList/EmptyList';
import { CompaniesFooter } from '@pages/Admin/Companies/CompaniesFooter/CompaniesFooter';
import { CompaniesList } from '@pages/Admin/Companies/CompaniesList/CompaniesList';
import { xcsrf } from '@src/constants/constants';
import { LinkEnum } from '@src/types/types';
import { useGetApiCompaniesQuery } from '@store/services/query.generated';
import { usePage } from '@utils/hooks/usePage';

import { AddEditCompany } from './AddEditCompanies/AddEditCompanies';

import s from './Companies.module.sass';

const pages = ['add-company', 'edit-company'];

export const Companies = () => {
  const { page } = usePage(pages);
  const { data = [], isLoading } = useGetApiCompaniesQuery(xcsrf, {
    skip: !!page,
  });
  const navigate = useNavigate();

  return page ? (
    <AddEditCompany />
  ) : (
    <div>
      {isLoading && <LinearProgress />}
      {!isLoading && !data.length && (
        <EmptyList
          listName="company"
          handler={() => navigate(LinkEnum.ADD_COMPANY, {
              state: { linkTemplate: LinkEnum.ADD_COMPANY },
            })}
        />
      )}
      {!!data.length && (
        <div className={s.wrapper}>
          {data && <CompaniesList companies={data} />}
          <CompaniesFooter />
        </div>
      )}
    </div>
  );
};
