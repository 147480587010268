import s from './KrakenNavBar.module.sass';

interface IProps {
  list: string[];
  gridTemplateColumns: string;
}

export const KrakenNavBar = ({ list, gridTemplateColumns }: IProps) => {
  const styles = {
    gridTemplateColumns,
  };
  return (
    <div className={s.wrapper} style={styles}>
      {list.map((item) => (
        <span key={item}>{item}</span>
      ))}
    </div>
  );
};
