import { Button, InputLabel } from '@mui/material';

import { Input } from '@components/Input/Input';
import { KrakenDialog } from '@components/KrakenDialog/KrakenDialog';
import { useActivityChecklist } from '@pages/Project/Activities/AddEditActivity/ActivityChecklist/useActivityChecklist';
import { kebabCase } from '@utils/kebabCase';

import { EAddEditActivityLinks } from '../types';

import { ListItem } from './ListItem';

import s from './ActivityChecklist.module.sass';

export const ActivityChecklist = () => {
  const {
    onAddChecklist,
    checklist,
    showPopup,
    footer,
    input,
    onInput,
    onClose,
  } = useActivityChecklist();

  return (
    <div className={s.wrapper}>
      <div id={kebabCase(EAddEditActivityLinks.CHECKLIST)}>
        <InputLabel shrink htmlFor="checklist">
          Checklist
        </InputLabel>
        <Button size="small" onClick={onAddChecklist} className={s.addBtn}>
          Add checklist item
        </Button>
      </div>
      <div className={s.checklistWrapper}>
        {checklist.length
          ? checklist?.map((item) => <ListItem key={item.acid} data={item} />)
          : null}
      </div>
      {showPopup && (
        <KrakenDialog
          footer={footer}
          onClose={onClose}
          visible={showPopup}
          title="Add Checklist Item"
        >
          <Input
            value={input}
            onChange={onInput}
            sx={{ marginBottom: '-24px' }}
          />
        </KrakenDialog>
      )}
    </div>
  );
};
