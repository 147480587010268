import { useNavigate, useParams } from 'react-router-dom';
import { Button } from '@mui/material';

import { Footer } from '@components/Footer/Footer';
import Icon from '@components/Icon/Icon';
import { LinkEnum } from '@src/types/types';

interface IProps {
  readyToSubmit: boolean;
}

export const ComponentFooter = ({ readyToSubmit }: IProps) => {
  const navigate = useNavigate();
  const { pid } = useParams();

  return (
    <Footer>
      <Button
        color="secondary"
        size="small"
        onClick={() => navigate(LinkEnum.COMPONENTS.replace(':pid', pid!))}
      >
        Back
      </Button>
      <Button
        color="primary"
        size="small"
        startIcon={<Icon iconName="save" />}
        disabled={!readyToSubmit}
        type="submit"
      >
        Save component
      </Button>
    </Footer>
  );
};
