import cn from 'classnames';
import { memo } from 'react';

import { KrakenLayout } from '@src/layouts/KrakenLayout/KrakenLayout';

import { accepted, mitigated, remedied } from './constants';
import { FindingAccepted } from './FindingAccepted';
import { FindingActivities } from './FindingActivities';
import { FindingAWSCategory } from './FindingAWSCategory';
import { FindingBreadCrumbs } from './FindingBreadCrumbs';
import { FindingChecklist } from './FindingChecklist';
import { FindingComponent } from './FindingComponent';
import { FindingCvss } from './FindingCVSS';
import { FindingDescription } from './FindingDescription';
import { FindingDifficulty } from './FindingDifficulty';
import { FindingFooter } from './FindingFooter';
import { FindingHistory } from './FindingHistory';
import { FindingImages } from './FindingImages';
import { FindingImpact } from './FindingImpact';
import { FindingInternalNotes } from './FindingInternalNotes';
import { FindingLastChanges } from './FindingLastChanges';
import { FindingLocation } from './FindingLocation';
import { FindingMitigated } from './FindingMitigated';
import { FindingName } from './FindingName';
import { FindingNotification } from './FindingNotification';
import { FindingObservation } from './FindingObservation';
import { FindingRecommendation } from './FindingRecommendation';
import { FindingReferences } from './FindingReferences';
import { FindingRemedied } from './FindingRemedied';
import { FindingSetDataFromTemplateBtn } from './FindingSetDataFromTemplateBtn';
import { FindingSeverity, OverridableFindingSeverity } from './FindingSeverity';
import { FindingStatus } from './FindingStatus';
import { FindingTemplate } from './FindingTemplate';
import { useAddEditFinding } from './useAddEditFinding';
import { useSubscription } from './useSubscription';

import s from './AddEditFindings.module.sass';

export const AddEditFinding = memo(() => {
  const {
    fid,
    prName,
    isLoading,
    severity_override,
    isAWS,
    readyToSubmit,
    visibleBtn,
    onSubmit,
    control,
    watch,
    statusId,
    setTemplate,
    setDataFromTemplate,
    setValue,
    getValues,
  } = useAddEditFinding();
  const { findingNotificationType } = useSubscription(setValue, getValues);

  return (
    <KrakenLayout
      wrapperClass={cn(s.addEditFindings, {
        [s.blocked]: !!findingNotificationType,
      })}
      breadCrumbs={<FindingBreadCrumbs watch={watch} />}
      checkList={<FindingChecklist watch={watch} />}
      footer={<FindingFooter readyToSubmit={readyToSubmit} watch={watch} />}
      onSubmit={onSubmit}
      isLoading={isLoading}
      needConnection
    >
      <div className={s.wrapper}>
        <FindingLastChanges />
        <h3 className={s.prName}>{prName}</h3>
        <FindingTemplate control={control} setTemplate={setTemplate} />
        <FindingName control={control} />
        <div className={s.twoColumns}>
          <FindingComponent control={control} />
          <FindingActivities
            control={control}
            watch={watch}
            setValue={setValue}
          />
        </div>
        {visibleBtn && (
          <FindingSetDataFromTemplateBtn
            setDataFromTemplate={setDataFromTemplate}
          />
        )}
        <FindingImpact control={control} />
        <FindingDifficulty control={control} />
        <div className={s.twoColumns}>
          <FindingStatus control={control} />
          {isAWS && <FindingAWSCategory control={control} />}
          {severity_override ? (
            <OverridableFindingSeverity watch={watch} control={control} />
          ) : (
            <FindingSeverity watch={watch} />
          )}
        </div>
        {statusId === accepted ? (
          <FindingAccepted control={control} />
        ) : statusId === mitigated ? (
          <FindingMitigated control={control} />
        ) : statusId === remedied ? (
          <FindingRemedied control={control} />
        ) : null}
        <FindingCvss control={control} watch={watch} setValue={setValue} />
        <FindingDescription control={control} />
        <FindingObservation control={control} />
        <FindingRecommendation control={control} />
        <div className={s.twoColumns}>
          <FindingLocation control={control} />
          <FindingReferences control={control} />
        </div>
        {fid && <FindingImages />}
        <FindingInternalNotes control={control} />
        {fid && <FindingHistory fid={fid} />}
        {findingNotificationType && <FindingNotification />}
      </div>
    </KrakenLayout>
  );
});
