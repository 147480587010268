import { CSSProperties, useEffect, useState } from 'react';
import { FormControlLabel } from '@mui/material';

import { ESort, IColumn } from '../type';

import s from '../KrakenTable.module.sass';

interface IProps<T> {
  column: IColumn<T>;
  onSort: any;
  outerChecked: boolean;
}

const normal = (
  <>
    <path d="M4 0L8 4H0L4 0Z" fill="#6A707E" />
    <path d="M4 11L0 7H8L4 11Z" fill="#6A707E" />
  </>
);
const ask = <path d="M4 11L0 7H8L4 11Z" fill="#6A707E" />;
const desc = <path d="M4 0L8 4H0L4 0Z" fill="#6A707E" />;

const sx = {
  sort: {
    justifyContent: 'flex-end',
    margin: 0,
    gap: '12px',
    '& .MuiTypography-root': {
      fontWeight: 700,
      fontSize: '12px',
      lineHeight: '12px',
      letterSpacing: '0.5px',
      textTransform: 'uppercase',
      color: '#6A707E',
      userSelect: 'none',
      '[data-theme="dark"] &': {
        color: '#fff',
      },
    },
  },
};

export function KrakenTableSort<T>({
  column,
  onSort,
  outerChecked = true,
}: IProps<T>) {
  const [isNeutral, setIsNeutral] = useState(true);
  const [isSort, setIsSort] = useState(false);

  const isNormal = isNeutral && !isSort;
  const isAsk = !isNeutral && isSort;
  const isDesc = !isNeutral && !isSort;

  const arrows = isNormal || outerChecked ? normal : isAsk ? ask : isDesc && desc;

  const onChange = () => {
    if (isNeutral && !isSort) {
      // if neutral then sort AZ
      setIsNeutral(false);
      setIsSort(true);
      onSort(ESort.ASK, column);
    } else if (!isNeutral && isSort) {
      // if sort AZ then sort ZA
      setIsNeutral(false);
      setIsSort(false);
      onSort(ESort.DESC, column);
    } else if (!isNeutral && !isSort) {
      // if sort ZA then neutral
      setIsNeutral(true);
      setIsSort(false);
      onSort(ESort.NORMAL, column);
    }
  };

  useEffect(() => {
    if (outerChecked) {
      setIsNeutral(true);
      setIsSort(false);
    }
  }, [outerChecked]);

  return (
    <FormControlLabel
      label={column.headerName}
      labelPlacement="start"
      sx={sx.sort as CSSProperties}
      id={column.headerName.replace(' ', '')}
      control={(
        <button type="button" onClick={onChange} className={s.arrows}>
          <svg
            width="8"
            height="11"
            viewBox="0 0 8 11"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            {arrows}
          </svg>
        </button>
      )}
    />
  );
}
