import { BreadCrumbs } from '@components/BreadCrumbs/BreadCrumbs';
import { STLastChanges } from '@pages/Admin/StereotypesTemplate/AddEditStereotypeTemplate/STLastChanges';
import { useStereotypeTemplate } from '@pages/Admin/StereotypesTemplate/AddEditStereotypeTemplate/useStereotypeTemplate';
import { KrakenLayout } from '@src/layouts/KrakenLayout/KrakenLayout';
import { LinkEnum } from '@src/types/types';

import { Checklist } from './Checklist';
import { STArea } from './STArea';
import { STAssociatedActivityTemplates } from './STAssociatedActivityTemplates';
import { STDescription } from './STDescription';
import { STFooter } from './STFooter';
import { STSurfaceName } from './STSurfaceName';
import { STThreatAnalysis } from './STThreatAnalysis';

import s from '../index.module.sass';

const crumbs = [['Stereotype Templates', LinkEnum.STEREOTYPE_TEMPLATE]];

export const AddEditStereotypeTemplate = () => {
  const {
    sid,
    control,
    watch,
    handleSubmit,
    readyToSubmit,
    onSubmit,
    isLoading,
  } = useStereotypeTemplate();

  return (
    <KrakenLayout
      wrapperClass={s.addEditStereotypeTemplate}
      checkList={<Checklist watch={watch} />}
      footer={<STFooter readyToSubmit={readyToSubmit} />}
      breadCrumbs={
        <BreadCrumbs crumbs={crumbs} currentPage={watch('SurfaceName')} />
      }
      onSubmit={handleSubmit(onSubmit)}
      isLoading={isLoading}
    >
      <STLastChanges />
      <STSurfaceName control={control} />
      <STArea control={control} />
      <STDescription control={control} />
      <STThreatAnalysis control={control} />
      {sid && <STAssociatedActivityTemplates />}
    </KrakenLayout>
  );
};
