import { Controller, UseFormWatch } from 'react-hook-form';
import { Control } from 'react-hook-form/dist/types';
import { UseFormSetValue } from 'react-hook-form/dist/types/form';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Collapse,
  Tooltip,
  Typography,
} from '@mui/material';

import Icon from '@components/Icon/Icon';
import { Input } from '@components/Input/Input';
import { kebabCase } from '@utils/kebabCase';

import { EAddEditFindingLinks, IFindingFields } from '../types';

import { vectorString } from './constants';
import { FindingCvssRow } from './FindingCVSSRow';
import { FindingCwe } from './FindingCwe';
import { ICvssMetrics } from './types';
import { useFindingCvss } from './useFindingCvss';

import s from './FindingCVSS.module.sass';

interface IProps {
  control: Control<IFindingFields>;
  watch: UseFormWatch<IFindingFields>;
  setValue: UseFormSetValue<IFindingFields>;
}

export const FindingCvss = ({ control, watch, setValue }: IProps) => {
  const {
    onBtn,
    visible,
    fid,
    finding,
    onReset,
    onClear,
    template,
    expanded,
    handleChange,
    onClick,
    open,
    onCopy,
  } = useFindingCvss(watch, setValue);

  return (
    <>
      <div className={s.cvssBtnWrapper}>
        <Button className={s.cvssBtn} onClick={onBtn}>
          CVSS Scoring
        </Button>
        <FindingCwe control={control} />
      </div>
      <Collapse in={visible}>
        <Controller
          control={control}
          name="cvss"
          render={({ field: { value, onChange } }) => (
            <>
              <Tooltip title="Copied" placement="bottom" open={open}>
                <Button
                  variant="text"
                  onClick={onCopy}
                  endIcon={<Icon iconName="copy" className={s.copy} />}
                >
                  Vector sting
                </Button>
              </Tooltip>
              {fid && finding?.cvss && (
                <Button
                  onClick={onReset}
                  variant="text"
                  disabled={finding.cvss === value}
                >
                  Reset
                </Button>
              )}
              <Button
                onClick={onClear}
                variant="text"
                disabled={value === vectorString}
              >
                Clear
              </Button>
              <Input
                id={kebabCase(EAddEditFindingLinks.CVSS)}
                value={value || vectorString}
                readOnly
              />
              <div className={s.cvssWrapper}>
                {template.map(({ metricName, metrics, score }, index) => (
                  <Accordion
                    key={metricName}
                    expanded={expanded === `panel${index + 1}`}
                    onChange={handleChange(`panel${index + 1}`)}
                  >
                    <AccordionSummary
                      aria-controls={`panel${index + 1}d-content`}
                      id={`panel${index + 1}d-header`}
                    >
                      <Typography sx={{ width: '100%' }}>
                        <span className={s.metricNameWrapper}>
                          <span className={s.metricName}>{metricName}</span>
                          <span className={s.score}>
                            Score:
                            {score}
                          </span>
                        </span>
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      {metrics.map(({ title, buttons }: ICvssMetrics) => (
                        <FindingCvssRow
                          key={title}
                          title={title}
                          buttons={buttons}
                          onClick={(val) => onClick(val, onChange, value)}
                        />
                      ))}
                    </AccordionDetails>
                  </Accordion>
                ))}
              </div>
            </>
          )}
        />
      </Collapse>
    </>
  );
};
