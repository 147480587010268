import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IFindingsByUser {
  humanId: string;
  startDate: string | null;
  endDate: string | null;
  projectId: string;
  risk: string;
  showPopup: boolean;
}

const initialState: IFindingsByUser = {
  humanId: '',
  startDate: null,
  endDate: null,
  projectId: '',
  risk: '',
  showPopup: false,
};

const findingsByUserSlice = createSlice({
  name: 'findingsByUser',
  initialState,
  reducers: {
    updateFBU(
      state: IFindingsByUser,
      {
        payload: { field, value },
      }: PayloadAction<{ field: string; value: any }>,
    ) {
      switch (field) {
        case 'humanId':
          state.humanId = value;
          break;
        case 'startDate':
          state.startDate = value;
          break;
        case 'endDate':
          state.endDate = value;
          break;
        case 'risk':
          state.risk = value;
          break;
        case 'projectId':
          state.projectId = value;
          break;
        case 'showPopup':
          state.showPopup = value;
          break;
        default:
          return state;
      }
    },
    resetFBU: () => initialState,
  },
});

const { reducer, actions } = findingsByUserSlice;

export default reducer;
export const { updateFBU, resetFBU } = actions;
