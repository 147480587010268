import cn from 'classnames';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button, MenuItem, SelectChangeEvent } from '@mui/material';

import { Datepicker } from '@components/Datepicker/Datepicker';
import { KrakenDialog } from '@components/KrakenDialog/KrakenDialog';
import { KrakenSelect } from '@components/KrakenSelect/KrakenSelect';
import { reverseDateFormat, Roles, xcsrf } from '@src/constants/constants';
import {
  useDeleteApiProjectsByPidRolesAndHidRidMutation,
  useGetApiHumansEmployeesQuery,
  usePostApiProjectsByPidTemporaryAccessAndHidMutation,
  usePutApiProjectsByPidRolesMutation,
} from '@store/services/query.generated';
import { getDate } from '@utils/dateUtils';

import s from './Users.module.sass';

interface IProps {
  visible: boolean;
  onClose: () => void;
  edit?: 'both' | 'full' | 'temp';
  hid?: string;
  rid?: string;
}

export const AddEditUser = ({
  visible,
  onClose,
  edit = 'both',
  hid = '',
  rid = '',
}: IProps) => {
  const [name, setName] = useState(hid?.toLowerCase());
  const [role, setRole] = useState(rid?.toUpperCase());
  const [isFull, setIsFull] = useState(edit !== 'temp');
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [readyToSubmit, setReadyToSubmit] = useState<boolean>(false);
  const { pid } = useParams();

  const { data: employees = [], isLoading } = useGetApiHumansEmployeesQuery(xcsrf);
  const [sendTempAccess] = usePostApiProjectsByPidTemporaryAccessAndHidMutation();
  const [editRole] = usePutApiProjectsByPidRolesMutation();
  const [deleteUser] = useDeleteApiProjectsByPidRolesAndHidRidMutation();

  const onFull = () => setIsFull(true);
  const onTemp = () => setIsFull(false);
  const onName = ({ target: { value } }: SelectChangeEvent<any>) => setName(value);
  const onRole = ({ target: { value } }: SelectChangeEvent<any>) => setRole(value);
  const onDate = (dates: (Date | null) | [Date | null, Date | null]) => {
    if (Array.isArray(dates)) {
      const [start, end] = dates;

      setStartDate(start);
      setEndDate(end);
    }
  };
  const onSend = async () => {
    if (!readyToSubmit) return;

    const onFinally = () => {
      onClose();
    };

    if (edit === 'both') {
      isFull
        ? editRole({
            pid: pid!,
            hid: name,
            rid: role,
          }).finally(onFinally)
        : sendTempAccess({
            pid: pid!,
            hid: name,
            notBefore: getDate(reverseDateFormat, startDate!),
            notAfter: getDate(reverseDateFormat, endDate!)!,
          }).finally(onFinally);
    } else if (edit === 'full') {
      editRole({
        pid: pid!,
        hid: name,
        rid: role,
      }).then(() => {
        deleteUser({
          pid: pid!,
          hid: name,
          rid: rid?.toUpperCase(),
        }).finally(onFinally);
      });
    }
  };

  useEffect(() => {
    setReadyToSubmit(
      isFull
        ? !!name.length && !!role.length
        : !!name.length && !!startDate && !!endDate,
    );
  }, [name, role, isFull, startDate, endDate]);

  useEffect(
    () => () => {
      setName('');
      setRole('');
      setStartDate(null);
      setEndDate(null);
    },
    [],
  );

  const footer = (
    <Button onClick={onSend} disabled={!readyToSubmit}>
      {edit !== 'both' ? 'Edit User' : 'Send Invite'}
    </Button>
  );

  return (
    <KrakenDialog
      visible={visible}
      onClose={onClose}
      title={`${edit !== 'both' ? 'Edit' : 'Add new'} user`}
      footer={footer}
    >
      <div className={cn(s.tabs, { [s.full]: isFull })}>
        <Button
          variant="text"
          onClick={onFull}
          style={{ pointerEvents: edit === 'temp' ? 'none' : 'auto' }}
        >
          Full access
        </Button>
        <Button
          variant="text"
          onClick={onTemp}
          style={{ pointerEvents: edit === 'full' ? 'none' : 'auto' }}
        >
          Temporary access grants
        </Button>
        <div className={s.line} />
      </div>
      <div className={cn(s.tabPanel, { [s.full]: isFull })}>
        <KrakenSelect
          label="User*"
          value={name}
          onChange={onName}
          isLoading={isLoading}
          inputProps={{ readOnly: edit !== 'both' }}
        >
          {employees?.map(({ HumanId, Fname, Lname }) => (
            <MenuItem key={HumanId} value={HumanId}>
              {`${Fname} ${Lname}`}
            </MenuItem>
          ))}
        </KrakenSelect>
        {isFull ? (
          <KrakenSelect label="Role*" value={role} onChange={onRole}>
            {Object.entries(Roles).map(([roleName, id]) => (
              <MenuItem key={id} value={id}>
                {roleName}
              </MenuItem>
            ))}
          </KrakenSelect>
        ) : (
          <Datepicker
            label="Choose start & end date*"
            startDate={startDate}
            endDate={endDate}
            selectsRange
            popperPlacement="top"
            onChange={onDate}
          />
        )}
      </div>
    </KrakenDialog>
  );
};
