import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

export const usePage = (pages: string[]) => {
  const [page, setPage] = useState<string | null>(null);
  const { pathname } = useLocation();

  useEffect(() => {
    const dividedPath = pathname.split('/');
    const last = dividedPath[dividedPath.length - 1];
    const penultimate = dividedPath[dividedPath.length - 2];

    setPage(
      pages.includes(last)
        ? last
        : pages.includes(penultimate)
        ? penultimate
        : null,
    );
  }, [pathname]);

  return { page };
};
