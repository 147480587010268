import { createSlice } from '@reduxjs/toolkit';

interface IComponents {
  rerender: boolean;
}

const initialState: IComponents = {
  rerender: true,
};

const components = createSlice({
  name: 'components',
  initialState,
  reducers: {
    rerenderComponents: (state: IComponents) => {
      state.rerender = !state.rerender;
    },
  },
});

const { reducer, actions } = components;

export default reducer;
export const { rerenderComponents } = actions;
