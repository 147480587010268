import { Controller } from 'react-hook-form';
import { Control } from 'react-hook-form/dist/types';

import { Input } from '@components/Input/Input';
import { kebabCase } from '@utils/kebabCase';

import { EAddProjectLinks, IProjectFields } from '../types';

interface IProps {
  control: Control<IProjectFields>;
}

export const ProjectName = ({ control }: IProps) => (
  <Controller
    control={control}
    name="prName"
    render={({ field: { value, onChange, ref } }) => (
      <Input
        id={kebabCase(EAddProjectLinks.PROJECT_NAME)}
        label="Project name*"
        value={value}
        onChange={onChange}
        inputRef={ref}
        info="Do not include company name"
      />
    )}
  />
);
