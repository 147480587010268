import { useNavigate, useParams } from 'react-router-dom';

import { ERoles } from '@src/constants/constants';
import { LinkEnum } from '@src/types/types';
import {
  useDeleteApiProjectsByPidMutation,
  useGetApiProjectsByPidQuery,
  usePutApiProjectsByPidUndeleteMutation,
} from '@store/services/query.generated';
import { useActions } from '@utils/hooks/useActions';
import { useIsInRole } from '@utils/hooks/useIsInRole';

export const useProjectFooter = () => {
  const { isInRole: isAdmin } = useIsInRole(ERoles.admin);
  const { pid } = useParams();
  const navigate = useNavigate();
  const { setConfirmationPopup } = useActions();

  const { data: project } = useGetApiProjectsByPidQuery(
    { pid: pid! },
    { skip: !pid },
  );
  const [deactivate] = useDeleteApiProjectsByPidMutation();
  const [activate] = usePutApiProjectsByPidUndeleteMutation();

  const onDelete = () => {
    setConfirmationPopup(true);
  };

  const onDeactivate = () => {
    pid && deactivate({ pid }).then(() => navigate(LinkEnum.PROJECTS));
  };

  const onActivate = () => {
    pid && activate({ pid });
  };

  return {
    pid,
    isAdmin,
    active: project?.active,
    onDelete,
    onDeactivate,
    onActivate,
  };
};
