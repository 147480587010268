import {
 ReactElement, ReactNode, useEffect, useState,
} from 'react';

import { KrakenTableBody } from '@components/KrakenTable/KrakenTableBody/KrakenTableBody';
import { KrakenTableHead } from '@components/KrakenTable/KrakenTableHead/KrakenTableHead';
import { IColumn } from '@components/KrakenTable/type';

import s from './KrakenTable.module.sass';

interface IProps<T> {
  tableData: T[];
  columns: IColumn<T>[];
  gridTemplateColumns: string;
  rowTemplate: (row: T) => ReactNode | ReactElement;
}

export function KrakenTable<T>({
  tableData,
  columns,
  rowTemplate,
  gridTemplateColumns,
}: IProps<T>) {
  const [rows, setRows] = useState(tableData);

  useEffect(() => {
    tableData
      && setRows(
        [...tableData].map((item, index) => ({
          ...item,
          sortIndex: index,
        })),
      );
  }, [tableData]);

  return (
    <div className={s.krakenTable}>
      <KrakenTableHead<T>
        data={rows}
        columns={columns}
        setTableData={setRows}
        gridTemplateColumns={gridTemplateColumns}
      />
      <KrakenTableBody<T> data={rows} rowTemplate={rowTemplate} />
    </div>
  );
}
