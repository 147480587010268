import { KrakenLayout } from '@src/layouts/KrakenLayout/KrakenLayout';

import { ESChecklist } from './ESChecklist';
import { ESDate } from './ESDate';
import { ESFooter } from './ESFooter';
import { ESFutureWork } from './ESFutureWork';
import { ESHistory } from './ESHistory';
import { ESNotes } from './ESNotes';
import { ESObjectives } from './ESObjectives';
import { ESObservations } from './ESObservations';
import { ESRecommendations } from './ESRecommendations';
import { ESScope } from './ESScope';
import { useExecutiveSummary } from './useExecutiveSummary';

export const ExecutiveSummary = () => {
  const {
 control, handleSubmit, onSubmit, watch, isLoading, readyToSubmit,
} = useExecutiveSummary();

  return (
    <KrakenLayout
      checkList={<ESChecklist watch={watch} />}
      footer={<ESFooter readyToSubmit={readyToSubmit} />}
      onSubmit={handleSubmit(onSubmit)}
      isLoading={isLoading}
    >
      <ESScope control={control} />
      <ESDate control={control} />
      <ESObjectives control={control} />
      <ESObservations control={control} />
      <ESRecommendations control={control} />
      <ESFutureWork />
      <ESNotes control={control} />
      <ESHistory />
    </KrakenLayout>
  );
};
