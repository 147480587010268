import { Controller, UseFormWatch } from 'react-hook-form';
import { Control } from 'react-hook-form/dist/types';
import { UseFormSetValue } from 'react-hook-form/dist/types/form';
import { MenuItem, Skeleton } from '@mui/material';

import { KrakenSelect } from '@components/KrakenSelect/KrakenSelect';
import { kebabCase } from '@utils/kebabCase';

import { EAddEditFindingLinks, IFindingFields } from '../types';

import { UseFindingActivities } from './useFindingActivities';

const skeletonStyle = { flexGrow: 1, height: 44, marginTop: '20px' };

interface IProps {
  control: Control<IFindingFields>;
  watch: UseFormWatch<IFindingFields>;
  setValue: UseFormSetValue<IFindingFields>;
}

export const FindingActivities = ({ control, watch, setValue }: IProps) => {
  const {
 isLoading, activityList, componentId, getValue,
} = UseFindingActivities(watch, setValue);

  return (
    <>
      {isLoading && !activityList?.length && (
        <Skeleton animation="wave" variant="rectangular" sx={skeletonStyle} />
      )}
      <Controller
        control={control}
        name="associatedActivities"
        render={({ field: { value, onChange } }) => (
          <KrakenSelect
            id={kebabCase(EAddEditFindingLinks.ACTIVITIES)}
            label="Activities"
            value={getValue(value)}
            onChange={(e) => onChange(e.target.value)}
            multiple
            disabled={!componentId}
          >
            {activityList?.map(({ id, Subject }) => (
              <MenuItem key={id} value={id}>
                {Subject}
              </MenuItem>
            ))}
          </KrakenSelect>
        )}
      />
    </>
  );
};
