import { OptionsObject, SnackbarKey, useSnackbar } from 'notistack';
import React, {
 useEffect, useMemo, useRef, useState,
} from 'react';
import { UseFormWatch } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from '@mui/material';

import { ERoles } from '@src/constants/constants';
import { EReviewOutcome, EReviewType, LinkEnum } from '@src/types/types';
import {
  KrakenModelsEnumsReviewOutcome,
  KrakenModelsEnumsReviewType,
  useDeleteApiFindingsByFidMutation,
  useGetApiProjectsByPidQuery,
  useGetApiV2FindingsByFidQuery,
  useGetApiV2ReviewsLastReviewByFidQuery,
  usePostApiReviewsFindingByFidMutation,
  usePutApiV2FindingsByFidMutation,
} from '@store/services/query.generated';
import { useIsInRole } from '@utils/hooks/useIsInRole';
import { useIsReviewType } from '@utils/hooks/useIsReviewType';

import {
  difficultyList,
  impactList,
  mitigated,
  requiredStatusId,
  retracted,
  underInvestigation,
} from '../constants';
import { vectorString } from '../FindingCVSS/constants';
import { IFindingFields } from '../types';

export const useFindingActions = (watch: UseFormWatch<IFindingFields>) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [notReadyForReview, setNotReadyForReview] = useState(false);
  const [tooltipText, setTooltipText] = useState('');
  const reviewType = useIsReviewType();
  const payloadRef = useRef<any>();
  const open = Boolean(anchorEl);
  const { pid, fid } = useParams();
  const { isInRole: isAdmin } = useIsInRole(ERoles.admin);
  const navigate = useNavigate();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const { data: project } = useGetApiProjectsByPidQuery(
    { pid: pid! },
    { skip: !pid },
  );
  const { data: finding }: any = useGetApiV2FindingsByFidQuery(
    { fid: fid! },
    { skip: !pid },
  );
  const { data: lastReview } = useGetApiV2ReviewsLastReviewByFidQuery(
    { fid: fid! },
    { skip: !fid },
  );
  const [postReviews] = usePostApiReviewsFindingByFidMutation();
  const [putFinding] = usePutApiV2FindingsByFidMutation();
  const [deleteFinding] = useDeleteApiFindingsByFidMutation();

  const action = (key: SnackbarKey) => (
    <Button color="primary" size="small" onClick={() => closeSnackbar(key)}>
      Hide
    </Button>
  );

  const options: OptionsObject = {
    variant: 'success',
    action,
    persist: true, // auto-hide disabled to keep Snackbars in the view indefinitely
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const onNeedsWork = () => {
    const krakenModelsDtoFindingWriteDto = { ...payloadRef.current };

    putFinding({
      fid: fid!,
      krakenModelsDtoFindingWriteDto,
    });

    postReviews({
      fid: fid!,
      outcome:
        EReviewOutcome.NEEDS_WORK as unknown as KrakenModelsEnumsReviewOutcome,
    }).then(() => {
      enqueueSnackbar('Finding marked as Needs work', options);
    });
  };
  const onReviewed = () => {
    const krakenModelsDtoFindingWriteDto = { ...payloadRef.current };

    putFinding({
      fid: fid!,
      krakenModelsDtoFindingWriteDto,
    });

    postReviews({
      fid: fid!,
      outcome: EReviewOutcome.OK as unknown as KrakenModelsEnumsReviewOutcome,
      ...(reviewType !== EReviewType.PEER
        ? { type: reviewType as unknown as KrakenModelsEnumsReviewType }
        : {}),
    }).then(() => {
      enqueueSnackbar(
        `Finding ${
          reviewType === EReviewType.PEER ? 'is ready for review' : 'reviewed'
        }`,
      );
    });
  };
  const onUpdate = () => {
    const krakenModelsDtoFindingWriteDto = { ...payloadRef.current };

    putFinding({
      fid: fid!,
      krakenModelsDtoFindingWriteDto,
      materialChange: true,
    }).then(() => {
      enqueueSnackbar('Finding marked as updated');
    });
  };
  const onRetract = () => {
    const krakenModelsDtoFindingWriteDto = { ...payloadRef.current };

    krakenModelsDtoFindingWriteDto.statusId = retracted;

    putFinding({
      fid: fid!,
      krakenModelsDtoFindingWriteDto,
    }).then(() => {
      enqueueSnackbar('Finding retracted');
    });
  };
  const onOversight = () => {
    postReviews({
      fid: fid!,
      outcome: EReviewOutcome.OK as unknown as KrakenModelsEnumsReviewOutcome,
      type: EReviewType.OVERSIGHT as unknown as KrakenModelsEnumsReviewType,
    }).then(() => {
      enqueueSnackbar('Finding reviewed as overseen');
    });
  };
  const onDelete = () => {
    deleteFinding({ fid: fid! }).then(() => navigate(LinkEnum.FINDINGS.replace(':pid', pid!)));
  };

  const list = useMemo(
    () => [
      {
        name: 'Mark as Needs work',
        icon: 'needsWork',
        handler: onNeedsWork,
        disabled: finding?.needs_work,
        hidden: false,
        tooltip: '',
      },
      {
        name:
          reviewType === EReviewType.PEER
            ? 'Ready for Review'
            : 'Mark as reviewed',
        icon: 'reviewed',
        handler: onReviewed,
        disabled: notReadyForReview,
        hidden: false,
        tooltip: tooltipText,
      },
      {
        name: 'Mark as updated',
        icon: 'update',
        handler: onUpdate,
        disabled: false,
        hidden: false,
        tooltip: '',
      },
      {
        name: 'Retract',
        icon: 'retract',
        handler: onRetract,
        disabled: false,
        hidden: false,
        tooltip: '',
      },
      {
        name: 'Oversight review',
        icon: 'airplane',
        handler: onOversight,
        disabled: Boolean(lastReview),
        hidden: !isAdmin,
        tooltip: '',
      },
      {
        name: 'Delete',
        icon: 'deleteFinding',
        handler: onDelete,
        disabled: false,
        hidden: false,
        tooltip: '',
      },
    ],
    [reviewType, finding, notReadyForReview, isAdmin, lastReview, tooltipText],
  );

  useEffect(() => {
    const subscription = watch((data) => {
      const requiredFields: any = {
        Name: Boolean(data.name),
        Observation: Boolean(data.observation),
        Description: Boolean(data.description),
        'non under investigation status': Boolean(
          data.statusId?.toUpperCase() === underInvestigation,
        ),
      };

      if (project?.isMasa) {
        requiredFields.Impact = Boolean(data.impactRationale);
        requiredFields.Difficulty = Boolean(data.difficultyRationale);
        requiredFields.Location = Boolean(data.location);
      }

      if (!Object.values(requiredFields).some(Boolean)) {
        const text = Object.entries(requiredFields)
          .filter(([, condition]) => !condition)
          .reduce(
            (acc, cur, index, arr) => (acc += `${cur[0]}${
                index === arr.length - 1
                  ? ''
                  : index === arr.length - 2
                  ? ' and '
                  : ', '
              }`),
            'Mandatory fields: ',
          );

        setNotReadyForReview(true);
        setTooltipText(text);
      }

      payloadRef.current = {
        templateId: data.templateId,
        name: data.name,
        ...(data.associatedActivities?.length
          ? { associatedActivities: data.associatedActivities }
          : {}),
        componentId: data.componentId,
        ...(data.impact !== null
          ? { impact: impactList[data.impact || 'Info'] }
          : {}),
        ...(data.impactRationale
          ? { impactRationale: data.impactRationale }
          : {}),
        ...(data.difficulty !== null
          ? { difficulty: difficultyList[data.difficulty || 'Simple'] }
          : {}),
        ...(data.overriddenSeverity
          ? { overriddenSeverity: data.overriddenSeverity }
          : {}),
        ...(data.difficultyRationale
          ? { difficultyRationale: data.difficultyRationale }
          : {}),
        ...(data.description ? { description: data.description } : {}),
        ...(data.observation ? { observation: data.observation } : {}),
        ...(data.recommendation ? { recommendation: data.recommendation } : {}),
        ...(data.location ? { location: data.location } : {}),
        ...(data.reference ? { reference: data.reference } : {}),
        ...(data.notes ? { notes: data.notes } : {}),
        ...(data.cvss !== vectorString ? { cvss: data.cvss } : {}),
        ...(data.awsCategory ? { awsCategory: data.awsCategory } : {}),
        ...(data.cwe
          ? {
              cweId: typeof data.cwe === 'number' ? data.cwe : data.cwe.Id,
            }
          : {}),
      };

      if (data.statusId) {
        payloadRef.current.statusId = data.statusId;
        if (requiredStatusId.includes(data.statusId)) {
          payloadRef.current.mitigation = data.mitigation;
          if (data.statusId === mitigated) {
            payloadRef.current.residualImpact = data.residualImpact;
            payloadRef.current.residualDifficulty = data.residualDifficulty;
          }
        }
      }
    });

    return () => subscription.unsubscribe();
  }, [watch]);

  useEffect(() => () => closeSnackbar(), []);

  return {
    anchorEl,
    open,
    handleClick,
    handleClose,
    list,
  };
};
