import { KrakenLayout } from '@src/layouts/KrakenLayout/KrakenLayout';

import { ATChecklist } from './ATChecklist';
import { ATFooter } from './ATFooter';
import { ATMethodology } from './ATMethodology';
import { ATNotes } from './ATNotes';
import { ATScope } from './ATScope';
import { ATSubject } from './ATSubject';
import { FieldChecklist } from './FieldChecklist';
import { useAddEditActivity } from './useAddEditActivity';

export const AddEditActivityTemplate = () => {
  const {
    atid,
    control,
    onSubmit,
    watch,
    handleSubmit,
    readyToSubmit,
    isLoading,
  } = useAddEditActivity();

  return (
    <KrakenLayout
      checkList={<FieldChecklist watch={watch} />}
      footer={<ATFooter readyToSubmit={readyToSubmit} />}
      onSubmit={handleSubmit(onSubmit)}
      isLoading={isLoading}
    >
      <h2>{`${atid ? 'Edit' : 'Add new'} Activity Template`}</h2>
      <ATSubject control={control} />
      <ATScope control={control} />
      <ATMethodology control={control} />
      {atid && <ATChecklist />}
      <ATNotes control={control} />
    </KrakenLayout>
  );
};
