/* eslint no-cond-assign: ["off"] */
import { format } from 'date-fns';

import { dateFormat, dateWithTimeAmPmFormat } from '@src/constants/constants';

export function isValidDate(d: Date | null) {
  return d instanceof Date && !(window as any).isNaN(d);
}

export const toLocalDate = (date: Date) => {
  const year = date.getFullYear();
  let month = (date.getMonth() + 1).toString();
  let day = date.getDate().toString();
  month = month.length < 2 ? `0${month}` : month;
  day = day.length < 2 ? `0${day}` : day;

  return `${year}-${month}-${day}`;
};

export const formatDate = (date: string) => {
  const d = new Date(date.split('T')[0].split('-').join(','));

  return format(d, dateFormat);
};

export interface IDate {
  year: number;
  month: number;
  date: number;
}

// Source: http://stackoverflow.com/questions/497790
export const dates = {
  convert(d: number[] | number | string | IDate) {
    // Converts the date in d to a date-object. The input can be:
    //   a date object: returned without modification
    //  an array      : Interpreted as [year,month,day]. NOTE: month is 0-11.
    //   a number     : Interpreted as number of milliseconds
    //                  since 1 Jan 1970 (a timestamp)
    //   a string     : Any format supported by the javascript engine, like
    //                  "YYYY/MM/DD", "MM/DD/YYYY", "Jan 31 2009" etc.
    //  an object     : Interpreted as an object with year, month and date
    //                  attributes.  **NOTE** month is 0-11.
    return d.constructor === Date
      ? d
      : d.constructor === Array
      ? new Date(d[0], d[1], d[2])
      : d.constructor === Number
      ? new Date(d)
      : d.constructor === String
      ? new Date(d)
      : d instanceof Object && 'year' in d && 'month' in d && 'date' in d
      ? new Date(d.year, d.month, d.date)
      : NaN;
  },
  compare(a: any, b: any) {
    // Compare two dates (could be of any type supported by the convert
    // function above) and returns:
    //  -1 : if a < b
    //   0 : if a = b
    //   1 : if a > b
    // NaN : if a or b is an illegal date
    // NOTE: The code inside isFinite does an assignment (=).
    return (window as any).isFinite((a = this.convert(a).valueOf()))
      && (window as any).isFinite((b = this.convert(b).valueOf())) // @ts-ignore
      ? (a > b) - (a < b)
      : NaN;
  },
  inRange(d: any, start: any, end: any): boolean | typeof NaN {
    // Checks if date in d is between dates in start and end.
    // Returns a boolean or NaN:
    //    true  : if d is between start and end (inclusive)
    //    false : if d is before start or after end
    //    NaN   : if one or more of the dates is illegal.
    // NOTE: The code inside isFinite does an assignment (=).
    return (window as any).isFinite((d = this.convert(d).valueOf()))
      && (window as any).isFinite((start = this.convert(start).valueOf()))
      && (window as any).isFinite((end = this.convert(end).valueOf()))
      ? start <= d && d <= end
      : NaN;
  },
};

export const getDate = (
  dateForm: string = dateWithTimeAmPmFormat,
  date = new Date(),
) => format(date, dateForm);

export function apiToLocalDateString(
  date: string | number | undefined | null,
): string {
  if (!date) return 'Unknown';
  return new Date(date).toLocaleString();
}
