import { Button } from '@mui/material';

const mb24 = { marginBottom: '24px' };

interface IProps {
  setDataFromTemplate: () => void;
}

export const FindingSetDataFromTemplateBtn = ({
  setDataFromTemplate,
}: IProps) => (
  <Button onClick={setDataFromTemplate} sx={mb24}>
    Set impact and difficulty from template
  </Button>
);
