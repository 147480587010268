import { useParams } from 'react-router-dom';

import { AddCompany } from '@pages/Projects/AddCompany/AddCompany';
import { KrakenLayout } from '@src/layouts/KrakenLayout/KrakenLayout';
import { kebabCase } from '@utils/kebabCase';

import { DeleteProjectConfirmation } from './DeleteProjectConfirmation';
import { ProjectBreadCrumbs } from './ProjectBreadCrumbs';
import { ProjectCheckList } from './ProjectCheckList';
import { ProjectCompany } from './ProjectCompany';
import { ProjectComponent } from './ProjectComponent';
import { ProjectDate } from './ProjectDate';
import { ProjectFooter } from './ProjectFooter';
import { ProjectLink } from './ProjectLink';
import { ProjectLogo } from './ProjectLogo';
import { ProjectName } from './ProjectName';
import { ProjectNotes } from './ProjectNotes';
import { ProjectOptions } from './ProjectOptions';
import { EAddProjectLinks } from './types';
import { useAddEditProject } from './useAddEditProject';

import styles from './AddProject.module.sass';

export function AddEditProject() {
  const {
    control,
    handleSubmit,
    onSubmit,
    readyToSubmit,
    hideConfirmationPopup,
    closeCompaniesPopup,
    showConfirmationPopup,
    showCompaniesPopup,
    watch,
    isAWS,
  } = useAddEditProject();
  const { pid } = useParams();

  return (
    <KrakenLayout
      wrapperClass={styles.addProject}
      breadCrumbs={<ProjectBreadCrumbs />}
      checkList={<ProjectCheckList watch={watch} />}
      footer={<ProjectFooter readyToSubmit={readyToSubmit} />}
      onSubmit={handleSubmit(onSubmit)}
    >
      <h2>{`${pid ? 'Edit' : 'Create new'} project`}</h2>
      <ProjectName control={control} />
      {!pid && <ProjectComponent control={control} />}
      <ProjectCompany control={control} />
      <div className={styles.date} id={kebabCase(EAddProjectLinks.PERIOD)}>
        <ProjectDate control={control} />
        <ProjectLink control={control} />
      </div>
      <ProjectOptions control={control} isAWS={isAWS} />
      <ProjectNotes control={control} />
      <ProjectLogo control={control} />
      {showConfirmationPopup && (
        <DeleteProjectConfirmation
          showConfirmationPopup={showConfirmationPopup}
          closePopup={hideConfirmationPopup}
        />
      )}
      {showCompaniesPopup && (
        <AddCompany
          showCompaniesPopup={showCompaniesPopup}
          closeCompaniesPopup={closeCompaniesPopup}
        />
      )}
    </KrakenLayout>
  );
}
