import { Controller } from 'react-hook-form';
import { Control } from 'react-hook-form/dist/types';

import { KrakenTextArea } from '@components/KrakenTextArea/KrakenTextArea';
import { kebabCase } from '@utils/kebabCase';

import { EAddEditFindingLinks, IFindingFields } from '../types';

const placeholder = `Point of view: Third person. Don’t use first person (e.g., I, we, our) or second person (e.g., you, your).

Content: Provide a general description of the type or class of vulnerability.`;

interface IProps {
  control: Control<IFindingFields>;
}

export const FindingInternalNotes = ({ control }: IProps) => (
  <Controller
    control={control}
    name="notes"
    render={({ field: { value, onChange } }) => (
      <KrakenTextArea
        id={kebabCase(EAddEditFindingLinks.INTERNAL_NOTES)}
        title="Internal Notes"
        value={value || ''}
        height="180"
        onChange={onChange}
        placeholder={placeholder}
      />
    )}
  />
);
