import { UseFormGetValues } from 'react-hook-form/dist/types/form';

import { BreadCrumbs } from '@components/BreadCrumbs/BreadCrumbs';

import { IComponentFields } from '../types';

import { useComponentBreadCrumbs } from './useComponentBreadCrumbs';

interface IProps {
  getValues: UseFormGetValues<IComponentFields>;
}

export const ComponentBreadCrumbs = ({ getValues }: IProps) => {
  const crumbs = useComponentBreadCrumbs();
  return <BreadCrumbs crumbs={crumbs} currentPage={getValues('Name')} />;
};
