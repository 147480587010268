import {
 ChangeEvent, useEffect, useRef, useState,
} from 'react';

import {
  KrakenBackendApiModelsActivityTemplatesApiActivityTemplateChecklistItem,
  useDeleteApiActivityTemplatesChecklistByAtcidMutation,
  usePutApiActivityTemplatesChecklistByAtcidMutation,
} from '@store/services/query.generated';

let val = '';

export const useListItem = ({
  atcid,
  heading,
}: KrakenBackendApiModelsActivityTemplatesApiActivityTemplateChecklistItem) => {
  const [readonly, setReadonly] = useState(true);
  const [textareaValue, setTextareaValue] = useState(heading || '');
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const textRef = useRef<string>(heading || '');

  const [putATChecklistByAtcid] = usePutApiActivityTemplatesChecklistByAtcidMutation();
  const [DeleteATChecklistByAtcid] = useDeleteApiActivityTemplatesChecklistByAtcidMutation();

  const sendActivity = () => {
    putATChecklistByAtcid({
      atcid: atcid!,
      body: val,
    });
  };

  const onEdit = () => {
    const end = textareaRef.current?.value.length || 0;

    setReadonly(false);
    textareaRef.current?.setSelectionRange(end, end);
    textareaRef.current?.focus();
    val = textareaValue;
  };

  const apply = () => {
    sendActivity();
    setReadonly(true);
  };

  const onKeyup = ({ keyCode }: KeyboardEvent) => {
    if (keyCode === 27) {
      setTextareaValue(textRef.current);
      setReadonly(true);
    }
  };

  const onTextarea = ({
    target: { value },
  }: ChangeEvent<HTMLTextAreaElement>) => {
    val = value;

    setTextareaValue(value);
  };

  const onDelete = () => {
    DeleteATChecklistByAtcid({ atcid: atcid! });
  };

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.addEventListener('dblclick', onEdit);
      textareaRef.current.addEventListener('blur', apply);
      textareaRef.current.addEventListener('keyup', onKeyup);
    }
    return () => {
      if (textareaRef.current) {
        textareaRef.current.removeEventListener('dblclick', onEdit);
        textareaRef.current.removeEventListener('blur', apply);
        textareaRef.current.removeEventListener('keyup', onKeyup);
      }
    };
  }, []);

  return {
    textareaValue,
    readonly,
    textareaRef,
    onTextarea,
    apply,
    onEdit,
    onDelete,
  };
};
