import { UseFormWatch } from 'react-hook-form';

import { CheckList } from '@components/CheckList/CheckList';

import { IStatusReportFields } from '../types';

import { useChecklist } from './useChecklist';

interface IProps {
  watch: UseFormWatch<IStatusReportFields>;
}

export const SRChecklist = ({ watch }: IProps) => {
  const links = useChecklist(watch);

  return <CheckList asideLinks={links} />;
};
