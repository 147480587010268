import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { AWS_ID } from '@src/constants/environment';
import { useGetApiProjectsByPidQuery } from '@store/services/query.generated';

export const isAWS = (companyId: string | null) => companyId?.toLowerCase() === AWS_ID.toLowerCase();

export const useAWS = () => {
  const [coID, setCoID] = useState<string | null>(null);
  const { pid } = useParams();

  const { data } = useGetApiProjectsByPidQuery({ pid: pid! }, { skip: !pid });

  useEffect(() => {
    if (data && data.coID) {
      setCoID(data.coID.toLowerCase());
    }
  }, [data]);

  return isAWS(coID);
};
