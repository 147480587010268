import { Controller } from 'react-hook-form';
import { Control } from 'react-hook-form/dist/types';

import { KrakenTextArea } from '@components/KrakenTextArea/KrakenTextArea';
import { kebabCase } from '@utils/kebabCase';
import { textAreaHandler } from '@utils/textAreaHandler';

import { EFTLinks, IFindingTemplateFields } from '../types';

interface IProps {
  control: Control<IFindingTemplateFields>;
}

export const FTRecommendation = ({ control }: IProps) => (
  <Controller
    control={control}
    name="Recommendation"
    render={({ field: { value, onChange } }) => (
      <KrakenTextArea
        id={kebabCase(EFTLinks.RECOMMENDATION)}
        title="Recommendation*"
        value={value || ''}
        onChange={(val) => textAreaHandler(val, onChange)}
        height={180}
        hideToolbar
      />
    )}
  />
);
