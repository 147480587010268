import { parseISO } from 'date-fns';
import { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';

import { yupDateRange, yupRequiredString } from '@src/constants/constants';
import { LinkEnum } from '@src/types/types';
import {
  useGetApiActivitiesByAidQuery,
  usePostApiActivitiesMutation,
  usePutApiActivitiesByAidMutation,
} from '@store/services/query.generated';

import { IActivityFields } from './types';

const schema = yup.object().shape({
  Subject: yupRequiredString,
  PcId: yupRequiredString,
  Scope: yupRequiredString,
  Description: yupRequiredString,
  Dates: yupDateRange,
});

export const useAddEditActivity = () => {
  const [readyToSubmit, setReadyToSubmit] = useState(false);
  const { pid, aid } = useParams();
  const navigate = useNavigate();
  const {
    control,
    watch,
    handleSubmit,
    reset,
    formState: { isDirty, isValid },
    setFocus,
  } = useForm<IActivityFields>({
    mode: 'onChange',
    resolver: yupResolver(schema),
  });

  const { data: activity, isLoading: activityIsLoading } = useGetApiActivitiesByAidQuery({ aid: aid! }, { skip: !aid });
  const [updateActivity, { isLoading: putIsLoading }] = usePutApiActivitiesByAidMutation();
  const [addActivity, { isLoading: postIsLoading }] = usePostApiActivitiesMutation();

  const onSubmit: SubmitHandler<IActivityFields> = (data) => {
    const krakenBackendApiModelsActivitiesApiActivityNew: any = {
      Subject: data.Subject,
      PcId: data.PcId,
      Scope: data.Scope,
      Description: data.Description,
      ...(data.Disposition ? { Disposition: data.Disposition } : {}),
      ...(data.FutureWork ? { FutureWork: data.FutureWork } : {}),
      ...(data.Notes ? { Notes: data.Notes } : {}),
      ...(data.Dates && data.Dates[0]
        ? { forecast_start_date: data.Dates[0] }
        : {}),
      ...(data.Dates && data.Dates[1]
        ? { forecast_end_date: data.Dates[1] }
        : {}),
      ...(data.Date ? { forecast_unblock_date: data.Date } : {}),
      ProjectId: pid,
    };

    if (aid) {
      updateActivity({ aid, krakenBackendApiModelsActivitiesApiActivityNew });
    } else {
      addActivity({ krakenBackendApiModelsActivitiesApiActivityNew }).then(
        (result) => {
          if ('data' in result && result?.data && pid) {
            const uri = LinkEnum.EDIT_ACTIVITY.replace(':pid', pid).replace(
              ':aid',
              result.data,
            );

            uri
              && navigate(uri, {
                state: { linkTemplate: LinkEnum.EDIT_ACTIVITY },
              });
          }
        },
      );
    }
  };

  useEffect(() => {
    setFocus('Subject');
  }, []);

  useEffect(() => {
    setReadyToSubmit(isDirty && isValid);
  }, [isDirty, isValid]);

  useEffect(() => {
    if (activity) {
      reset({
        Subject: activity.Subject,
        PcId: activity.PcId,
        Scope: activity.Scope,
        Description: activity.Description,
        Disposition: activity.Disposition,
        FutureWork: activity.FutureWork,
        Dates:
          activity.forecast_start_date && activity.forecast_end_date
            ? [
                parseISO(activity.forecast_start_date as string),
                parseISO(activity.forecast_end_date as string),
              ]
            : undefined,
        Date: activity.forecast_unblock_date
          ? parseISO(activity.forecast_unblock_date as string)
          : undefined,
        Notes: activity.Notes,
      });
    }
  }, [activity]);

  return {
    aid,
    control,
    watch,
    handleSubmit,
    onSubmit,
    readyToSubmit,
    isLoading: activityIsLoading || putIsLoading || postIsLoading,
    status: activity?.InferredStatus || 'DEFAULT',
  };
};
