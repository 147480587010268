export enum EESLinks {
  SCOPE = 'Scope',
  DATE = 'Date',
  OBJECTIVES = 'Objectives',
  OBSERVATIONS = 'Observations',
  RECOMMENDATIONS = 'Recommendations',
  INTERNAL_NOTES = 'Internal notes',
}

export interface IExecutiveSummaryFields {
  Scope: string | null;
  Dates: [Date | null, Date | null];
  Objectives: string | null;
  Observations: string | null;
  Recommendations: string | null;
  Notes: string | null;
}
