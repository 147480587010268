import { Link, useParams } from 'react-router-dom';
import { Button } from '@mui/material';

import { Footer } from '@components/Footer/Footer';
import Icon from '@components/Icon/Icon';
import { LinkEnum } from '@src/types/types';

interface IProps {
  readyToSubmit: boolean;
}

export const ActivityFooter = ({ readyToSubmit }: IProps) => {
  const { aid, pid } = useParams();
  const activitiesLink = LinkEnum.ACTIVITIES.replace(':pid', pid!);

  return (
    <Footer>
      <Button
        component={Link}
        color="secondary"
        size="small"
        to={activitiesLink}
      >
        Back
      </Button>
      <Button
        color="primary"
        size="small"
        startIcon={<Icon iconName="save" />}
        type="submit"
        disabled={!readyToSubmit}
      >
        {aid ? 'Save' : 'Add'}
        {' '}
        activity
      </Button>
    </Footer>
  );
};
