import { Controller } from 'react-hook-form';
import { Control } from 'react-hook-form/dist/types';
import { MenuItem, Skeleton } from '@mui/material';

import { KrakenSelect } from '@components/KrakenSelect/KrakenSelect';
import { kebabCase } from '@utils/kebabCase';

import { EAddEditFindingLinks, IFindingFields } from '../types';

import { useFindingComponent } from './useFindingComponent';

const skeletonStyle = { flexGrow: 1, height: 44, marginTop: '20px' };

interface IProps {
  control: Control<IFindingFields>;
}

export const FindingComponent = ({ control }: IProps) => {
  const { components, isLoading } = useFindingComponent();

  return (
    <>
      {isLoading && !components.length && (
        <Skeleton animation="wave" variant="rectangular" sx={skeletonStyle} />
      )}
      {components.length ? (
        <Controller
          control={control}
          name="componentId"
          render={({ field: { value, onChange } }) => (
            <KrakenSelect
              id={kebabCase(EAddEditFindingLinks.COMPONENT)}
              label="Component*"
              value={value && !components?.length ? '' : value || ''}
              onChange={(e) => onChange(e.target.value)}
            >
              {components.map(({ pc_id, name }) => (
                <MenuItem key={pc_id} value={pc_id}>
                  {name}
                </MenuItem>
              ))}
            </KrakenSelect>
          )}
        />
      ) : null}
    </>
  );
};
