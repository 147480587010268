import { IconButton, TextareaAutosize, Zoom } from '@mui/material';

import Icon from '@components/Icon/Icon';
import { RadioButtons } from '@components/RadioButtons/RadioButtons';
import s from '@pages/Project/Activities/AddEditActivity/ActivityChecklist/ActivityChecklist.module.sass';
import { buttonsList } from '@src/constants/constants';
import { KrakenBackendApiModelsActivityTemplatesApiActivityTemplateChecklistItem } from '@store/services/query.generated';

import { useListItem } from './useListItem';

interface IProps {
  data: KrakenBackendApiModelsActivityTemplatesApiActivityTemplateChecklistItem;
}

export const ListItem = ({ data }: IProps) => {
  const {
    textareaValue,
    readonly,
    textareaRef,
    onTextarea,
    apply,
    onEdit,
    onDelete,
  } = useListItem(data);

  return (
    <div className={s.listItemWrapper}>
      <div className={s.title} />
      <div className={s.heading}>
        <TextareaAutosize
          aria-label="header of checklist"
          value={textareaValue}
          readOnly={readonly}
          minRows={3}
          className={s.textarea}
          ref={textareaRef}
          onChange={onTextarea}
        />
        <div className={s.btns}>
          <Zoom in={readonly}>
            <IconButton size="small" aria-label="edit" onClick={onEdit}>
              <Icon iconName="edit" />
            </IconButton>
          </Zoom>
          <IconButton size="small" aria-label="delete" onClick={onDelete}>
            <Icon iconName="delete" />
          </IconButton>
        </div>
        <Zoom in={!readonly} className={s.apply}>
          <IconButton size="small" aria-label="apply" onClick={apply}>
            <Icon iconName="apply" />
          </IconButton>
        </Zoom>
      </div>
      <div className={s.btnWrapper}>
        <RadioButtons
          list={Object.keys(buttonsList)}
          checked={null}
          onChange={() => {}}
          disabled
        />
      </div>
    </div>
  );
};
