import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Avatar } from '@mui/material';

import { KrakenListItem } from '@components/KrakenListItem/KrakenListItem';
import { IColumn } from '@components/KrakenTable/type';
import { LinkEnum } from '@src/types/types';
import { KrakenBackendApiModelsCompaniesCompany } from '@store/services/query.generated';

import s from '../Companies.module.sass';

const columns: IColumn<KrakenBackendApiModelsCompaniesCompany>[] = [
  {
    headerName: 'LOGO',
    sortable: false,
  },
  {
    headerName: 'NAME',
    field: 'Name',
    sortable: true,
  },
  {
    headerName: 'ACTIVE',
    field: 'Active',
    sortable: true,
  },
];

export const useCompaniesList = () => {
  const navigate = useNavigate();

  const onClick = (id: string | undefined) => {
    id
      && navigate(LinkEnum.EDIT_COMPANY.replace(':cid', id), {
        state: { linkTemplate: LinkEnum.EDIT_COMPANY },
      });
  };

  const rowTemplate = useCallback(
    ({
 CompanyId, Name, Active, Logo,
}) => (
  <KrakenListItem
    key={CompanyId}
    fid={CompanyId}
    classname={s.companyWrapper}
    handler={onClick}
  >
    <Avatar
      sx={{
            bgcolor: '#A5A6F6',
            width: '44px',
            height: '44px',
            '[data-theme="light"] &': {
              borderColor: '#eaeaee',
            },
            '[data-theme="dark"] &': {
              borderColor: 'rgba(#354053,0.5)',
            },
          }}
      src={`data:image/png;base64,${Logo}` || ''}
    />
    <div>{Name}</div>
    <div>{Active ? 'Active' : 'Not Active'}</div>
  </KrakenListItem>
    ),
    [],
  );

  return {
    columns,
    rowTemplate,
  };
};
