import { KrakenTable } from '@components/KrakenTable/KrakenTable';
import {
  GetApiActivitiesProjectByPidApiResponse,
  KrakenBackendApiModelsActivitiesApiActivityDetailed,
} from '@store/services/query.generated';

import { useActivityList } from './useActivityList';

import s from './ActivityList.module.sass';

interface IProps {
  activities: GetApiActivitiesProjectByPidApiResponse;
}

export const ActivityList = ({ activities }: IProps) => {
  const { columns, rowTemplate } = useActivityList();

  return (
    <div className={s.ActivitiesWrapper}>
      <KrakenTable<KrakenBackendApiModelsActivitiesApiActivityDetailed>
        tableData={activities}
        columns={columns}
        gridTemplateColumns="minmax(100px, 235px) minmax(100px, 304px) 203px 88px 200px"
        rowTemplate={rowTemplate}
      />
    </div>
  );
};
