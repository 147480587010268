import { ChangeEvent, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { RadioButtons } from '@components/RadioButtons/RadioButtons';
import {
  KrakenBackendUtilityConstantsActivityStatus,
  usePutApiActivitiesByAidStatusMutation,
} from '@store/services/query.generated';
import { getKeyByValue } from '@utils/getKeyByValue';

export const buttonsList = {
  'Not Started': 'DEFAULT',
  Progressing: 'PROGRESSING',
  Blocked: 'BLOCKED',
  'Qualified Done': 'QUALIFIED_DONE',
  Done: 'DONE',
  Impossible: 'IMPOSSIBLE',
};

interface IProps {
  status: KrakenBackendUtilityConstantsActivityStatus;
}

export const ActivityStatusBar = ({ status }: IProps) => {
  const [btnChecked, setBtnChecked] = useState<string>(
    getKeyByValue(buttonsList, status) || 'Not Started',
  );
  const { aid } = useParams();

  const [updateStatus] = usePutApiActivitiesByAidStatusMutation();

  const onChange = ({
    target: { value },
  }: ChangeEvent<
    HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
  >) => {
    setBtnChecked(value);
    updateStatus({
      aid: aid!,
      krakenBackendUtilityConstantsActivityStatus: buttonsList[
        value as keyof typeof buttonsList
      ] as KrakenBackendUtilityConstantsActivityStatus,
    });
  };

  useEffect(() => {
    status
      && setBtnChecked(getKeyByValue(buttonsList, status) || 'Not Started');
  }, [status]);

  return (
    <RadioButtons
      list={Object.keys(buttonsList)}
      checked={btnChecked}
      onChange={onChange}
    />
  );
};
