import * as yup from 'yup';

import { yupRequiredString, yupString } from '@src/constants/constants';

import { vectorString } from './FindingCVSS/constants';
import { cvssRegexp, requiredStatusId } from './constants';

export const schema = yup.object().shape({
  templateId: yupRequiredString,
  name: yupRequiredString,
  componentId: yupRequiredString,
  mitigation: yupString.when('statusId', {
    is: (value: string) => requiredStatusId.includes(value),
    then: yupRequiredString,
    otherwise: yupString.nullable(),
  }),
  validCVSS: yupString.when('cvss', {
    is: (cvssVector: string) => cvssVector !== vectorString && !cvssRegexp.test(cvssVector),
    then: yup.string().required('Base CVSS is required'),
    otherwise: yupString,
  }),
  awsCategory: yupString.when('isAWS', {
    is: true,
    then: yupRequiredString,
    otherwise: yupString.nullable(),
  }),
});
