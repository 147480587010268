import cn from 'classnames';
import { useNavigate } from 'react-router-dom';
import { Avatar, useTheme } from '@mui/material';

import { KrakenListItem } from '@components/KrakenListItem/KrakenListItem';
import { KrakenNavBar } from '@components/KrakenNavBar/KrakenNavBar';
import { severityColorScheme } from '@pages/Project/Findings/AddEditFinding/constants';
import { xcsrf } from '@src/constants/constants';
import { LinkEnum } from '@src/types/types';
import { useGetApiFindingsNeedsWorkQuery } from '@store/services/query.generated';

import s from './NeedsWork.module.sass';

const list = ['PROJECT', 'FINDING', 'SEVERITY', 'STATUS'];

export const NeedsWork = () => {
  const { data } = useGetApiFindingsNeedsWorkQuery(xcsrf);
  const navigate = useNavigate();
  const {
    palette: { mode },
  } = useTheme();

  const onClick = (fid: string | undefined, pid: string | undefined) => {
    pid
      && fid
      && navigate(
        LinkEnum.EDIT_FINDING.replace(':pid', pid!).replace(':fid', fid!),
        { state: { linkTemplate: LinkEnum.EDIT_FINDING } },
      );
  };

  return (
    <div className={s.wrapper}>
      <h2>Needs Work</h2>
      {data?.length ? (
        <>
          <KrakenNavBar list={list} gridTemplateColumns="repeat(4, 1fr)" />
          {data?.map(
            (
              {
                finding_id,
                projectID,
                projectName,
                risk_text,
                summary,
                status_name,
                projectLogoUrl,
              },
              index,
            ) => (
              <KrakenListItem
                fid={finding_id}
                sid={projectID}
                key={finding_id}
                classname={cn(s.listItem, { [s.first]: index === 0 })}
                handler={onClick}
              >
                <div className={s.prName}>
                  <Avatar
                    sx={{
                      bgcolor: '#A5A6F6',
                      width: '40px',
                      height: '40px',
                    }}
                    alt={projectName || ''}
                    src={projectLogoUrl || ''}
                  />
                  {projectName}
                </div>
                <div>{summary}</div>
                <div
                  className={s.severity}
                  style={{
                    backgroundColor:
                      severityColorScheme[mode || 'light'][risk_text || 'Info']
                        .bg,
                    color:
                      severityColorScheme[mode || 'light'][risk_text || 'Info']
                        .color,
                  }}
                >
                  <span>{risk_text || 'Info'}</span>
                </div>
                <div>{status_name}</div>
              </KrakenListItem>
            ),
          )}
        </>
      ) : (
        'No Data'
      )}
    </div>
  );
};
