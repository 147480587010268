import { useEffect, useState } from 'react';
import { UseFormWatch } from 'react-hook-form';

import { ICheckListLink } from '@components/CheckList/CheckList';

import { ESTLinks, IStereotypeTemplateFields } from '../types';

const linkState: ICheckListLink = {
  [ESTLinks.SURFACE_NAME]: false,
  [ESTLinks.AREA]: false,
  [ESTLinks.DESCRIPTION]: false,
  [ESTLinks.THREAT_ANALYSIS]: false,
  [ESTLinks.ASSOCIATED_ACTIVITY_TEMPLATE]: true,
};

export const useChecklist = (
  watch: UseFormWatch<IStereotypeTemplateFields>,
) => {
  const [links, setLinks] = useState(linkState);

  useEffect(() => {
    const subscription = watch((value) => {
      setLinks({
        [ESTLinks.SURFACE_NAME]: Boolean(value.SurfaceName),
        [ESTLinks.AREA]: Boolean(value.Area),
        [ESTLinks.DESCRIPTION]: Boolean(value.Description),
        [ESTLinks.THREAT_ANALYSIS]: Boolean(value.ThreatAnalysis),
        [ESTLinks.ASSOCIATED_ACTIVITY_TEMPLATE]: true,
      });
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  return links;
};
