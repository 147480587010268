import UserBar from '../UserBar/UserBar';

import s from './Header.module.sass';

function Header() {
  return (
    <header className={s.header}>
      <div className={s.search} />
      <div className={s.aside}>
        <UserBar />
      </div>
    </header>
  );
}

export default Header;
