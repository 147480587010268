import { Controller } from 'react-hook-form';
import { Control } from 'react-hook-form/dist/types';
import { Checkbox, FormControlLabel } from '@mui/material';

import { IFindingTemplateFields } from '@pages/Admin/FindingTemplate/AddEditFindingTemplate/types';

interface IProps {
  control: Control<IFindingTemplateFields>;
}

export const FTShippableRecommendation = ({ control }: IProps) => (
  <Controller
    control={control}
    name="ShippableRecommendation"
    render={({ field: { value, onChange } }) => (
      <FormControlLabel
        control={
          <Checkbox checked={value || false} onClick={() => onChange(!value)} />
        }
        label="Shippable Recommendation"
      />
    )}
  />
);
