export enum ESTLinks {
  SURFACE_NAME = 'Surface Name',
  AREA = 'Area',
  DESCRIPTION = 'Description',
  THREAT_ANALYSIS = 'Threat Analysis',
  ASSOCIATED_ACTIVITY_TEMPLATE = 'Associated Activity Template',
}

export interface IStereotypeTemplateFields {
  Area: string | null;
  SurfaceName: string | null;
  Description: string | null;
  ThreatAnalysis: string | null;
}
