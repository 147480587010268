import { KrakenTable } from '@components/KrakenTable/KrakenTable';
import { useComponentsList } from '@pages/Project/Components/ComponentsList/useComponentsList';
import {
  GetApiComponentsByPidApiResponse,
  KrakenBackendApiModelsProjectComponentsV1ProjectComponentsDetailed,
} from '@store/services/query.generated';

interface IProps {
  components: GetApiComponentsByPidApiResponse;
}

export const ComponentsList = ({ components }: IProps) => {
  const { columns, rowTemplate } = useComponentsList();

  return (
    <KrakenTable<KrakenBackendApiModelsProjectComponentsV1ProjectComponentsDetailed>
      tableData={components}
      columns={columns}
      gridTemplateColumns="minmax(100px, 235px) 250px 250px 200px"
      rowTemplate={rowTemplate}
    />
  );
};
