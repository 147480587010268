import { Controller } from 'react-hook-form';
import { Control } from 'react-hook-form/dist/types';
import { MenuItem } from '@mui/material';

import { KrakenSelect } from '@components/KrakenSelect/KrakenSelect';
import { kebabCase } from '@utils/kebabCase';

import { EAddEditFindingLinks, IFindingFields } from '../types';

import { useFindingTemplate } from './useFindingTemplate';

interface IProps {
  control: Control<IFindingFields>;
  setTemplate: (value: any) => void;
}

export const FindingTemplate = ({ control, setTemplate }: IProps) => {
  const { templates, isLoading, handler } = useFindingTemplate(setTemplate);

  return (
    <Controller
      control={control}
      name="templateId"
      render={({ field: { value, onChange } }) => (
        <KrakenSelect
          id={kebabCase(EAddEditFindingLinks.FINDING_TEMPLATE)}
          label="Finding Template*"
          value={value && !templates?.length ? '' : value || ''}
          onChange={(e) => handler(e, onChange)}
          isLoading={isLoading}
        >
          {templates?.map((temp) => (
            <MenuItem key={temp.VulnId} value={temp.VulnId || ''}>
              {temp.Name || ''}
            </MenuItem>
          ))}
        </KrakenSelect>
      )}
    />
  );
};
