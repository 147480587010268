import { ReactElement, ReactNode } from 'react';
import { v4 } from 'uuid';
import {
  FormControl,
  InputBase,
  InputLabel,
  Select,
  SelectChangeEvent,
  Skeleton,
} from '@mui/material';

interface IProps {
  id?: string;
  label?: string;
  value: string | number | string[];
  onChange: (event: SelectChangeEvent<any>) => void;
  children: ReactNode | ReactElement | ReactElement[];
  multiple?: boolean;
  isLoading?: boolean;
  // All others properties
  [x: string]: any;
}

const skeletonStyle = { flexGrow: 1, height: 44, marginTop: '20px' };

export const KrakenSelect = ({
  id = '',
  label = '',
  value,
  onChange,
  children,
  multiple = false,
  isLoading = false,
  ...other
}: IProps) => {
  const selectId = v4();

  return (
    <FormControl
      id={id}
      variant="standard"
      fullWidth
      disabled={'disabled' in other}
    >
      {label && (
        <InputLabel shrink id={selectId} htmlFor={`${label}-label`}>
          {label}
        </InputLabel>
      )}
      {!isLoading || children ? (
        <Select
          labelId={`${label || 'just'}-label`}
          id={selectId}
          value={value}
          input={<InputBase />}
          onChange={onChange}
          multiple={multiple}
          {...other}
        >
          {children}
        </Select>
      ) : (
        <Skeleton animation="wave" variant="rectangular" sx={skeletonStyle} />
      )}
    </FormControl>
  );
};
