import { Controller } from 'react-hook-form';
import { Control } from 'react-hook-form/dist/types';
import { MenuItem } from '@mui/material';

import { KrakenSelect } from '@components/KrakenSelect/KrakenSelect';
import { GetApiReportsAvailableFontsApiResponse } from '@store/services/query.generated';

import { IStatusReportFields } from '../types';

interface IProps {
  control: Control<IStatusReportFields>;
  fonts?: GetApiReportsAvailableFontsApiResponse;
  fontsIsLoading: boolean;
}

export const SRHeaderFooterFont = ({
  control,
  fonts,
  fontsIsLoading,
}: IProps) => (
  <Controller
    control={control}
    name="HeaderFooter"
    render={({ field: { value, onChange } }) => (
      <KrakenSelect
        label="Header and footer font"
        value={value || 'IBMPlexMono'}
        onChange={onChange}
        isLoading={fontsIsLoading}
      >
        {fonts?.map((fontName) => (
          <MenuItem key={fontName} value={fontName}>
            {fontName}
          </MenuItem>
        ))}
      </KrakenSelect>
    )}
  />
);
