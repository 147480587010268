import { useEffect, useState } from 'react';
import { UseFormWatch } from 'react-hook-form';

import { ICheckListLink } from '@components/CheckList/CheckList';
import { isValidDate } from '@utils/dateUtils';

import { EESLinks, IExecutiveSummaryFields } from '../types';

const linkState: ICheckListLink = {
  [EESLinks.SCOPE]: false,
  [EESLinks.DATE]: false,
  [EESLinks.OBJECTIVES]: false,
  [EESLinks.OBSERVATIONS]: false,
  [EESLinks.RECOMMENDATIONS]: false,
  [EESLinks.INTERNAL_NOTES]: false,
};

export const useESChecklist = (
  watch: UseFormWatch<IExecutiveSummaryFields>,
) => {
  const [links, setLinks] = useState(linkState);

  useEffect(() => {
    const subscription = watch((value) => {
      setLinks({
        [EESLinks.SCOPE]: Boolean(value.Scope),
        [EESLinks.DATE]: Boolean(
          'Dates' in value
            && value.Dates?.length
            && isValidDate(value.Dates[0] as Date)
            && isValidDate(value.Dates[1] as Date),
        ),
        [EESLinks.OBJECTIVES]: Boolean(value.Objectives),
        [EESLinks.OBSERVATIONS]: Boolean(value.Observations),
        [EESLinks.RECOMMENDATIONS]: Boolean(value.Recommendations),
        [EESLinks.INTERNAL_NOTES]: Boolean(value.Notes),
      });
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  return links;
};
