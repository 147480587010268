import { useEffect, useState } from 'react';
import { UseFormWatch } from 'react-hook-form/dist/types/form';
import { useParams } from 'react-router-dom';

import { ICheckListLink } from '@components/CheckList/CheckList';
import { useTypedSelector } from '@src/utils';
import { formattedValue } from '@utils/formattedValue';
import { SendItemDataUpdated } from '@utils/signalR';

import { EAddEditFindingLinks, IFindingFields } from '../types';

const linksState: ICheckListLink = {
  [EAddEditFindingLinks.FINDING_TEMPLATE]: false,
  [EAddEditFindingLinks.NAME]: false,
  [EAddEditFindingLinks.IMPACT]: false,
  [EAddEditFindingLinks.DIFFICULTY]: false,
  [EAddEditFindingLinks.DESCRIPTION]: false,
  [EAddEditFindingLinks.OBSERVATION]: false,
  [EAddEditFindingLinks.RECOMMENDATION]: false,
  [EAddEditFindingLinks.INTERNAL_NOTES]: false,
};

export const useFindingChecklist = (watch: UseFormWatch<IFindingFields>) => {
  const [links, setLinks] = useState(linksState);
  const { fid } = useParams();
  const { isEditor, hasObserver } = useTypedSelector(
    (state) => state.addEditFindingReducer,
  );

  useEffect(() => {
    const subscription = watch((value) => {
      fid
        && isEditor
        && hasObserver
        && SendItemDataUpdated(`finding_edit_${fid}`, value);
      setLinks({
        [EAddEditFindingLinks.FINDING_TEMPLATE]: Boolean(value.templateId),
        [EAddEditFindingLinks.NAME]: Boolean(value.name),
        [EAddEditFindingLinks.ACTIVITIES]: Boolean(
          value.associatedActivities && value.associatedActivities.length,
        ),
        [EAddEditFindingLinks.COMPONENT]: Boolean(value.componentId),
        [EAddEditFindingLinks.IMPACT]: Boolean(value.impactRationale),
        [EAddEditFindingLinks.DIFFICULTY]: Boolean(value.difficultyRationale),
        [EAddEditFindingLinks.DESCRIPTION]: Boolean(value.description),
        [EAddEditFindingLinks.OBSERVATION]: Boolean(value.observation),
        [EAddEditFindingLinks.RECOMMENDATION]: Boolean(value.recommendation),
        [EAddEditFindingLinks.INTERNAL_NOTES]: Boolean(
          value.notes && formattedValue(value.notes),
        ),
      });
    });

    return () => subscription.unsubscribe();
  }, [watch, fid, isEditor, hasObserver]);

  return links;
};
