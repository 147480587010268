import { UseFormWatch } from 'react-hook-form';

import { CheckList } from '@components/CheckList/CheckList';

import { IFinalReportFields } from '../types';

import { useFRChecklist } from './useFRChecklist';

interface IProps {
  watch: UseFormWatch<IFinalReportFields>;
}

export const FRChecklist = ({ watch }: IProps) => {
  const links = useFRChecklist(watch);

  return <CheckList asideLinks={links} />;
};
