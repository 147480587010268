import { SelectChangeEvent } from '@mui/material';

import { xcsrf } from '@src/constants/constants';
import {
  api,
  useGetApiVulnerabilitiesActiveQuery,
} from '@store/services/query.generated';

export const useFindingTemplate = (setTemplate: (value: any) => void) => {
  const { data: templates = [], isLoading: templatesIsLoading } = useGetApiVulnerabilitiesActiveQuery(xcsrf);
  const [getTemplate, { isLoading: templateIsLoading }] = api.useLazyGetApiVulnerabilitiesByVidQuery();

  const handler = (
    { target: { value } }: SelectChangeEvent,
    onChange: (...event: any[]) => void,
  ) => {
    onChange(value);

    getTemplate({ vid: value }).then(
      (result) => result.data && setTemplate(result.data),
    );
  };

  return {
    templates,
    isLoading: templatesIsLoading || templateIsLoading,
    handler,
  };
};
