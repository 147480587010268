import { Controller } from 'react-hook-form';
import { Control } from 'react-hook-form/dist/types';
import { MenuItem } from '@mui/material';

import { KrakenSelect } from '@components/KrakenSelect/KrakenSelect';
import { IFinalReportFields } from '@pages/Reporting/GeneralFinalReport/types';
import { GetApiReportsAvailableFontsApiResponse } from '@store/services/query.generated';

interface IProps {
  control: Control<IFinalReportFields>;
  fonts?: GetApiReportsAvailableFontsApiResponse;
  fontsIsLoading: boolean;
}

export const FRSecondFont = ({ control, fonts, fontsIsLoading }: IProps) => (
  <Controller
    control={control}
    name="Classification"
    render={({ field: { value, onChange } }) => (
      <KrakenSelect
        label="Classification page second font"
        value={value || 'PTRootUI'}
        onChange={onChange}
        isLoading={fontsIsLoading}
      >
        {fonts?.map((fontName) => (
          <MenuItem key={fontName} value={fontName}>
            {fontName}
          </MenuItem>
        ))}
      </KrakenSelect>
    )}
  />
);
