import { UseFormWatch } from 'react-hook-form/dist/types/form';

import { CheckList } from '@components/CheckList/CheckList';
import { useProjectCheckList } from '@pages/Projects/AddEditProject/ProjectCheckList/useProjectCheckList';

import { IProjectFields } from '../types';

interface IProps {
  watch: UseFormWatch<IProjectFields>;
}

export const ProjectCheckList = ({ watch }: IProps) => {
  const links = useProjectCheckList(watch);

  return <CheckList asideLinks={links} />;
};
