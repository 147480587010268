export const MuiInputLabel = {
  styleOverrides: {
    root: {
      transform: 'none!important',
      fontWeight: '600',
      fontSize: '12px',
      lineHeight: '14px',
      color: '#9BA1AA',
      '&.Mui-focused': {
        borderColor: '#877CFF',
        color: '#877CFF',
      },
    },
  },
};
