const inputBaseStyles = {
  width: '100%',
  height: '44px !important',
  padding: '12px 16px',
  fontWeight: '400',
  fontSize: '14px',
  lineHeight: '20px',
  borderRadius: 4,
  backgroundColor: 'transparent',
  border: '1px solid #d1d1d9',
  boxSizing: 'border-box',
  '&:focus': {
    borderColor: '#B5AEFF',
  },
  '&.Mui-disabled': {
    borderColor: '#D1D1D9',
    color: '#9BA1AA',
  },
};
export const MuiInputBase = {
  styleOverrides: {
    root: {
      fontFamily: ['Hauora-Bold', 'sans-serif'].join(','),
      borderRadius: 4,
      input: inputBaseStyles,
      '& .MuiSelect-select': {
        ...inputBaseStyles,
        '&~.MuiSvgIcon-root': {
          right: '16px',
        },
        '&.Mui-disabled~svg': {
          color: '#9BA1AA',
        },
      },
      '&.Mui-error': {
        '.MuiInputBase-input': {
          border: '1px solid #FFC8C8',
          color: '#FF4646',
        },
        '& svg': {
          color: '#FF4646',
        },
      },
      'label + &': {
        marginTop: '20px',
      },
    },
  },
};
