import { Controller } from 'react-hook-form';
import { Control } from 'react-hook-form/dist/types';
import { MenuItem } from '@mui/material';

import { KrakenSelect } from '@components/KrakenSelect/KrakenSelect';

import { IFinalReportFields } from '../types';

const list = ['Final', 'Draft'];

interface IProps {
  control: Control<IFinalReportFields>;
}

export const FRVersion = ({ control }: IProps) => (
  <Controller
    control={control}
    name="ReportVersion"
    render={({ field: { value, onChange } }) => (
      <KrakenSelect
        label="Version*"
        value={value || 'Final'}
        onChange={onChange}
      >
        {list.map((name) => (
          <MenuItem key={name} value={name}>
            {name}
          </MenuItem>
        ))}
      </KrakenSelect>
    )}
  />
);
