import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IconButton, Menu, MenuItem } from '@mui/material';

import Icon from '@components/Icon/Icon';
import { ERoles } from '@src/constants/constants';
import { LinkEnum } from '@src/types/types';
import { useIsInRole } from '@utils/hooks/useIsInRole';

import styles from '../ProjectsList.module.sass';

interface IProps {
  onDelete: (pid: string) => void;
  onDeactivate: (pid: string) => void;
  onActivate: (pid: string) => void;
  isActive: boolean;
  prId: string;
}

export const ProjectsListMenu = ({
  onDelete,
  onDeactivate,
  onActivate,
  isActive,
  prId,
}: IProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const menuRef = useRef(null);
  const { isInRole: isAdmin } = useIsInRole(ERoles.admin);
  const navigate = useNavigate();

  const handleClick = (e: any) => {
    e.preventDefault();
    setAnchorEl(menuRef.current);
  };

  const onClose = (e?: any) => {
    e?.preventDefault();
    setAnchorEl(null);
  };

  const onEdit = (e: any) => {
    e.preventDefault();
    navigate(LinkEnum.EDIT_PROJECT.replace(':pid', prId));
    onClose();
  };

  const deleteItem = (e: any) => {
    e.preventDefault();
    onDelete(prId);
    onClose();
  };

  const deactivateItem = (e: any) => {
    e.preventDefault();
    onDeactivate(prId);
    onClose();
  };

  const activateItem = (e: any) => {
    e.preventDefault();
    onActivate(prId);
    onClose();
  };

  return (
    <div className={styles.projectsListCardMenu}>
      <IconButton
        aria-label="more"
        id="project-button"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        ref={menuRef}
        onClick={handleClick}
      >
        <Icon iconName="more" />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'project-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={onClose}
        transformOrigin={{ horizontal: 'center', vertical: 'top' }}
      >
        <MenuItem onClick={onEdit}>Edit</MenuItem>
        {isActive ? (
          <MenuItem onClick={deactivateItem}>Deactivate</MenuItem>
        ) : (
          <MenuItem onClick={activateItem}>Activate</MenuItem>
        )}
        {isAdmin && <MenuItem onClick={deleteItem}>Delete</MenuItem>}
      </Menu>
    </div>
  );
};
