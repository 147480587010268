export enum EAddProjectLinks {
  COMPANY = 'Company',
  PROJECT_NAME = 'Project name',
  COMPONENT = 'Component name',
  PERIOD = 'Period',
  STATUS_TEXT = 'Notes',
}

export interface IProjectFields {
  prName: string;
  component?: string;
  coID: string;
  date: [Date | null, Date | null];
  external_project_link: string;
  regression: boolean;
  rating_justifications: boolean;
  severity_override: boolean;
  google_restricted_scope: boolean;
  testproject: boolean;
  isMasa: boolean;
  notes: string;
  logo: string;
}

export interface IOptions {
  [key: string]: {
    name: string;
    keyName: string;
  };
}
