import { signalNotificationHandler } from '@utils/signalR/types';

import { notifications } from './notifications';
import { queueConnection } from './queueConnection';

export const addHandler = (
  methodName: string,
  newMethod: signalNotificationHandler,
) => {
  queueConnection(() => {
    notifications.on(methodName, newMethod);
  });
};
