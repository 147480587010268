import { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';

import { LinkEnum } from '@src/types/types';
import {
  useGetApiFindingsByFidActivitiesQuery,
  useGetApiProjectsByPidQuery,
  useGetApiV2FindingsByFidQuery,
  usePostApiV2FindingsMutation,
  usePutApiV2FindingsByFidMutation,
} from '@store/services/query.generated';
import { getKeyByValue } from '@utils/getKeyByValue';
import { useActions } from '@utils/hooks/useActions';
import { useAWS } from '@utils/hooks/useAWS';

import { vectorString } from './FindingCVSS/constants';
import {
  difficultyList,
  impactList,
  mitigated,
  requiredStatusId,
  underInvestigation,
} from './constants';
import { schema } from './schema';
import { IFindingFields } from './types';

export const useAddEditFinding = () => {
  const [visibleBtn, setVisibleBtn] = useState(false);
  const [template, setTemplate] = useState<any>(null);
  const [pausedTemplate, setPausedTemplate] = useState<any>(null);
  const { pid, fid } = useParams();
  const isAWS = useAWS();
  const navigate = useNavigate();
  const { resetAddEditFinding } = useActions();
  const {
    control,
    watch,
    handleSubmit,
    reset,
    formState: { isDirty, isValid },
    setValue,
    getValues,
    unregister,
  } = useForm<IFindingFields>({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      cvss: vectorString,
      statusId: underInvestigation.toLowerCase(),
      ...(isAWS ? { AWSCategory: 'Audit and Logging' } : {}),
    },
  });
  const statusId = watch('statusId');

  const { data: finding, isLoading: findingIsLoading }: any = useGetApiV2FindingsByFidQuery({ fid: fid! }, { skip: !fid });
  const { data: projects, isLoading: projectsIsLoading } = useGetApiProjectsByPidQuery({ pid: pid! }, { skip: !pid });
  const { data: activity = [], isLoading: activityIsLoading } = useGetApiFindingsByFidActivitiesQuery({ fid: fid! }, { skip: !fid });
  const [postFinding] = usePostApiV2FindingsMutation();
  const [putFinding] = usePutApiV2FindingsByFidMutation();

  const setDataFromTemplate = () => {
    const {
 Impact, ImpactRationale, Difficulty, DifficultyRationale,
} = pausedTemplate;

    Impact
      && setValue('impact', getKeyByValue(impactList, Impact || 0) || 'Info');
    ImpactRationale && setValue('impactRationale', ImpactRationale);
    Difficulty
      && setValue(
        'difficulty',
        getKeyByValue(difficultyList, Difficulty || 3) || 'Simple',
      );
    DifficultyRationale && setValue('difficultyRationale', DifficultyRationale);

    setVisibleBtn(false);
  };

  const onSubmit: SubmitHandler<IFindingFields> = (data) => {
    const krakenModelsDtoFindingWriteDto: any = {
      templateId: data.templateId,
      name: data.name,
      ...(data.associatedActivities?.length
        ? { associatedActivities: data.associatedActivities }
        : {}),
      componentId: data.componentId,
      ...(data.impact !== null
        ? { impact: impactList[data.impact || 'Info'] }
        : {}),
      ...(data.impactRationale
        ? { impactRationale: data.impactRationale }
        : {}),
      ...(data.difficulty !== null
        ? { difficulty: difficultyList[data.difficulty || 'Simple'] }
        : {}),
      ...(data.overriddenSeverity
        ? { overriddenSeverity: data.overriddenSeverity }
        : {}),
      ...(data.difficultyRationale
        ? { difficultyRationale: data.difficultyRationale }
        : {}),
      ...(data.description ? { description: data.description } : {}),
      ...(data.observation ? { observation: data.observation } : {}),
      ...(data.recommendation ? { recommendation: data.recommendation } : {}),
      ...(data.location ? { location: data.location } : {}),
      ...(data.reference ? { reference: data.reference } : {}),
      ...(data.notes ? { notes: data.notes } : {}),
      ...(data.cvss !== vectorString ? { cvss: data.cvss } : {}),
      ...(data.awsCategory ? { awsCategory: data.awsCategory } : {}),
      ...(data.cwe
        ? {
            cweId: typeof data.cwe === 'number' ? data.cwe : data.cwe.Id,
          }
        : {}),
    };

    if (data.statusId) {
      krakenModelsDtoFindingWriteDto.statusId = data.statusId;
      if (requiredStatusId.includes(data.statusId)) {
        krakenModelsDtoFindingWriteDto.mitigation = data.mitigation;
        if (data.statusId === mitigated) {
          krakenModelsDtoFindingWriteDto.residualImpact = impactList[data.residualImpact || 'Info'];
          krakenModelsDtoFindingWriteDto.residualDifficulty = difficultyList[data.residualDifficulty || 'Simple'];
        }
      }
    }

    fid
      ? putFinding({
          fid,
          krakenModelsDtoFindingWriteDto,
        })
      : postFinding({ krakenModelsDtoFindingWriteDto }).then((result) => {
          if ('data' in result && result?.data && pid) {
            const uri = LinkEnum.EDIT_FINDING.replace(':pid', pid).replace(
              ':fid',
              result.data,
            );

            uri && navigate(uri);
          }
        });
  };

  useEffect(() => {
    if (finding) {
      reset({
        ...getValues(),
        templateId: finding.templateId,
        name: finding.name,
        componentId: finding.componentId,
        impact: getKeyByValue(impactList, finding.impact),
        impactRationale: finding.impactRationale,
        difficulty: getKeyByValue(difficultyList, finding.difficulty),
        difficultyRationale: finding.difficultyRationale,
        statusId: finding.statusId,
        awsCategory: finding.awsCategory,
        overriddenSeverity: finding.overriddenSeverity,
        residualImpact: getKeyByValue(impactList, finding.residualImpact),
        residualDifficulty: getKeyByValue(
          difficultyList,
          finding.residualDifficulty,
        ),
        cwe: finding.cweId,
        mitigation: finding.mitigation,
        cvss: finding.cvss || vectorString,
        description: finding.description,
        observation: finding.observation,
        recommendation: finding.recommendation,
        location: finding.location,
        reference: finding.reference,
        notes: finding.notes,
      });
    }
  }, [finding]);

  useEffect(() => {
    if (template) {
      const {
        Title,
        Description,
        Observation,
        Recommendation,
        Notes,
        Reference,
        Impact,
        ImpactRationale,
        Difficulty,
        DifficultyRationale,
      } = template;

      Title && setValue('name', Title);
      Description && setValue('description', Description);
      Observation && setValue('observation', Observation);
      Recommendation && setValue('recommendation', Recommendation);
      Notes && setValue('notes', Notes);
      Reference && setValue('reference', Reference);

      if (Impact || ImpactRationale || Difficulty || DifficultyRationale) {
        setPausedTemplate({
          Impact,
          ImpactRationale,
          Difficulty,
          DifficultyRationale,
        });
        setVisibleBtn(true);
      }
    }
  }, [template]);

  useEffect(() => {
    reset({
      ...getValues(),
      isAWS,
    });
  }, [isAWS]);

  useEffect(() => {
    if (activity && activity.length) {
      reset({
        ...getValues(),
        associatedActivities: activity,
      });
    }
  }, [activity]);

  useEffect(() => {
    if (statusId && !requiredStatusId.includes(statusId)) {
      unregister(['mitigation', 'residualImpact', 'residualDifficulty']);
    } else if (statusId !== mitigated) {
      unregister(['residualImpact', 'residualDifficulty']);
    }
  }, [unregister, statusId]);

  useEffect(
    () => () => {
      resetAddEditFinding();
    },
    [],
  );

  return {
    fid,
    prName: projects?.prName || '',
    isLoading: projectsIsLoading || findingIsLoading || activityIsLoading,
    severity_override: projects?.severity_override || null,
    isAWS,
    readyToSubmit: isDirty && isValid,
    visibleBtn,
    onSubmit: handleSubmit(onSubmit),
    control,
    watch,
    statusId,
    setTemplate,
    setDataFromTemplate,
    setValue,
    getValues,
  };
};
