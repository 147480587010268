import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { xcsrf } from '@src/constants/constants';
import {
  GetApiStereotypesApiResponse,
  useDeleteApiComponentsByCidStereotypeAndSidMutation,
  useGetApiStereotypesByProjectByPidQuery,
  useGetApiStereotypesQuery,
  usePutApiComponentsByCidAddActivitiesFromStereotypesMutation,
} from '@store/services/query.generated';
import { useActions } from '@utils/hooks/useActions';

export const useComponentStereotypes = (componentId?: string) => {
  const [value, setValue] = useState<GetApiStereotypesApiResponse>([]);
  const [allStereotypes, setAllStereotypes] = useState<GetApiStereotypesApiResponse>([]);
  const { pid, cid } = useParams();
  const { updateAddEditComponentField } = useActions();

  const { data: stereotypes = [], isLoading: isLoadingAllStereotypes } = useGetApiStereotypesQuery(xcsrf);
  const { data: stereotype = [] } = useGetApiStereotypesByProjectByPidQuery({
    pid: pid!,
  });
  const [putStereotype] = usePutApiComponentsByCidAddActivitiesFromStereotypesMutation();
  const [deleteStereotype] = useDeleteApiComponentsByCidStereotypeAndSidMutation();

  const onChange = (e: any, newValue: GetApiStereotypesApiResponse) => {
    e.stopPropagation();

    if (cid || componentId) {
      if (newValue.length > value.length) {
        const body = newValue.map((b) => b.Id!).filter(Boolean);

        putStereotype({ cid: cid || componentId || '', body });
      } else if (value.length > newValue.length) {
        const sid = value.find((b) => !newValue.some((s) => s.Id === b.Id))?.Id;

        (cid || componentId)
          && sid
          && deleteStereotype({ cid: (cid || componentId)!, sid });
      }
    }

    setValue(newValue);
  };

  const onClick = (e: any) => {
    e.stopPropagation();
  };

  useEffect(() => {
    if (stereotypes.length) {
      setAllStereotypes(stereotypes.filter((f) => f?.NumActivities) || []);
    }
  }, [stereotypes]);

  useEffect(() => {
    if (stereotype.length) {
      const stereotypesByCid = stereotype.filter(
          (item) => item.pc_id === cid || item.pc_id === componentId,
        ) || [];

      if (stereotypesByCid.length) {
        const filteredValue = allStereotypes.filter((item) => stereotype.some((i) => item.Id === i.sid));

        setValue(filteredValue);
        updateAddEditComponentField({
          field: 'stereotypes',
          value: stereotypesByCid,
        });
      }
    }
  }, [stereotype, allStereotypes]);

  return {
    isLoadingAllStereotypes,
    allStereotypes,
    value,
    onChange,
    onClick,
  };
};
