import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IDownloadPDF {
  visible: boolean;
  method: 'GET' | 'POST' | 'PUT' | 'DELETE';
  payload?: any;
  uri: string;
  fileName: string;
}

const initialState: IDownloadPDF = {
  visible: false,
  method: 'POST',
  payload: null,
  uri: '',
  fileName: '',
};

const downloadFileSlice = createSlice({
  name: 'downloadFile',
  initialState,
  reducers: {
    showDownloadFilePopup(
      state: IDownloadPDF,
      {
        payload: {
 uri, method = 'POST', payload = null, fileName,
},
      }: PayloadAction<any>,
    ) {
      state.visible = true;
      state.method = method;
      state.payload = payload;
      state.uri = uri;
      state.fileName = fileName;
    },
    hideDownloadFilePopup: () => initialState,
  },
});

const { reducer, actions } = downloadFileSlice;

export default reducer;
export const { showDownloadFilePopup, hideDownloadFilePopup } = actions;
