import { Controller } from 'react-hook-form';
import { Control } from 'react-hook-form/dist/types';

import { KrakenTextArea } from '@components/KrakenTextArea/KrakenTextArea';
import { textAreaHandler } from '@utils/textAreaHandler';

import { IFindingFields } from '../types';

const placeholder = 'Find credible sources that describe the general issue or vulnerability';

interface IProps {
  control: Control<IFindingFields>;
}

export const FindingReferences = ({ control }: IProps) => (
  <Controller
    control={control}
    name="reference"
    render={({ field: { value, onChange } }) => (
      <KrakenTextArea
        title="References"
        value={value || ''}
        onChange={(val) => textAreaHandler(val, onChange)}
        placeholder={placeholder}
        height={180}
        hideToolbar
      />
    )}
  />
);
