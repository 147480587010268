import { useNavigate, useParams } from 'react-router-dom';

import { LinkEnum } from '@src/types/types';
import { useDeleteApiProjectsDeleteByPidMutation } from '@store/services/query.generated';

export const useDeleteProjectConfirmation = () => {
  const { pid } = useParams();
  const navigate = useNavigate();

  const [deletePr] = useDeleteApiProjectsDeleteByPidMutation();

  return () => {
    pid && deletePr({ pid }).then(() => navigate(LinkEnum.PROJECTS));
  };
};
