import cn from 'classnames';
import React, { useEffect, useState } from 'react';
import {
 IconButton, Menu, MenuItem, Tooltip, Zoom,
} from '@mui/material';

import Icon from '@components/Icon/Icon';
import s from '@components/Sidebar/Sidebar.module.sass';
import { useActions } from '@utils/hooks/useActions';

import { ECheckedTheme } from './types';

const body = document.querySelector('body');

const getSavedScheme = () => localStorage.getItem('color-scheme') as ECheckedTheme | null;
const clearScheme = () => localStorage.removeItem('color-scheme');
const getSystemScheme = () => {
  const darkThemeMq = window.matchMedia('(prefers-color-scheme: dark)');
  const darkScheme = darkThemeMq.matches;

  return darkScheme ? 'dark' : 'light';
};

export const ThemeSwitcher = () => {
  const [checked, setChecked] = useState<ECheckedTheme>(ECheckedTheme.auto);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { updateTheme } = useActions();

  const onTheme = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const saveScheme = (scheme: ECheckedTheme) => {
    localStorage.setItem('color-scheme', scheme);
    body?.setAttribute('data-theme', scheme);
    updateTheme(scheme);
  };

  const setScheme = (scheme: ECheckedTheme) => {
    const systemScheme = getSystemScheme() as ECheckedTheme;

    setChecked(scheme);

    if (scheme === 'auto') {
      body?.setAttribute('data-theme', systemScheme);
      updateTheme(systemScheme);
      clearScheme();
    } else {
      saveScheme(scheme);
    }
  };

  useEffect(() => {
    const savedScheme = getSavedScheme();
    const systemScheme = getSystemScheme() as ECheckedTheme;

    if (savedScheme !== null) {
      setChecked(savedScheme);
      body?.setAttribute('data-theme', savedScheme);
      updateTheme(savedScheme);
    } else {
      setChecked(ECheckedTheme.auto);
      body?.setAttribute('data-theme', systemScheme);
      updateTheme(systemScheme);
    }
  }, []);

  return (
    <>
      <Tooltip title="Theme" placement="right" TransitionComponent={Zoom}>
        <IconButton
          onClick={onTheme}
          aria-controls={open ? 'theme-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
        >
          <Icon iconName={checked} />
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        id="theme-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        PaperProps={{
          style: {
            width: 168,
            transform: 'translateX(10px)',
            padding: 12,
          },
        }}
        MenuListProps={{
          style: {
            padding: 0,
          },
        }}
      >
        <MenuItem
          className={cn(s.menuItem, {
            [s.active]: checked === ECheckedTheme.light,
          })}
          onClick={() => setScheme(ECheckedTheme.light)}
        >
          <span className={s.item}>
            <Icon iconName="light" />
            Light mode
          </span>
        </MenuItem>
        <MenuItem
          className={cn(s.menuItem, {
            [s.active]: checked === ECheckedTheme.dark,
          })}
          onClick={() => setScheme(ECheckedTheme.dark)}
        >
          <span className={s.item}>
            <Icon iconName="dark" />
            Dark Mode
          </span>
        </MenuItem>
        <MenuItem
          className={cn(s.menuItem, {
            [s.active]: checked === ECheckedTheme.auto,
          })}
          onClick={() => setScheme(ECheckedTheme.auto)}
        >
          <span className={s.item}>
            <Icon iconName="auto" />
            OS Mode
          </span>
        </MenuItem>
      </Menu>
    </>
  );
};
