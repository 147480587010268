import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  GetApiActivitiesByAidApiResponse,
  KrakenBackendApiModelsActivityTemplatesApiActivityTemplateChecklistItem,
} from '@store/services/query.generated';

export interface IAddEditActivity {
  isEdit: boolean;
  activityName: string;
  componentId: string;
  scope: string;
  checklist: KrakenBackendApiModelsActivityTemplatesApiActivityTemplateChecklistItem[];
  methodology: string;
  observations: string;
  futureWork: string;
  findings: string[];
  status: string | null;
  forecastEndDate: string;
  forecastStartDate: string;
  forecastUnblockDate: string;
  notes: string;
  dataFromServer: any;
}

const initialState: IAddEditActivity = {
  isEdit: false,
  activityName: '',
  componentId: '',
  scope: '',
  checklist: [],
  methodology: '',
  observations: '',
  futureWork: '',
  findings: [],
  status: null,
  forecastEndDate: '',
  forecastStartDate: '',
  forecastUnblockDate: '',
  notes: '',
  dataFromServer: null,
};

const addEditActivitySlice = createSlice({
  name: 'addEditActivity',
  initialState,
  reducers: {
    updateActivityFields(
      state: IAddEditActivity,
      {
        payload: { field, value },
      }: PayloadAction<{ field: string; value: any }>,
    ) {
      switch (field) {
        case 'activityName':
          state.activityName = value;
          break;
        case 'componentId':
          state.componentId = value;
          break;
        case 'scope':
          state.scope = value;
          break;
        case 'checklist':
          state.checklist = value;
          break;
        case 'methodology':
          state.methodology = value;
          break;
        case 'observations':
          state.observations = value;
          break;
        case 'futureWork':
          state.futureWork = value;
          break;
        case 'findings':
          state.findings = value;
          break;
        case 'status':
          state.status = value;
          break;
        case 'forecastEndDate':
          state.forecastEndDate = value;
          break;
        case 'forecastStartDate':
          state.forecastStartDate = value;
          break;
        case 'forecastUnblockDate':
          state.forecastUnblockDate = value;
          break;
        case 'notes':
          state.notes = value;
          break;
        default:
          return state;
      }
    },
    updateActivity(
      state: IAddEditActivity,
      {
        payload: {
          Subject,
          PcId,
          Scope,
          Checklist,
          Description,
          Disposition,
          FutureWork,
          DeclaredStatus,
          forecast_end_date,
          forecast_start_date,
          forecast_unblock_date,
          Notes,
        },
      }: PayloadAction<GetApiActivitiesByAidApiResponse>,
    ) {
      state.isEdit = true;
      state.activityName = Subject as string;
      state.componentId = PcId as string;
      state.scope = Scope as string;
      state.checklist = Checklist as KrakenBackendApiModelsActivityTemplatesApiActivityTemplateChecklistItem[];
      state.methodology = Description as string;
      state.observations = Disposition as string;
      state.futureWork = FutureWork as string;
      // state.findings = payload.findings as string[]
      state.status = DeclaredStatus as string;
      state.forecastEndDate = forecast_end_date as string;
      state.forecastStartDate = forecast_start_date as string;
      state.forecastUnblockDate = forecast_unblock_date as string;
      state.notes = Notes as string;
      state.dataFromServer = {
        activityName: Subject,
        componentId: PcId,
        scope: Scope,
        checklist: Checklist,
        methodology: Description,
        observations: Disposition,
        futureWork: FutureWork,
        status: DeclaredStatus,
        forecastEndDate: forecast_end_date,
        forecastStartDate: forecast_start_date,
        forecastUnblockDate: forecast_unblock_date,
        notes: Notes,
      };
    },
    resetActivity: () => initialState,
  },
});

const { reducer, actions } = addEditActivitySlice;

export default reducer;
export const { updateActivityFields, updateActivity, resetActivity } = actions;
