import { useParams } from 'react-router-dom';

import { useGetApiComponentsByPidQuery } from '@store/services/query.generated';

export const useFindingComponent = () => {
  const { pid } = useParams();
  const { data: components = [], isLoading } = useGetApiComponentsByPidQuery({
    pid: pid!,
  });

  return {
    components,
    isLoading,
  };
};
