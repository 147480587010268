import { useEffect } from 'react';

import { FBUDates } from '@pages/Dashboard/Widgets/FindingsByUser/FBUDates/FBUDates';
import { useTypedSelector } from '@src/utils';
import { useActions } from '@utils/hooks/useActions';

import { FBUFindings } from './FBUFindings/FBUFindings';
import { FBUList } from './FBUList/FBUList';
import { FBUUsers } from './FBUUsers/FBUUsers';

import s from './FindingsByUser.module.sass';

export const FindingsByUser = () => {
  const { resetFBU } = useActions();

  const { humanId, showPopup } = useTypedSelector(
    (state) => state.findingsByUserReducer,
  );

  useEffect(
    () => () => {
      resetFBU();
    },
    [],
  );

  return (
    <div className={s.wrapper}>
      <h2>Findings by User</h2>
      <div className={s.headerWrapper}>
        <FBUUsers />
        {humanId && <FBUDates />}
      </div>
      {humanId && <FBUList />}
      {showPopup && <FBUFindings />}
    </div>
  );
};
