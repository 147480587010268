import { ReactElement, ReactNode } from 'react';

interface IProps {
  handler: (fid?: string | undefined, sid?: string) => void;
  children: ReactNode | ReactElement | ReactElement[];
  classname?: string;
  fid?: string | undefined;
  sid?: string;
  // All others properties
  [x: string]: any;
}

export const KrakenListItem = ({
  classname = '',
  handler,
  children,
  fid = '',
  sid = '',
  ...other
}: IProps) => {
  const onItem = (e: any) => {
    if ('code' in e && (e.code !== 'Enter' || e.code !== 'Space')) {
      return;
    }

    handler(fid, sid);
  };

  return (
    <div
      className={classname}
      onClick={onItem}
      onKeyDown={onItem}
      aria-label="Row"
      role="link"
      tabIndex={0}
      {...other}
    >
      {children}
    </div>
  );
};
