const defaultStyles = {
  marginBottom: '32px',
};

export const MuiFormControlLight = {
  styleOverrides: {
    root: {
      ...defaultStyles,
    },
  },
};

export const MuiFormControlDark = {
  styleOverrides: {
    root: {
      ...defaultStyles,
      '& textarea': {
        color: '#fff',
      },
    },
  },
};
