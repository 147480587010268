import { ERoles } from '@src/constants/constants';
import { useGetApiCompaniesAllTerseQuery } from '@store/services/query.generated';
import { useActions } from '@utils/hooks/useActions';
import { useIsInRole } from '@utils/hooks/useIsInRole';

export const useProjectCompany = () => {
  const { setCompaniesPopup } = useActions();
  const { isInRole: isAdmin } = useIsInRole(ERoles.admin);

  const { data: companies, isLoading } = useGetApiCompaniesAllTerseQuery({
    search: '',
  });

  const onAddCompany = async () => setCompaniesPopup(true);

  return {
    isAdmin,
    onAddCompany,
    companies,
    isLoading,
  };
};
