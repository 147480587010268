const defaultStyles = {
  defaultProps: {
    variant: 'contained',
  },
  styleOverrides: {
    root: {
      fontSize: '14px',
      fontWeight: 400,
      borderRadius: '56px',
      borderWidth: '2px',
      borderStyle: 'solid',
      outlineWidth: '1px',
      outlineStyle: 'solid',
      outlineColor: 'transparent',
      lineHeight: '19px',
      gap: '10px',
      '& .MuiChip-icon': {
        margin: 0,
      },
      '& .MuiChip-label': {
        padding: 0,
      },
      '&:focus': {
        borderColor: '#A3EED6',
        outlineColor: '#A3EED6',
      },
    },
    filled: {
      background: '#F0EFFF',
      color: '#544DA9',
      borderColor: '#877CFF',
      borderRadius: '56px',
      borderWidth: '2px',
      borderStyle: 'solid',
      outlineWidth: '1px',
      outlineStyle: 'solid',
      outlineColor: 'transparent',
      '& .MuiChip-label': {
        color: '#877CFF',
      },
      '& path': {
        fill: '#877CFF',
      },
      '&:focus': {
        borderColor: '#877CFF',
        outlineColor: '#877CFF',
        background: '#fff',
        color: '#544DA9',
      },
      '&:hover': {
        background: '#fff !important',
        color: '#544DA9',
        '& path': {
          fill: '#877CFF !important',
        },
        '& .MuiChip-label': {
          color: '#877CFF',
        },
      },
    },
  },
  variants: [
    {
      props: { size: 'medium' },
      style: {
        padding: '4px 13px',
        height: '35px',
      },
    },
  ],
};

export const MuiChipLight = {
  ...defaultStyles,
  styleOverrides: {
    ...defaultStyles.styleOverrides,
    root: {
      ...defaultStyles.styleOverrides.root,
      borderColor: '#EAEAEE',
      '& .MuiChip-icon': {
        margin: 0,
      },
      '&:focus': {
        borderColor: '#D1D1D9',
        outlineColor: '#D1D1D9',
      },
    },
  },
};

export const MuiChipDark = {
  ...defaultStyles,
  styleOverrides: {
    ...defaultStyles.styleOverrides,
    root: {
      ...defaultStyles.styleOverrides.root,
      background: '#565C71',
      borderColor: '#717786',
      color: '#fff',
      '& .MuiChip-icon': {
        margin: 0,
        '& path': {
          fill: '#fff',
        },
      },
      '& .MuiChip-label': {
        padding: 0,
        color: '#fff',
      },
      '&:hover': {
        background: '#677389 !important',
      },
    },
  },
};
