import { ESeverityList } from './types';

const awsMatrix = [
  ESeverityList.INFO,
  ESeverityList.LOW,
  ESeverityList.LOW, // 1-2
  ESeverityList.MEDIUM,
  ESeverityList.MEDIUM,
  ESeverityList.MEDIUM, // 3-5
  ESeverityList.HIGH,
  ESeverityList.HIGH,
  ESeverityList.HIGH,
  ESeverityList.HIGH,
  ESeverityList.HIGH,
  ESeverityList.HIGH,
  ESeverityList.HIGH, // 6-12
];

const lsgMatrix = [
  ESeverityList.INFO,
  ESeverityList.LOW,
  ESeverityList.LOW,
  ESeverityList.LOW, // 1-3
  ESeverityList.MEDIUM,
  ESeverityList.MEDIUM,
  ESeverityList.MEDIUM, // 4-6
  ESeverityList.HIGH,
  ESeverityList.HIGH,
  ESeverityList.HIGH, // 7-9
  ESeverityList.CRITICAL,
  ESeverityList.CRITICAL,
  ESeverityList.CRITICAL, // 10-12
];

export const awsSeverityText = (severity: number): string => {
  if (severity < 0 || severity > 12) return ESeverityList.INFO;
  return awsMatrix[severity];
};

export const LsgSeverityText = (severity: number): string => {
  if (severity < 0 || severity > 12) return ESeverityList.INFO;
  return lsgMatrix[severity];
};
export const severityScoreToText = (risk: number, isAws: boolean) => (isAws ? awsSeverityText(risk) : LsgSeverityText(risk));

export const getSeverityText = (
  impact: number,
  difficulty: number,
  isAws: boolean,
) => severityScoreToText(impact * difficulty, isAws);

// TODO: Switch to getSeverityText
export const getSeverity = (impact: number, difficulty: number) => getSeverityText(impact, difficulty, false);

export const severityOverrideOptions: { [key: string]: string } = {
  [ESeverityList.INFO]: '0',
  [ESeverityList.LOW]: '1',
  [ESeverityList.MEDIUM]: '4',
  [ESeverityList.HIGH]: '7',
  [ESeverityList.CRITICAL]: '10',
  None: '',
};
