import { useEffect, useState } from 'react';
import { UseFormWatch } from 'react-hook-form';

import { ICheckListLink } from '@components/CheckList/CheckList';
import { isValidDate } from '@utils/dateUtils';

import { ESRLinks, IStatusReportFields } from '../types';

const linkState: ICheckListLink = {
  [ESRLinks.LAST_STATUS_DATE]: false,
  [ESRLinks.REPORT_DATE]: false,
  [ESRLinks.ADDRESS_AND_CONTACT_INFO]: false,
  [ESRLinks.STAKEHOLDERS]: false,
  [ESRLinks.BACKGROUND]: false,
  [ESRLinks.CURRENT_STATUS]: false,
  [ESRLinks.PROJECT_STATE]: false,
  [ESRLinks.FONTS]: true,
};

export const useChecklist = (watch: UseFormWatch<IStatusReportFields>) => {
  const [links, setLinks] = useState(linkState);

  useEffect(() => {
    const subscription = watch((value) => {
      setLinks({
        [ESRLinks.LAST_STATUS_DATE]: isValidDate(value.lastStatusDate as Date),
        [ESRLinks.REPORT_DATE]: Boolean(value.ReportDate),
        [ESRLinks.ADDRESS_AND_CONTACT_INFO]: Boolean(
          value.AddressAndContactInfo,
        ),
        [ESRLinks.STAKEHOLDERS]: Boolean(value.Stakeholders),
        [ESRLinks.BACKGROUND]: Boolean(value.Background),
        [ESRLinks.CURRENT_STATUS]: Boolean(value.CurrentStatus),
        [ESRLinks.PROJECT_STATE]: Boolean(value.ProjectState),
        [ESRLinks.FONTS]: true,
      });
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  return links;
};
