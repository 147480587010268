import cn from 'classnames';
import { UseFormWatch } from 'react-hook-form';
import { Button, Menu, MenuItem } from '@mui/material';

import Icon from '@components/Icon/Icon';
import { KrakenTooltip } from '@components/KrakenTooltip/KrakenTooltip';
import { useTypedSelector } from '@src/utils';

import { IFindingFields } from '../types';

import { useFindingActions } from './useFindingActions';

import s from '../AddEditFindings.module.sass';

const styles = {
  paper: {
    width: 204,
    transform: 'translateY(-10px)',
    padding: '0',
  },
};

interface IProps {
  watch: UseFormWatch<IFindingFields>;
}

export const FindingActions = ({ watch }: IProps) => {
  const {
 anchorEl, open, handleClick, handleClose, list,
} = useFindingActions(watch);
  const { isEditor } = useTypedSelector((state) => state.addEditFindingReducer);
  const { hasSignalRConnection } = useTypedSelector(
    (state) => state.signalRReducer,
  );

  return (
    <>
      <Button
        className={s.allActions}
        aria-controls={open ? 'all-actions-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="contained"
        disableElevation
        onClick={handleClick}
        endIcon={<Icon iconName="arrow" />}
        size="small"
        disabled={!isEditor || !hasSignalRConnection}
      >
        All Actions
      </Button>
      <Menu
        id="all-actions-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ horizontal: 'left', vertical: 'bottom' }}
        PaperProps={{
          style: styles.paper,
        }}
      >
        {list.map(({
 tooltip, name, icon, handler, disabled, hidden,
}) => (
  <KrakenTooltip
    key={name}
    enableTooltip={!!tooltip}
    title={tooltip}
    placement="right-start"
  >
    <MenuItem
      className={cn(s.menuItem, { [s.red]: icon === 'deleteFinding' })}
      onClick={disabled ? undefined : handler}
      disableRipple
      disabled={disabled}
      sx={{
                display: `${hidden ? 'none' : 'inline-flex'}`,
                pointerEvents: 'auto !important',
              }}
    >
      <Icon
        iconName={icon}
        className={cn(s.icon, { [s.red]: icon === 'deleteFinding' })}
      />
      {name}
    </MenuItem>
  </KrakenTooltip>
        ))}
      </Menu>
    </>
  );
};
