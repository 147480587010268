import { forwardRef, ReactElement, Ref } from 'react';
import AppBar from '@mui/material/AppBar';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import Slide from '@mui/material/Slide';
import Toolbar from '@mui/material/Toolbar';
import { TransitionProps } from '@mui/material/transitions';
import Typography from '@mui/material/Typography';

import Icon from '@components/Icon/Icon';

import s from './FullScreenDialog.module.sass';

const Transition = forwardRef(
  (
    props: TransitionProps & {
      children: ReactElement;
    },
    ref: Ref<unknown>,
  ) => <Slide direction="up" ref={ref} {...props} />,
);

interface IProps {
  open: boolean;
  onClose: () => void;
  children: any;
  title: string;
}

export function FullScreenDialog({
 open, onClose, children, title,
}: IProps) {
  return (
    <Dialog
      fullScreen
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
    >
      <AppBar sx={{ position: 'relative', background: '#877CFF' }}>
        <Toolbar>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            {title}
          </Typography>
          <IconButton
            edge="end"
            color="inherit"
            onClick={onClose}
            aria-label="close"
            sx={{
              '& path': {
                fill: '#fff',
              },
            }}
          >
            <Icon iconName="close" />
          </IconButton>
        </Toolbar>
      </AppBar>
      <div className={s.content}>{children}</div>
    </Dialog>
  );
}
