import { showError } from '@store/reducers/errorSlice';
import { store } from '@store/store';

export const downloadFile = (blob: Blob, fileName: string) => {
  try {
    // Create blob link to download
    const url = window.URL.createObjectURL(new Blob([blob]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);

    // Append to html link element page
    document.body.appendChild(link);

    // Start download
    link.click();

    // Clean up and remove the link
    link.parentNode?.removeChild(link);
  } catch (e) {
    store.dispatch(showError(e as Error));
  }
};
