import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Chip, useTheme } from '@mui/material';

import {
  retracted,
  severityColorScheme,
} from '@pages/Project/Findings/AddEditFinding/constants';
import { getSeverity } from '@pages/Project/Findings/AddEditFinding/helper';
import {
  GetApiFindingsProjectByPidFallbackApiResponse,
  useGetApiFindingsProjectByPidFallbackQuery,
} from '@store/services/query.generated';
import { kebabCase } from '@utils/kebabCase';

import { EAddEditComponentLinks } from '../types';

import s from './ComponentFindings.module.sass';

export const ComponentFindings = () => {
  const [findings, setFindings] = useState<GetApiFindingsProjectByPidFallbackApiResponse>([]);
  const { pid, cid } = useParams();
  const {
    palette: { mode },
  } = useTheme();
  const { data = [] } = useGetApiFindingsProjectByPidFallbackQuery(
    { pid: pid! },
    { skip: !pid },
  );

  useEffect(() => {
    if (data.length) {
      const formattedData = data.filter(
        ({ finding_status_id, component_id }) => finding_status_id !== retracted && component_id === cid,
      );

      setFindings(formattedData);
    }
  }, [data]);

  return (
    <>
      <h3 id={kebabCase(EAddEditComponentLinks.FINDINGS)}>Findings</h3>
      <div className={s.wrapper}>
        {findings?.map(
          ({
 finding_id, finding_ref, summary, impact, difficulty,
}) => (
  <Chip
    key={finding_id}
    label={`(${finding_ref}) ${summary}`}
    sx={{
                background:
                  severityColorScheme[mode || 'light'][
                    getSeverity(impact!, difficulty!)
                  ].bg,
                color:
                  severityColorScheme[mode || 'light'][
                    getSeverity(impact!, difficulty!)
                  ].color,
              }}
  />
          ),
        )}
      </div>
    </>
  );
};
