import { Controller } from 'react-hook-form';
import { Control } from 'react-hook-form/dist/types';

import { Datepicker } from '@components/Datepicker/Datepicker';
import { kebabCase } from '@utils/kebabCase';

import { ESRLinks, IStatusReportFields } from '../types';

interface IProps {
  control: Control<IStatusReportFields>;
}

export const SRStatusDate = ({ control }: IProps) => (
  <Controller
    control={control}
    name="lastStatusDate"
    render={({ field: { value, onChange } }) => (
      <Datepicker
        id={kebabCase(ESRLinks.LAST_STATUS_DATE)}
        label="Last status date"
        startDate={value}
        onChange={onChange}
      />
    )}
  />
);
