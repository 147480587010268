import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';

import { Footer } from '@components/Footer/Footer';
import { LinkEnum } from '@src/types/types';

export const CompaniesFooter = () => {
  const navigate = useNavigate();

  const onClick = () => {
    navigate(LinkEnum.ADD_COMPANY, {
      state: { linkTemplate: LinkEnum.ADD_COMPANY },
    });
  };

  return (
    <Footer>
      <Button size="small" onClick={onClick}>
        Add Company
      </Button>
    </Footer>
  );
};
