export enum ESRLinks {
  LAST_STATUS_DATE = 'Last status date',
  REPORT_DATE = 'Report date',
  ADDRESS_AND_CONTACT_INFO = 'Address and contact info',
  STAKEHOLDERS = 'Stakeholders',
  BACKGROUND = 'Background',
  CURRENT_STATUS = 'Current status',
  PROJECT_STATE = 'Project state',
  FONTS = 'Fonts',
}

export interface IStatusReportFields {
  lastStatusDate: Date | null;
  ReportDate: Date | null;
  AddressAndContactInfo: string | null;
  Stakeholders: string | null;
  ProjectState: string | null;
  Background: string | null;
  CurrentStatus: string | null;
  AddAppendixA: boolean;
  AppendixAHeader: string | null;
  AppendixABody: string | null;
  MainFont: string;
  HeaderFooter: string;
  Classification: string;
}
