import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { LinkEnum } from '@src/types/types';

const initialCrumb = [['All projects', LinkEnum.PROJECTS]];

export const useComponentBreadCrumbs = () => {
  const [crumbs, setCrumbs] = useState(initialCrumb);
  const { pid } = useParams();

  useEffect(() => {
    if (pid) {
      setCrumbs([
        ...initialCrumb,
        ['All Components', LinkEnum.COMPONENTS.replace(':pid', pid!)],
      ]);
    }
  }, [pid]);

  return crumbs;
};
