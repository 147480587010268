import {
  BaseQueryFn,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query';

import { BASE_URL } from '@src/constants/environment';
import { getToken, isValidToken } from '@src/utils';
import { hideError, showError } from '@store/reducers/errorSlice';

const baseUrl = BASE_URL;

const baseQuery = fetchBaseQuery({
  baseUrl,
  prepareHeaders: async (headers) => {
    const idToken = () => localStorage.getItem('idToken');

    headers.set('Access-Control-Allow-Origin', '*');
    headers.set('X-CSRF', '1');
    headers.set('redirect', 'error');
    headers.set('mode', 'cors');
    headers.set('Content-Type', 'application/json');

    if (idToken() && isValidToken()) {
      headers.set('Authorization', `Bearer ${idToken()}`);
    } else {
      const token = await getToken();

      if (token && isValidToken()) {
        headers.set('Authorization', `Bearer ${token}`);
      } else {
        headers.delete('authorization');
      }
    }

    return headers;
  },
});

const baseQueryWithReAuth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);

  if (result.error && result.error.status === 401) {
    await new Promise((resolve) => {
      const onStorage = async () => {
        if (
          localStorage.getItem('isRefresh')
          && localStorage.getItem('authSuccess')
        ) {
          localStorage.removeItem('isRefresh');
          localStorage.removeItem('authSuccess');
          api.dispatch(hideError());
          window.removeEventListener('storage', onStorage);

          result = await baseQuery(args, api, extraOptions);
          resolve(true);
        }
      };

      api.dispatch(
        showError({
          message:
            'Please press the button "Refresh Auth"<br />if nothing changes, please re-authorize',
          title: 'Authorization error',
          is401: true,
        }),
      );

      window.addEventListener('storage', onStorage);
    });
  }

  return result;
};

export default baseQueryWithReAuth;
