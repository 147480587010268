import { parseISO } from 'date-fns';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';

import { IExecutiveSummaryFields } from '@pages/Reporting/ExecutiveSummary/types';
import {
  yupRequiredDateRange,
  yupRequiredString,
} from '@src/constants/constants';
import {
  useGetApiProjectsByPidExecsummaryQuery,
  useGetApiProjectsByPidQuery,
  usePutApiProjectsByPidDatesMutation,
  usePutApiProjectsByPidExecsummaryMutation,
} from '@store/services/query.generated';
import { isValidDate, toLocalDate } from '@utils/dateUtils';
import { useWritingPrompts } from '@utils/hooks/useWritingPrompts';

const schema = yup.object().shape({
  Scope: yupRequiredString,
  Dates: yupRequiredDateRange,
  Objectives: yupRequiredString,
  Observations: yupRequiredString,
  Recommendations: yupRequiredString,
  Notes: yupRequiredString,
});

export const useExecutiveSummary = () => {
  const [readyToSubmit, setReadyToSubmit] = useState(false);
  const { observationsText, recommendationsText, objectiveText } = useWritingPrompts();
  const {
    control,
    handleSubmit,
    watch,
    reset,
    formState: { isDirty, isValid },
    setValue,
  } = useForm<IExecutiveSummaryFields>({
    mode: 'onChange',
    resolver: yupResolver(schema),
  });
  const { pid } = useParams();

  const { data: executiveSummary, isLoading: ESLoading } = useGetApiProjectsByPidExecsummaryQuery({ pid: pid! }, { skip: !pid });
  const { data: currentProject, isLoading: ProjectLoading } = useGetApiProjectsByPidQuery({ pid: pid! }, { skip: !pid });
  const [saveES, { isLoading: saveESLoading }] = usePutApiProjectsByPidExecsummaryMutation();
  const [saveDates, { isLoading: saveDatesLoading }] = usePutApiProjectsByPidDatesMutation();

  const onSubmit = (data: any) => {
    if (pid && data) {
      saveES({
        pid,
        krakenBackendApiModelsProjectsApiExecutiveSummary: {
          Objectives: data.Objectives,
          Scope: data.Scope,
          Observations: data.Observations,
          Recommendations: data.Recommendations,
          Notes: data.Notes,
        },
      });

      let [start_date, end_date] = data.Dates;

      if (start_date && isValidDate(start_date as Date)) {
        start_date = toLocalDate(start_date as Date);
      }
      if (end_date && isValidDate(end_date as Date)) {
        end_date = toLocalDate(end_date as Date);
      }

      saveDates({
        pid,
        krakenBackendApiModelsProjectsProjectDates: {
          start_date,
          end_date,
        },
      });
    }
  };

  useEffect(() => {
    setReadyToSubmit(isDirty && isValid);
  }, [isDirty, isValid]);

  useEffect(() => {
    if (executiveSummary && currentProject) {
      reset({
        Scope: executiveSummary?.Scope || null,
        Dates: [
          parseISO(currentProject?.startDate as string) || null,
          parseISO(currentProject?.endDate as string) || null,
        ],
        Objectives: executiveSummary?.Objectives || null,
        Observations: executiveSummary?.Observations || null,
        Recommendations: executiveSummary?.Recommendations || null,
        Notes: executiveSummary?.Notes || null,
      });
    }
  }, [executiveSummary, currentProject]);

  useEffect(() => {
    !executiveSummary?.Objectives && setValue('Objectives', objectiveText);
    !executiveSummary?.Observations
      && setValue('Observations', observationsText);
    !executiveSummary?.Recommendations
      && setValue('Recommendations', recommendationsText);
  }, [executiveSummary, objectiveText, observationsText, recommendationsText]);

  return {
    control,
    handleSubmit,
    onSubmit,
    watch,
    isLoading: ESLoading || ProjectLoading || saveESLoading || saveDatesLoading,
    readyToSubmit,
  };
};
