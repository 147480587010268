import { useEffect, useState } from 'react';
import { UseFormWatch } from 'react-hook-form';
import { UseFormSetValue } from 'react-hook-form/dist/types/form';
import { useParams } from 'react-router-dom';

import {
  GetApiActivitiesProjectByPidApiResponse,
  useGetApiActivitiesProjectByPidQuery,
} from '@store/services/query.generated';
import { usePrevious } from '@utils/hooks/usePrevious';

import { IFindingFields } from '../types';

export const UseFindingActivities = (
  watch: UseFormWatch<IFindingFields>,
  setValue: UseFormSetValue<IFindingFields>,
) => {
  const [activityList, setActivityList] = useState<GetApiActivitiesProjectByPidApiResponse>([]);
  const componentId = watch('componentId');
  const previousComponentId = usePrevious(componentId);
  const { pid } = useParams();

  const { data: activities = [], isLoading } = useGetApiActivitiesProjectByPidQuery({ pid: pid! }, { skip: !pid });

  useEffect(() => {
    if (activities.length && componentId) {
      if (previousComponentId && previousComponentId !== componentId) {
        setValue('associatedActivities', []);
      }
      const filteredData = activities.filter(
        ({ PcId }) => componentId === PcId,
      );

      setActivityList(filteredData);
    }
  }, [activities, componentId]);

  const getValue = (value: string[] | null) => (value?.length && !activityList?.length
      ? []
      : value?.length
        && value.some((aid) => activityList.some(({ id }) => id === aid))
      ? value
      : []);

  return {
    isLoading,
    activityList,
    componentId,
    getValue,
  };
};
