import { useNavigate } from 'react-router-dom';
import { LinearProgress } from '@mui/material';

import { EmptyList } from '@components/EmptyList/EmptyList';
import { xcsrf } from '@src/constants/constants';
import { LinkEnum } from '@src/types/types';
import { useGetApiStereotypesQuery } from '@store/services/query.generated';
import { usePage } from '@utils/hooks/usePage';

import { AddEditStereotypeTemplate } from './AddEditStereotypeTemplate';
import { STFooter } from './STFooter';
import { STList } from './STList';

import s from './index.module.sass';

const pages = ['add-stereotype-template', 'edit-stereotype-template'];

export const StereotypesTemplate = () => {
  const { page } = usePage(pages);
  const { data = [], isLoading } = useGetApiStereotypesQuery(xcsrf);
  const navigate = useNavigate();

  return page ? (
    <AddEditStereotypeTemplate />
  ) : (
    <div className={s.stereotypeTemplateList}>
      {isLoading && (
        <div className={s.progress}>
          <LinearProgress />
        </div>
      )}
      {!isLoading && !data.length && (
        <EmptyList
          listName="stereotype template"
          handler={() => navigate(LinkEnum.ADD_STEREOTYPE_TEMPLATE)}
        />
      )}
      {!!data.length && (
        <>
          <STList templates={data} />
          <STFooter />
        </>
      )}
    </div>
  );
};
