import cn from 'classnames';
import { ChangeEvent } from 'react';
import { v4 } from 'uuid';
import { useTheme } from '@mui/material';

import { severityColorScheme } from '@pages/Project/Findings/AddEditFinding/constants';

import s from './RadioButtons.module.sass';

interface IProps {
  list: string[];
  checked: string | null;
  onChange: (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>,
  ) => void;
  disabled?: boolean;
}

const severity = [
  'Info',
  'Warning',
  'Low',
  'Medium',
  'High',
  'Critical',
  'Simple',
  'Moderate',
  'Advanced',
];

export const RadioButtons = ({
  list,
  checked,
  onChange,
  disabled = false,
}: IProps) => {
  const uniqueName = v4();
  const {
    palette: { mode },
  } = useTheme();
  const inputProps = (name: string) => (severity.includes(name)
      ? {
          style: {
            backgroundColor:
              severityColorScheme[mode || 'light'][name || 'Info'].bg,
            color: severityColorScheme[mode || 'light'][name || 'Info'].color,
          },
        }
      : null);

  return (
    <fieldset className={s.radioBtns}>
      <legend className={s.legend}>Scheme</legend>
      {list.map((btn) => (
        <input
          key={btn}
          className={cn(s.btn, btn, { [s.disabled]: disabled })}
          type="radio"
          name={uniqueName}
          value={btn}
          aria-label={btn}
          onChange={onChange}
          checked={disabled ? false : btn === checked}
          {...inputProps(btn)}
        />
      ))}
    </fieldset>
  );
};
