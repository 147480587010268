import { useNavigate, useParams } from 'react-router-dom';
import { Button } from '@mui/material';

import { Footer } from '@components/Footer/Footer';
import { LinkEnum } from '@src/types/types';

export const SRFooter = () => {
  const { pid } = useParams();
  const navigate = useNavigate();

  const onBack = () => {
    navigate(LinkEnum.REPORTING.replace(':pid', pid!));
  };

  return (
    <Footer>
      <Button size="small" color="secondary" onClick={onBack} disabled={!pid}>
        Back
      </Button>
      <Button size="small" type="submit">
        Generate Status Report
      </Button>
    </Footer>
  );
};
