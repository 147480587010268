import cn from 'classnames';
import React, { useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import {
 IconButton, Menu, MenuItem, Tooltip, Zoom,
} from '@mui/material';

import Icon from '@components/Icon/Icon';
import { LinkEnum } from '@src/types/types';

import s from '../Sidebar.module.sass';

export const Settings = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { pathname } = useLocation();

  const onSettings = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Tooltip title="Settings" placement="right" TransitionComponent={Zoom}>
        <IconButton
          onClick={onSettings}
          aria-controls={open ? 'settings-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
        >
          <Icon iconName="settings" />
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        id="settings-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        PaperProps={{
          style: {
            width: 168,
            transform: 'translateX(10px)',
            padding: 12,
          },
        }}
        MenuListProps={{
          style: {
            padding: 0,
          },
        }}
      >
        <MenuItem
          className={cn(s.menuItem, {
            [s.active]: pathname === LinkEnum.NOTIFICATIONS,
          })}
        >
          <NavLink className={s.item} to={LinkEnum.NOTIFICATIONS}>
            <Icon iconName="notification" />
            Notifications
          </NavLink>
        </MenuItem>
        <MenuItem
          className={cn(s.menuItem, {
            [s.active]: pathname === LinkEnum.INTEGRATIONS,
          })}
        >
          <NavLink className={s.item} to={LinkEnum.INTEGRATIONS}>
            <Icon iconName="integrations2" />
            Integrations
          </NavLink>
        </MenuItem>
      </Menu>
    </>
  );
};
