import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { IconButton } from '@mui/material';

import Icon from '@components/Icon/Icon';
import { KrakenListItem } from '@components/KrakenListItem/KrakenListItem';
import { IColumn } from '@components/KrakenTable/type';
import { dateWithTimeFormat } from '@src/constants/constants';
import { LinkEnum } from '@src/types/types';
import {
  KrakenModelsDatabaseTablesVulnerabilities,
  useDeleteApiVulnerabilitiesByVidMutation,
} from '@store/services/query.generated';
import { getDate } from '@utils/dateUtils';

import s from './FTList.module.sass';

const columns: IColumn<KrakenModelsDatabaseTablesVulnerabilities>[] = [
  {
    headerName: 'Name',
    field: 'Name',
    sortable: true,
  },
  {
    headerName: 'Last Modified',
    field: 'Updated',
    sortable: true,
  },
  {
    headerName: 'Ref',
    field: 'Reference',
    sortable: true,
  },
];

const styles = {
  delete: { width: '48px', height: '48px', margin: '-12px -20px' },
};

export const useFTList = () => {
  const navigate = useNavigate();

  const [deleteFindingTemplate] = useDeleteApiVulnerabilitiesByVidMutation();

  const onClick = (id: string | undefined) => {
    id
      && navigate(LinkEnum.EDIT_FINDING_TEMPLATE.replace(':vid', id), {
        state: { linkTemplate: LinkEnum.EDIT_FINDING_TEMPLATE },
      });
  };

  const onDelete = (vid: string) => {
    deleteFindingTemplate({ vid });
  };

  const rowTemplate = useCallback(
    ({
 VulnId, Name, Updated, Reference,
}) => (
  <KrakenListItem
    key={VulnId}
    fid={VulnId}
    classname={s.listWrapper}
    handler={onClick}
  >
    <div>{Name}</div>
    <div>
      {Updated ? getDate(dateWithTimeFormat, new Date(Updated)) : ''}
    </div>
    <div>{Reference}</div>
    <IconButton
      size="small"
      aria-label="delete"
      onClick={(e) => {
            e.stopPropagation();
            onDelete(VulnId as string);
          }}
      sx={styles.delete}
    >
      <Icon iconName="delete" className={s.delete} />
    </IconButton>
  </KrakenListItem>
    ),
    [],
  );

  return {
    columns,
    rowTemplate,
  };
};
