import { IPublicClientApplication } from '@azure/msal-browser';

import { showError } from '@store/reducers/errorSlice';
import { store } from '@store/store';

import { loginRequest } from '../config/authConfig';

export const handleLogin = (instance: IPublicClientApplication) => {
  instance.loginRedirect(loginRequest).catch((e) => {
    store.dispatch(showError(e));
  });
};
