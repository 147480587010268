import { Controller } from 'react-hook-form';
import { Control } from 'react-hook-form/dist/types';

import { KrakenTextArea } from '@components/KrakenTextArea/KrakenTextArea';
import { kebabCase } from '@utils/kebabCase';
import { textAreaHandler } from '@utils/textAreaHandler';

import { EAddEditActivityLinks, IActivityFields } from '../types';

const futureWorkPlaceholder = `Content: Describe future testing work that needs to be done.
Point of view, tense/sentence structure: State in the imperative, implied subject; do not use second person (i.e., you, your, yours)
•\tThis field is required for the “Qualified Done” status or if the Activity is still progressing at status report time.
•\tIf important scope was removed from the project, include it here as future work.
•\tThis field is usually left blank for the “Done” status but may be used to recommend important work not covered in the current engagement.
Tip: If this field is used, the “future work” text will appear in the Future Work section of the Executive Summary, so text must be able to stand on its own, without context.`;

interface IProps {
  control: Control<IActivityFields>;
}

export const ActivityFutureWork = ({ control }: IProps) => (
  <Controller
    control={control}
    name="FutureWork"
    render={({ field: { value, onChange } }) => (
      <KrakenTextArea
        id={kebabCase(EAddEditActivityLinks.FUTURE_WORK)}
        title="Future Work"
        value={value || ''}
        onChange={(val) => textAreaHandler(val, onChange)}
        placeholder={futureWorkPlaceholder}
        height={180}
        hideToolbar
      />
    )}
  />
);
