import { useState } from 'react';
import AvatarEditor from 'react-avatar-edit';
import { Button, Dialog } from '@mui/material';

import './DragAndDrop.sass';

interface IProps {
  open: boolean;
  onApply: (img: any) => void;
  close: () => void;
  img: any;
  filename: string;
}

export function AvatarDialog({
 open, img, close, onApply, filename,
}: IProps) {
  const [preview, setPreview] = useState(null);

  const onClose = () => {
    setPreview(null);
    close();
  };

  const onCrop = (pv: any) => {
    setPreview(pv);
  };

  const onDone = () => {
    onApply(preview);
    close();
  };

  return (
    <Dialog open={open}>
      <div className="avatarDialog">
        <div className="avatarWrapper">
          <AvatarEditor
            width={179}
            height={179}
            cropColor="#d1d1d9"
            lineWidth={1}
            onCrop={onCrop}
            onClose={onClose}
            src={img}
          />
        </div>
        <span className="filename">
          {filename.length <= 25
            ? filename
            : `${filename.substring(0, 25)}...${filename.substring(
                filename.length - 4,
              )}`}
        </span>
        <div className="buttonWrapper">
          <Button size="large" color="error" sx={{ width: '124px' }}>
            Cancel
          </Button>
          <Button size="large" sx={{ width: '124px' }} onClick={onDone}>
            Done
          </Button>
        </div>
      </div>
    </Dialog>
  );
}
