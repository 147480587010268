import { Controller } from 'react-hook-form';
import { Control } from 'react-hook-form/dist/types';

import { Datepicker } from '@components/Datepicker/Datepicker';
import { IActivityFields } from '@pages/Project/Activities/AddEditActivity/types';

import s from '../AddEditActivity.module.sass';

interface IProps {
  control: Control<IActivityFields>;
}

const getValue = (
  dates: (Date | null) | [Date | null, Date | null],
  type: string,
) => {
  if (Array.isArray(dates)) {
    const [start, end] = dates;

    return type === 'startDate' ? start : end;
  }

  return null;
};

export const ActivityForecasted = ({ control }: IProps) => (
  <div className={s.twoColumns}>
    <Controller
      control={control}
      name="Dates"
      render={({ field: { value, onChange } }) => (
        <Datepicker
          label="Forecasted start & end date"
          startDate={getValue(value, 'startDate')}
          endDate={getValue(value, 'endDate')}
          selectsRange
          onChange={onChange}
        />
      )}
    />
    <Controller
      control={control}
      name="Date"
      render={({ field: { value, onChange } }) => (
        <Datepicker
          label="Forecasted unblock date"
          startDate={value}
          onChange={onChange}
        />
      )}
    />
  </div>
);
