import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ECheckedTheme } from '@components/Sidebar/ThemeSwitcher/types';

export interface ITheme {
  theme: string;
}

const ThemeInitialState: ITheme = {
  theme: 'light',
};

const themeSlice = createSlice({
  name: 'theme',
  initialState: ThemeInitialState,
  reducers: {
    updateTheme(state: ITheme, action: PayloadAction<ECheckedTheme>) {
      state.theme = action.payload;
    },
  },
});

const { reducer, actions } = themeSlice;

export default reducer;
export const { updateTheme } = actions;
