import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { KrakenListItem } from '@components/KrakenListItem/KrakenListItem';
import { IColumn } from '@components/KrakenTable/type';
import { LinkEnum } from '@src/types/types';
import { KrakenBackendApiModelsStereotypesApiStereotypeAssociated } from '@store/services/query.generated';

import s from './index.module.sass';

const columns: IColumn<KrakenBackendApiModelsStereotypesApiStereotypeAssociated>[] = [
    {
      headerName: 'NAME',
      field: 'SurfaceName',
      sortable: true,
    },
    {
      headerName: 'AREA',
      field: 'Area',
      sortable: true,
    },
    {
      headerName: 'DESCRIPTION',
      field: 'Description',
      sortable: true,
    },
    {
      headerName: 'ACTIVITIES',
      field: 'ActivityId',
      sortable: false,
    },
  ];

export const useSTList = () => {
  const navigate = useNavigate();

  const onClick = (id: string | undefined) => {
    id && navigate(LinkEnum.EDIT_STEREOTYPE_TEMPLATE.replace(':sid', id));
  };

  const rowTemplate = useCallback(
    ({
 Id, SurfaceName, Area, Description, ActivityId,
}) => (
  <KrakenListItem
    key={Id}
    fid={Id}
    classname={s.listWrapper}
    handler={onClick}
  >
    <span>{SurfaceName}</span>
    <span>{Area}</span>
    <span>{Description}</span>
    <span className={s.activity}>{ActivityId?.length || 0}</span>
  </KrakenListItem>
    ),
    [],
  );

  return {
    columns,
    rowTemplate,
  };
};
