import { useEffect, useState } from 'react';
import { UseFormWatch } from 'react-hook-form';

import { ICheckListLink } from '@components/CheckList/CheckList';
import {
  EFRLinks,
  IFinalReportFields,
} from '@pages/Reporting/GeneralFinalReport/types';
import { isValidDate } from '@utils/dateUtils';

const linkState: ICheckListLink = {
  [EFRLinks.DISPLAY_DATE]: false,
  [EFRLinks.ADDRESS_AND_CONTACT_INFO]: false,
  [EFRLinks.STAKEHOLDERS]: false,
  [EFRLinks.OBJECTIVES_CONCLUSION]: false,
  [EFRLinks.FONTS]: true,
};

export const useFRChecklist = (watch: UseFormWatch<IFinalReportFields>) => {
  const [links, setLinks] = useState(linkState);

  useEffect(() => {
    const subscription = watch((value) => {
      setLinks({
        [EFRLinks.DISPLAY_DATE]: isValidDate(value.ReportDate as Date),
        [EFRLinks.ADDRESS_AND_CONTACT_INFO]: Boolean(
          value.AddressAndContactInfo,
        ),
        [EFRLinks.STAKEHOLDERS]: Boolean(value.Stakeholders),
        [EFRLinks.OBJECTIVES_CONCLUSION]: Boolean(value.ObjectivesConclusion),
        [EFRLinks.FONTS]: true,
      });
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  return links;
};
