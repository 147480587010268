import { useNavigate, useParams } from 'react-router-dom';
import { Button } from '@mui/material';

import { Footer } from '@components/Footer/Footer';
import { LinkEnum } from '@src/types/types';

export const ActivitiesFooter = () => {
  const { pid } = useParams();
  const navigate = useNavigate();

  const onClick = () => navigate(LinkEnum.ADD_ACTIVITY.replace(':pid', pid!), {
      state: { linkTemplate: LinkEnum.ADD_ACTIVITY },
    });

  return (
    <Footer>
      <Button size="small" onClick={onClick}>
        Add Activity
      </Button>
    </Footer>
  );
};
