import { ReactNode } from 'react';

import s from './Footer.module.sass';

interface IProps {
  children: ReactNode;
}

export const Footer = ({ children }: IProps) => (
  <footer className={s.footer}>{children}</footer>
);
