import { Controller } from 'react-hook-form';
import { Control } from 'react-hook-form/dist/types';
import { Chip, FormControl, InputLabel } from '@mui/material';

import Icon from '@components/Icon/Icon';

import { options } from '../constants';
import { IProjectFields } from '../types';

import s from '../AddProject.module.sass';

interface IProps {
  control: Control<IProjectFields>;
  isAWS: boolean;
}

export const ProjectOptions = ({ control, isAWS }: IProps) => (
  <FormControl fullWidth className={s.chooseOptions}>
    <InputLabel shrink id="choose-options-label">
      Choose options
    </InputLabel>
    <div className={s.options}>
      {Object.values(options).map(({ name, keyName }) => (
        <Controller
          key={keyName}
          control={control}
          name={keyName as keyof IProjectFields}
          render={({ field: { value, onChange } }) => (
            <Chip
              disabled={
                isAWS
                && (keyName === 'severity_override'
                  || keyName === 'rating_justifications')
              }
              icon={<Icon iconName="chip" />}
              label={name}
              variant={value ? 'filled' : 'outlined'}
              clickable
              onClick={() => onChange(!value)}
            />
          )}
        />
      ))}
    </div>
  </FormControl>
);
