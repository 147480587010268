import { KrakenTable } from '@components/KrakenTable/KrakenTable';
import {
  GetApiVulnerabilitiesActiveApiResponse,
  KrakenModelsDatabaseTablesVulnerabilities,
} from '@store/services/query.generated';

import { useFTList } from './useFTList';

interface IProps {
  templates: GetApiVulnerabilitiesActiveApiResponse;
}

export const FTList = ({ templates }: IProps) => {
  const { columns, rowTemplate } = useFTList();

  return (
    <KrakenTable<KrakenModelsDatabaseTablesVulnerabilities>
      tableData={templates}
      columns={columns}
      gridTemplateColumns="repeat(3, 1fr) 48px"
      rowTemplate={rowTemplate}
    />
  );
};
