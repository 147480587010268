import { ReactElement, ReactNode } from 'react';

interface IProps<T> {
  data: T[];
  rowTemplate: (row: T) => ReactNode | ReactElement;
}

export function KrakenTableBody<T>({ data, rowTemplate }: IProps<T>) {
  return <div>{data.map((row) => rowTemplate(row))}</div>;
}
