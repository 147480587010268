import { useEffect, useState } from 'react';
import { UseFormWatch } from 'react-hook-form';

import { ICheckListLink } from '@components/CheckList/CheckList';

import { EAddEditComponentLinks, IComponentFields } from '../types';

const linksState: ICheckListLink = {
  [EAddEditComponentLinks.NAME]: false,
  [EAddEditComponentLinks.THREAT_ANALYSIS]: false,
  [EAddEditComponentLinks.OBSERVATIONS]: false,
  [EAddEditComponentLinks.INTERNAL_NOTES]: false,
};

export const useComponentCheckList = (
  watch: UseFormWatch<IComponentFields>,
) => {
  const [links, setLinks] = useState(linksState);

  useEffect(() => {
    const subscription = watch((value) => {
      setLinks({
        [EAddEditComponentLinks.NAME]: Boolean(value.Name),
        [EAddEditComponentLinks.THREAT_ANALYSIS]: Boolean(value.ThreatAnalysis),
        [EAddEditComponentLinks.OBSERVATIONS]: Boolean(value.Observations),
        [EAddEditComponentLinks.INTERNAL_NOTES]: Boolean(value.Notes),
      });
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  return links;
};
