import { Button } from '@mui/material';

import { KrakenDialog } from '@components/KrakenDialog/KrakenDialog';

import { useDeleteProjectConfirmation } from './useDeleteProjectConfirmation';

import s from '../../ProjectsList/ProjectsList.module.sass';

interface IProps {
  showConfirmationPopup: boolean;
  closePopup: () => void;
}

export const DeleteProjectConfirmation = ({
  showConfirmationPopup,
  closePopup,
}: IProps) => {
  const onYes = useDeleteProjectConfirmation();

  return (
    <KrakenDialog
      maxWidth="xs"
      onClose={closePopup}
      visible={showConfirmationPopup}
      title="Are you Sure?"
      footer={(
        <footer className={s.gap}>
          <Button onClick={onYes}>Yes</Button>
          <Button color="error" onClick={closePopup}>
            No
          </Button>
        </footer>
      )}
    >
      <span>
        The project will be completely deleted. Are you sure you want to
        proceed?
      </span>
    </KrakenDialog>
  );
};
