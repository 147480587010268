import { useEffect, useState } from 'react';
import { UseFormWatch } from 'react-hook-form';
import { useTheme } from '@mui/material';

import { Input } from '@components/Input/Input';
import { useAWS } from '@src/utils/hooks/useAWS';

import { difficultyList, impactList, severityColorScheme } from '../constants';
import { getSeverityText } from '../helper';
import { ESeverityList, IFindingFields } from '../types';

interface IProps {
  watch: UseFormWatch<IFindingFields>;
}

export const FindingSeverity = ({ watch }: IProps) => {
  const [severityValue, setSeverityValue] = useState<string>(
    ESeverityList.INFO,
  );
  const {
    palette: { mode },
  } = useTheme();

  const isAWS = useAWS();

  const impact = watch('impact');
  const difficulty = watch('difficulty');

  useEffect(() => {
    setSeverityValue(
      getSeverityText(
        impactList[impact || 'Info'],
        difficultyList[difficulty || 'Simple'],
        isAWS,
      ),
    );
  }, [impact, difficulty]);

  return (
    <Input
      label="Severity"
      value={severityValue}
      sx={{
        background:
          severityColorScheme[mode || 'light'][severityValue || 'Info'].bg,
        color:
          severityColorScheme[mode || 'light'][severityValue || 'Info'].color,
      }}
    />
  );
};
