import { ReactNode } from 'react';
import Draggable from 'react-draggable';
import {
  Dialog,
  DialogActions,
  DialogTitle,
  Paper,
  PaperProps,
} from '@mui/material';

import Icon from '@components/Icon/Icon';

import s from './KrakenDialog.module.sass';

interface ITitleProps {
  title: string;
  onClose: () => void;
}

interface IProps {
  children: ReactNode;
  footer?: ReactNode;
  onClose: () => void;
  visible: boolean;
  title?: string;
  fullWidth?: boolean;
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
  // All others properties
  [x: string]: any;
}

function PaperComponent(props: PaperProps) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

const KrakenDialogTitle = ({ title, onClose }: ITitleProps) => (
  <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
    <div>{title}</div>
    <button type="button" onClick={onClose}>
      <Icon iconName="close" />
    </button>
  </DialogTitle>
);

export const KrakenDialog = ({
  children,
  footer,
  onClose,
  visible,
  title = '',
  fullWidth = true,
  maxWidth = 'sm',
  ...other
}: IProps) => (
  <Dialog
    onClose={onClose}
    open={visible}
    fullWidth={fullWidth}
    maxWidth={maxWidth}
    PaperComponent={PaperComponent}
    aria-labelledby="draggable-dialog-title"
    {...other}
  >
    <div className={s.krakenDialog}>
      <KrakenDialogTitle title={title} onClose={onClose} />
      <div className={s.wrapper}>
        {children}
        {footer && <DialogActions>{footer}</DialogActions>}
      </div>
    </div>
  </Dialog>
);
