import { FinalReport } from '@pages/Reporting/GeneralFinalReport/FinalReport';
import { StatusReport } from '@pages/Reporting/StatusReport/StatusReport';
import { usePage } from '@utils/hooks/usePage';

import { ReportingList } from './ReportingList/ReportingList';

const pages = ['final-report', 'status-report'];

export const Reporting = () => {
  const { page } = usePage(pages);

  switch (page) {
    case 'final-report':
      return <FinalReport />;
    case 'status-report':
      return <StatusReport />;
    default:
      return <ReportingList />;
  }
};
