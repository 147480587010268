import {
 SyntheticEvent, useCallback, useEffect, useState,
} from 'react';
import { UseFormWatch } from 'react-hook-form';
import { UseFormSetValue } from 'react-hook-form/dist/types/form';
import { useParams } from 'react-router-dom';

import { useGetApiV2FindingsByFidQuery } from '@store/services/query.generated';

import { IFindingFields } from '../types';

import { cvssTemplate, templateCvss, vectorString } from './constants';
import { ICvssButton, ICvssMetric } from './types';

export const useFindingCvss = (
  watch: UseFormWatch<IFindingFields>,
  setValue: UseFormSetValue<IFindingFields>,
) => {
  const [expanded, setExpanded] = useState<string | false>('');
  const [template, setTemplate] = useState<ICvssMetric[]>([...templateCvss]);
  const [visible, setVisible] = useState(false);
  const [open, setOpen] = useState(false);
  const { fid } = useParams();
  const cvss = watch('cvss');

  const { data: finding }: any = useGetApiV2FindingsByFidQuery(
    { fid: fid! },
    { skip: !fid },
  );

  const handleChange = (panel: string) => (_event: SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  const onClick = (
    { parent, code }: ICvssButton,
    onChange: (...event: any[]) => void,
    value: string | null,
  ) => {
    const regex = new RegExp(`(?<=/${parent}:).`);
    const cvssVector = value?.replace(regex, code);
    const newTemplate = cvssTemplate(cvssVector);

    setTemplate(newTemplate);
    onChange(cvssVector);
  };

  const onReset = () => {
    setValue('cvss', finding?.cvss);
    setTemplate(cvssTemplate(finding?.cvss || vectorString));
  };

  const onClear = () => {
    setValue('cvss', vectorString);
    setTemplate(cvssTemplate(finding?.cvss || vectorString));
  };

  const onCopy = useCallback(() => {
    setOpen(true);
    navigator.clipboard.writeText(cvss || vectorString);

    setTimeout(() => {
      setOpen(false);
    }, 1000);
  }, [cvss]);

  const onBtn = () => setVisible((prev) => !prev);

  useEffect(() => {
    if (cvss) {
      setTemplate(cvssTemplate(cvss));
    }
  }, [cvss]);

  return {
    onBtn,
    visible,
    fid,
    finding,
    onReset,
    onClear,
    template,
    expanded,
    handleChange,
    onClick,
    open,
    onCopy,
  };
};
