export enum EAddEditComponentLinks {
  NAME = 'Name',
  DESCRIPTION = 'DESCRIPTION',
  THREAT_ANALYSIS = 'Threat Analysis',
  OBSERVATIONS = 'Observations',
  FINDINGS = 'Findings',
  INTERNAL_NOTES = 'Internal Notes',
  STEREOTYPES = 'Stereotypes',
}

export type TObject = { [key: string]: string };

export interface IComponentFields {
  Name: string | null;
  Description: string | null;
  Notes: string | null;
  Observations: string | null;
  ThreatAnalysis: string | null;
}
