import { Chip } from '@mui/material';

import { ICvssButton } from './types';

import s from './FindingCVSS.module.sass';

interface IProps {
  title: string;
  buttons: ICvssButton[];
  onClick: (opts: ICvssButton) => void;
}

export const FindingCvssRow = ({ title, buttons, onClick }: IProps) => (
  <div className={s.row}>
    <span className={s.title}>{title}</span>
    <div className={s.options}>
      {buttons.map(({
 buttonName, checked, parent, code,
}) => (
  <div key={parent + code} data-parent={parent} data-code={code}>
    <Chip
      label={buttonName}
      variant={checked ? 'filled' : 'outlined'}
      clickable
      onClick={() => onClick({
                buttonName,
                parent,
                code,
                checked,
              })}
    />
  </div>
      ))}
    </div>
  </div>
);
