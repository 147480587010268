import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { TabBox } from '@components/TabBox/TabBox';
import { LinkEnum } from '@src/types/types';
import { kebabCase } from '@utils/kebabCase';

import { ActivityTemplates } from './ActivityTemplates/ActivityTemplates';
import { Archive } from './Archive/Archive';
import { Companies } from './Companies/Companies';
import { FindingTemplate } from './FindingTemplate/FindingTemplate';
import { StereotypesTemplate } from './StereotypesTemplate';
import { EAdminTab } from './types';

const tabs = [
  {
    tabName: EAdminTab.ARCHIVE,
    iconName: 'archive',
    link: LinkEnum.ARCHIVE,
    component: <Archive />,
  },
  {
    tabName: EAdminTab.FINDING_TEMPLATE,
    iconName: 'findings',
    link: LinkEnum.FINDING_TEMPLATE,
    component: <FindingTemplate />,
  },
  {
    tabName: EAdminTab.STEREOTYPES,
    iconName: 'activities',
    link: LinkEnum.STEREOTYPE_TEMPLATE,
    component: <StereotypesTemplate />,
  },
  {
    tabName: EAdminTab.ACTIVITY_TEMPLATES,
    iconName: 'activities',
    link: LinkEnum.ACTIVITY_TEMPLATE,
    component: <ActivityTemplates />,
  },
  {
    tabName: EAdminTab.COMPANIES,
    iconName: 'components',
    link: LinkEnum.COMPANIES,
    component: <Companies />,
  },
];

export const Admin = () => {
  const [activeTab, setActiveTab] = useState<number | null>(null);
  const { pathname } = useLocation();

  useEffect(() => {
    if (pathname) {
      const index = tabs.findIndex((t) => pathname.toLowerCase().includes(kebabCase(t.tabName)));
      setActiveTab(index >= 0 ? index : 4);
    }
  }, [pathname]);

  return (
    <div>
      {activeTab !== null && activeTab >= 0 && (
        <TabBox
          headerName="Admin"
          tabs={tabs}
          activeTab={activeTab}
          urlNavigate
          orientation="vertical"
        />
      )}
    </div>
  );
};
