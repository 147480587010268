import { useLayoutEffect } from 'react';
import { InteractionType } from '@azure/msal-browser';
import {
  useIsAuthenticated,
  useMsal,
  useMsalAuthentication,
} from '@azure/msal-react';

import { loginRequest } from '@src/config/authConfig';
import { xcsrf } from '@src/constants/constants';
import { handleLogout } from '@src/utils';
import { usePostAuthSignalrAppendMutation } from '@store/services/query.generated';
import { useActions } from '@utils/hooks/useActions';
import { useUnload } from '@utils/hooks/useUnload';
import { connect } from '@utils/signalR';

import { authSuccess } from './authSuccess';
import { ifNotAuthenticated } from './ifNotAuthenticated';
import { intervalRequestProfileData } from './intervalRequestProfileData';
import { onUnload } from './onUnload';
import { requestProfileData } from './requestProfileData';
import { shutdownConnection } from './shutdownConnection';
import { tokenHandler } from './tokenHandler';

export const useMsalToken = () => {
  const isAuthenticated = useIsAuthenticated();
  const { instance, accounts } = useMsal();
  const { result, error } = useMsalAuthentication(
    InteractionType.Redirect,
    loginRequest,
  );
  const { resetMsal, updateUserParams, resetSignalR } = useActions();

  const [postAuthSignalr] = usePostAuthSignalrAppendMutation();

  useLayoutEffect(() => {
    if (error) {
      handleLogout(instance);
    }

    if (result) {
      tokenHandler(result);
    }
  }, [result, error]);

  useLayoutEffect(() => {
    (async () => {
      if (isAuthenticated) {
        await requestProfileData(instance, accounts);
        await postAuthSignalr(xcsrf).then(() => {
          shutdownConnection();

          connect();
        });

        intervalRequestProfileData(instance, accounts);

        updateUserParams({ instance, accounts });

        authSuccess();
      } else {
        await ifNotAuthenticated(instance);
      }
    })();

    return () => {
      resetMsal();
      resetSignalR();
      shutdownConnection();
    };
  }, []);

  useUnload(onUnload);
};
