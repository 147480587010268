import { KrakenTable } from '@components/KrakenTable/KrakenTable';
import { useCompaniesList } from '@pages/Admin/Companies/CompaniesList/useCompaniesList';
import {
  GetApiCompaniesApiResponse,
  KrakenBackendApiModelsCompaniesCompany,
} from '@store/services/query.generated';

interface IProps {
  companies: GetApiCompaniesApiResponse;
}

export const CompaniesList = ({ companies }: IProps) => {
  const { columns, rowTemplate } = useCompaniesList();

  return (
    <KrakenTable<KrakenBackendApiModelsCompaniesCompany>
      tableData={companies}
      columns={columns}
      gridTemplateColumns="44px 1fr 80px"
      rowTemplate={rowTemplate}
    />
  );
};
