import { Controller } from 'react-hook-form';
import { Control } from 'react-hook-form/dist/types';

import { KrakenTextArea } from '@components/KrakenTextArea/KrakenTextArea';
import { textAreaHandler } from '@utils/textAreaHandler';

import { IFindingFields } from '../types';

const placeholder = 'Web path, or function, file path, line number. EG: VulnerableClass::Validate foo/bar.cpp Line: 1337';

interface IProps {
  control: Control<IFindingFields>;
}

export const FindingLocation = ({ control }: IProps) => (
  <Controller
    control={control}
    name="location"
    render={({ field: { value, onChange } }) => (
      <KrakenTextArea
        title="Location"
        value={value || ''}
        onChange={(val) => textAreaHandler(val, onChange)}
        placeholder={placeholder}
        height={180}
        hideToolbar
      />
    )}
  />
);
