import Parser from 'html-react-parser';
import { Button } from '@mui/material';

import { KrakenDialog } from '@components/KrakenDialog/KrakenDialog';
import { LinkEnum } from '@src/types/types';
import { useTypedSelector } from '@src/utils';
import { useActions } from '@utils/hooks/useActions';

import IMG from './img/bubble-gum-downloading 1.png';

import s from './ErrorPopup.module.sass';

export const ErrorPopup = () => {
  const { hideError } = useActions();

  const {
 visible, message, title, is401,
} = useTypedSelector(
    (state) => state.errorReducer,
  );

  const onClose = () => {
    if (is401 && localStorage.getItem('isRefresh')) {
      localStorage.removeItem('isRefresh');
    }

    hideError();
  };

  const onRefresh = () => {
    window.localStorage.setItem('isRefresh', 'true');
    window.open(window.location.origin + LinkEnum.RE_AUTH, '_blank');
  };

  const footer = (
    <footer className={s.footer}>
      <Button
        size="small"
        color="error"
        href="https://leviathansecurity.atlassian.net/servicedesk/customer/portal/7/group/25/create/104"
        target="_blank"
      >
        Report Issue
      </Button>
      {is401 && (
        <Button
          size="small"
          color="primary"
          onClick={onRefresh}
          data-testid="refreshAuth"
        >
          Refresh Auth
        </Button>
      )}
    </footer>
  );

  return (
    <KrakenDialog
      footer={footer}
      onClose={onClose}
      visible={visible}
      maxWidth="xs"
    >
      <div className={s.wrapper}>
        <img src={IMG} alt="" />
        <h3 className={s.title}>{title || 'Error loading Page'}</h3>
        <div className={s.message}>{Parser(message)}</div>
      </div>
    </KrakenDialog>
  );
};
