import cn from 'classnames';
import { useCallback, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { Avatar } from '@mui/material';

import Icon from '@components/Icon/Icon';
import { IColumn } from '@components/KrakenTable/type';
import { ERoles } from '@src/constants/constants';
import { ICord, LinkEnum } from '@src/types/types';
import {
  KrakenModelsDtoProjectWithEditInfoQueryResult,
  useDeleteApiProjectsByPidMutation,
  useDeleteApiProjectsDeleteByPidMutation,
  usePutApiProjectsByPidUndeleteMutation,
} from '@store/services/query.generated';
import { formatDate } from '@utils/dateUtils';
import { useIsInRole } from '@utils/hooks/useIsInRole';

import { ProjectsListMenu } from './ProjectsListMenu/ProjectsListMenu';

import s from './ProjectsList.module.sass';

const columns: IColumn<KrakenModelsDtoProjectWithEditInfoQueryResult>[] = [
  {
    headerName: 'Company',
    field: 'coName',
    sortable: true,
  },
  {
    headerName: 'Project',
    field: 'prName',
    sortable: true,
  },
  {
    headerName: 'End date',
    field: 'endDate',
    sortable: true,
  },
  {
    headerName: 'Last update',
    field: 'UpdatedAt',
    sortable: true,
    sortType: 'date',
  },
];

const styles = {
  companyAvatar: {
    backgroundColor: '#A5A6F6',
    width: '80px',
    height: '80px',
    '[data-theme="light"] &': {
      borderColor: '#eaeaee',
    },
    '[data-theme="dark"] &': {
      borderColor: 'rgba(#354053,0.5)',
    },
  },
  userAvatar: {
    bgcolor: '#A5A6F6',
    width: '44px',
    height: '44px',
    '[data-theme="light"] &': {
      borderColor: '#eaeaee',
    },
    '[data-theme="dark"] &': {
      borderColor: 'rgba(#354053,0.5)',
    },
  },
};

export const useProjectsList = () => {
  const [contextMenu, setContextMenu] = useState<ICord | null>(null);
  const [isActive, setIsActive] = useState(true);
  const [pid, setPid] = useState('');
  const [showPopup, setShowPopup] = useState(false);
  const navigate = useNavigate();
  const { isInRole: isAdmin } = useIsInRole(ERoles.admin);

  const [onDeactivate] = useDeleteApiProjectsByPidMutation();
  const [onActivate] = usePutApiProjectsByPidUndeleteMutation();
  const [onDelete] = useDeleteApiProjectsDeleteByPidMutation();

  const onContextMenu = (cord: ICord | null, prID: string, active: boolean) => {
    setContextMenu(cord);
    setPid(prID);
    setIsActive(active);
  };

  const closePopup = () => {
    setPid('');
    setShowPopup(false);
  };

  const close = () => {
    setShowPopup(false);
    setPid('');
    closePopup();
    setContextMenu(null);
  };

  const onEdit = (e: any) => {
    e.preventDefault();
    navigate(LinkEnum.EDIT_PROJECT.replace(':pid', pid));
    close();
  };

  const deactivateHandler = (prID: string) => {
    onDeactivate({ pid: prID }).then(close);
  };

  const activateHandler = (prID: string) => {
    onActivate({ pid: prID }).then(close);
  };

  const deleteHandler = (prID: string) => {
    setPid(prID);
    setShowPopup(true);
    setContextMenu(null);
  };

  const onDeactivateHandler = (e: any) => {
    e.preventDefault();
    deactivateHandler(pid);
  };

  const onActivateHandler = (e: any) => {
    if (typeof e === 'object' && 'preventDefault' in e) {
      e.preventDefault();
    }
    activateHandler(pid || (typeof e === 'string' && e) || '');
  };

  const onDeleteHandler = (e: any) => {
    e.preventDefault();
    setShowPopup(true);
  };

  const onYes = () => {
    onDelete({ pid }).then(close);
  };

  const rowTemplate = useCallback(
    (item) => (
      <NavLink
        key={item.prID}
        className={s.projectsListCard}
        to={LinkEnum.FINDINGS.replace(':pid', item.prID)}
        onContextMenu={(e: any) => {
          e.preventDefault();
          onContextMenu(
            contextMenu === null
              ? {
                  mouseX: e.clientX - 2,
                  mouseY: e.clientY - 4,
                }
              : null,
            item.prID,
            item.active,
          );
        }}
      >
        <div className={s.projectsListCardProject}>
          <Avatar
            sx={styles.companyAvatar}
            alt={item.coName}
            src={item.CompanyLogoUrl}
          />
          <div className={s.projectsListCardProjectWrap}>
            <div className={cn(s.projectsListCardProjectTitle, s.cell)}>
              {item.coName}
            </div>
            <div className={s.projectsListCardProjectLabel} />
          </div>
        </div>

        <div className={s.projectsListCardDescr}>{item.prName}</div>

        <div className={s.projectsListCardEnddate}>
          <Icon className={s.projectsListCardEnddateIcon} iconName="calendar" />
          <span className={s.projectsListCardEnddateLabel}>
            {formatDate(item.endDate)}
          </span>
        </div>
        <div className={s.projectsListCardLastupdate}>
          <Avatar
            sx={styles.userAvatar}
            alt={`${item.EditorFirstName[0]} ${item.EditorLastName[0]}`}
          >
            {`${item.EditorFirstName[0]} ${item.EditorLastName[0]}`}
          </Avatar>
          <div className={s.projectsListCardLastupdateWrap}>
            <div className={s.projectsListCardLastupdateTitle}>
              {`${item.EditorFirstName} ${item.EditorLastName}`}
            </div>
            <div className={s.projectsListCardLastupdateLabel}>
              {item.UpdatedAt ? item.UpdatedAt.split('T')[0] : ''}
            </div>
          </div>
        </div>
        <ProjectsListMenu
          onDelete={deleteHandler}
          onDeactivate={deactivateHandler}
          onActivate={onActivateHandler}
          isActive={item.active}
          prId={item.prID}
        />
      </NavLink>
    ),
    [],
  );

  return {
    columns,
    rowTemplate,
    contextMenu,
    onEdit,
    isActive,
    onDeactivateHandler,
    onActivateHandler,
    isAdmin,
    onDeleteHandler,
    pid,
    showPopup,
    closePopup,
    onYes,
    close,
  };
};
