import { UseFormWatch } from 'react-hook-form';

import { CheckList } from '@components/CheckList/CheckList';
import { useChecklist } from '@pages/Admin/StereotypesTemplate/AddEditStereotypeTemplate/Checklist/useChecklist';
import { IStereotypeTemplateFields } from '@pages/Admin/StereotypesTemplate/AddEditStereotypeTemplate/types';

interface IProps {
  watch: UseFormWatch<IStereotypeTemplateFields>;
}

export const Checklist = ({ watch }: IProps) => {
  const links = useChecklist(watch);

  return <CheckList asideLinks={links} />;
};
