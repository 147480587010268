import { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import {
  difficultyList,
  impactList,
} from '@pages/Project/Findings/AddEditFinding/constants';
import { LinkEnum } from '@src/types/types';
import {
  useGetApiVulnerabilitiesByVidQuery,
  usePostApiVulnerabilitiesMutation,
  usePutApiVulnerabilitiesMutation,
} from '@store/services/query.generated';
import { getKeyByValue } from '@utils/getKeyByValue';

import { IFindingTemplateFields } from './types';

export const useAddEditFindingTemplate = () => {
  const [readyToSubmit, setReadyToSubmit] = useState(false);
  const { vid } = useParams();
  const navigate = useNavigate();
  const {
    control,
    watch,
    handleSubmit,
    reset,
    formState: { isDirty, isValid },
    setFocus,
  } = useForm<IFindingTemplateFields>({
    mode: 'onChange',
  });

  const { data: findingTemplate, isLoading: isFTLoading } = useGetApiVulnerabilitiesByVidQuery({ vid: vid! }, { skip: !vid });
  const [updateFindingTemplate, { isLoading: putLoading }] = usePutApiVulnerabilitiesMutation();
  const [addFindingTemplate, { isLoading: postLoading }] = usePostApiVulnerabilitiesMutation();

  const onSubmit: SubmitHandler<IFindingTemplateFields> = (data) => {
    const krakenModelsDatabaseTablesVulnerabilities: any = {
      Name: data.Name,
      Description: data.Description,
      Reference: data.Reference,
      Recommendation: data.Recommendation,
      Observation: data.Observation,
      Title: data.Title,
      Notes: data.Notes,
      Impact: impactList[data.Impact || 'Info'],
      ImpactRationale: data.ImpactRationale,
      Difficulty: difficultyList[data.Difficulty || 'Simple'],
      DifficultyRationale: data.DifficultyRationale,
      ShippableRecommendation: data.ShippableRecommendation,
    };

    if (vid) {
      krakenModelsDatabaseTablesVulnerabilities.VulnId = vid;

      updateFindingTemplate({ krakenModelsDatabaseTablesVulnerabilities });
    } else {
      addFindingTemplate({
        krakenBackendApiModelsVulnerabilitiesApiVulnerability:
          krakenModelsDatabaseTablesVulnerabilities,
      }).then((resp: any) => {
        navigate(
          resp?.data
            ? LinkEnum.EDIT_FINDING_TEMPLATE.replace(':vid', resp.data)
            : LinkEnum.FINDING_TEMPLATE,
        );
      });
    }
  };

  useEffect(() => {
    setFocus('Name');
  }, []);

  useEffect(() => {
    if (findingTemplate) {
      reset({
        Name: findingTemplate.Name,
        Description: findingTemplate.Description,
        Reference: findingTemplate.Reference,
        Recommendation: findingTemplate.Recommendation,
        Observation: findingTemplate.Observation,
        Title: findingTemplate.Title,
        Notes: findingTemplate.Notes,
        Impact: getKeyByValue(impactList, findingTemplate.Impact) || 'Info',
        ImpactRationale: findingTemplate.ImpactRationale,
        Difficulty:
          getKeyByValue(difficultyList, findingTemplate.Difficulty) || 'Simple',
        DifficultyRationale: findingTemplate.DifficultyRationale,
        ShippableRecommendation: findingTemplate.ShippableRecommendation,
      });
    }
  }, [findingTemplate]);

  useEffect(() => {
    setReadyToSubmit(isDirty && isValid);
  }, [isDirty, isValid]);

  return {
    vid,
    isLoading: isFTLoading || putLoading || postLoading,
    control,
    watch,
    handleSubmit,
    onSubmit,
    readyToSubmit,
  };
};
