import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { SelectChangeEvent } from '@mui/material';

import { xcsrf } from '@src/constants/constants';
import {
  useDeleteApiActivityTemplatesByAtidAssociationAndSidMutation,
  useGetApiActivityTemplatesQuery,
  useGetApiStereotypesBySidQuery,
  usePutApiActivityTemplatesByAtidAssociationAndSidMutation,
} from '@store/services/query.generated';

export const useSTAssociatedActivityTemplates = () => {
  const [activityIds, setActivityIds] = useState<string[]>([]);
  const { sid } = useParams();

  const { data: stereotypeTemplate, isLoading: isLoadingTemplate } = useGetApiStereotypesBySidQuery({ sid: sid! }, { skip: !sid });
  const { data: activities = [], isLoading: isLoadingActivities } = useGetApiActivityTemplatesQuery(xcsrf);
  const [Add] = usePutApiActivityTemplatesByAtidAssociationAndSidMutation();
  const [Remove] = useDeleteApiActivityTemplatesByAtidAssociationAndSidMutation();

  const handler = ({ target: { value } }: SelectChangeEvent<any>) => {
    if (value.length > activityIds.length) {
      // add
      const atid = value.find((id: string) => !activityIds.includes(id));
      atid && sid && Add({ atid, sid });
    } else {
      // remove
      const atid = activityIds.find((id) => !value.includes(id));
      atid && sid && Remove({ atid, sid });
    }

    setActivityIds(value);
  };

  useEffect(() => {
    if (stereotypeTemplate && 'ActivityId' in stereotypeTemplate) {
      setActivityIds(stereotypeTemplate?.ActivityId || []);
    }
  }, [stereotypeTemplate]);

  return {
    activityIds,
    activities,
    handler,
    isLoading: isLoadingTemplate || isLoadingActivities,
  };
};
