import { LinearProgress } from '@mui/material';

import { CompanyFooter } from './CompanyFooter/CompanyFooter';
import { CompanyLogo } from './CompanyLogo';
import { CompanyName } from './CompanyName';
import { useAddEditCompany } from './useAddEditCompany';

import styles from './AddCompany.module.sass';

export function AddEditCompany() {
  const {
    companyLoading,
    putLoading,
    postLoading,
    cid,
    handleSubmit,
    onSubmit,
    control,
    formState,
  } = useAddEditCompany();

  return (
    <div className={styles.addCompany}>
      {(companyLoading || putLoading || postLoading) && <LinearProgress />}
      <main className={styles.main}>
        <h2>{`${cid ? 'Edit' : 'Add new'} company`}</h2>
        <form onSubmit={handleSubmit(onSubmit)}>
          <CompanyName control={control} />
          <CompanyLogo control={control} />
          <CompanyFooter formState={formState} />
        </form>
      </main>
    </div>
  );
}
