import { UseFormWatch } from 'react-hook-form';

import { CheckList } from '@components/CheckList/CheckList';
import { IActivityFields } from '@pages/Project/Activities/AddEditActivity/types';

import { useActivityFieldsChecklist } from './useActivityFieldsChecklist';

interface IProps {
  watch: UseFormWatch<IActivityFields>;
}

export const ActivityFieldsChecklist = ({ watch }: IProps) => {
  const links = useActivityFieldsChecklist(watch);

  return <CheckList asideLinks={links} />;
};
