import { UseFormWatch } from 'react-hook-form';
import { Link, useParams } from 'react-router-dom';
import { Button } from '@mui/material';

import { Footer } from '@components/Footer/Footer';
import Icon from '@components/Icon/Icon';
import { LinkEnum } from '@src/types/types';
import { useTypedSelector } from '@src/utils';

import { IFindingFields } from '../types';

import { FindingActions } from './FindingActions';

interface IProps {
  readyToSubmit: boolean;
  watch: UseFormWatch<IFindingFields>;
}

export const FindingFooter = ({ readyToSubmit, watch }: IProps) => {
  const { fid, pid } = useParams();
  const { isEditor } = useTypedSelector((state) => state.addEditFindingReducer);
  const { hasSignalRConnection } = useTypedSelector(
    (state) => state.signalRReducer,
  );

  return (
    <Footer>
      {fid && <FindingActions watch={watch} />}
      <Button
        component={Link}
        color="secondary"
        size="small"
        to={LinkEnum.FINDINGS.replace(':pid', pid!)}
      >
        Cancel
      </Button>
      <Button
        color="primary"
        size="small"
        startIcon={<Icon iconName="save" />}
        type="submit"
        disabled={!readyToSubmit || !isEditor || !hasSignalRConnection}
      >
        {fid ? 'Save ' : 'Add '}
        finding
      </Button>
    </Footer>
  );
};
