import cn from 'classnames';
import { useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { Tooltip, Zoom } from '@mui/material';

import Icon from '@components/Icon/Icon';
import { IRoutes } from '@components/Sidebar/routes';
import styles from '@components/Sidebar/Sidebar.module.sass';
import { LinkEnum } from '@src/types/types';

const map: any = {
  '': LinkEnum.DASHBOARD,
  projects: LinkEnum.PROJECTS,
  admin: LinkEnum.ADMIN,
};

export const CustomNavLink = ({ to, iconName, itemName }: IRoutes) => {
  const [isActive, setIsActive] = useState(false);
  const { pathname } = useLocation();

  useEffect(() => {
    setIsActive(to.includes(map[pathname.split('/')[1]]));
  }, [pathname, to]);

  return (
    <li className={styles.sidebarMenuItem}>
      <Tooltip title={itemName} placement="right" TransitionComponent={Zoom}>
        <NavLink
          className={cn(styles.sidebarMenuLink, { active: isActive })}
          to={to}
        >
          <Icon
            className={styles.sidebarMenuIcon}
            iconName={`${iconName}${isActive ? '-active' : ''}`}
          />
        </NavLink>
      </Tooltip>
    </li>
  );
};
