import { useState } from 'react';
import { MenuItem, SelectChangeEvent } from '@mui/material';

import { KrakenSelect } from '@components/KrakenSelect/KrakenSelect';
import { xcsrf } from '@src/constants/constants';
import { useGetApiHumansEmployeesQuery } from '@store/services/query.generated';
import { useActions } from '@utils/hooks/useActions';

export const FBUUsers = () => {
  const [name, setName] = useState('');
  const { updateFBU } = useActions();

  const { data: employees = [], isLoading } = useGetApiHumansEmployeesQuery(xcsrf);

  const onName = ({ target: { value } }: SelectChangeEvent<any>) => {
    setName(value);
    updateFBU({ field: 'humanId', value });
  };

  return (
    <KrakenSelect
      label="User*"
      value={name}
      onChange={onName}
      isLoading={isLoading}
    >
      {employees?.map(({ HumanId, Fname, Lname }) => (
        <MenuItem key={HumanId} value={HumanId}>
          {`${Fname} ${Lname}`}
        </MenuItem>
      ))}
    </KrakenSelect>
  );
};
