import cn from 'classnames';
import {
  FormEventHandler,
  ReactElement,
  ReactNode,
  useEffect,
  useState,
} from 'react';
import { LinearProgress } from '@mui/material';

import { useTypedSelector } from '@src/utils';

import s from './KrakenLayout.module.sass';

interface IProps {
  children: ReactNode | ReactElement | ReactElement[];
  checkList: ReactElement;
  footer: ReactElement;
  breadCrumbs?: ReactElement;
  wrapperClass?: string;
  onSubmit?: FormEventHandler;
  isLoading?: boolean;
  needConnection?: boolean;
}

const styles = {
  mainWithBreadCrumbs: {
    height: 'calc(100% - 64px)',
  },
  mainWithoutBreadCrumbs: {
    height: '100%',
  },
};

export const KrakenLayout = ({
  children,
  breadCrumbs,
  checkList,
  footer,
  onSubmit,
  wrapperClass = '',
  isLoading = false,
  needConnection = false,
}: IProps) => {
  const [hasConnection, setHasConnection] = useState(false);

  const { hasSignalRConnection } = useTypedSelector(
    (state) => state.signalRReducer,
  );

  useEffect(() => {
    setHasConnection(hasSignalRConnection);
  }, [hasSignalRConnection]);

  return (
    <div
      className={cn(s.krakenLayout, wrapperClass, {
        [s.disabled]: needConnection && !hasConnection,
      })}
    >
      {isLoading && <LinearProgress />}
      <form {...(onSubmit ? { onSubmit } : {})}>
        <div className={s.wrapper}>
          <div className={s.content}>
            {breadCrumbs && breadCrumbs}
            <main
              className={s.main}
              style={
                breadCrumbs
                  ? styles.mainWithBreadCrumbs
                  : styles.mainWithoutBreadCrumbs
              }
            >
              {children && children}
            </main>
          </div>
        </div>
        {checkList}
        {footer && footer}
      </form>
    </div>
  );
};
