import { Controller, UseFormWatch } from 'react-hook-form';
import { Control } from 'react-hook-form/dist/types';
import { MenuItem, useTheme } from '@mui/material';

import { KrakenSelect } from '@components/KrakenSelect/KrakenSelect';

import { severityColorScheme } from '../constants';
import { severityOverrideOptions } from '../helper';
import { IFindingFields } from '../types';

import { useOverridableFindingSeverity } from './useOverridableFindingSeverity';

interface IProps {
  watch: UseFormWatch<IFindingFields>;
  control: Control<IFindingFields>;
}

//  Doesn't support Aws Severity ranking system
export const OverridableFindingSeverity = ({ watch, control }: IProps) => {
  const {
    palette: { mode },
  } = useTheme();
  const {
 handler, overriddenSeverityText, severityValue, selectedText,
} = useOverridableFindingSeverity(watch);

  return (
    <Controller
      control={control}
      name="overriddenSeverity"
      render={({ field: { value, onChange } }) => (
        <KrakenSelect
          label={
            value == null
              ? 'Severity'
              : 'Overridden Severity (Original Severity)'
          }
          value={(value == null ? '' : value).toString()}
          onChange={(e) => handler(e.target.value, onChange)}
          sx={{
            background:
              severityColorScheme[mode || 'light'][
                overriddenSeverityText(value) ?? severityValue
              ].bg,
            color:
              severityColorScheme[mode || 'light'][
                overriddenSeverityText(value) ?? severityValue
              ].color,
          }}
          displayEmpty
          renderValue={() => selectedText(value)}
        >
          {Object.keys(severityOverrideOptions).map((arrKey) => (
            <MenuItem key={arrKey} value={severityOverrideOptions[arrKey]}>
              {arrKey}
            </MenuItem>
          ))}
        </KrakenSelect>
      )}
    />
  );
};
