import { Controller } from 'react-hook-form';
import { Control } from 'react-hook-form/dist/types';

import { KrakenTextArea } from '@components/KrakenTextArea/KrakenTextArea';
import { kebabCase } from '@utils/kebabCase';

import { EAddProjectLinks, IProjectFields } from '../types';

interface IProps {
  control: Control<IProjectFields>;
}

export const ProjectNotes = ({ control }: IProps) => (
  <Controller
    control={control}
    name="notes"
    render={({ field: { value, onChange } }) => (
      <KrakenTextArea
        id={kebabCase(EAddProjectLinks.STATUS_TEXT)}
        title="Notes"
        value={value}
        height="180"
        onChange={onChange}
      />
    )}
  />
);
