import { Provider } from 'react-redux';

import { KrakenThemeProvider } from '@components/KrakenThemeProvider';
import { Authenticated } from '@pages/Authenticated';
import { Unauthenticated } from '@pages/Unauthenticated/Unauthenticated';
import { ErrorPopup } from '@src/popups/ErrorPopup/ErrorPopup';
import { store } from '@store/store';

import './App.sass';

function App() {
  return (
    <div className="App">
      <Provider store={store}>
        <KrakenThemeProvider>
          <Authenticated />
          <Unauthenticated />
          <ErrorPopup />
        </KrakenThemeProvider>
      </Provider>
    </div>
  );
}

export default App;
