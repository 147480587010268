import { ReactNode, useEffect, useState } from 'react';
import { UseFormWatch } from 'react-hook-form';

import { isNumber } from '@utils/isNumber';

import { difficultyList, impactList } from '../constants';
import { getSeverityText, severityScoreToText } from '../helper';
import { ESeverityList, IFindingFields } from '../types';

export const useOverridableFindingSeverity = (
  watch: UseFormWatch<IFindingFields>,
) => {
  const [severityValue, setSeverityValue] = useState<string>(
    ESeverityList.INFO,
  );
  const impact = watch('impact');
  const difficulty = watch('difficulty');

  useEffect(() => {
    setSeverityValue(
      getSeverityText(
        impactList[impact || 'Info'],
        difficultyList[difficulty || 'Simple'],
        false,
      ),
    );
  }, [impact, difficulty]);

  const handler = (
    value: number | null,
    onChange: (...event: any[]) => void,
  ) => {
    const result = isNumber(value) && value !== null ? +value : null;

    onChange(result);
  };

  const overriddenSeverityText = (overriddenSeverity: number | null) => (overriddenSeverity == null
      ? null
      : severityScoreToText(overriddenSeverity, false));

  const selectedText = (overriddenSeverity: number | null): ReactNode => {
    const overriddenSeverityTxt = overriddenSeverityText(overriddenSeverity);
    return overriddenSeverityTxt == null
      ? severityValue
      : `${overriddenSeverityTxt}(${severityValue})`;
  };

  return {
    handler,
    overriddenSeverityText,
    severityValue,
    selectedText,
  };
};
