import { useParams } from 'react-router-dom';
import { Button } from '@mui/material';

import { KrakenListItem } from '@components/KrakenListItem/KrakenListItem';
import { KrakenNavBar } from '@components/KrakenNavBar/KrakenNavBar';
import { gridTemplate } from '@pages/Admin/Archive/constants';
import {
  useGetApiV2ProjectsByPidInactiveComponentsQuery,
  usePutApiComponentsByCidUndeleteMutation,
} from '@store/services/query.generated';

import { DeletedDate } from '../components/DeletedDate';

import s from '../ArchiveProject.module.sass';

const list = ['COMPONENT', '', 'DELETED BY', ''];

export const DeletedComponents = () => {
  const { pid } = useParams();
  const { data = [] } = useGetApiV2ProjectsByPidInactiveComponentsQuery(
    { pid: pid! },
    { skip: !pid },
  );
  const [undelete] = usePutApiComponentsByCidUndeleteMutation();

  return (
    <div className={s.deletedComponents}>
      <h2>Deleted Components</h2>
      <KrakenNavBar list={list} gridTemplateColumns={gridTemplate} />
      {data.map(({
 componentId, name, deletedDate, userDeleted,
}) => (
  <KrakenListItem
    key={componentId}
    classname={s.listItem}
    handler={() => {}}
  >
    <div className={s.name}>{name}</div>
    <div />
    <div>
      <div className={s.userDeleted}>
        {userDeleted ? `@${userDeleted}` : ''}
      </div>
      <DeletedDate deletedDate={deletedDate as string} />
    </div>
    <Button size="large" onClick={() => undelete({ cid: componentId! })}>
      Undelete Component
    </Button>
  </KrakenListItem>
      ))}
    </div>
  );
};
