import { AuthenticationResult } from '@azure/msal-common';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IGraphData {
  businessPhones: string;
  displayName: string;
  givenName: string;
  jobTitle: string;
  mail: string;
  mobilePhone: string | null;
  officeLocation: string | null;
  preferredLanguage: string | null;
  surname: string;
  userPrincipalName: string;
  id: string;
}

export interface IdTokenClaims {
  aud: string;
  iss: string;
  iat: number;
  nbf: number;
  exp: number;
  aio: string;
  family_name: string;
  given_name: string;
  ipaddr: string;
  name: string;
  nonce: string;
  oid: string;
  preferred_username: string;
  rh: string;
  roles: string[];
  sub: string;
  tid: string;
  upn: string;
  uti: string;
  ver: string;
}

export interface IUserParams {
  instance: any;
  accounts: any;
}

export interface IMsal {
  tokenData: AuthenticationResult | {};
  graphData: IGraphData | {};
  userParams: IUserParams | {};
  userPhoto: string | null;
}

const initialState: IMsal = {
  tokenData: {},
  graphData: {},
  userParams: {},
  userPhoto: null,
};

const msalSlice = createSlice({
  name: 'msal',
  initialState,
  reducers: {
    updateTokenData(state: IMsal, action: PayloadAction<AuthenticationResult>) {
      state.tokenData = action.payload;
    },
    updateGraphData(state: IMsal, action: PayloadAction<IGraphData>) {
      state.graphData = action.payload;
    },
    updateUserParams(state: IMsal, action: PayloadAction<IUserParams>) {
      state.userParams = action.payload;
    },
    updateUserPhoto(state: IMsal, action: PayloadAction<string>) {
      state.userPhoto = action.payload;
    },
    resetMsal: () => initialState,
  },
});

const { reducer, actions } = msalSlice;

export default reducer;
export const {
  updateTokenData,
  updateGraphData,
  updateUserParams,
  updateUserPhoto,
  resetMsal,
} = actions;
