import cn from 'classnames';
import {
  CSSProperties,
  ReactNode,
  SyntheticEvent,
  useEffect,
  useState,
} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';

import { EAdminTab } from '@pages/Admin/types';
import { EProjectTab } from '@pages/Project/types';
import { ERoles } from '@src/constants/constants';
import { LinkEnum } from '@src/types/types';
import { useIsInRole } from '@src/utils/hooks/useIsInRole';

import Icon from '../Icon/Icon';

import s from './TabBox.module.sass';

export interface ITab {
  tabName: string;
  iconName?: string;
  link?: string | LinkEnum;
  component: JSX.Element;
}

interface ITabBox {
  headerName?: string;
  tabs: ITab[];
  activeTab?: number;
  urlNavigate?: boolean;
  // All others properties
  [x: string]: any;
}

interface TabPanelProps {
  children?: ReactNode;
  index: number;
  value: number;
  style?: CSSProperties;
}

function TabPanel({
 children, value, index, ...other
}: TabPanelProps) {
  return (
    <div
      className={s.tabpanel}
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ height: '100%' }}>
          <Typography component="span" variant="body2">
            {children}
          </Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`,
  };
}

const settingsBoxStyle = (mode: 'light' | 'dark') => ({
  position: 'sticky',
  top: 0,
  backgroundColor: mode === 'light' ? '#fff' : '#181c29',
  borderBottom: 8,
  borderColor: 'divider',
  padding: '0 0 0 24px',
  zIndex: 1,
});

const initialBlackList: string[] = [EProjectTab.PROGRESS];

export function TabBox({
  headerName,
  tabs,
  activeTab,
  urlNavigate = false,
  ...other
}: ITabBox) {
  const [value, setValue] = useState(activeTab || 0);
  const [blackList, setBlackList] = useState([
    ...initialBlackList,
    EAdminTab.FINDING_TEMPLATE,
  ]);
  const navigate = useNavigate();
  const { pid } = useParams();
  const {
    palette: { mode },
  } = useTheme();

  const { isInRole: isTemplateEditor } = useIsInRole(ERoles.templateEditor);

  const handleChange = (_event: SyntheticEvent, newValue: number) => {
    if (urlNavigate && tabs[newValue].link) {
      const { link } = tabs[newValue];
      const formattedLink = link && /:pid/g.test(link as string) && pid
          ? link.replace(':pid', pid)
          : link;
      navigate(formattedLink as LinkEnum);
    }

    setValue(newValue);
  };

  useEffect(() => {
    if (!pid && isTemplateEditor) {
      setBlackList(initialBlackList);
    }

    setValue(activeTab || 0);
  }, [activeTab, isTemplateEditor]);

  return (
    <div
      className={cn(s.tabBox, {
        [s.vertical]:
          'orientation' in other && other.orientation === 'vertical',
      })}
    >
      <Box sx={settingsBoxStyle(mode)}>
        <h2 className={s.tabBoxHeader}>{headerName}</h2>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="tabs"
          className={s.tabs}
          {...other}
        >
          {tabs.map(({ tabName, iconName = '', link }, i) => (
            <Tab
              key={tabName}
              label={(
                <div className={s.tab}>
                  {iconName && (
                    <Icon
                      iconName={`${iconName}${i === value ? '-active' : ''}`}
                    />
                  )}
                  {tabName}
                </div>
              )}
              {...a11yProps(i)}
              onClick={() => link && navigate(pid ? link.replace(':pid', pid) : link)}
              disabled={blackList.includes(tabName)}
            />
          ))}
        </Tabs>
      </Box>
      {tabs.map(({ tabName, component }, i) => (
        <TabPanel key={tabName} value={value} index={i}>
          {component}
        </TabPanel>
      ))}
    </div>
  );
}
