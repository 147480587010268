import { useState } from 'react';
import { Controller } from 'react-hook-form';
import { Control } from 'react-hook-form/dist/types';
import { Checkbox, Collapse, FormControlLabel } from '@mui/material';

import { Input } from '@components/Input/Input';
import { KrakenTextArea } from '@components/KrakenTextArea/KrakenTextArea';

import { IStatusReportFields } from '../types';

interface IProps {
  control: Control<IStatusReportFields>;
}

export const SRAppendixA = ({ control }: IProps) => {
  const [checked, setChecked] = useState(true);

  const handler = (value: boolean, onChange: (...event: any[]) => void) => {
    setChecked(!value);
    onChange(!value);
  };

  return (
    <div>
      <Controller
        control={control}
        name="AddAppendixA"
        render={({ field: { value, onChange } }) => (
          <FormControlLabel
            control={(
              <Checkbox
                checked={value || true}
                onClick={() => handler(value, onChange)}
              />
            )}
            label="Add Appendix A"
          />
        )}
      />
      <Collapse in={checked}>
        <Controller
          control={control}
          name="AppendixAHeader"
          render={({ field: { value, onChange } }) => (
            <Input
              label="Appendix a header*"
              value={value || ''}
              onChange={onChange}
            />
          )}
        />
        <Controller
          control={control}
          name="AppendixABody"
          render={({ field: { value, onChange } }) => (
            <KrakenTextArea
              id="AppendixA"
              title="Appendix A body*"
              value={value || ''}
              onChange={onChange}
              height={180}
            />
          )}
        />
      </Collapse>
    </div>
  );
};
