import { Controller } from 'react-hook-form';
import { Control } from 'react-hook-form/dist/types';

import { Datepicker } from '@components/Datepicker/Datepicker';
import {
  EFRLinks,
  IFinalReportFields,
} from '@pages/Reporting/GeneralFinalReport/types';
import { kebabCase } from '@utils/kebabCase';

interface IProps {
  control: Control<IFinalReportFields>;
}

export const FRDate = ({ control }: IProps) => (
  <Controller
    control={control}
    name="ReportDate"
    render={({ field: { value, onChange } }) => (
      <Datepicker
        id={kebabCase(EFRLinks.DISPLAY_DATE)}
        label="Display Date"
        startDate={value}
        onChange={onChange}
      />
    )}
  />
);
