import { useParams } from 'react-router-dom';

import { EditsHistory } from '@components/EditsHistory/EditsHistory';
import { useGetApiReviewablesEditHistoryByRidQuery } from '@store/services/query.generated';

export const ComponentHistory = () => {
  const { cid } = useParams();

  const { data = [] } = useGetApiReviewablesEditHistoryByRidQuery({
    rid: cid!,
  });

  return <EditsHistory data={data} />;
};
