import { useParams } from 'react-router-dom';

import { KrakenLayout } from '@src/layouts/KrakenLayout/KrakenLayout';

import { ComponentBreadCrumbs } from './ComponentBreadCrumbs';
import { ComponentCheckList } from './ComponentCheckList';
import { ComponentDescription } from './ComponentDescription';
import { ComponentFindings } from './ComponentFindings';
import { ComponentFooter } from './ComponentFooter';
import { ComponentHistory } from './ComponentHistory';
import { ComponentInternalNotes } from './ComponentInternalNotes';
import { ComponentName } from './ComponentName';
import { ComponentObservations } from './ComponentObservations';
import { ComponentStereotypes } from './ComponentStereotypes';
import { ComponentThreatAnalysis } from './ComponentThreatAnalysis';
import { useAddEditComponent } from './useAddEditComponent';

export const AddEditComponent = () => {
  const {
    getValues,
    control,
    handleSubmit,
    onSubmit,
    watch,
    isLoading,
    readyToSubmit,
  } = useAddEditComponent();
  const { cid } = useParams();

  return (
    <KrakenLayout
      breadCrumbs={<ComponentBreadCrumbs getValues={getValues} />}
      checkList={<ComponentCheckList watch={watch} />}
      footer={<ComponentFooter readyToSubmit={readyToSubmit} />}
      onSubmit={handleSubmit(onSubmit)}
      isLoading={isLoading}
    >
      <div>
        <ComponentName control={control} />
        <ComponentDescription control={control} />
        <ComponentThreatAnalysis control={control} />
        <ComponentObservations control={control} />
        {cid && <ComponentFindings />}
        <ComponentInternalNotes control={control} />
        <ComponentStereotypes />
        {cid && <ComponentHistory />}
      </div>
    </KrakenLayout>
  );
};
