import { AccountInfo, IPublicClientApplication } from '@azure/msal-browser';

import { requestProfileData } from './requestProfileData';

export const intervalRequestProfileData = (
  instance: IPublicClientApplication,
  accounts: AccountInfo[],
) => {
  setInterval(() => {
    requestProfileData(instance, accounts);
  }, 600000);
};
