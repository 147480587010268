import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { LinkEnum } from '@src/types/types';
import {
  GetApiActivitiesProjectByPidApiResponse,
  useGetApiActivitiesProjectByPidQuery,
} from '@store/services/query.generated';

import s from './ESFutureWork.module.sass';

export const ESFutureWork = () => {
  const [futureWork, setFutureWork] = useState<GetApiActivitiesProjectByPidApiResponse>([]);
  const navigate = useNavigate();
  const { pid } = useParams();

  const { data } = useGetApiActivitiesProjectByPidQuery(
    { pid: pid! },
    { skip: !pid },
  );

  useEffect(() => {
    if (data) {
      setFutureWork(data.filter((a) => a.FutureWork));
    }
  }, [data]);

  return (
    <>
      <h3>
        Future Work&nbsp;
        <span className={s.span}>(Order may differ in the final report)</span>
      </h3>
      <ul>
        {futureWork.map(({ id, FutureWork }) => (
          <li key={id}>
            <button
              type="button"
              className={s.link}
              onClick={() => navigate(
                  LinkEnum.EDIT_ACTIVITY.replace(':pid', pid!).replace(
                    ':aid',
                    id!,
                  ),
                )}
            >
              {FutureWork}
            </button>
          </li>
        ))}
      </ul>
    </>
  );
};
