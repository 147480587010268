import { Button } from '@mui/material';

import { Footer } from '@components/Footer/Footer';

interface IProps {
  showPopup: () => void;
}

export const ComponentsFooter = ({ showPopup }: IProps) => (
  <Footer>
    <Button size="small" onClick={showPopup}>
      Add Component
    </Button>
  </Footer>
);
