import { ReactNode, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import Header from '@components/Header/Header';
import Sidebar from '@components/Sidebar/Sidebar';
import { useMsalToken } from '@src/utils';

import s from './Base.module.sass';

type LayoutProps = {
  children: ReactNode;
};

export function BaseLayout({ children }: LayoutProps) {
  const { pathname, hash, key } = useLocation();

  useMsalToken();

  useEffect(() => {
    // if not a hash link, scroll to top
    if (hash === '') {
      window.scrollTo(0, 0);
      // else scroll to id
    } else {
      setTimeout(() => {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({
            block: 'center',
            behavior: 'smooth',
          });
        }
      });
    }
  }, [pathname, hash, key]); // do this on route change

  return (
    <div className={s.wrapper}>
      <Sidebar />
      <div className={s.content}>
        <Header />
        <div className={s.main}>{children}</div>
      </div>
    </div>
  );
}

export default BaseLayout;
