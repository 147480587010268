import { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';

import { yupRequiredString } from '@src/constants/constants';
import {
  useGetApiV2ComponentsByCidQuery,
  usePutApiComponentsByCidMutation,
} from '@store/services/query.generated';

import { IComponentFields } from './types';

const schema: any = yup.object().shape({
  Name: yupRequiredString,
});

export const useAddEditComponent = () => {
  const [readyToSubmit, setReadyToSubmit] = useState(false);

  const {
    control,
    reset,
    handleSubmit,
    formState: { isDirty, isValid },
    setFocus,
    watch,
    getValues,
  } = useForm<IComponentFields>({
    mode: 'onChange',
    resolver: yupResolver(schema),
  });
  const { cid } = useParams();

  const { data: component, isLoading: componentIsLoading } = useGetApiV2ComponentsByCidQuery({ cid: cid! }, { skip: !cid });
  const [saveComponent] = usePutApiComponentsByCidMutation();

  const onSubmit: SubmitHandler<IComponentFields> = (data) => {
    const krakenBackendApiModelsProjectComponentsApiProjectComponent = {
      name: data.Name,
      description: data.Description,
      threat_analysis: data.ThreatAnalysis,
      observations: data.Observations,
      notes: data.Notes,
    };

    saveComponent({
      cid: cid!,
      krakenBackendApiModelsProjectComponentsApiProjectComponent,
    });
  };

  useEffect(() => {
    setFocus('Name');
  }, [setFocus]);

  useEffect(() => {
    setReadyToSubmit(isDirty && isValid);
  }, [isDirty, isValid]);

  useEffect(() => {
    if (component) {
      reset({
        Name: component.Name,
        Description: component.Description,
        Notes: component.Notes,
        Observations: component.Observations,
        ThreatAnalysis: component.ThreatAnalysis,
      });
    }
  }, [component]);

  return {
    getValues,
    control,
    handleSubmit,
    onSubmit,
    watch,
    readyToSubmit,
    isLoading: componentIsLoading,
  };
};
