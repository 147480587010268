import { PaletteMode } from '@mui/material';

import { MuiAlertDark, MuiAlertLight } from './theme/MuiAlert';
import {
  MuiAutocompleteDark,
  MuiAutocompleteLight,
} from './theme/MuiAutocomplete';
import { MuiBreadcrumbs } from './theme/MuiBreadcrumbs';
import { MuiButtonDark, MuiButtonLight } from './theme/MuiButton';
import { MuiChipDark, MuiChipLight } from './theme/MuiChip';
import {
  MuiFormControlDark,
  MuiFormControlLight,
} from './theme/MuiFormControl';
import { MuiInputBase } from './theme/MuiInputBase';
import { MuiInputLabel } from './theme/MuiInputLabel';
import { MuiMenuItem } from './theme/MuiMenuItem';

const light = {
  MuiButton: MuiButtonLight,
  MuiChip: MuiChipLight,
  MuiAlert: MuiAlertLight,
  MuiFormControl: MuiFormControlLight,
  MuiAutocomplete: MuiAutocompleteLight,
};

const dark = {
  MuiButton: MuiButtonDark,
  MuiChip: MuiChipDark,
  MuiAlert: MuiAlertDark,
  MuiFormControl: MuiFormControlDark,
  MuiAutocomplete: MuiAutocompleteDark,
};

export const getDesignTokens: any = (mode: PaletteMode) => ({
  palette: {
    mode,
  },
  typography: {
    fontFamily: [
      'Hauora-Bold',
      'Roboto',
      'Helvetica',
      'Arial',
      'sans-serif',
    ].join(','),
  },
  components: {
    MuiInputBase,
    MuiInputLabel,
    MuiMenuItem,
    MuiBreadcrumbs,
    ...(mode === 'light' ? light : dark),
  },
});
