import { MenuItem, Skeleton } from '@mui/material';

import { KrakenSelect } from '@components/KrakenSelect/KrakenSelect';
import { kebabCase } from '@utils/kebabCase';

import { ESTLinks } from '../types';

import { useSTAssociatedActivityTemplates } from './useSTAssociatedActivityTemplates';

const skeletonStyle = { flexGrow: 1, height: 44, marginTop: '20px' };

export const STAssociatedActivityTemplates = () => {
  const {
 activityIds, activities, handler, isLoading,
} = useSTAssociatedActivityTemplates();

  return (
    <>
      {isLoading && !activities?.length && (
        <Skeleton animation="wave" variant="rectangular" sx={skeletonStyle} />
      )}
      {activities?.length ? (
        <KrakenSelect
          id={kebabCase(ESTLinks.ASSOCIATED_ACTIVITY_TEMPLATE)}
          label="Associated Activity Templates"
          value={activityIds || []}
          onChange={handler}
          multiple
        >
          {activities.map(({ Id, Subject }) => (
            <MenuItem key={Id} value={Id}>
              {Subject}
            </MenuItem>
          ))}
        </KrakenSelect>
      ) : null}
    </>
  );
};
