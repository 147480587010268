import { Controller } from 'react-hook-form';
import { Control } from 'react-hook-form/dist/types';
import { MenuItem } from '@mui/material';

import { KrakenSelect } from '@components/KrakenSelect/KrakenSelect';
import { kebabCase } from '@utils/kebabCase';

import { ESRLinks, IStatusReportFields } from '../types';

interface IProps {
  control: Control<IStatusReportFields>;
}

const list = ['OnTrack', 'Completed'];

export const SRProjectState = ({ control }: IProps) => (
  <Controller
    control={control}
    name="ProjectState"
    render={({ field: { value, onChange } }) => (
      <KrakenSelect
        id={kebabCase(ESRLinks.PROJECT_STATE)}
        label="Project state*"
        value={value || 'OnTrack'}
        onChange={onChange}
      >
        {list.map((name) => (
          <MenuItem key={name} value={name}>
            {name}
          </MenuItem>
        ))}
      </KrakenSelect>
    )}
  />
);
