import { Controller } from 'react-hook-form';
import { Control } from 'react-hook-form/dist/types';

import { KrakenTextArea } from '@components/KrakenTextArea/KrakenTextArea';
import { kebabCase } from '@utils/kebabCase';
import { textAreaHandler } from '@utils/textAreaHandler';

import { EAddEditActivityLinks, IActivityFields } from '../types';

const scopePlaceholder = `Provide a high-level description (in full sentences) of what we are testing for in X (e.g., a component, a web app, a system).
Sentence template: “Validate that X does/does not do Y.”
Point of view: State in the imperative (implied subject).
Always write complete sentences followed by a period.`;

interface IProps {
  control: Control<IActivityFields>;
}

export const ActivityScope = ({ control }: IProps) => (
  <Controller
    control={control}
    name="Scope"
    render={({ field: { value, onChange } }) => (
      <KrakenTextArea
        id={kebabCase(EAddEditActivityLinks.SCOPE)}
        title="Scope*"
        value={value || ''}
        onChange={(val) => textAreaHandler(val, onChange)}
        placeholder={scopePlaceholder}
        height={180}
        hideToolbar
      />
    )}
  />
);
