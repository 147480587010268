import { CSSProperties } from 'react';
import { Alert, Avatar, Fade } from '@mui/material';

import BLANK_ICO from './images/Vector.svg';
import { useDragAndDrop } from './useDragAndDrop';

export interface IProps {
  handler: (file: File) => void;
}

const styles: { [key: string]: CSSProperties } = {
  dnd: {
    minHeight: 182,
    flexDirection: 'column',
  },
  avatar: {
    backgroundColor: '#F8F8F8',
    width: '58px',
    height: '58px',
    display: 'flex',
  },
  center: {
    textAlign: 'center',
  },
};

export function JustDragAndDrop({ handler }: IProps) {
  const {
    inputRef,
    onFileUpload,
    inDropZone,
    handleDrop,
    handleDragOver,
    handleDragEnter,
    handleDragLeave,
    onClickContainer,
    errorMessage,
  } = useDragAndDrop({ JustDnDHandler: handler });

  return (
    <>
      <input type="file" hidden ref={inputRef} onChange={onFileUpload} />
      <button
        type="button"
        className={inDropZone ? 'dnd dndInside' : 'dnd'}
        onDrop={handleDrop}
        onDragOver={handleDragOver}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        onClick={onClickContainer}
        style={styles.dnd}
      >
        <Avatar sx={styles.avatar} src={BLANK_ICO} />
        <div className="dndRight" style={styles.center}>
          <div className="dndTitle">Drag and drop an image, or Browse</div>
          <p>Use PNG, JPG or JPEG</p>
        </div>
      </button>
      <Fade in={Boolean(errorMessage)}>
        <Alert severity="error">{errorMessage}</Alert>
      </Fade>
    </>
  );
}
