/**
 * Attaches a given access token to a MS Graph API call. Returns information about the user
 * @param accessToken
 */

import { graphConfig } from '../config/authConfig';

export async function callMsGraph(accessToken: string) {
  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;

  headers.append('Authorization', bearer);

  const options = {
    method: 'GET',
    headers,
  };

  const response = await fetch(graphConfig.graphMeEndpoint, options);

  return !response.ok ? null : response.json();
}
