import { KrakenLayout } from '@src/layouts/KrakenLayout/KrakenLayout';

import { FTChecklist } from './FTChecklist';
import { FTDescription } from './FTDescription';
import { FTDifficulty } from './FTDifficulty';
import { FTFooter } from './FTFooter';
import { FTImpact } from './FTImpact';
import { FTName } from './FTName';
import { FTNotes } from './FTNotes';
import { FTObservation } from './FTObservation';
import { FTRecommendation } from './FTRecomendation';
import { FTReference } from './FTReference';
import { FTShippableRecommendation } from './FTShippableRecommendation';
import { FTTitle } from './FTTitle';
import { useAddEditFindingTemplate } from './useAddEditFindingTemplate';

export const AddEditFindingTemplate = () => {
  const {
    vid,
    isLoading,
    control,
    watch,
    handleSubmit,
    onSubmit,
    readyToSubmit,
  } = useAddEditFindingTemplate();

  return (
    <KrakenLayout
      checkList={<FTChecklist watch={watch} />}
      footer={<FTFooter readyToSubmit={readyToSubmit} />}
      onSubmit={handleSubmit(onSubmit)}
      isLoading={isLoading}
    >
      <h2>{`${vid ? 'Edit' : 'Add new'} Finding Template`}</h2>
      <FTName control={control} />
      <FTTitle control={control} />
      <FTImpact control={control} />
      <FTDifficulty control={control} />
      <FTDescription control={control} />
      <FTObservation control={control} />
      <FTShippableRecommendation control={control} />
      <FTRecommendation control={control} />
      <FTReference control={control} />
      <FTNotes control={control} />
    </KrakenLayout>
  );
};
