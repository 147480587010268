import { KrakenNavBar } from '@components/KrakenNavBar/KrakenNavBar';
import { useTypedSelector } from '@src/utils';
import {
  GetApiStatisticsFindingsCountApiResponse,
  useGetApiStatisticsFindingsCountQuery,
} from '@store/services/query.generated';
import { useActions } from '@utils/hooks/useActions';

import s from '../FindingsByUser.module.sass';

const list = ['Name', 'Critical', 'High', 'Medium', 'Low', 'Info'];

export const FBUList = () => {
  const { updateFBU } = useActions();
  let data: undefined | GetApiStatisticsFindingsCountApiResponse;

  const { humanId, startDate, endDate } = useTypedSelector(
    (state) => state.findingsByUserReducer,
  );

  if (humanId) {
    const payload = {
      humanId,
      ...(startDate ? { startDate } : {}),
      ...(endDate ? { endDate } : {}),
    };
    ({ data } = useGetApiStatisticsFindingsCountQuery(payload));
  }

  const onClick = (e: any) => {
    const { target } = e;
    if ('code' in e && (e.code !== 'Enter' || e.code !== 'Space')) {
      return;
    }
    const risk = target.getAttribute('aria-label');
    const projectId = target.closest('#listItem').dataset.id;

    updateFBU({ field: 'risk', value: risk });
    updateFBU({ field: 'projectId', value: projectId });
    updateFBU({ field: 'showPopup', value: true });
  };

  const attributes = (label: string, count: number = 0) => {
    if (count > 0) {
      return {
        className: s.clickableCell,
        role: 'link',
        'aria-label': label,
        tabIndex: 0,
        onKeyDown: onClick,
        onClick,
      };
    }

    return {};
  };

  return !data?.length ? (
    <>No Data</>
  ) : (
    <>
      <KrakenNavBar
        list={list}
        gridTemplateColumns="minmax(100px, 200px) repeat(5, 1fr)"
      />
      {data?.map(
        ({
 ProjectId, ProjectName, Critical, High, Medium, Low, Info,
}) => (
  <div
    key={ProjectId}
    id="listItem"
    data-id={ProjectId}
    className={`${s.listItem}`}
  >
    <div>{ProjectName}</div>
    <div {...attributes('Critical', Critical)}>{Critical}</div>
    <div {...attributes('High', High)}>{High}</div>
    <div {...attributes('Medium', Medium)}>{Medium}</div>
    <div {...attributes('Low', Low)}>{Low}</div>
    <div {...attributes('Info', Info)}>{Info}</div>
  </div>
        ),
      )}
    </>
  );
};
