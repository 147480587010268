import { Controller } from 'react-hook-form';
import { Control } from 'react-hook-form/dist/types';

import { KrakenTextArea } from '@components/KrakenTextArea/KrakenTextArea';
import { kebabCase } from '@utils/kebabCase';
import { textAreaHandler } from '@utils/textAreaHandler';

import { EATList, IActivityTemplateFields } from '../types';

interface IProps {
  control: Control<IActivityTemplateFields>;
}

export const ATScope = ({ control }: IProps) => (
  <Controller
    control={control}
    name="Scope"
    render={({ field: { value, onChange } }) => (
      <KrakenTextArea
        id={kebabCase(EATList.SCOPE)}
        title="Scope"
        value={value || ''}
        onChange={(val) => textAreaHandler(val, onChange)}
        height={100}
        hideToolbar
      />
    )}
  />
);
