import { Controller } from 'react-hook-form';
import { Control } from 'react-hook-form/dist/types';

import { KrakenTextArea } from '@components/KrakenTextArea/KrakenTextArea';
import { useWritingPrompts } from '@utils/hooks/useWritingPrompts';
import { kebabCase } from '@utils/kebabCase';
import { textAreaHandler } from '@utils/textAreaHandler';

import { EESLinks, IExecutiveSummaryFields } from '../types';

interface IProps {
  control: Control<IExecutiveSummaryFields>;
}

export const ESScope = ({ control }: IProps) => {
  const { scopePrefix, scopePostfix } = useWritingPrompts();

  return (
    <Controller
      control={control}
      name="Scope"
      render={({ field: { value, onChange } }) => (
        <KrakenTextArea
          id={kebabCase(EESLinks.SCOPE)}
          title="Scope*"
          height={180}
          hideToolbar
          value={value || ''}
          onChange={(val) => textAreaHandler(val, onChange)}
          prefix={scopePrefix}
          info={scopePostfix}
        />
      )}
    />
  );
};
