import { useEffect, useState } from 'react';

import { ERoles } from '@src/constants/constants';
import { useTypedSelector } from '@src/utils';

export const useIsInRole = (roleId: ERoles) => {
  const [isInRole, setIsInRole] = useState(false);

  const { idTokenClaims } = useTypedSelector(
    (state: any) => state.msalReducer.tokenData,
  );

  useEffect(() => {
    if (idTokenClaims?.roles) {
      setIsInRole(
        idTokenClaims?.roles?.some(
          (role: ERoles) => role.toLowerCase() === roleId.toLowerCase(),
        ),
      );
    }
  }, [idTokenClaims]);

  return {
    isInRole,
  };
};
