import { IPublicClientApplication } from '@azure/msal-browser';

import { showError } from '@store/reducers/errorSlice';
import { store } from '@store/store';

export const handleLogout = (instance: IPublicClientApplication) => {
  instance
    .logoutRedirect()
    .catch((e) => {
      store.dispatch(showError(e));
    })
    .finally(() => {
      localStorage.removeItem('idToken');
    });
};
