import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { LinearProgress } from '@mui/material';

import { EmptyList } from '@components/EmptyList/EmptyList';
import { useGetApiComponentsByPidQuery } from '@store/services/query.generated';
import { usePage } from '@utils/hooks/usePage';

import { AddComponent } from './AddComponent/AddComponent';
import { AddEditComponent } from './AddEditComponent/AddEditComponent';
import { ComponentsFooter } from './ComponentsFooter/ComponentsFooter';
import { ComponentsList } from './ComponentsList/ComponentsList';

import s from './Components.module.sass';

export const Components = () => {
  const [visible, setVisible] = useState(false);
  const { pid } = useParams();
  const { page } = usePage(['edit-components']);

  const { data: components = [], isLoading } = useGetApiComponentsByPidQuery({
    pid: pid!,
  });

  const showPopup = () => setVisible(true);
  const onClose = () => setVisible(false);

  return page ? (
    <AddEditComponent />
  ) : (
    <div className={s.components}>
      {isLoading && (
        <div className={s.progress}>
          <LinearProgress />
        </div>
      )}
      {!isLoading && !components.length && (
        <EmptyList handler={showPopup} listName="components" />
      )}
      {!!components.length && (
        <>
          <ComponentsList components={components} />
          <ComponentsFooter showPopup={showPopup} />
        </>
      )}
      {visible && <AddComponent visible={visible} onClose={onClose} />}
    </div>
  );
};
