import { Controller } from 'react-hook-form';
import { Control } from 'react-hook-form/dist/types';

import { KrakenTextArea } from '@components/KrakenTextArea/KrakenTextArea';
import { RadioButtons } from '@components/RadioButtons/RadioButtons';
import {
  EFTLinks,
  IFindingTemplateFields,
} from '@pages/Admin/FindingTemplate/AddEditFindingTemplate/types';
import { difficultyList } from '@pages/Project/Findings/AddEditFinding/constants';
import { kebabCase } from '@utils/kebabCase';
import { textAreaHandler } from '@utils/textAreaHandler';

const difficultyPlaceholder = `Difficulty Rationale: Point of view: Third person. Don’t use first person (e.g., I, we, our) or second person (e.g., you, your).

Content and sentence structure: Attacker must have/would require [detail the tools, skills, access, time, or special knowledge required].`;

interface IProps {
  control: Control<IFindingTemplateFields>;
}

export const FTDifficulty = ({ control }: IProps) => (
  <Controller
    control={control}
    name="DifficultyRationale"
    render={({ field: { value, onChange } }) => (
      <KrakenTextArea
        id={kebabCase(EFTLinks.DIFFICULTY)}
        title="Difficulty"
        value={value || ''}
        onChange={(val) => textAreaHandler(val, onChange)}
        placeholder={difficultyPlaceholder}
        height={180}
        radioBtns={(
          <Controller
            control={control}
            name="Difficulty"
            render={({ field: { value: checked, onChange: handler } }) => (
              <RadioButtons
                list={Object.keys(difficultyList)}
                checked={checked || 'Simple'}
                onChange={(e) => handler(e.target.value)}
              />
            )}
          />
        )}
        hideToolbar
      />
    )}
  />
);
