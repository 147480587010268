import { LinearProgress } from '@mui/material';

import { KrakenNothingFound } from '@components/KrakenNothingFound/KrakenNothingFound';
import { xcsrf } from '@src/constants/constants';
import { useGetApiV2ProjectsWithInactiveQuery } from '@store/services/query.generated';
import { usePage } from '@utils/hooks/usePage';

import { ArchiveProject } from './ArchiveProject/ArchiveProject';
import { ArchiveProjectsList } from './ArchiveProjectsList/ArchiveProjectsList';

const pages = ['deleted-data'];

export const Archive = () => {
  const { page } = usePage(pages);
  const { data = [], isLoading } = useGetApiV2ProjectsWithInactiveQuery(xcsrf);

  return page ? (
    <ArchiveProject />
  ) : (
    <div>
      {isLoading && <LinearProgress />}
      {!isLoading && !data.length && <KrakenNothingFound />}
      {!!data.length && <ArchiveProjectsList />}
    </div>
  );
};
