import { Button } from '@mui/material';

import { FullScreenDialog } from '@components/FullScreenDialog/FullScreenDialog';
import Icon from '@components/Icon/Icon';
import { CompanyLogo } from '@pages/Admin/Companies/AddEditCompanies/CompanyLogo/CompanyLogo';
import { CompanyName } from '@pages/Admin/Companies/AddEditCompanies/CompanyName/CompanyName';

import { useAddCompany } from './useAddCompany';

interface IProps {
  showCompaniesPopup: boolean;
  closeCompaniesPopup: () => void;
}

export function AddCompany({
  showCompaniesPopup,
  closeCompaniesPopup,
}: IProps) {
  const {
 handleSubmit, onSubmit, control, readySubmit,
} = useAddCompany(closeCompaniesPopup);

  return (
    <FullScreenDialog
      open={showCompaniesPopup}
      onClose={closeCompaniesPopup}
      title="Show edits"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <CompanyName control={control} />
        <CompanyLogo control={control} />
        <Button
          color="primary"
          size="small"
          startIcon={<Icon iconName="save" />}
          type="submit"
          disabled={!readySubmit}
        >
          Add Company
        </Button>
      </form>
    </FullScreenDialog>
  );
}
