import {
 ReactElement, ReactNode, useEffect, useState,
} from 'react';
import { createTheme, ThemeProvider } from '@mui/material';

import { getDesignTokens } from '@src/config/getDesignTokens';
import { useTypedSelector } from '@src/utils';

interface IProps {
  children: ReactNode | ReactElement | ReactElement[];
}

export const KrakenThemeProvider = ({ children }: IProps) => {
  const [theme, setTheme] = useState({});
  const themeColor = useTypedSelector((state) => state.themeReducer.theme);

  useEffect(() => {
    setTheme(createTheme(getDesignTokens(themeColor)));
  }, [themeColor]);

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};
