import cn from 'classnames';
import { Controller } from 'react-hook-form';
import { Control } from 'react-hook-form/dist/types';
import { Button, MenuItem } from '@mui/material';

import Icon from '@components/Icon/Icon';
import { KrakenSelect } from '@components/KrakenSelect/KrakenSelect';
import {
  EAddProjectLinks,
  IProjectFields,
} from '@pages/Projects/AddEditProject/types';
import { kebabCase } from '@utils/kebabCase';

import { useProjectCompany } from './useProjectCompany';

import s from '../AddProject.module.sass';

interface IProps {
  control: Control<IProjectFields>;
}

export const ProjectCompany = ({ control }: IProps) => {
  const {
 isAdmin, onAddCompany, companies, isLoading,
} = useProjectCompany();

  return (
    <div className={cn(s.company, { [s.isAdmin]: isAdmin })}>
      <Controller
        control={control}
        name="coID"
        render={({ field: { value, onChange } }) => (
          <KrakenSelect
            id={kebabCase(EAddProjectLinks.COMPANY)}
            label="Company*"
            value={value && !companies?.length ? '' : value || ''}
            defaultValue=""
            isLoading={isLoading}
            onChange={onChange}
          >
            {companies
              ?.filter(({ Active }) => Active)
              ?.map(({ CompanyId, Name }) => (
                <MenuItem key={CompanyId} value={CompanyId || ''}>
                  {Name || ''}
                </MenuItem>
              )) || []}
          </KrakenSelect>
        )}
      />
      {isAdmin && (
        <Button
          size="small"
          color="secondary"
          startIcon={<Icon iconName="plus" />}
          onClick={onAddCompany}
        >
          Add company
        </Button>
      )}
    </div>
  );
};
