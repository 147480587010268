import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { IconButton } from '@mui/material';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';

import Icon from '@components/Icon/Icon';
import { JustDragAndDrop } from '@src/components/DragAndDrop/JustDragAndDrop';
import { endpoints } from '@src/store/services/customApiEndpoints';
import {
  useDeleteApiAttachmentsByAidMutation,
  useGetApiV2FindingsImagesByFidQuery,
} from '@src/store/services/query.generated';

import s from './FindingImages.module.sass';

interface FLink {
  link: string;
  id: string;
}

const styles = {
  imageList: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gridTemplateColumns: 'none !important',
    transform: 'translateZ(0)',
    overflow: 'hidden',
  },
  fullWidth: {
    flex: 1,
  },
  imageListItem: {
    flexDirection: 'row',
  },
  imageListItemBar: {
    background: 'transparent',
  },
  closeIcon: {
    width: '24px',
    height: '24px',
    margin: '10px 10px 0 0',
    padding: 0,
    background: '#fff',
    position: 'relative',
  },
};

export const FindingImages = () => {
  const [imageLinks, setImageLinks] = useState<FLink[]>([]);
  const { fid } = useParams();

  const { data: imageLinksFromApi } = useGetApiV2FindingsImagesByFidQuery(
    { fid: fid! },
    { skip: !fid },
  );
  const [deleteImg] = useDeleteApiAttachmentsByAidMutation();

  useEffect(() => {
    const links = imageLinksFromApi as FLink[];
    links && setImageLinks(links);
  }, [imageLinksFromApi]);

  const onDeleteClick = (id: string) => {
    const newList = imageLinks.filter((l) => l.id !== id);
    deleteImg({ aid: id }).then(() => setImageLinks(newList));
  };

  const addImg = (img: File) => {
    endpoints.finding.postImg(fid!, img).then((result: any) => {
      const link = result as FLink;
      setImageLinks(imageLinks.concat(link));
    });
  };

  return (
    <ImageList gap={16} rowHeight={182} sx={styles.imageList}>
      {imageLinks
        .map((item) => (
          <ImageListItem key={item.id} sx={styles.imageListItem}>
            <img src={item.link} alt={`kraken-img${item.id}`} />
            <ImageListItemBar
              position="top"
              actionIcon={(
                <IconButton
                  sx={styles.closeIcon}
                  aria-label={`delete ${item.id}`}
                  onClick={() => onDeleteClick(item.id)}
                >
                  <Icon iconName="close" className={s.closeSvg} />
                </IconButton>
              )}
              actionPosition="right"
              sx={styles.imageListItemBar}
            />
          </ImageListItem>
        ))
        .concat([
          <ImageListItem key="add-new-item" sx={styles.fullWidth}>
            <JustDragAndDrop handler={addImg} />
          </ImageListItem>,
        ])}
    </ImageList>
  );
};
