export enum EAddEditActivityLinks {
  COMPONENT = 'Component',
  SUBJECT = 'Subject',
  SCOPE = 'Scope',
  CHECKLIST = 'Checklist',
  METHODOLOGY = 'Methodology',
  OBSERVATIONS = 'Observations',
  FUTURE_WORK = 'Future Work',
  FINDINGS = 'Findings',
  NOTES = 'Internal Notes',
}

export interface IActivityFields {
  Subject: string | null;
  PcId: string | null;
  Scope: string | null;
  Description: string | null;
  Disposition: string | null;
  FutureWork: string | null;
  Dates: [Date | null, Date | null];
  Date: Date | null;
  Notes: string | null;
}
