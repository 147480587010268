import { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { KrakenListItem } from '@components/KrakenListItem/KrakenListItem';
import { IColumn } from '@components/KrakenTable/type';
import { LinkEnum } from '@src/types/types';
import { apiToLocalDateString } from '@src/utils/dateUtils';
import { KrakenBackendApiModelsActivitiesApiActivityDetailed } from '@store/services/query.generated';

import { ActivityChecklist } from './ActivityChecklist';
import { ActivityStatus } from './ActivityStatus';

import s from './ActivityList.module.sass';

const columns: IColumn<KrakenBackendApiModelsActivitiesApiActivityDetailed>[] = [
    {
      headerName: 'SUBJECT',
      field: 'Subject',
      sortable: true,
    },
    {
      headerName: 'COMPONENT',
      field: 'PcName',
      sortable: true,
    },
    {
      headerName: 'STATUS',
      sortable: false,
    },
    {
      headerName: 'CHECKLIST',
      sortable: false,
    },
    {
      headerName: 'Last Modified',
      field: 'UpdatedAt',
      sortable: true,
      sortType: 'date',
    },
  ];

export const useActivityList = () => {
  const { pid } = useParams();
  const navigate = useNavigate();
  const onClick = (aid: string | undefined) => {
    aid
      && navigate(
        LinkEnum.EDIT_ACTIVITY.replace(':pid', pid!).replace(':aid', aid!),
        { state: { linkTemplate: LinkEnum.EDIT_ACTIVITY } },
      );
  };

  const rowTemplate = useCallback(
    (activity) => (
      <KrakenListItem
        key={activity.id}
        fid={activity.id}
        classname={s.wrapper}
        handler={onClick}
      >
        <div className={s.subject}>{activity.Subject}</div>
        <div className={s.component}>{activity.PcName}</div>
        <div className={s.status}>
          <ActivityStatus
            aid={activity.id!}
            defaultValue={activity.DeclaredStatus!}
          />
        </div>
        <div className={s.checklist}>
          <ActivityChecklist checklist={activity.Checklist} />
        </div>
        <div className={s.updated_at}>
          {apiToLocalDateString(activity.UpdatedAt)}
        </div>
      </KrakenListItem>
    ),
    [],
  );

  return {
    columns,
    rowTemplate,
  };
};
