export type TSort = 'ask' | 'desc' | 'normal';
export type TSortType = 'string' | 'number' | 'date';

export interface IColumn<T> {
  headerName: string;
  field?: string;
  sortable?: boolean;
  sortType?: TSortType;
  comparator?: (x: keyof T, y: keyof T) => number;
}

export enum ESort {
  NORMAL = 'normal',
  ASK = 'ask',
  DESC = 'desc',
}
