import { useNavigate } from 'react-router-dom';
import { LinearProgress } from '@mui/material';

import { EmptyList } from '@components/EmptyList/EmptyList';
import { AddEditActivityTemplate } from '@pages/Admin/ActivityTemplates/AddEditActivityTemplate/AddEditActivityTemplate';
import { xcsrf } from '@src/constants/constants';
import { LinkEnum } from '@src/types/types';
import { useGetApiActivityTemplatesQuery } from '@store/services/query.generated';
import { usePage } from '@utils/hooks/usePage';

import { ATFooter } from './ATFooter/ATFooter';
import { ATList } from './ATList/ATList';

import s from './ActivityTemplates.module.sass';

const pages = ['add-activity-template', 'edit-activity-template'];

export const ActivityTemplates = () => {
  const { page } = usePage(pages);
  const { data = [], isLoading } = useGetApiActivityTemplatesQuery(xcsrf);
  const navigate = useNavigate();

  return page ? (
    <AddEditActivityTemplate />
  ) : (
    <div className={s.activityTemplateList}>
      {isLoading && (
        <div className={s.progress}>
          <LinearProgress />
        </div>
      )}
      {!isLoading && !data.length && (
        <EmptyList
          listName="activity template"
          handler={() => navigate(LinkEnum.ADD_ACTIVITY_TEMPLATE, {
              state: { linkTemplate: LinkEnum.ADD_ACTIVITY_TEMPLATE },
            })}
        />
      )}
      {!!data.length && (
        <>
          <ATList templates={data} />
          <ATFooter />
        </>
      )}
    </div>
  );
};
