import { Controller } from 'react-hook-form';
import { Control } from 'react-hook-form/dist/types';

import { KrakenTextArea } from '@components/KrakenTextArea/KrakenTextArea';
import { kebabCase } from '@utils/kebabCase';
import { textAreaHandler } from '@utils/textAreaHandler';

import { EAddEditFindingLinks, IFindingFields } from '../types';

const placeholder = `Point of view: Begin with first person plural pronoun (e.g., we, our; ); don’t use Leviathan (e.g., “Leviathan found …”). After first sentence, first and third person are both OK, but don’t use second person (e.g., you).

Content and sentence structure: [name their thing, describe what you observed about it, and explain the vulnerability it creates]. If applicable, provide steps to reproduce.`;

interface IProps {
  control: Control<IFindingFields>;
}

export const FindingObservation = ({ control }: IProps) => (
  <Controller
    control={control}
    name="observation"
    render={({ field: { value, onChange } }) => (
      <KrakenTextArea
        id={kebabCase(EAddEditFindingLinks.OBSERVATION)}
        title="Observation"
        value={value || ''}
        onChange={(val) => textAreaHandler(val, onChange)}
        height={180}
        placeholder={placeholder}
        info="This should have everything needed for a customer to reproduce the finding themselves"
        hideToolbar
      />
    )}
  />
);
