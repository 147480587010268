import enGb from 'date-fns/locale/en-GB';
import enLocale from 'date-fns/locale/en-US';
import { useEffect, useState } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import {
 FormControl, InputBase, InputLabel, Portal,
} from '@mui/material';

import { datepickerDateFormat } from '@src/constants/constants';

import 'react-datepicker/dist/react-datepicker.css';
import './Datepicker.sass';

registerLocale('en-gb', enGb);
registerLocale('en', enLocale);

function CalendarContainer({ children }: any) {
  const el = document.getElementById('calendar-portal');

  return <Portal container={el}>{children}</Portal>;
}

interface IProps {
  label?: string;
  startDate: Date | null | undefined;
  endDate?: Date | null | undefined;
  selectsRange?: boolean;
  onChange: (dates: (Date | null) | [Date | null, Date | null]) => void;
  // All others properties
  [x: string]: any;
}

export function Datepicker({
  label = '',
  startDate,
  endDate,
  onChange,
  selectsRange = false,
  ...rest
}: IProps) {
  const [locale, setLocale] = useState<string>('en');

  useEffect(() => {
    if (navigator.language === 'ru-RU' || navigator.language === 'ru-UA') {
      setLocale('en-gb');
    }
  }, []);

  return (
    <FormControl variant="standard" fullWidth className="datepicker">
      {label && (
        <InputLabel shrink id="date-label">
          {label}
        </InputLabel>
      )}
      <DatePicker
        id="date-id"
        selected={startDate}
        selectsRange={selectsRange}
        startDate={startDate}
        endDate={endDate}
        placeholderText="Select date range"
        dateFormat={datepickerDateFormat}
        monthsShown={selectsRange ? 2 : 1}
        onChange={onChange}
        locale={locale}
        popperContainer={CalendarContainer}
        customInput={<InputBase />}
        autoComplete="off"
        onKeyDown={(e) => e.preventDefault()}
        {...rest}
      />
    </FormControl>
  );
}
