import { Controller } from 'react-hook-form';
import { Control } from 'react-hook-form/dist/types';
import { MenuItem } from '@mui/material';

import { KrakenSelect } from '@components/KrakenSelect/KrakenSelect';

import { IFindingFields } from '../types';

const AWSList = [
  {
    val: 'Audit and Logging',
    key: 'a',
    name: 'Audit and Logging --- Recording of Security Events',
  },
  {
    val: 'Authentication',
    key: 'b',
    name: 'Authentication --- Identification of User',
  },
  {
    val: 'Authorization and Access Controls',
    key: 'c',
    name: 'Authorization and Access Controls --- Permission Management',
  },
  {
    val: 'Client Side Security',
    key: 'd',
    name: 'Client Side Security --- SDK, CLI, Browser, Mobile Components',
  },
  {
    val: 'Communication Security',
    key: 'e',
    name: 'Communication Security --- Network Protocols Security',
  },
  {
    val: 'Configuration and Environment',
    key: 'f',
    name: 'Configuration and Environment --- Secure Configurations and Patching of Server, Software, Device',
  },
  {
    val: 'Cryptography',
    key: 'g',
    name: 'Cryptography --- Use of Cryptography and Key Management System',
  },
  {
    val: 'Customer Trust',
    key: 'h',
    name: 'Customer Trust --- Earning and Keeping Customers Trust',
  },
  {
    val: 'Data Protection',
    key: 'i',
    name: 'Data Protection --- Data Classification, Integrity, Encryption, Destruction, Retention',
  },
  {
    val: 'Denial of Service',
    key: 'j',
    name: 'Denial of Service --- Detecting and Preventing Availability Issues',
  },
  {
    val: 'Error Handling and Reporting',
    key: 'k',
    name: 'Error Handling and Reporting --- Secure Exception Handling and Displaying',
  },
  {
    val: 'Input Validation',
    key: 'l',
    name: 'Input Validation --- Data Validation and Malicious Input Handling',
  },
  {
    val: 'Session Management',
    key: 'm',
    name: 'Session Management --- Identification of Authenticated User',
  },
];

interface IProps {
  control: Control<IFindingFields>;
}

export const FindingAWSCategory = ({ control }: IProps) => (
  <Controller
    control={control}
    name="awsCategory"
    render={({ field: { value, onChange } }) => (
      <KrakenSelect
        label="AWS Category*"
        value={value || ''}
        onChange={(e) => onChange(e.target.value)}
      >
        {Object.values(AWSList).map(({ val, key, name }) => (
          <MenuItem key={key} value={val}>
            {name}
          </MenuItem>
        ))}
      </KrakenSelect>
    )}
  />
);
