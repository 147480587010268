import { useEffect, useState } from 'react';
import { UseFormWatch } from 'react-hook-form';

import { ICheckListLink } from '@components/CheckList/CheckList';

import { EFTLinks, IFindingTemplateFields } from '../types';

const linkState: ICheckListLink = {
  [EFTLinks.NAME]: false,
  [EFTLinks.TITLE]: false,
  [EFTLinks.DESCRIPTION]: false,
  [EFTLinks.OBSERVATION]: false,
  [EFTLinks.RECOMMENDATION]: false,
  [EFTLinks.REFERENCE]: false,
  [EFTLinks.NOTES_TO_PENTESTER]: false,
};

export const useFTChecklist = (watch: UseFormWatch<IFindingTemplateFields>) => {
  const [links, setLinks] = useState(linkState);

  useEffect(() => {
    const subscription = watch((value) => {
      setLinks({
        [EFTLinks.NAME]: Boolean(value.Name),
        [EFTLinks.TITLE]: Boolean(value.Title),
        [EFTLinks.DESCRIPTION]: Boolean(value.Description),
        [EFTLinks.OBSERVATION]: Boolean(value.Observation),
        [EFTLinks.RECOMMENDATION]: Boolean(value.Recommendation),
        [EFTLinks.REFERENCE]: Boolean(value.Reference),
        [EFTLinks.NOTES_TO_PENTESTER]: Boolean(value.Notes),
      });
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  return links;
};
