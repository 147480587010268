import { createRoot } from 'react-dom/client';
import {
  Configuration,
  EventType,
  PublicClientApplication,
} from '@azure/msal-browser';
import { AuthenticationResult } from '@azure/msal-common';
import { MsalProvider } from '@azure/msal-react';

import { msalConfig } from './config/authConfig';
import App from './App';

import './index.css';

export const msalInstance = new PublicClientApplication(
  msalConfig as Configuration,
);
const container = document.getElementById('root');

msalInstance.addEventCallback((event) => {
  const payload = event.payload as AuthenticationResult;

  if (event.eventType === EventType.LOGIN_SUCCESS && payload.account) {
    msalInstance.setActiveAccount(payload.account);
  }
  if (event && payload && payload.extExpiresOn) {
    sessionStorage.setItem('tokensTime', payload.extExpiresOn.toString());
  }
  if (payload && payload.idToken) {
    localStorage.setItem('idToken', payload.idToken);
  }
});

if (container) {
  const root = createRoot(container);

  root.render(
    <MsalProvider instance={msalInstance}>
      <App />
    </MsalProvider>,
  );
}
