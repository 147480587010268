import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface ISignalRSlice {
  hasSignalRConnection: boolean;
}

const initialState: ISignalRSlice = {
  hasSignalRConnection: false,
};

const signalRSlice = createSlice({
  name: 'signalR',
  initialState,
  reducers: {
    updateConnectionState(
      state: ISignalRSlice,
      { payload }: PayloadAction<boolean>,
    ) {
      state.hasSignalRConnection = payload;
    },
    resetSignalR: () => initialState,
  },
});

const { reducer, actions } = signalRSlice;

export default reducer;
export const { updateConnectionState, resetSignalR } = actions;
