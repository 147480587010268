import {
 ChangeEvent, useEffect, useRef, useState,
} from 'react';

import { buttonsList } from '@src/constants/constants';
import { EChecklistBtn } from '@src/types/types';
import {
  KrakenBackendApiModelsActivitiesApiActivityChecklistItem,
  useDeleteApiActivitiesChecklistByAcidMutation,
  usePutApiActivitiesChecklistByAcidCheckedMutation,
  usePutApiActivitiesChecklistByAcidMutation,
} from '@store/services/query.generated';
import { getKeyByValue } from '@utils/getKeyByValue';

export const useListItem = (
  data: KrakenBackendApiModelsActivitiesApiActivityChecklistItem,
) => {
  const [btnChecked, setBtnChecked] = useState(
    getKeyByValue(buttonsList, data.status) || EChecklistBtn.INCOMPLETE,
  );
  const [readonly, setReadonly] = useState(true);
  const [textareaValue, setTextareaValue] = useState(data.heading || '');
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const textRef = useRef<string>(data.heading || '');

  const [putChecklist] = usePutApiActivitiesChecklistByAcidMutation();
  const [putChecklistBtn] = usePutApiActivitiesChecklistByAcidCheckedMutation();
  const [deleteChecklist] = useDeleteApiActivitiesChecklistByAcidMutation();

  const onChange = ({
    target: { value },
  }: ChangeEvent<
    HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
  >) => {
    const statusName: any = buttonsList[value as keyof typeof buttonsList];

    setBtnChecked(value as EChecklistBtn);
    putChecklistBtn({
      acid: data.acid!,
      krakenModelsEnumsActivityCheckListItemStatus: statusName,
    });
  };

  const onEdit = () => {
    const end = textareaRef.current?.value.length || 0;

    setReadonly(false);
    textareaRef.current?.setSelectionRange(end, end);
    textareaRef.current?.focus();
  };

  const apply = () => {
    putChecklist({
      acid: data.acid!,
      body: textareaRef.current?.value || textareaValue,
    });
    setReadonly(true);
  };

  const onKeyup = ({ keyCode }: KeyboardEvent) => {
    if (keyCode === 27) {
      setTextareaValue(textRef.current);
      setReadonly(true);
    }
  };

  const onTextarea = ({
    target: { value },
  }: ChangeEvent<HTMLTextAreaElement>) => {
    setTextareaValue(value);
  };

  const onDelete = () => {
    deleteChecklist({ acid: data.acid! });
  };

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.addEventListener('dblclick', onEdit);
      textareaRef.current.addEventListener('blur', apply);
      textareaRef.current.addEventListener('keyup', onKeyup);
    }
    return () => {
      if (textareaRef.current) {
        textareaRef.current.removeEventListener('dblclick', onEdit);
        textareaRef.current.removeEventListener('blur', apply);
        textareaRef.current.removeEventListener('keyup', onKeyup);
      }
    };
  }, []);

  return {
    textareaValue,
    readonly,
    textareaRef,
    onEdit,
    onDelete,
    apply,
    btnChecked,
    onTextarea,
    onChange,
  };
};
