const defaultStyles = {
  styleOverrides: {
    root: {
      fontWeight: 600,
      fontSize: '12px',
      lineHeight: '14px',
      '& a': {
        textDecoration: 'none',
        '&:hover': {
          textDecoration: 'underline',
        },
      },
      '& a, & .MuiBreadcrumbs-separator': {
        color: '#877cff',
      },
      '& a, & .MuiBreadcrumbs-separator, & .MuiTypography-root': {
        fontSize: '12px',
      },
    },
  },
};

export const MuiBreadcrumbs = {
  ...defaultStyles,
};
