import { Box, Button, useTheme } from '@mui/material';

import Icon from '../Icon/Icon';

import styles from './Promotion.module.sass';

const boxStyles = (mode: 'light' | 'dark') => ({
  width: '100%',
  maxWidth: '984px',
  height: 76,
  backgroundColor: mode === 'light' ? '#F8F8F8' : '#181c29',
  borderRadius: '4px',
  padding: '16px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginTop: '26px',
  marginBottom: '56px',
});

interface IProps {
  iconName: string;
  text: string;
  btnText: string;
  onClick: () => void;
}

export function Promotion({
 iconName, text, btnText, onClick,
}: IProps) {
  const {
    palette: { mode },
  } = useTheme();

  return (
    <Box sx={boxStyles(mode)}>
      <div className={styles.iconWrapper}>
        <Icon iconName={iconName} className={styles.slackIco} />
        <span className={styles.boxText}>{text}</span>
      </div>
      <Button size="small" color="primary" onClick={onClick} disabled>
        {btnText}
      </Button>
    </Box>
  );
}
