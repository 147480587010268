import { useNavigate, useParams } from 'react-router-dom';
import { LinearProgress } from '@mui/material';

import { EmptyList } from '@components/EmptyList/EmptyList';
import { LinkEnum } from '@src/types/types';
import { useGetApiActivitiesProjectByPidQuery } from '@store/services/query.generated';
import { usePage } from '@utils/hooks/usePage';

import { ActivitiesFooter } from './ActivitiesFooter/ActivitiesFooter';
import { ActivityList } from './ActivityList/ActivityList';
import { AddEditActivity } from './AddEditActivity/AddEditActivity';

const pages = ['add-activity', 'edit-activity'];

export const Activities = () => {
  const { pid } = useParams();
  const { page } = usePage(pages);
  const navigate = useNavigate();

  const { data = [], isLoading } = useGetApiActivitiesProjectByPidQuery(
    { pid: pid! },
    { skip: !pid },
  );

  const handler = () => navigate(LinkEnum.ADD_ACTIVITY.replace(':pid', pid!), {
      state: { linkTemplate: LinkEnum.ADD_ACTIVITY },
    });

  return page ? (
    <AddEditActivity />
  ) : (
    <div>
      {isLoading && <LinearProgress />}
      {!isLoading && !data.length && (
        <EmptyList handler={handler} listName="activity" />
      )}
      {!!data.length && (
        <>
          <ActivityList activities={data} />
          <ActivitiesFooter />
        </>
      )}
    </div>
  );
};
