import { resetMsal } from '@store/reducers/msalSlice';
import { resetSignalR } from '@store/reducers/signalRSlice';
import { store } from '@store/store';
import { SendConcurrencyToken } from '@utils/signalR';

import { shutdownConnection } from './shutdownConnection';

export const onUnload = async (e: Event) => {
  e.preventDefault();

  SendConcurrencyToken(localStorage.getItem('idToken') || '', false);
  store.dispatch(resetMsal());
  store.dispatch(resetSignalR());
  await shutdownConnection();
};
