const defaultStyles = {
  defaultProps: {
    variant: 'contained',
  },
  styleOverrides: {
    root: {
      fontSize: '14px',
      fontWeight: 700,
      backgroundColor: 'transparent',
      border: 'none',
      borderRadius: '4px',
      outline: 'transparent',
      textTransform: 'none',
      boxShadow: 'none',
      lineHeight: '20px',
      whiteSpace: 'nowrap',
      '&.Mui-disabled': {
        color: '#717786',
        '& path': {
          fill: '#717786',
        },
      },
      '&.MuiButton-text': {
        '[data-theme="light"] & path': {
          fill: 'black',
        },
      },
    },
  },
  variants: [
    {
      props: { size: 'small' },
      style: {
        padding: '12px 16px',
        height: '44px',
      },
    },
    {
      props: { size: 'large' },
      style: {
        padding: '16px 20px',
        height: '52px',
      },
    },
    {
      props: { color: 'primary' },
      style: {
        background: '#877cff',
        color: '#fff',
        '& path:not(.colored)': {
          fill: '#fff',
        },
        '&:hover': {
          background: '#544da9',
        },
        '&:active': {
          background: '#443E89',
        },
        '&:focus': {
          background: '#544da9',
          outline: '3px solid #b5aeff',
        },
      },
    },
  ],
};

export const MuiButtonLight = {
  ...defaultStyles,
  variants: [
    ...defaultStyles.variants,
    {
      props: { color: 'secondary' },
      style: {
        background: '#f0efff',
        color: '#544da9',
        '& path': {
          fill: '#544da9',
        },
        '&:hover': {
          background: '#E1DFF7',
        },
        '&:active': {
          background: '#D3D1F2',
        },
        '&:focus': {
          background: '#f0efff',
          outline: '3px solid #b5aeff',
        },
      },
    },
    {
      props: { color: 'error' },
      style: {
        border: '1px solid #ffc8C8',
        color: '#ff4646',
        '&:hover': {
          background: '#fff1f1',
        },
        '&:active': {
          background: '#fff1f1',
          filter: 'brightness(102%)',
        },
        '&:focus': {
          background: '#fff1f1',
          outline: '3px solid #ffc8c8',
        },
      },
    },
    {
      props: { variant: 'text' },
      style: {
        background: 'transparent',
        fontWeight: 600,
        fontSize: '12px',
        lineHeight: '14px',
        color: '#090C15',
        '&:hover': {
          background: 'transparent',
        },
        '&:focus, &:active': {
          background: 'transparent',
          border: 'none',
          outline: 'none',
        },
      },
    },
  ],
};

export const MuiButtonDark = {
  ...defaultStyles,
  variants: [
    ...defaultStyles.variants,
    {
      props: { color: 'secondary' },
      style: {
        background: '#565c71',
        color: '#fff',
        '& path:not(.colored)': {
          fill: '#fff',
        },
        '&:hover': {
          background: '#51566e',
        },
        '&:active': {
          background: '#4C516D',
        },
        '&:focus': {
          background: '#565c71',
          outline: '3px solid #8f909f',
        },
      },
    },
    {
      props: { color: 'error' },
      style: {
        border: '1px solid #844c4c',
        color: '#ff4646',
        '&:hover': {
          background: '#210000',
          border: '1px solid #844c4c',
        },
        '&:active': {
          background: '#210000',
          border: '1px solid #844c4c',
          filter: 'brightness(102%)',
        },
        '&:focus': {
          background: '#210000',
          outline: '3px solid #844c4c',
        },
      },
    },
    {
      props: { variant: 'text' },
      style: {
        background: 'transparent',
        fontWeight: 600,
        fontSize: '12px',
        lineHeight: '14px',
        color: '#fff',
        '&:hover': {
          background: 'transparent',
        },
        '&:focus, &:active': {
          background: 'transparent',
          border: 'none',
          outline: 'none',
        },
        '& path': {
          fill: '#fff',
        },
      },
    },
  ],
};
