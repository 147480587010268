import { EditsHistory } from '@components/EditsHistory/EditsHistory';
import { useGetApiReviewablesEditHistoryByRidQuery } from '@store/services/query.generated';

interface IProps {
  fid: any;
}

export const FindingHistory = ({ fid }: IProps) => {
  const { data = [] } = useGetApiReviewablesEditHistoryByRidQuery({
    rid: fid!,
  });

  return data.length ? <EditsHistory data={data} /> : null;
};
