import { ChangeEvent, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button } from '@mui/material';

import s from '@pages/Project/Activities/AddEditActivity/ActivityChecklist/ActivityChecklist.module.sass';
import {
  KrakenBackendApiModelsActivityTemplatesApiActivityTemplateChecklistItem,
  useGetApiActivityTemplatesByAtidQuery,
  usePostApiActivityTemplatesByAtidChecklistMutation,
} from '@store/services/query.generated';

export const useATChecklist = () => {
  const { atid } = useParams();
  const [checklist, setChecklist] = useState<
    KrakenBackendApiModelsActivityTemplatesApiActivityTemplateChecklistItem[]
  >([]);
  const [showPopup, setShowPopup] = useState(false);
  const [input, setInput] = useState('');

  const [addChecklist] = usePostApiActivityTemplatesByAtidChecklistMutation();
  const { data } = useGetApiActivityTemplatesByAtidQuery(
    { atid: atid! },
    { skip: !atid },
  );

  const onAddChecklist = () => {
    setShowPopup(true);
  };

  const onOk = () => {
    addChecklist({
      atid: atid!,
      body: input,
    }).finally(() => {
      setShowPopup(false);
      setInput('');
    });
  };

  const onInput = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
    setInput(value);
  };

  const onClose = () => {
    setShowPopup(false);
    setInput('');
  };

  const footer = (
    <footer className={s.footer}>
      <Button size="small" color="secondary" onClick={onClose}>
        Cancel
      </Button>
      <Button size="small" color="primary" onClick={onOk} disabled={!input}>
        Ok
      </Button>
    </footer>
  );

  useEffect(() => {
    setChecklist(data?.Checklist || []);
  }, [data]);

  return {
    onAddChecklist,
    checklist,
    showPopup,
    footer,
    onClose,
    input,
    onInput,
  };
};
