import { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { KrakenListItem } from '@components/KrakenListItem/KrakenListItem';
import { IColumn } from '@components/KrakenTable/type';
import { LinkEnum } from '@src/types/types';
import { apiToLocalDateString } from '@src/utils/dateUtils';
import { KrakenBackendApiModelsProjectComponentsV1ProjectComponentsDetailed } from '@store/services/query.generated';

import { ComponentStereotypes } from '../AddEditComponent/ComponentStereotypes/ComponentStereotypes';
import { ComponentActivities } from '../ComponentActivities/ComponentActivities';

import s from './ComponentsList.module.sass';

const columns: IColumn<KrakenBackendApiModelsProjectComponentsV1ProjectComponentsDetailed>[] = [
    {
      headerName: 'NAME',
      field: 'name',
      sortable: true,
    },
    {
      headerName: 'STEREOTYPES',
      sortable: false,
    },
    {
      headerName: 'ACTIVITY TEMPLATES',
      sortable: false,
    },
    {
      headerName: 'LAST MODIFIED',
      field: 'updated_at',
      sortable: true,
      sortType: 'date',
    },
  ];

export const useComponentsList = () => {
  const { pid } = useParams();
  const navigate = useNavigate();

  const onClick = (cid: string | undefined) => {
    cid
      && navigate(
        LinkEnum.EDIT_COMPONENT.replace(':pid', pid!).replace(':cid', cid),
        { state: { linkTemplate: LinkEnum.EDIT_COMPONENT } },
      );
  };

  const rowTemplate = useCallback(
    ({ pc_id, name, updated_at }) => (
      <KrakenListItem
        fid={pc_id}
        key={pc_id}
        classname={s.componentWrapper}
        handler={onClick}
      >
        <div className={s.name}>{name || 'No Name'}</div>
        <div className={s.stereotype}>
          <ComponentStereotypes componentId={pc_id} />
        </div>
        <div className={s.activity}>
          <ComponentActivities cid={pc_id} />
        </div>
        <div className={s.updated_at}>
          <p>{apiToLocalDateString(updated_at)}</p>
        </div>
      </KrakenListItem>
    ),
    [],
  );

  return {
    columns,
    rowTemplate,
  };
};
