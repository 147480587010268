import { Alert, Avatar, Fade } from '@mui/material';

import BLANK_ICO from './images/Vector.svg';
import { AvatarDialog } from './avatarDialog';
import { useDragAndDrop } from './useDragAndDrop';

import './DragAndDrop.sass';

interface IProps {
  value: string;
  handler: (state: string) => void;
}

export function DragAndDrop({ handler, value }: IProps) {
  const {
    inputRef,
    onFileUpload,
    inDropZone,
    handleDrop,
    handleDragOver,
    handleDragEnter,
    handleDragLeave,
    onClickContainer,
    filename,
    image,
    errorMessage,
    visible,
    onApply,
    setVisible,
  } = useDragAndDrop({ handler, value });

  return (
    <>
      <input type="file" hidden ref={inputRef} onChange={onFileUpload} />
      <button
        type="button"
        className={inDropZone ? 'dnd dndInside' : 'dnd'}
        onDrop={handleDrop}
        onDragOver={handleDragOver}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        onClick={onClickContainer}
      >
        <Avatar
          sx={{
            bgcolor: '#F8F8F8',
            width: '80px',
            height: '80px',
            display: 'flex',
          }}
          alt={filename}
          src={image || BLANK_ICO}
        />
        <div className="dndRight">
          <div className="dndTitle">Drag and drop an image, or Browse</div>
          <p>
            Use PNG, JPG or JPEG, 1200x1200 or higher recommended. Max size 10
            MB each
          </p>
        </div>
      </button>
      <Fade in={Boolean(errorMessage)}>
        <Alert severity="error">{errorMessage}</Alert>
      </Fade>
      {visible && (
        <AvatarDialog
          img={image}
          open={visible}
          onApply={onApply}
          filename={filename}
          close={() => setVisible(false)}
        />
      )}
    </>
  );
}
