import { Controller } from 'react-hook-form';
import { Control } from 'react-hook-form/dist/types';
import { Autocomplete, InputLabel, TextField } from '@mui/material';

import { xcsrf } from '@src/constants/constants';
import {
  GetApiCweApiResponse,
  useGetApiCweQuery,
} from '@store/services/query.generated';

import { IFindingFields } from '../../types';

import s from '../FindingCVSS.module.sass';

interface IProps {
  control: Control<IFindingFields>;
}

export const FindingCwe = ({ control }: IProps) => {
  const { data: CWE, isLoading } = useGetApiCweQuery(xcsrf);

  return (
    <div className={s.cwe}>
      <InputLabel shrink id="cwe-label">
        CWE
      </InputLabel>
      {CWE ? (
        <Controller
          control={control}
          name="cwe"
          render={({ field: { value, onChange } }) => (
            <Autocomplete
              id="cwe-label"
              value={(value && !CWE?.length ? '' : value || '') as any}
              options={CWE as GetApiCweApiResponse}
              renderOption={(props, { Id, Name }) => (
                <li {...props} style={{ fontSize: '12px' }}>
                  {`CWE - ${Id}: ${Name}`}
                </li>
              )}
              getOptionLabel={({ Id, Name }) => (Id && Name && `CWE - ${Id}: ${Name}`) || ''}
              isOptionEqualToValue={(option, val) => option?.Id === val}
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              freeSolo
              loading={isLoading}
              renderInput={(params) => (
                <TextField
                  {...params}
                  inputProps={{
                    ...params.inputProps,
                  }}
                />
              )}
              onChange={(_e, data) => onChange(data)}
            />
          )}
        />
      ) : (
        'Loading...'
      )}
    </div>
  );
};
