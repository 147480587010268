import { useParams } from 'react-router-dom';
import { LinearProgress } from '@mui/material';

import { DeletedComponents } from '@pages/Admin/Archive/ArchiveProject/DeletedComponents/DeletedComponents';
import {
  useGetApiV2ProjectsByPidInactiveActivitiesQuery,
  useGetApiV2ProjectsByPidInactiveComponentsQuery,
  useGetApiV2ProjectsByPidInactiveFindingsQuery,
} from '@store/services/query.generated';

import { DeletedActivities } from './DeletedActivities/DeletedActivities';
import { DeletedFindings } from './DeletedFindings/DeletedFindings';

import s from './ArchiveProject.module.sass';

export const ArchiveProject = () => {
  const { pid } = useParams();
  const { data: deletedFindings = [], isLoading: isFindingsLoading } = useGetApiV2ProjectsByPidInactiveFindingsQuery(
      { pid: pid! },
      { skip: !pid },
    );
  const { data: deletedActivities = [], isLoading: isActivitiesLoading } = useGetApiV2ProjectsByPidInactiveActivitiesQuery(
      { pid: pid! },
      { skip: !pid },
    );
  const { data: deletedComponents = [], isLoading: isComponentsLoading } = useGetApiV2ProjectsByPidInactiveComponentsQuery(
      { pid: pid! },
      { skip: !pid },
    );

  return (
    <div className={s.wrapper}>
      {(isFindingsLoading || isActivitiesLoading || isComponentsLoading) && (
        <LinearProgress />
      )}
      {!!deletedFindings.length && <DeletedFindings />}
      {!!deletedActivities.length && <DeletedActivities />}
      {!!deletedComponents.length && <DeletedComponents />}
    </div>
  );
};
