import { Controller } from 'react-hook-form';
import { Control } from 'react-hook-form/dist/types';
import { FormControl, InputBase, InputLabel } from '@mui/material';

import Icon from '@components/Icon/Icon';
import { IProjectFields } from '@pages/Projects/AddEditProject/types';

const linkStyle = {
  display: 'flex',
  alignItems: 'center',
  gap: '6px',
};

interface IProps {
  control: Control<IProjectFields>;
}

export const ProjectLink = ({ control }: IProps) => (
  <FormControl variant="standard" fullWidth>
    <InputLabel shrink htmlFor="projectLink" style={linkStyle}>
      <Icon iconName="link" />
      Project link
    </InputLabel>
    <Controller
      control={control}
      name="external_project_link"
      render={({ field: { value, onChange, ref } }) => (
        <InputBase
          value={value || ''}
          id="projectLink"
          onChange={onChange}
          inputRef={ref}
        />
      )}
    />
  </FormControl>
);
