import { useParams } from 'react-router-dom';
import { Button, useTheme } from '@mui/material';

import { KrakenListItem } from '@components/KrakenListItem/KrakenListItem';
import { KrakenNavBar } from '@components/KrakenNavBar/KrakenNavBar';
import { gridTemplate } from '@pages/Admin/Archive/constants';
import { severityColorScheme } from '@pages/Project/Findings/AddEditFinding/constants';
import { getSeverity } from '@pages/Project/Findings/AddEditFinding/helper';
import {
  useGetApiV2ProjectsByPidInactiveFindingsQuery,
  usePutApiFindingsByFidUndeleteMutation,
} from '@store/services/query.generated';

import { DeletedDate } from '../components/DeletedDate';

import s from '../ArchiveProject.module.sass';

const list = ['FINDING', 'SEVERITY', 'DELETED BY', ''];

export const DeletedFindings = () => {
  const { pid } = useParams();
  const {
    palette: { mode },
  } = useTheme();
  const { data = [] } = useGetApiV2ProjectsByPidInactiveFindingsQuery(
    { pid: pid! },
    { skip: !pid },
  );
  const [undelete] = usePutApiFindingsByFidUndeleteMutation();

  return (
    <div className={s.deletedFindings}>
      <h2>Deleted Findings</h2>
      <KrakenNavBar list={list} gridTemplateColumns={gridTemplate} />
      {data.map(
        ({
          findingId,
          summary,
          userDeleted,
          deletedDate,
          impact,
          difficulty,
        }) => {
          const severity = getSeverity(impact || 0, difficulty || 0);
          return (
            <KrakenListItem
              key={findingId}
              classname={s.listItem}
              handler={() => {}}
            >
              <div className={s.summary}>{summary}</div>
              <div
                className={s.severity}
                style={{
                  backgroundColor:
                    severityColorScheme[mode || 'light'][severity || 'Info'].bg,
                  color:
                    severityColorScheme[mode || 'light'][severity || 'Info']
                      .color,
                }}
              >
                <span>{severity || 'Info'}</span>
              </div>
              <div>
                <div className={s.userDeleted}>
                  {userDeleted ? `@${userDeleted}` : ''}
                </div>
                <DeletedDate deletedDate={deletedDate as string} />
              </div>
              <Button
                size="large"
                onClick={() => undelete({ fid: findingId! })}
              >
                Undelete Finding
              </Button>
            </KrakenListItem>
          );
        },
      )}
    </div>
  );
};
