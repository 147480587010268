import { parseISO } from 'date-fns';
import { useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { xcsrf } from '@src/constants/constants';
import { useTypedSelector } from '@src/utils';
import {
  useGetApiFinalReportEditableInfoByPidQuery,
  useGetApiReportsAvailableFontsQuery,
} from '@store/services/query.generated';
import { useActions } from '@utils/hooks/useActions';

import { IFinalReportFields } from './types';

export const useFinalReport = () => {
  const { pid } = useParams();
  const { showDownloadFilePopup } = useActions();
  const {
 control, handleSubmit, reset, watch, getValues,
} = useForm<IFinalReportFields>({
      mode: 'onChange',
    });

  const { visible } = useTypedSelector((state) => state.downloadFileReducer);

  const { data: finalReport, isLoading: reportIsLoading } = useGetApiFinalReportEditableInfoByPidQuery({ pid: pid! });
  const { data: fonts, isLoading: fontsIsLoading } = useGetApiReportsAvailableFontsQuery(xcsrf);

  const onSubmit: SubmitHandler<IFinalReportFields> = (data) => {
    const {
      ReportDate,
      ReportVersion,
      AddressAndContactInfo,
      Stakeholders,
      ObjectivesConclusion,
      AddPolicyProcedure,
      AddAppendixC,
      AppendixC,
      MainFont,
      HeaderFooter,
      Classification,
      TableOfContent,
    } = data;

    const payload = {
      ReportDate,
      ReportVersion,
      AddressAndContactInfo,
      Stakeholders,
      ObjectivesConclusion,
      AddPolicyProcedure,
      AddAppendixC,
      AppendixC,
      FontSettings: {
        MainFont,
        HeaderFooter,
        Classification,
        TableOfContent,
      },
    };

    showDownloadFilePopup({
      uri: `/api/FinalReport/${pid}/v2`,
      payload,
      fileName: 'FinalReport.pdf',
    });
  };

  useEffect(() => {
    if (finalReport) {
      reset({
        ReportVersion: finalReport.ReportVersion || 'Final',
        ReportDate: parseISO(finalReport.ReportDate as string) || null,
        AddressAndContactInfo: finalReport.AddressAndContactInfo || null,
        Stakeholders: finalReport.Stakeholders || null,
        ObjectivesConclusion: finalReport.ObjectivesConclusion || null,
        AddPolicyProcedure: finalReport.AddPolicyProcedure || false,
        AddAppendixC: finalReport.AddAppendixC || false,
        MainFont: finalReport?.FontSettings?.MainFont || 'Manrope',
        HeaderFooter: finalReport?.FontSettings?.HeaderFooter || 'IBMPlexMono',
        Classification: finalReport?.FontSettings?.Classification || 'PTRootUI',
        TableOfContent: finalReport?.FontSettings?.TableOfContent || 'Manrope',
        ...(finalReport.AddAppendixC
          ? { AppendixC: finalReport.AppendixC || null }
          : {}),
      });
    }
  }, [finalReport]);

  return {
    control,
    handleSubmit,
    isLoading: reportIsLoading || fontsIsLoading,
    onSubmit,
    watch,
    visible,
    getValues,
    fonts,
    fontsIsLoading,
  };
};
