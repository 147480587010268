import { useCallback, useEffect } from 'react';
import {
  UseFormGetValues,
  UseFormSetValue,
} from 'react-hook-form/dist/types/form';
import { useParams } from 'react-router-dom';

import { useTypedSelector } from '@src/utils';
import { useGetApiV2FindingsByFidQuery } from '@store/services/query.generated';
import { useActions } from '@utils/hooks/useActions';
import {
  addHandler,
  removeHandler,
  SendConcurrencyToken,
  SendItemDataUpdated,
} from '@utils/signalR';

import { IControlRequested, IFindingFields, IOnInUse } from './types';

export const useSubscription = (
  setValue: UseFormSetValue<IFindingFields>,
  getValues: UseFormGetValues<IFindingFields>,
) => {
  const { fid } = useParams();
  const {
    setIsEditor,
    setHasObserver,
    resetFindingNotification,
    setFindingNotificationName,
    setFindingNotificationRequesterConnectionId,
    setFindingNotificationType,
  } = useActions();

  const { findingNotificationType } = useTypedSelector(
    (state) => state.addEditFindingReducer,
  );

  const { refetch }: any = useGetApiV2FindingsByFidQuery(
    { fid: fid! },
    { skip: !fid },
  );

  const onInUse = useCallback(
    ({ connectionRole, editorName, connectionsCount }: IOnInUse) => {
      const newRoleIsEditor = connectionRole === 'editor';

      setIsEditor(newRoleIsEditor);
      setHasObserver(connectionsCount > 1);

      if (!newRoleIsEditor) {
        setFindingNotificationName(editorName);
        setFindingNotificationType('askForControl');
      } else {
        resetFindingNotification();
      }
      fid
        && newRoleIsEditor
        && connectionsCount > 1
        && SendItemDataUpdated(`finding_edit_${fid}`, getValues());
    },
    [],
  );

  const controlRequested = useCallback(
    ({ requesterName, requesterConnectionId }: IControlRequested) => {
      setFindingNotificationType('requesting');
      setFindingNotificationName(requesterName);
      setFindingNotificationRequesterConnectionId(requesterConnectionId);
    },
    [],
  );

  const controlRequestResult = useCallback((controlAllowed: boolean) => {
    setFindingNotificationType(controlAllowed ? 'accepted' : 'declined');
  }, []);

  const controlRequestImpossible = useCallback(() => {
    setFindingNotificationType('askControlBlocked');
  }, []);

  const OnItemDataUpdate = useCallback((itemData: any) => {
    const json = JSON.parse(itemData);
    Object.entries(json).forEach(([key, value]) => {
      setValue(key as keyof IFindingFields, value as any);
    });
  }, []);

  const FindingSaved = useCallback(() => refetch(), []);

  useEffect(() => {
    if (fid) {
      SendConcurrencyToken(`finding_edit_${fid}`, true);
      addHandler('inUse', onInUse);
      addHandler('controlRequested', controlRequested);
      addHandler('controlRequestResult', controlRequestResult);
      addHandler('controlRequestImpossible', controlRequestImpossible);
      addHandler('objectPreviewUpdate', OnItemDataUpdate);
      addHandler('FindingSaved', FindingSaved);
    }
  }, [fid]);

  useEffect(
    () => () => {
      fid && SendConcurrencyToken(`finding_edit_${fid}`, false);
      removeHandler('inUse');
      removeHandler('controlRequested');
      removeHandler('controlRequestResult');
      removeHandler('controlRequestImpossible');
      removeHandler('objectPreviewUpdate');
      removeHandler('FindingSaved');
    },
    [],
  );

  return {
    findingNotificationType,
  };
};
