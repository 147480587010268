import { fetchOptions } from '@src/constants/constants';
import { BASE_URL } from '@src/constants/environment';
import { hideError, showError } from '@store/reducers/errorSlice';
import { store } from '@store/store';

export const fetcher = async (
  uri: string,
  options?: any,
  setState?: (payload: any) => any,
  isJsonResponse: boolean = true,
) => {
  let response = await fetch(BASE_URL + uri, { ...fetchOptions, ...options });

  if (!response.ok) {
    if (response.status === 401) {
      await new Promise((resolve) => {
        const onStorage = async () => {
          localStorage.getItem('isRefresh')
            && localStorage.getItem('authSuccess')
            && (await (async () => {
              localStorage.removeItem('isRefresh');
              localStorage.removeItem('authSuccess');
              store.dispatch(hideError());
              window.removeEventListener('storage', onStorage);

              response = await fetch(BASE_URL + uri, {
                ...fetchOptions,
                ...options,
              });

              setState && setState(response.json());

              resolve(true);
            })());
        };

        store.dispatch(
          showError({
            message:
              'Please press the button "Refresh Auth"<br />if nothing changes, please re-authorize',
            title: 'Authorization error',
            is401: true,
          }),
        );

        window.addEventListener('storage', onStorage);
      });
    } else {
      store.dispatch(showError({ message: JSON.stringify(response) }));

      return false;
    }
  }

  return isJsonResponse ? response.json() : response;
};
