import { useState } from 'react';
import {
 InputBase, MenuItem, Select, SelectChangeEvent,
} from '@mui/material';

import {
  KrakenBackendUtilityConstantsActivityStatus,
  usePutApiActivitiesByAidStatusMutation,
} from '@store/services/query.generated';
import { getKeyByValue } from '@utils/getKeyByValue';

import { buttonsList } from '../AddEditActivity/ActivityStatusBar/ActivityStatusBar';

interface IProps {
  aid: string;
  defaultValue: string;
}

export const ActivityStatus = ({ aid, defaultValue }: IProps) => {
  const [selectValue, setSelectValue] = useState(
    getKeyByValue(buttonsList, defaultValue),
  );

  const [updateStatus] = usePutApiActivitiesByAidStatusMutation();

  const onChange = ({ target: { value } }: SelectChangeEvent<any>) => {
    setSelectValue(value);
    updateStatus({
      aid: aid!,
      krakenBackendUtilityConstantsActivityStatus: buttonsList[
        value as keyof typeof buttonsList
      ] as KrakenBackendUtilityConstantsActivityStatus,
    });
  };

  return (
    <Select
      labelId="status-label"
      id={aid}
      value={selectValue}
      input={<InputBase />}
      onChange={onChange}
      onClose={(event) => {
        event.preventDefault();
        event.stopPropagation();
      }}
      sx={{
        width: '100%',
      }}
    >
      {Object.keys(buttonsList).map((item) => (
        <MenuItem key={item} value={item}>
          {item}
        </MenuItem>
      ))}
    </Select>
  );
};
