import { Controller } from 'react-hook-form';
import { Control } from 'react-hook-form/dist/types';

import { KrakenTextArea } from '@components/KrakenTextArea/KrakenTextArea';
import { kebabCase } from '@utils/kebabCase';
import { textAreaHandler } from '@utils/textAreaHandler';

import { EAddEditComponentLinks, IComponentFields } from '../types';

const placeholder = `What does this component do?

How does it fit within the rest of the system?

What kind of technologies does it implement?`;

interface IProps {
  control: Control<IComponentFields>;
}

export const ComponentDescription = ({ control }: IProps) => (
  <Controller
    control={control}
    name="Description"
    render={({ field: { value, onChange } }) => (
      <KrakenTextArea
        id={kebabCase(EAddEditComponentLinks.DESCRIPTION)}
        title="Description"
        value={value || ''}
        onChange={(val) => textAreaHandler(val, onChange)}
        placeholder={placeholder}
        height={180}
        hideToolbar
      />
    )}
  />
);
