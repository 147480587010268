// Documentation: https://redux-toolkit.js.org/rtk-query/api/created-api/code-splitting#enhanceendpoints
import { api } from '@store/services/query.generated';

enum TagsEnum {
  ACTIVITIES = 'activities',
  EXECUTIVE_SUMMARY = 'executive-summary',
  COMPONENTS = 'components',
  COMPANIES = 'companies',
  PROJECTS = 'projects',
  EMPLOYEES = 'employees',
  FINDINGS = 'findings',
  ROLES = 'roles',
  VULNERABILITIES = 'vulnerabilities',
  ACTIVITY_TEMPLATE = 'activityTemplate',
  STEREOTYPE_TEMPLATE = 'stereotypeTemplate',
  ARCHIVE = 'archive',
  HISTORY = 'history',
}

const addTagTypes: string[] = Object.values(TagsEnum);
const keepUnusedDataFor = 5;
// addTagTypes: list of all tags,
// keepUnusedDataFor: https://redux-toolkit.js.org/rtk-query/api/createApi#keepunuseddatafor
// providesTags: https://redux-toolkit.js.org/rtk-query/api/createApi#providestags
// invalidatesTags: https://redux-toolkit.js.org/rtk-query/api/createApi#invalidatestags
const endpoints: any = {
  // Activities
  getApiActivitiesProjectByPid: {
    providesTags: [TagsEnum.ACTIVITIES, TagsEnum.COMPONENTS, TagsEnum.FINDINGS],
  },
  getApiActivitiesByAid: { providesTags: [TagsEnum.ACTIVITIES] },
  getApiFindingsByFidActivities: {
    providesTags: [TagsEnum.ACTIVITIES, TagsEnum.FINDINGS],
  },
  getApiActivitiesByAidFindings: {
    providesTags: [TagsEnum.FINDINGS, TagsEnum.ACTIVITIES],
  },
  postApiActivities: {
    invalidatesTags: [TagsEnum.ACTIVITIES, TagsEnum.HISTORY],
  },
  putApiActivitiesByAid: {
    invalidatesTags: [TagsEnum.ACTIVITIES, TagsEnum.HISTORY],
  },
  deleteApiActivitiesChecklistByAcid: {
    invalidatesTags: [TagsEnum.ACTIVITIES, TagsEnum.HISTORY],
  },
  postApiActivitiesByAidChecklist: {
    invalidatesTags: [TagsEnum.ACTIVITIES, TagsEnum.HISTORY],
  },
  putApiActivitiesByAidStatus: {
    invalidatesTags: [TagsEnum.ACTIVITIES, TagsEnum.HISTORY],
  },
  putApiActivitiesChecklistByAcid: {
    invalidatesTags: [TagsEnum.ACTIVITIES, TagsEnum.HISTORY],
  },
  putApiActivitiesChecklistByAcidChecked: {
    invalidatesTags: [TagsEnum.ACTIVITIES, TagsEnum.HISTORY],
  },
  // Executive Summary
  getApiProjectsByPidExecsummary: {
    providesTags: [TagsEnum.EXECUTIVE_SUMMARY],
  },
  putApiProjectsByPidExecsummary: {
    invalidatesTags: [TagsEnum.EXECUTIVE_SUMMARY],
  },
  // Components
  getApiComponentsByPid: { providesTags: [TagsEnum.COMPONENTS] },
  getApiV2ComponentsByCid: { providesTags: [TagsEnum.COMPONENTS] },
  postApiComponentsByPid: { invalidatesTags: [TagsEnum.COMPONENTS] },
  putApiComponentsByCid: { invalidatesTags: [TagsEnum.COMPONENTS] },
  // Companies
  getApiCompanies: { providesTags: [TagsEnum.COMPANIES] },
  getApiV2CompaniesByCid: { providesTags: [TagsEnum.COMPANIES] },
  getApiCompaniesAllTerse: { providesTags: [TagsEnum.COMPANIES] },
  postApiCompaniesOld: { invalidatesTags: [TagsEnum.COMPANIES] },
  putApiCompaniesByCidName: { invalidatesTags: [TagsEnum.COMPANIES] },
  putApiV2CompaniesByCid: { invalidatesTags: [TagsEnum.COMPANIES] },
  putApiCompaniesByCidLogo: { invalidatesTags: [TagsEnum.COMPANIES] },
  // Employees
  getApiHumansEmployees: { providesTags: [TagsEnum.EMPLOYEES] },
  // Findings
  getApiFindingsByFid: { providesTags: [TagsEnum.FINDINGS] },
  getApiV2FindingsByFid: { providesTags: [TagsEnum.FINDINGS] },
  getApiFindingsNeedsWork: {
    providesTags: [TagsEnum.FINDINGS],
    keepUnusedDataFor,
  },
  getApiFindingsProjectByPidFallback: { providesTags: [TagsEnum.FINDINGS] },
  postApiFindings: { invalidatesTags: [TagsEnum.FINDINGS] },
  putApiFindingsByFid: { invalidatesTags: [TagsEnum.FINDINGS] },
  postApiV2Findings: {
    invalidatesTags: [TagsEnum.FINDINGS, TagsEnum.ACTIVITIES],
  },
  putApiV2FindingsByFid: {
    invalidatesTags: [TagsEnum.FINDINGS, TagsEnum.ACTIVITIES],
  },
  deleteApiFindingsByFid: {
    invalidatesTags: [TagsEnum.FINDINGS, TagsEnum.ARCHIVE],
  },
  // Finding images
  getApiV2FindingsImagesByFid: { keepUnusedDataFor: 0 },
  // Projects
  getApiProjectsV2: { providesTags: [TagsEnum.PROJECTS], keepUnusedDataFor },
  getApiProjectsByPid: { providesTags: [TagsEnum.PROJECTS] },
  postApiProjects: { invalidatesTags: [TagsEnum.PROJECTS] },
  putApiProjectsByPid: { invalidatesTags: [TagsEnum.PROJECTS] },
  deleteApiProjectsByPid: { invalidatesTags: [TagsEnum.PROJECTS] },
  deleteApiProjectsDeleteByPid: { invalidatesTags: [TagsEnum.PROJECTS] },
  putApiProjectsByPidUndelete: { invalidatesTags: [TagsEnum.PROJECTS] },
  putApiProjectsByPidDates: {
    invalidatesTags: [TagsEnum.PROJECTS, TagsEnum.EXECUTIVE_SUMMARY],
  },
  patchApiProjectsByPidLogo: { invalidatesTags: [TagsEnum.PROJECTS] },
  // Roles
  getApiProjectsByPidRoles: { providesTags: [TagsEnum.ROLES] },
  getApiProjectsByPidTemporaryAccess: { providesTags: [TagsEnum.ROLES] },
  deleteApiProjectsByPidRolesAndHidRid: {
    invalidatesTags: [TagsEnum.ROLES, TagsEnum.EMPLOYEES],
  },
  deleteApiProjectsByPidTemporaryAccessAndHid: {
    invalidatesTags: [TagsEnum.ROLES, TagsEnum.EMPLOYEES],
  },
  postApiProjectsByPidTemporaryAccessAndHid: {
    invalidatesTags: [TagsEnum.ROLES, TagsEnum.EMPLOYEES],
  },
  putApiProjectsByPidRoles: {
    invalidatesTags: [TagsEnum.ROLES, TagsEnum.EMPLOYEES],
  },
  // Vulnerabilities
  getApiVulnerabilitiesActive: { providesTags: [TagsEnum.VULNERABILITIES] },
  getApiVulnerabilitiesByVid: { providesTags: [TagsEnum.VULNERABILITIES] },
  deleteApiVulnerabilitiesByVid: {
    invalidatesTags: [TagsEnum.VULNERABILITIES],
  },
  putApiVulnerabilities: { invalidatesTags: [TagsEnum.VULNERABILITIES] },
  postApiVulnerabilities: { invalidatesTags: [TagsEnum.VULNERABILITIES] },
  // ActivityTemplates
  getApiActivityTemplates: { providesTags: [TagsEnum.ACTIVITY_TEMPLATE] },
  getApiActivityTemplatesByAtid: { providesTags: [TagsEnum.ACTIVITY_TEMPLATE] },
  getApiActivityTemplatesByAtidChecklist: {
    providesTags: [TagsEnum.ACTIVITY_TEMPLATE],
  },
  postApiActivityTemplates: { invalidatesTags: [TagsEnum.ACTIVITY_TEMPLATE] },
  postApiActivityTemplatesByAtidChecklist: {
    invalidatesTags: [TagsEnum.ACTIVITY_TEMPLATE],
  },
  putApiActivityTemplatesByAtid: {
    invalidatesTags: [TagsEnum.ACTIVITY_TEMPLATE],
  },
  putApiComponentsByCidAddActivitiesFromTemplates: {
    invalidatesTags: [TagsEnum.ACTIVITY_TEMPLATE],
  },
  putApiActivityTemplatesChecklistByAtcid: {
    invalidatesTags: [TagsEnum.ACTIVITY_TEMPLATE],
  },
  deleteApiActivityTemplatesByAtid: {
    invalidatesTags: [TagsEnum.ACTIVITY_TEMPLATE],
  },
  deleteApiActivityTemplatesChecklistByAtcid: {
    invalidatesTags: [TagsEnum.ACTIVITY_TEMPLATE],
  },
  // Stereotype Templates
  getApiStereotypes: { providesTags: [TagsEnum.STEREOTYPE_TEMPLATE] },
  getApiStereotypesBySid: {
    providesTags: [TagsEnum.STEREOTYPE_TEMPLATE],
    keepUnusedDataFor,
  },
  postApiStereotypes: {
    invalidatesTags: [TagsEnum.STEREOTYPE_TEMPLATE, TagsEnum.ACTIVITY_TEMPLATE],
  },
  putApiStereotypesBySid: {
    invalidatesTags: [TagsEnum.STEREOTYPE_TEMPLATE, TagsEnum.ACTIVITY_TEMPLATE],
  },
  putApiActivityTemplatesByAtidAssociationAndSid: {
    invalidatesTags: [TagsEnum.STEREOTYPE_TEMPLATE],
  },
  deleteApiActivityTemplatesByAtidAssociationAndSid: {
    invalidatesTags: [TagsEnum.STEREOTYPE_TEMPLATE],
  },
  // ReviewablesEditHistory
  getApiReviewablesEditHistoryByRid: {
    providesTags: [TagsEnum.HISTORY],
    keepUnusedDataFor,
  },
  postApiReviewsFindingByFid: {
    invalidatesTags: [TagsEnum.HISTORY, TagsEnum.FINDINGS],
  },
  // ARCHIVE
  getApiV2ProjectsWithInactive: { providesTags: [TagsEnum.ARCHIVE] },
  getApiV2ProjectsByPidInactiveFindings: { providesTags: [TagsEnum.ARCHIVE] },
  getApiV2ProjectsByPidInactiveActivities: { providesTags: [TagsEnum.ARCHIVE] },
  getApiV2ProjectsByPidInactiveComponents: { providesTags: [TagsEnum.ARCHIVE] },
  putApiFindingsByFidUndelete: {
    invalidatesTags: [TagsEnum.ARCHIVE, TagsEnum.FINDINGS],
  },
  putApiActivitiesByAidUndelete: {
    invalidatesTags: [TagsEnum.ARCHIVE, TagsEnum.ACTIVITIES],
  },
  putApiComponentsByCidUndelete: {
    invalidatesTags: [TagsEnum.ARCHIVE, TagsEnum.COMPONENTS],
  },
};

const config = { addTagTypes, endpoints };

// it works as Object.assign(api, config)
export const enhancedApi = () => api.enhanceEndpoints(config);
