import { createApi } from '@reduxjs/toolkit/query/react';
import { default as customBaseQuery } from './customBaseQuery';
export const api = createApi({
  baseQuery: customBaseQuery,
  tagTypes: [],
  endpoints: (build) => ({
    getApiActivitiesComponentByPcid: build.query<
      GetApiActivitiesComponentByPcidApiResponse,
      GetApiActivitiesComponentByPcidApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Activities/Component/${queryArg.pcid}`,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    getApiActivitiesComponentByPcidInactive: build.query<
      GetApiActivitiesComponentByPcidInactiveApiResponse,
      GetApiActivitiesComponentByPcidInactiveApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Activities/Component/${queryArg.pcid}/Inactive`,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    getApiActivitiesProjectByPid: build.query<
      GetApiActivitiesProjectByPidApiResponse,
      GetApiActivitiesProjectByPidApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Activities/Project/${queryArg.pid}`,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    getApiActivitiesProjectByPidInactive: build.query<
      GetApiActivitiesProjectByPidInactiveApiResponse,
      GetApiActivitiesProjectByPidInactiveApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Activities/Project/${queryArg.pid}/Inactive`,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    getApiActivitiesByAid: build.query<
      GetApiActivitiesByAidApiResponse,
      GetApiActivitiesByAidApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Activities/${queryArg.aid}`,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    putApiActivitiesByAid: build.mutation<
      PutApiActivitiesByAidApiResponse,
      PutApiActivitiesByAidApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Activities/${queryArg.aid}`,
        method: 'PUT',
        body: queryArg.krakenBackendApiModelsActivitiesApiActivityNew,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
        params: {
          s: queryArg.s,
          reviewType: queryArg.reviewType,
          reviewOutcome: queryArg.reviewOutcome,
        },
      }),
    }),
    deleteApiActivitiesByAid: build.mutation<
      DeleteApiActivitiesByAidApiResponse,
      DeleteApiActivitiesByAidApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Activities/${queryArg.aid}`,
        method: 'DELETE',
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    postApiActivitiesByAidChecklist: build.mutation<
      PostApiActivitiesByAidChecklistApiResponse,
      PostApiActivitiesByAidChecklistApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Activities/${queryArg.aid}/Checklist`,
        method: 'POST',
        body: queryArg.body,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
        params: { forceGuid: queryArg.forceGuid },
      }),
    }),
    deleteApiActivitiesChecklistByAcid: build.mutation<
      DeleteApiActivitiesChecklistByAcidApiResponse,
      DeleteApiActivitiesChecklistByAcidApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Activities/Checklist/${queryArg.acid}`,
        method: 'DELETE',
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    putApiActivitiesChecklistByAcid: build.mutation<
      PutApiActivitiesChecklistByAcidApiResponse,
      PutApiActivitiesChecklistByAcidApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Activities/Checklist/${queryArg.acid}`,
        method: 'PUT',
        body: queryArg.body,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    putApiActivitiesChecklistByAcidChecked: build.mutation<
      PutApiActivitiesChecklistByAcidCheckedApiResponse,
      PutApiActivitiesChecklistByAcidCheckedApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Activities/Checklist/${queryArg.acid}/Checked`,
        method: 'PUT',
        body: queryArg.krakenModelsEnumsActivityCheckListItemStatus,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    postApiActivities: build.mutation<
      PostApiActivitiesApiResponse,
      PostApiActivitiesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Activities`,
        method: 'POST',
        body: queryArg.krakenBackendApiModelsActivitiesApiActivityNew,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
        params: { forceGuid: queryArg.forceGuid },
      }),
    }),
    putApiActivitiesByAidStatus: build.mutation<
      PutApiActivitiesByAidStatusApiResponse,
      PutApiActivitiesByAidStatusApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Activities/${queryArg.aid}/Status`,
        method: 'PUT',
        body: queryArg.krakenBackendUtilityConstantsActivityStatus,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    postApiActivitiesByAidBulkCheckListItems: build.mutation<
      PostApiActivitiesByAidBulkCheckListItemsApiResponse,
      PostApiActivitiesByAidBulkCheckListItemsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Activities/${queryArg.aid}/BulkCheckListItems`,
        method: 'POST',
        body: queryArg.krakenModelsEnumsActivityCheckListItemStatus,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    deleteApiActivitiesByAidTemplateLink: build.mutation<
      DeleteApiActivitiesByAidTemplateLinkApiResponse,
      DeleteApiActivitiesByAidTemplateLinkApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Activities/${queryArg.aid}/TemplateLink`,
        method: 'DELETE',
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    putApiActivitiesByAidUndelete: build.mutation<
      PutApiActivitiesByAidUndeleteApiResponse,
      PutApiActivitiesByAidUndeleteApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Activities/${queryArg.aid}/Undelete`,
        method: 'PUT',
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    putApiActivitiesByAidFindingAndFid: build.mutation<
      PutApiActivitiesByAidFindingAndFidApiResponse,
      PutApiActivitiesByAidFindingAndFidApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Activities/${queryArg.aid}/Finding/${queryArg.fid}`,
        method: 'PUT',
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    deleteApiActivitiesByAidFindingAndFid: build.mutation<
      DeleteApiActivitiesByAidFindingAndFidApiResponse,
      DeleteApiActivitiesByAidFindingAndFidApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Activities/${queryArg.aid}/Finding/${queryArg.fid}`,
        method: 'DELETE',
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    getApiActivitiesByAidFindings: build.query<
      GetApiActivitiesByAidFindingsApiResponse,
      GetApiActivitiesByAidFindingsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Activities/${queryArg.aid}/Findings`,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    getApiActivityTemplates: build.query<
      GetApiActivityTemplatesApiResponse,
      GetApiActivityTemplatesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/ActivityTemplates`,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    postApiActivityTemplates: build.mutation<
      PostApiActivityTemplatesApiResponse,
      PostApiActivityTemplatesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/ActivityTemplates`,
        method: 'POST',
        body: queryArg.krakenBackendApiModelsActivityTemplatesApiActivityTemplateWithChecklist,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    getApiActivityTemplatesInactive: build.query<
      GetApiActivityTemplatesInactiveApiResponse,
      GetApiActivityTemplatesInactiveApiArg
    >({
      query: (queryArg) => ({
        url: `/api/ActivityTemplates/Inactive`,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    getApiActivityTemplatesByAtid: build.query<
      GetApiActivityTemplatesByAtidApiResponse,
      GetApiActivityTemplatesByAtidApiArg
    >({
      query: (queryArg) => ({
        url: `/api/ActivityTemplates/${queryArg.atid}`,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    putApiActivityTemplatesByAtid: build.mutation<
      PutApiActivityTemplatesByAtidApiResponse,
      PutApiActivityTemplatesByAtidApiArg
    >({
      query: (queryArg) => ({
        url: `/api/ActivityTemplates/${queryArg.atid}`,
        method: 'PUT',
        body: queryArg.krakenBackendApiModelsActivityTemplatesApiActivityTemplate,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    deleteApiActivityTemplatesByAtid: build.mutation<
      DeleteApiActivityTemplatesByAtidApiResponse,
      DeleteApiActivityTemplatesByAtidApiArg
    >({
      query: (queryArg) => ({
        url: `/api/ActivityTemplates/${queryArg.atid}`,
        method: 'DELETE',
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    getApiActivityTemplatesByAtidChecklist: build.query<
      GetApiActivityTemplatesByAtidChecklistApiResponse,
      GetApiActivityTemplatesByAtidChecklistApiArg
    >({
      query: (queryArg) => ({
        url: `/api/ActivityTemplates/${queryArg.atid}/Checklist`,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    postApiActivityTemplatesByAtidChecklist: build.mutation<
      PostApiActivityTemplatesByAtidChecklistApiResponse,
      PostApiActivityTemplatesByAtidChecklistApiArg
    >({
      query: (queryArg) => ({
        url: `/api/ActivityTemplates/${queryArg.atid}/Checklist`,
        method: 'POST',
        body: queryArg.body,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    putApiActivityTemplatesByAtidUndelete: build.mutation<
      PutApiActivityTemplatesByAtidUndeleteApiResponse,
      PutApiActivityTemplatesByAtidUndeleteApiArg
    >({
      query: (queryArg) => ({
        url: `/api/ActivityTemplates/${queryArg.atid}/Undelete`,
        method: 'PUT',
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    putApiActivityTemplatesChecklistByAtcid: build.mutation<
      PutApiActivityTemplatesChecklistByAtcidApiResponse,
      PutApiActivityTemplatesChecklistByAtcidApiArg
    >({
      query: (queryArg) => ({
        url: `/api/ActivityTemplates/Checklist/${queryArg.atcid}`,
        method: 'PUT',
        body: queryArg.body,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    deleteApiActivityTemplatesChecklistByAtcid: build.mutation<
      DeleteApiActivityTemplatesChecklistByAtcidApiResponse,
      DeleteApiActivityTemplatesChecklistByAtcidApiArg
    >({
      query: (queryArg) => ({
        url: `/api/ActivityTemplates/Checklist/${queryArg.atcid}`,
        method: 'DELETE',
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    putApiActivityTemplatesByAtidAssociationAndSid: build.mutation<
      PutApiActivityTemplatesByAtidAssociationAndSidApiResponse,
      PutApiActivityTemplatesByAtidAssociationAndSidApiArg
    >({
      query: (queryArg) => ({
        url: `/api/ActivityTemplates/${queryArg.atid}/Association/${queryArg.sid}`,
        method: 'PUT',
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    deleteApiActivityTemplatesByAtidAssociationAndSid: build.mutation<
      DeleteApiActivityTemplatesByAtidAssociationAndSidApiResponse,
      DeleteApiActivityTemplatesByAtidAssociationAndSidApiArg
    >({
      query: (queryArg) => ({
        url: `/api/ActivityTemplates/${queryArg.atid}/Association/${queryArg.sid}`,
        method: 'DELETE',
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    putApiActivityTemplatesBulkAssociation: build.mutation<
      PutApiActivityTemplatesBulkAssociationApiResponse,
      PutApiActivityTemplatesBulkAssociationApiArg
    >({
      query: (queryArg) => ({
        url: `/api/ActivityTemplates/Bulk/Association`,
        method: 'PUT',
        body: queryArg.body,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    deleteApiActivityTemplatesBulkAssociation: build.mutation<
      DeleteApiActivityTemplatesBulkAssociationApiResponse,
      DeleteApiActivityTemplatesBulkAssociationApiArg
    >({
      query: (queryArg) => ({
        url: `/api/ActivityTemplates/Bulk/Association`,
        method: 'DELETE',
        body: queryArg.body,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    getApiActivityTemplatesByAtidAssociation: build.query<
      GetApiActivityTemplatesByAtidAssociationApiResponse,
      GetApiActivityTemplatesByAtidAssociationApiArg
    >({
      query: (queryArg) => ({
        url: `/api/ActivityTemplates/${queryArg.atid}/Association`,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    putApiV2AssigneeFindingByFid: build.mutation<
      PutApiV2AssigneeFindingByFidApiResponse,
      PutApiV2AssigneeFindingByFidApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v2/Assignee/finding/${queryArg.fid}`,
        method: 'PUT',
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
        params: { id: queryArg.id },
      }),
    }),
    deleteApiV2AssigneeFindingByFid: build.mutation<
      DeleteApiV2AssigneeFindingByFidApiResponse,
      DeleteApiV2AssigneeFindingByFidApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v2/Assignee/finding/${queryArg.fid}`,
        method: 'DELETE',
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    getApiAttachmentsProjectByPid: build.query<
      GetApiAttachmentsProjectByPidApiResponse,
      GetApiAttachmentsProjectByPidApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Attachments/Project/${queryArg.pid}`,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    getApiAttachmentsProjectByPidInactive: build.query<
      GetApiAttachmentsProjectByPidInactiveApiResponse,
      GetApiAttachmentsProjectByPidInactiveApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Attachments/Project/${queryArg.pid}/Inactive`,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    getApiAttachmentsByAid: build.query<
      GetApiAttachmentsByAidApiResponse,
      GetApiAttachmentsByAidApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Attachments/${queryArg.aid}`,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    deleteApiAttachmentsByAid: build.mutation<
      DeleteApiAttachmentsByAidApiResponse,
      DeleteApiAttachmentsByAidApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Attachments/${queryArg.aid}`,
        method: 'DELETE',
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    putApiAttachmentsByAidUndelete: build.mutation<
      PutApiAttachmentsByAidUndeleteApiResponse,
      PutApiAttachmentsByAidUndeleteApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Attachments/${queryArg.aid}/Undelete`,
        method: 'PUT',
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    postApiAttachmentsFindingByFid: build.mutation<
      PostApiAttachmentsFindingByFidApiResponse,
      PostApiAttachmentsFindingByFidApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Attachments/Finding/${queryArg.fid}`,
        method: 'POST',
        body: queryArg.body,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    putApiAttachmentsReportEmitterLogo: build.mutation<
      PutApiAttachmentsReportEmitterLogoApiResponse,
      PutApiAttachmentsReportEmitterLogoApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Attachments/report-emitter-logo`,
        method: 'PUT',
        body: queryArg.body,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    getApiAttachmentsReportEmitterLogo: build.query<
      GetApiAttachmentsReportEmitterLogoApiResponse,
      GetApiAttachmentsReportEmitterLogoApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Attachments/report-emitter-logo`,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    getAuthCheck: build.query<GetAuthCheckApiResponse, GetAuthCheckApiArg>({
      query: (queryArg) => ({
        url: `/auth/check`,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    postAuthSignalrAppend: build.mutation<
      PostAuthSignalrAppendApiResponse,
      PostAuthSignalrAppendApiArg
    >({
      query: (queryArg) => ({
        url: `/auth/signalr/append`,
        method: 'POST',
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    getApiCompanies: build.query<
      GetApiCompaniesApiResponse,
      GetApiCompaniesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Companies`,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
        params: { search: queryArg.search },
      }),
    }),
    postApiCompanies: build.mutation<
      PostApiCompaniesApiResponse,
      PostApiCompaniesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Companies`,
        method: 'POST',
        body: queryArg.body,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    getApiCompaniesTerse: build.query<
      GetApiCompaniesTerseApiResponse,
      GetApiCompaniesTerseApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Companies/terse`,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
        params: { search: queryArg.search },
      }),
    }),
    getApiCompaniesAllTerse: build.query<
      GetApiCompaniesAllTerseApiResponse,
      GetApiCompaniesAllTerseApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Companies/all/terse`,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
        params: { search: queryArg.search },
      }),
    }),
    postApiCompaniesOld: build.mutation<
      PostApiCompaniesOldApiResponse,
      PostApiCompaniesOldApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Companies/old`,
        method: 'POST',
        body: queryArg.krakenBackendApiModelsCompaniesApiNewCompany,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    postApiCompaniesNameonly: build.mutation<
      PostApiCompaniesNameonlyApiResponse,
      PostApiCompaniesNameonlyApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Companies/nameonly`,
        method: 'POST',
        body: queryArg.body,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    putApiCompaniesByCidName: build.mutation<
      PutApiCompaniesByCidNameApiResponse,
      PutApiCompaniesByCidNameApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Companies/${queryArg.cid}/Name`,
        method: 'PUT',
        body: queryArg.body,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    putApiCompaniesByCidLogo: build.mutation<
      PutApiCompaniesByCidLogoApiResponse,
      PutApiCompaniesByCidLogoApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Companies/${queryArg.cid}/Logo`,
        method: 'PUT',
        body: queryArg.body,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    getApiCompaniesByCidLogo: build.query<
      GetApiCompaniesByCidLogoApiResponse,
      GetApiCompaniesByCidLogoApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Companies/${queryArg.cid}/Logo`,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    getApiV2CompaniesByCid: build.query<
      GetApiV2CompaniesByCidApiResponse,
      GetApiV2CompaniesByCidApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v2/Companies/${queryArg.cid}`,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    putApiV2CompaniesByCid: build.mutation<
      PutApiV2CompaniesByCidApiResponse,
      PutApiV2CompaniesByCidApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v2/Companies/${queryArg.cid}`,
        method: 'PUT',
        body: queryArg.krakenModelsDtoCompanyUpdateCompanyDto,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    getApiComponentsByPid: build.query<
      GetApiComponentsByPidApiResponse,
      GetApiComponentsByPidApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Components/${queryArg.pid}`,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    postApiComponentsByPid: build.mutation<
      PostApiComponentsByPidApiResponse,
      PostApiComponentsByPidApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Components/${queryArg.pid}`,
        method: 'POST',
        body: queryArg.krakenBackendApiModelsProjectComponentsApiProjectComponent,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    getApiComponentsByComponentIdDetail: build.query<
      GetApiComponentsByComponentIdDetailApiResponse,
      GetApiComponentsByComponentIdDetailApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Components/${queryArg.componentId}/detail`,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    getApiComponentsByPidInactive: build.query<
      GetApiComponentsByPidInactiveApiResponse,
      GetApiComponentsByPidInactiveApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Components/${queryArg.pid}/Inactive`,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    putApiComponentsByCid: build.mutation<
      PutApiComponentsByCidApiResponse,
      PutApiComponentsByCidApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Components/${queryArg.cid}`,
        method: 'PUT',
        body: queryArg.krakenBackendApiModelsProjectComponentsApiProjectComponent,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
        params: {
          reviewType: queryArg.reviewType,
          reviewOutcome: queryArg.reviewOutcome,
        },
      }),
    }),
    deleteApiComponentsByCid: build.mutation<
      DeleteApiComponentsByCidApiResponse,
      DeleteApiComponentsByCidApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Components/${queryArg.cid}`,
        method: 'DELETE',
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    putApiComponentsByCidUndelete: build.mutation<
      PutApiComponentsByCidUndeleteApiResponse,
      PutApiComponentsByCidUndeleteApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Components/${queryArg.cid}/Undelete`,
        method: 'PUT',
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    postApiComponentsMergeBySrcIntoAndDst: build.mutation<
      PostApiComponentsMergeBySrcIntoAndDstApiResponse,
      PostApiComponentsMergeBySrcIntoAndDstApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Components/Merge/${queryArg.src}/Into/${queryArg.dst}`,
        method: 'POST',
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    putApiComponentsByCidAddActivitiesFromStereotypes: build.mutation<
      PutApiComponentsByCidAddActivitiesFromStereotypesApiResponse,
      PutApiComponentsByCidAddActivitiesFromStereotypesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Components/${queryArg.cid}/AddActivitiesFromStereotypes`,
        method: 'PUT',
        body: queryArg.body,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    putApiComponentsByCidAddActivitiesFromTemplates: build.mutation<
      PutApiComponentsByCidAddActivitiesFromTemplatesApiResponse,
      PutApiComponentsByCidAddActivitiesFromTemplatesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Components/${queryArg.cid}/AddActivitiesFromTemplates`,
        method: 'PUT',
        body: queryArg.body,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    deleteApiComponentsByCidStereotypeAndSid: build.mutation<
      DeleteApiComponentsByCidStereotypeAndSidApiResponse,
      DeleteApiComponentsByCidStereotypeAndSidApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Components/${queryArg.cid}/Stereotype/${queryArg.sid}`,
        method: 'DELETE',
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    getApiV2ComponentsByCid: build.query<
      GetApiV2ComponentsByCidApiResponse,
      GetApiV2ComponentsByCidApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v2/Components/${queryArg.cid}`,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    getApiConfigByItem: build.query<
      GetApiConfigByItemApiResponse,
      GetApiConfigByItemApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Config/${queryArg.item}`,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    putApiConfigByItem: build.mutation<
      PutApiConfigByItemApiResponse,
      PutApiConfigByItemApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Config/${queryArg.item}`,
        method: 'PUT',
        body: queryArg.body,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    getApiConfig: build.query<GetApiConfigApiResponse, GetApiConfigApiArg>({
      query: (queryArg) => ({
        url: `/api/Config`,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    getApiConfigUserByItem: build.query<
      GetApiConfigUserByItemApiResponse,
      GetApiConfigUserByItemApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Config/User/${queryArg.item}`,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    putApiConfigUserByItem: build.mutation<
      PutApiConfigUserByItemApiResponse,
      PutApiConfigUserByItemApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Config/User/${queryArg.item}`,
        method: 'PUT',
        body: queryArg.body,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    getApiConfigUser: build.query<
      GetApiConfigUserApiResponse,
      GetApiConfigUserApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Config/User`,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    putApiConfigUser: build.mutation<
      PutApiConfigUserApiResponse,
      PutApiConfigUserApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Config/User`,
        method: 'PUT',
        body: queryArg.krakenModelsDtoSetUserConfigDto,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    getApiCwe: build.query<GetApiCweApiResponse, GetApiCweApiArg>({
      query: (queryArg) => ({
        url: `/api/cwe`,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    getApiEmailJobDailyEmail: build.query<
      GetApiEmailJobDailyEmailApiResponse,
      GetApiEmailJobDailyEmailApiArg
    >({
      query: (queryArg) => ({
        url: `/api/EmailJob/DailyEmail`,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    getApiEmailJobDailyEmailRender: build.query<
      GetApiEmailJobDailyEmailRenderApiResponse,
      GetApiEmailJobDailyEmailRenderApiArg
    >({
      query: (queryArg) => ({
        url: `/api/EmailJob/DailyEmail/Render`,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
        params: { interim: queryArg.interim },
      }),
    }),
    getApiEmailJobDailyEmailRenderAll: build.query<
      GetApiEmailJobDailyEmailRenderAllApiResponse,
      GetApiEmailJobDailyEmailRenderAllApiArg
    >({
      query: (queryArg) => ({
        url: `/api/EmailJob/DailyEmail/Render/All`,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
        params: { interim: queryArg.interim },
      }),
    }),
    getApiEmailJobDailyEmailRenderByHid: build.query<
      GetApiEmailJobDailyEmailRenderByHidApiResponse,
      GetApiEmailJobDailyEmailRenderByHidApiArg
    >({
      query: (queryArg) => ({
        url: `/api/EmailJob/DailyEmail/Render/${queryArg.hid}`,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    postApiEmailJobDailyEmailSendAll: build.mutation<
      PostApiEmailJobDailyEmailSendAllApiResponse,
      PostApiEmailJobDailyEmailSendAllApiArg
    >({
      query: (queryArg) => ({
        url: `/api/EmailJob/DailyEmail/SendAll`,
        method: 'POST',
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    postApiEmailJobDailyEmailSendAdminInterim: build.mutation<
      PostApiEmailJobDailyEmailSendAdminInterimApiResponse,
      PostApiEmailJobDailyEmailSendAdminInterimApiArg
    >({
      query: (queryArg) => ({
        url: `/api/EmailJob/DailyEmail/SendAdminInterim`,
        method: 'POST',
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    getApiRouteDebug: build.query<
      GetApiRouteDebugApiResponse,
      GetApiRouteDebugApiArg
    >({
      query: (queryArg) => ({
        url: `/api/RouteDebug`,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    getApiFinalReportByPid: build.query<
      GetApiFinalReportByPidApiResponse,
      GetApiFinalReportByPidApiArg
    >({
      query: (queryArg) => ({
        url: `/api/FinalReport/${queryArg.pid}`,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    postApiFinalReportByPidV2: build.mutation<
      PostApiFinalReportByPidV2ApiResponse,
      PostApiFinalReportByPidV2ApiArg
    >({
      query: (queryArg) => ({
        url: `/api/FinalReport/${queryArg.pid}/v2`,
        method: 'POST',
        body: queryArg.krakenModelsDtoFinalReportConfigurableDetails,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    getApiFinalReportEditableInfoByPid: build.query<
      GetApiFinalReportEditableInfoByPidApiResponse,
      GetApiFinalReportEditableInfoByPidApiArg
    >({
      query: (queryArg) => ({
        url: `/api/FinalReport/editable-info/${queryArg.pid}`,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    postApiFindingReviewsByFid: build.mutation<
      PostApiFindingReviewsByFidApiResponse,
      PostApiFindingReviewsByFidApiArg
    >({
      query: (queryArg) => ({
        url: `/api/FindingReviews/${queryArg.fid}`,
        method: 'POST',
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
        params: { type: queryArg['type'], outcome: queryArg.outcome },
      }),
    }),
    getApiFindingReviewsByFid: build.query<
      GetApiFindingReviewsByFidApiResponse,
      GetApiFindingReviewsByFidApiArg
    >({
      query: (queryArg) => ({
        url: `/api/FindingReviews/${queryArg.fid}`,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    getApiFindingReviewsByFidLast: build.query<
      GetApiFindingReviewsByFidLastApiResponse,
      GetApiFindingReviewsByFidLastApiArg
    >({
      query: (queryArg) => ({
        url: `/api/FindingReviews/${queryArg.fid}/last`,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    getApiFindingReviewsByFidLastSet: build.query<
      GetApiFindingReviewsByFidLastSetApiResponse,
      GetApiFindingReviewsByFidLastSetApiArg
    >({
      query: (queryArg) => ({
        url: `/api/FindingReviews/${queryArg.fid}/last/set`,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    getApiFindingsProjectByPid: build.query<
      GetApiFindingsProjectByPidApiResponse,
      GetApiFindingsProjectByPidApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Findings/Project/${queryArg.pid}`,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
        params: {
          sort: queryArg.sort,
          page: queryArg.page,
          pageSize: queryArg.pageSize,
          search: queryArg.search,
        },
      }),
    }),
    getApiFindingsProjectByPidFallback: build.query<
      GetApiFindingsProjectByPidFallbackApiResponse,
      GetApiFindingsProjectByPidFallbackApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Findings/project/${queryArg.pid}/fallback`,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    getApiFindingsProjectByPidInactive: build.query<
      GetApiFindingsProjectByPidInactiveApiResponse,
      GetApiFindingsProjectByPidInactiveApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Findings/Project/${queryArg.pid}/Inactive`,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    getApiFindingsHumanByHid: build.query<
      GetApiFindingsHumanByHidApiResponse,
      GetApiFindingsHumanByHidApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Findings/human/${queryArg.hid}`,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
        params: { projectId: queryArg.projectId, risk: queryArg.risk },
      }),
    }),
    getApiFindingsAnnualHumanByHid: build.query<
      GetApiFindingsAnnualHumanByHidApiResponse,
      GetApiFindingsAnnualHumanByHidApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Findings/Annual/Human/${queryArg.hid}`,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
        params: { threshold: queryArg.threshold },
      }),
    }),
    getApiFindingsAnnualByLimit: build.query<
      GetApiFindingsAnnualByLimitApiResponse,
      GetApiFindingsAnnualByLimitApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Findings/Annual/${queryArg.limit}`,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    getApiFindingsReviewAll: build.query<
      GetApiFindingsReviewAllApiResponse,
      GetApiFindingsReviewAllApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Findings/Review/All`,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
        params: { type: queryArg['type'] },
      }),
    }),
    getApiFindingsReviewByPid: build.query<
      GetApiFindingsReviewByPidApiResponse,
      GetApiFindingsReviewByPidApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Findings/Review/${queryArg.pid}`,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
        params: { type: queryArg['type'] },
      }),
    }),
    getApiFindingsNeedsWorkCount: build.query<
      GetApiFindingsNeedsWorkCountApiResponse,
      GetApiFindingsNeedsWorkCountApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Findings/needs-work/count`,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
        params: { active: queryArg.active },
      }),
    }),
    getApiFindingsNeedsWork: build.query<
      GetApiFindingsNeedsWorkApiResponse,
      GetApiFindingsNeedsWorkApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Findings/needs-work`,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
        params: { active: queryArg.active },
      }),
    }),
    getApiFindingsByFid: build.query<
      GetApiFindingsByFidApiResponse,
      GetApiFindingsByFidApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Findings/${queryArg.fid}`,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    deleteApiFindingsByFid: build.mutation<
      DeleteApiFindingsByFidApiResponse,
      DeleteApiFindingsByFidApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Findings/${queryArg.fid}`,
        method: 'DELETE',
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    putApiFindingsByFid: build.mutation<
      PutApiFindingsByFidApiResponse,
      PutApiFindingsByFidApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Findings/${queryArg.fid}`,
        method: 'PUT',
        body: queryArg.krakenBackendApiModelsFindingsApiFinding,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
        params: {
          materialChange: queryArg.materialChange,
          reviewType: queryArg.reviewType,
          reviewOutcome: queryArg.reviewOutcome,
        },
      }),
    }),
    getApiFindingsRefByFindingRef: build.query<
      GetApiFindingsRefByFindingRefApiResponse,
      GetApiFindingsRefByFindingRefApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Findings/ref/${queryArg.findingRef}`,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    getApiFindingsByFidTerse: build.query<
      GetApiFindingsByFidTerseApiResponse,
      GetApiFindingsByFidTerseApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Findings/${queryArg.fid}/terse`,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    putApiFindingsByFidUndelete: build.mutation<
      PutApiFindingsByFidUndeleteApiResponse,
      PutApiFindingsByFidUndeleteApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Findings/${queryArg.fid}/Undelete`,
        method: 'PUT',
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    postApiFindings: build.mutation<
      PostApiFindingsApiResponse,
      PostApiFindingsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Findings`,
        method: 'POST',
        body: queryArg.krakenBackendApiModelsFindingsApiFinding,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
        params: { forceGuid: queryArg.forceGuid },
      }),
    }),
    getApiFindingsByFidActivities: build.query<
      GetApiFindingsByFidActivitiesApiResponse,
      GetApiFindingsByFidActivitiesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Findings/${queryArg.fid}/Activities`,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    getApiV2FindingsProjectByPid: build.query<
      GetApiV2FindingsProjectByPidApiResponse,
      GetApiV2FindingsProjectByPidApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v2/Findings/Project/${queryArg.pid}`,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
        params: {
          page: queryArg.page,
          pageSize: queryArg.pageSize,
          search: queryArg.search,
        },
      }),
    }),
    getApiV2FindingsProjectByPidFallback: build.query<
      GetApiV2FindingsProjectByPidFallbackApiResponse,
      GetApiV2FindingsProjectByPidFallbackApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v2/Findings/project/${queryArg.pid}/fallback`,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    getApiV2FindingsProjectByPidInactive: build.query<
      GetApiV2FindingsProjectByPidInactiveApiResponse,
      GetApiV2FindingsProjectByPidInactiveApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v2/Findings/Project/${queryArg.pid}/Inactive`,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    getApiV2FindingsAnnualHumanByHid: build.query<
      GetApiV2FindingsAnnualHumanByHidApiResponse,
      GetApiV2FindingsAnnualHumanByHidApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v2/Findings/Annual/Human/${queryArg.hid}`,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
        params: { threshold: queryArg.threshold },
      }),
    }),
    getApiV2FindingsAnnualByLimit: build.query<
      GetApiV2FindingsAnnualByLimitApiResponse,
      GetApiV2FindingsAnnualByLimitApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v2/Findings/Annual/${queryArg.limit}`,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    getApiV2FindingsReviewAll: build.query<
      GetApiV2FindingsReviewAllApiResponse,
      GetApiV2FindingsReviewAllApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v2/Findings/Review/All`,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
        params: { type: queryArg['type'] },
      }),
    }),
    getApiV2FindingsReviewByPid: build.query<
      GetApiV2FindingsReviewByPidApiResponse,
      GetApiV2FindingsReviewByPidApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v2/Findings/Review/${queryArg.pid}`,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
        params: { type: queryArg['type'] },
      }),
    }),
    getApiV2FindingsByFid: build.query<
      GetApiV2FindingsByFidApiResponse,
      GetApiV2FindingsByFidApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v2/Findings/${queryArg.fid}`,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    putApiV2FindingsByFid: build.mutation<
      PutApiV2FindingsByFidApiResponse,
      PutApiV2FindingsByFidApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v2/Findings/${queryArg.fid}`,
        method: 'PUT',
        body: queryArg.krakenModelsDtoFindingWriteDto,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
        params: {
          materialChange: queryArg.materialChange,
          reviewType: queryArg.reviewType,
          reviewOutcome: queryArg.reviewOutcome,
        },
      }),
    }),
    getApiV2FindingsRefByFindingRef: build.query<
      GetApiV2FindingsRefByFindingRefApiResponse,
      GetApiV2FindingsRefByFindingRefApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v2/Findings/ref/${queryArg.findingRef}`,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    postApiV2Findings: build.mutation<
      PostApiV2FindingsApiResponse,
      PostApiV2FindingsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v2/Findings`,
        method: 'POST',
        body: queryArg.krakenModelsDtoFindingWriteDto,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
        params: { forceGuid: queryArg.forceGuid },
      }),
    }),
    getApiV2FindingsImagesByFid: build.query<
      GetApiV2FindingsImagesByFidApiResponse,
      GetApiV2FindingsImagesByFidApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v2/Findings/images/${queryArg.fid}`,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    postApiV2FindingsImagesByFid: build.mutation<
      PostApiV2FindingsImagesByFidApiResponse,
      PostApiV2FindingsImagesByFidApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v2/Findings/images/${queryArg.fid}`,
        method: 'POST',
        body: queryArg.body,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    getApiHumansEmployees: build.query<
      GetApiHumansEmployeesApiResponse,
      GetApiHumansEmployeesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Humans/Employees`,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    postApiHumans: build.mutation<
      PostApiHumansApiResponse,
      PostApiHumansApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Humans`,
        method: 'POST',
        body: queryArg.krakenBackendApiModelsHumansApiNewHuman,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    putApiHumansByHid: build.mutation<
      PutApiHumansByHidApiResponse,
      PutApiHumansByHidApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Humans/${queryArg.hid}`,
        method: 'PUT',
        body: queryArg.krakenBackendApiModelsHumansApiNewHuman,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    deleteApiHumansByHidEnabled: build.mutation<
      DeleteApiHumansByHidEnabledApiResponse,
      DeleteApiHumansByHidEnabledApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Humans/${queryArg.hid}/Enabled`,
        method: 'DELETE',
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    putApiHumansByHidEnabled: build.mutation<
      PutApiHumansByHidEnabledApiResponse,
      PutApiHumansByHidEnabledApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Humans/${queryArg.hid}/Enabled`,
        method: 'PUT',
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    getApiJiraProjectsEnabled: build.query<
      GetApiJiraProjectsEnabledApiResponse,
      GetApiJiraProjectsEnabledApiArg
    >({
      query: (queryArg) => ({
        url: `/api/JiraProjects/enabled`,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    getApiJiraProjects: build.query<
      GetApiJiraProjectsApiResponse,
      GetApiJiraProjectsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/JiraProjects`,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    getApiJiraProjectsByKey: build.query<
      GetApiJiraProjectsByKeyApiResponse,
      GetApiJiraProjectsByKeyApiArg
    >({
      query: (queryArg) => ({
        url: `/api/JiraProjects/${queryArg.key}`,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    getApiLeaderboardFindingCounts: build.query<
      GetApiLeaderboardFindingCountsApiResponse,
      GetApiLeaderboardFindingCountsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Leaderboard/FindingCounts`,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    getApiLeaderboardAnnualFindingsTopByLimit: build.query<
      GetApiLeaderboardAnnualFindingsTopByLimitApiResponse,
      GetApiLeaderboardAnnualFindingsTopByLimitApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Leaderboard/AnnualFindings/Top/${queryArg.limit}`,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    getApiLeaderboardMyFindingsSinceByWhen: build.query<
      GetApiLeaderboardMyFindingsSinceByWhenApiResponse,
      GetApiLeaderboardMyFindingsSinceByWhenApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Leaderboard/MyFindings/Since/${queryArg.when}`,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
        params: { hid: queryArg.hid },
      }),
    }),
    getApiLeaderboardFindingsSinceByWhen: build.query<
      GetApiLeaderboardFindingsSinceByWhenApiResponse,
      GetApiLeaderboardFindingsSinceByWhenApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Leaderboard/Findings/Since/${queryArg.when}`,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
        params: { threshold: queryArg.threshold },
      }),
    }),
    getApiLeaderboardFindingsCustomByFromAndTo: build.query<
      GetApiLeaderboardFindingsCustomByFromAndToApiResponse,
      GetApiLeaderboardFindingsCustomByFromAndToApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Leaderboard/Findings/Custom/${queryArg['from']}/${queryArg.to}`,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    getApiLeaderboardHeatmapHumansByDaysAndHid: build.query<
      GetApiLeaderboardHeatmapHumansByDaysAndHidApiResponse,
      GetApiLeaderboardHeatmapHumansByDaysAndHidApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Leaderboard/Heatmap/Humans/${queryArg.days}/${queryArg.hid}`,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    getApiLeaderboardHeatmapHumansByDaysMe: build.query<
      GetApiLeaderboardHeatmapHumansByDaysMeApiResponse,
      GetApiLeaderboardHeatmapHumansByDaysMeApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Leaderboard/Heatmap/Humans/${queryArg.days}/me`,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    getApiLeaderboardHeatmapHumansByDays: build.query<
      GetApiLeaderboardHeatmapHumansByDaysApiResponse,
      GetApiLeaderboardHeatmapHumansByDaysApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Leaderboard/Heatmap/Humans/${queryArg.days}`,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    getApiLeaderboardHeatmapProjectsByDaysAndPid: build.query<
      GetApiLeaderboardHeatmapProjectsByDaysAndPidApiResponse,
      GetApiLeaderboardHeatmapProjectsByDaysAndPidApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Leaderboard/Heatmap/Projects/${queryArg.days}/${queryArg.pid}`,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
        params: { hid: queryArg.hid },
      }),
    }),
    getApiLeaderboardHeatmapProjectsByDays: build.query<
      GetApiLeaderboardHeatmapProjectsByDaysApiResponse,
      GetApiLeaderboardHeatmapProjectsByDaysApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Leaderboard/Heatmap/Projects/${queryArg.days}`,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
        params: { mineOnly: queryArg.mineOnly },
      }),
    }),
    getApiLeaderboardEditsByUidAndFromToBare: build.query<
      GetApiLeaderboardEditsByUidAndFromToBareApiResponse,
      GetApiLeaderboardEditsByUidAndFromToBareApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Leaderboard/Edits/${queryArg.uid}/${queryArg['from']}/${queryArg.to}/Bare`,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    getApiLeaderboardEditsAliasByLoginAndFromToBare: build.query<
      GetApiLeaderboardEditsAliasByLoginAndFromToBareApiResponse,
      GetApiLeaderboardEditsAliasByLoginAndFromToBareApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Leaderboard/Edits/Alias/${queryArg.login}/${queryArg['from']}/${queryArg.to}/Bare`,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    getApiLeaderboardProjectEditsByPidBare: build.query<
      GetApiLeaderboardProjectEditsByPidBareApiResponse,
      GetApiLeaderboardProjectEditsByPidBareApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Leaderboard/ProjectEdits/${queryArg.pid}/Bare`,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    getApiLeaderboardGoogleRestrictedScopeDigestBySince: build.query<
      GetApiLeaderboardGoogleRestrictedScopeDigestBySinceApiResponse,
      GetApiLeaderboardGoogleRestrictedScopeDigestBySinceApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Leaderboard/GoogleRestrictedScopeDigest/${queryArg.since}`,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    postApiProjectfilesByProjectId: build.mutation<
      PostApiProjectfilesByProjectIdApiResponse,
      PostApiProjectfilesByProjectIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/projectfiles/${queryArg.projectId}`,
        method: 'POST',
        body: queryArg.body,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    getApiProjectfilesProjectByPid: build.query<
      GetApiProjectfilesProjectByPidApiResponse,
      GetApiProjectfilesProjectByPidApiArg
    >({
      query: (queryArg) => ({
        url: `/api/projectfiles/project/${queryArg.pid}`,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    getApiProjectfilesById: build.query<
      GetApiProjectfilesByIdApiResponse,
      GetApiProjectfilesByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/projectfiles/${queryArg.id}`,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    deleteApiProjectfilesById: build.mutation<
      DeleteApiProjectfilesByIdApiResponse,
      DeleteApiProjectfilesByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/projectfiles/${queryArg.id}`,
        method: 'DELETE',
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    getApiProjects: build.query<
      GetApiProjectsApiResponse,
      GetApiProjectsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Projects`,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
        params: { active: queryArg.active, mine: queryArg.mine },
      }),
    }),
    postApiProjects: build.mutation<
      PostApiProjectsApiResponse,
      PostApiProjectsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Projects`,
        method: 'POST',
        body: queryArg.krakenModelsDtoProjectDto,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    getApiProjectsV2: build.query<
      GetApiProjectsV2ApiResponse,
      GetApiProjectsV2ApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Projects/v2`,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
        params: { active: queryArg.active, mine: queryArg.mine },
      }),
    }),
    getApiProjectsDetailed: build.query<
      GetApiProjectsDetailedApiResponse,
      GetApiProjectsDetailedApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Projects/Detailed`,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
        params: { active: queryArg.active, mine: queryArg.mine },
      }),
    }),
    getApiProjectsByPid: build.query<
      GetApiProjectsByPidApiResponse,
      GetApiProjectsByPidApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Projects/${queryArg.pid}`,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    putApiProjectsByPid: build.mutation<
      PutApiProjectsByPidApiResponse,
      PutApiProjectsByPidApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Projects/${queryArg.pid}`,
        method: 'PUT',
        body: queryArg.krakenModelsDtoProjectDto,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    deleteApiProjectsByPid: build.mutation<
      DeleteApiProjectsByPidApiResponse,
      DeleteApiProjectsByPidApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Projects/${queryArg.pid}`,
        method: 'DELETE',
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    getApiProjectsByPidDetailed: build.query<
      GetApiProjectsByPidDetailedApiResponse,
      GetApiProjectsByPidDetailedApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Projects/${queryArg.pid}/Detailed`,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    putApiProjectsByPidDates: build.mutation<
      PutApiProjectsByPidDatesApiResponse,
      PutApiProjectsByPidDatesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Projects/${queryArg.pid}/Dates`,
        method: 'PUT',
        body: queryArg.krakenBackendApiModelsProjectsProjectDates,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    deleteApiProjectsDeleteByPid: build.mutation<
      DeleteApiProjectsDeleteByPidApiResponse,
      DeleteApiProjectsDeleteByPidApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Projects/delete/${queryArg.pid}`,
        method: 'DELETE',
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    putApiProjectsByPidUndelete: build.mutation<
      PutApiProjectsByPidUndeleteApiResponse,
      PutApiProjectsByPidUndeleteApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Projects/${queryArg.pid}/Undelete`,
        method: 'PUT',
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    getApiProjectsByPidRoles: build.query<
      GetApiProjectsByPidRolesApiResponse,
      GetApiProjectsByPidRolesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Projects/${queryArg.pid}/Roles`,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
        params: { hid: queryArg.hid },
      }),
    }),
    putApiProjectsByPidRoles: build.mutation<
      PutApiProjectsByPidRolesApiResponse,
      PutApiProjectsByPidRolesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Projects/${queryArg.pid}/Roles`,
        method: 'PUT',
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
        params: { hid: queryArg.hid, rid: queryArg.rid },
      }),
    }),
    deleteApiProjectsByPidRoles: build.mutation<
      DeleteApiProjectsByPidRolesApiResponse,
      DeleteApiProjectsByPidRolesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Projects/${queryArg.pid}/Roles`,
        method: 'DELETE',
        body: queryArg.body,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    deleteApiProjectsByPidRolesAndHid: build.mutation<
      DeleteApiProjectsByPidRolesAndHidApiResponse,
      DeleteApiProjectsByPidRolesAndHidApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Projects/${queryArg.pid}/Roles/${queryArg.hid}`,
        method: 'DELETE',
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    deleteApiProjectsByPidRolesAndHidRid: build.mutation<
      DeleteApiProjectsByPidRolesAndHidRidApiResponse,
      DeleteApiProjectsByPidRolesAndHidRidApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Projects/${queryArg.pid}/Roles/${queryArg.hid}/${queryArg.rid}`,
        method: 'DELETE',
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    getApiProjectsByPidTemporaryAccess: build.query<
      GetApiProjectsByPidTemporaryAccessApiResponse,
      GetApiProjectsByPidTemporaryAccessApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Projects/${queryArg.pid}/TemporaryAccess`,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    deleteApiProjectsByPidTemporaryAccess: build.mutation<
      DeleteApiProjectsByPidTemporaryAccessApiResponse,
      DeleteApiProjectsByPidTemporaryAccessApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Projects/${queryArg.pid}/TemporaryAccess`,
        method: 'DELETE',
        body: queryArg.body,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    postApiProjectsByPidTemporaryAccessAndHid: build.mutation<
      PostApiProjectsByPidTemporaryAccessAndHidApiResponse,
      PostApiProjectsByPidTemporaryAccessAndHidApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Projects/${queryArg.pid}/TemporaryAccess/${queryArg.hid}`,
        method: 'POST',
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
        params: {
          not_before: queryArg.notBefore,
          not_after: queryArg.notAfter,
        },
      }),
    }),
    putApiProjectsByPidTemporaryAccessAndHid: build.mutation<
      PutApiProjectsByPidTemporaryAccessAndHidApiResponse,
      PutApiProjectsByPidTemporaryAccessAndHidApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Projects/${queryArg.pid}/TemporaryAccess/${queryArg.hid}`,
        method: 'PUT',
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
        params: {
          not_before: queryArg.notBefore,
          not_after: queryArg.notAfter,
          new_not_after: queryArg.newNotAfter,
        },
      }),
    }),
    deleteApiProjectsByPidTemporaryAccessAndHid: build.mutation<
      DeleteApiProjectsByPidTemporaryAccessAndHidApiResponse,
      DeleteApiProjectsByPidTemporaryAccessAndHidApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Projects/${queryArg.pid}/TemporaryAccess/${queryArg.hid}`,
        method: 'DELETE',
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    getApiProjectsByPidFindingActivityBinding: build.query<
      GetApiProjectsByPidFindingActivityBindingApiResponse,
      GetApiProjectsByPidFindingActivityBindingApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Projects/${queryArg.pid}/FindingActivityBinding`,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    getApiProjectsByPidLastEditDate: build.query<
      GetApiProjectsByPidLastEditDateApiResponse,
      GetApiProjectsByPidLastEditDateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Projects/${queryArg.pid}/LastEditDate`,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    getApiProjectsLateEdits: build.query<
      GetApiProjectsLateEditsApiResponse,
      GetApiProjectsLateEditsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Projects/Late/Edits`,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    deleteApiProjectsOld: build.mutation<
      DeleteApiProjectsOldApiResponse,
      DeleteApiProjectsOldApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Projects/Old`,
        method: 'DELETE',
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    getApiProjectsMavenlinkByMid: build.query<
      GetApiProjectsMavenlinkByMidApiResponse,
      GetApiProjectsMavenlinkByMidApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Projects/Mavenlink/${queryArg.mid}`,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    getApiProjectsClarizenByClarizenId: build.query<
      GetApiProjectsClarizenByClarizenIdApiResponse,
      GetApiProjectsClarizenByClarizenIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Projects/Clarizen/${queryArg.clarizenId}`,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    getApiProjectsActiveProjectsForUserByHid: build.query<
      GetApiProjectsActiveProjectsForUserByHidApiResponse,
      GetApiProjectsActiveProjectsForUserByHidApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Projects/ActiveProjectsForUser/${queryArg.hid}`,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    putApiProjectsByPidExecsummary: build.mutation<
      PutApiProjectsByPidExecsummaryApiResponse,
      PutApiProjectsByPidExecsummaryApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Projects/${queryArg.pid}/execsummary`,
        method: 'PUT',
        body: queryArg.krakenBackendApiModelsProjectsApiExecutiveSummary,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
        params: {
          reviewType: queryArg.reviewType,
          reviewOutcome: queryArg.reviewOutcome,
        },
      }),
    }),
    getApiProjectsByPidExecsummary: build.query<
      GetApiProjectsByPidExecsummaryApiResponse,
      GetApiProjectsByPidExecsummaryApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Projects/${queryArg.pid}/execsummary`,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    patchApiProjectsByPidLogo: build.mutation<
      PatchApiProjectsByPidLogoApiResponse,
      PatchApiProjectsByPidLogoApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Projects/${queryArg.pid}/logo`,
        method: 'PATCH',
        body: queryArg.body,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    getApiProjectsByPidLogo: build.query<
      GetApiProjectsByPidLogoApiResponse,
      GetApiProjectsByPidLogoApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Projects/${queryArg.pid}/Logo`,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    getApiV2ProjectsWithInactive: build.query<
      GetApiV2ProjectsWithInactiveApiResponse,
      GetApiV2ProjectsWithInactiveApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v2/Projects/with-inactive`,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    getApiV2ProjectsByPidInactiveFindings: build.query<
      GetApiV2ProjectsByPidInactiveFindingsApiResponse,
      GetApiV2ProjectsByPidInactiveFindingsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v2/Projects/${queryArg.pid}/inactive-findings`,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    getApiV2ProjectsByPidInactiveActivities: build.query<
      GetApiV2ProjectsByPidInactiveActivitiesApiResponse,
      GetApiV2ProjectsByPidInactiveActivitiesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v2/Projects/${queryArg.pid}/inactive-activities`,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    getApiV2ProjectsByPidInactiveComponents: build.query<
      GetApiV2ProjectsByPidInactiveComponentsApiResponse,
      GetApiV2ProjectsByPidInactiveComponentsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v2/Projects/${queryArg.pid}/inactive-components`,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    postApiQuestionsAnswersByQuestionIdAndVersion: build.mutation<
      PostApiQuestionsAnswersByQuestionIdAndVersionApiResponse,
      PostApiQuestionsAnswersByQuestionIdAndVersionApiArg
    >({
      query: (queryArg) => ({
        url: `/api/questions/answers/${queryArg.questionId}/${queryArg.version}`,
        method: 'POST',
        body: queryArg.krakenBackendApiModelsQuestionsAnswer,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    putApiQuestionsAnswersById: build.mutation<
      PutApiQuestionsAnswersByIdApiResponse,
      PutApiQuestionsAnswersByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/questions/answers/${queryArg.id}`,
        method: 'PUT',
        body: queryArg.body,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    getApiQuestionsAnswersProjectByPid: build.query<
      GetApiQuestionsAnswersProjectByPidApiResponse,
      GetApiQuestionsAnswersProjectByPidApiArg
    >({
      query: (queryArg) => ({
        url: `/api/questions/answers/project/${queryArg.pid}`,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    getApiQuestionsTemplatesApplicableByPid: build.query<
      GetApiQuestionsTemplatesApplicableByPidApiResponse,
      GetApiQuestionsTemplatesApplicableByPidApiArg
    >({
      query: (queryArg) => ({
        url: `/api/questions/templates/applicable/${queryArg.pid}`,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    getApiQuestionsTemplatesActive: build.query<
      GetApiQuestionsTemplatesActiveApiResponse,
      GetApiQuestionsTemplatesActiveApiArg
    >({
      query: (queryArg) => ({
        url: `/api/questions/templates/active`,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    postApiQuestionsTemplates: build.mutation<
      PostApiQuestionsTemplatesApiResponse,
      PostApiQuestionsTemplatesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/questions/templates`,
        method: 'POST',
        body: queryArg.krakenBackendApiModelsQuestionsBasicTemplate,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    putApiQuestionsTemplatesById: build.mutation<
      PutApiQuestionsTemplatesByIdApiResponse,
      PutApiQuestionsTemplatesByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/questions/templates/${queryArg.id}`,
        method: 'PUT',
        body: queryArg.krakenBackendApiModelsQuestionsTemplate,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    deleteApiQuestionsTemplatesById: build.mutation<
      DeleteApiQuestionsTemplatesByIdApiResponse,
      DeleteApiQuestionsTemplatesByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/questions/templates/${queryArg.id}`,
        method: 'DELETE',
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    getApiQuestionsContextActive: build.query<
      GetApiQuestionsContextActiveApiResponse,
      GetApiQuestionsContextActiveApiArg
    >({
      query: (queryArg) => ({
        url: `/api/questions/context/active`,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    putApiQuestionsContextById: build.mutation<
      PutApiQuestionsContextByIdApiResponse,
      PutApiQuestionsContextByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/questions/context/${queryArg.id}`,
        method: 'PUT',
        body: queryArg.krakenBackendApiModelsQuestionsContext,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    deleteApiQuestionsContextById: build.mutation<
      DeleteApiQuestionsContextByIdApiResponse,
      DeleteApiQuestionsContextByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/questions/context/${queryArg.id}`,
        method: 'DELETE',
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    postApiQuestionsContext: build.mutation<
      PostApiQuestionsContextApiResponse,
      PostApiQuestionsContextApiArg
    >({
      query: (queryArg) => ({
        url: `/api/questions/context`,
        method: 'POST',
        body: queryArg.krakenBackendApiModelsQuestionsContext,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    getApiQuestionsOutstanding: build.query<
      GetApiQuestionsOutstandingApiResponse,
      GetApiQuestionsOutstandingApiArg
    >({
      query: (queryArg) => ({
        url: `/api/questions/outstanding`,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    getApiRawBugsXmlByPid: build.query<
      GetApiRawBugsXmlByPidApiResponse,
      GetApiRawBugsXmlByPidApiArg
    >({
      query: (queryArg) => ({
        url: `/api/RawBugs/XML/${queryArg.pid}`,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    getApiRawBugsAwsJsonByPid: build.query<
      GetApiRawBugsAwsJsonByPidApiResponse,
      GetApiRawBugsAwsJsonByPidApiArg
    >({
      query: (queryArg) => ({
        url: `/api/RawBugs/AWSJson/${queryArg.pid}`,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    getApiReportsAvailableFonts: build.query<
      GetApiReportsAvailableFontsApiResponse,
      GetApiReportsAvailableFontsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Reports/available-fonts`,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    getApiReviewablesEditHistoryByRid: build.query<
      GetApiReviewablesEditHistoryByRidApiResponse,
      GetApiReviewablesEditHistoryByRidApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Reviewables/EditHistory/${queryArg.rid}`,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    getApiReviewablesEditHistoryProjectByPid: build.query<
      GetApiReviewablesEditHistoryProjectByPidApiResponse,
      GetApiReviewablesEditHistoryProjectByPidApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Reviewables/EditHistory/Project/${queryArg.pid}`,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    getApiReviewablesOversight: build.query<
      GetApiReviewablesOversightApiResponse,
      GetApiReviewablesOversightApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Reviewables/Oversight`,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    getApiReviewablesFindByRid: build.query<
      GetApiReviewablesFindByRidApiResponse,
      GetApiReviewablesFindByRidApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Reviewables/Find/${queryArg.rid}`,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    getApiV2ReviewablesEditHistoryByRid: build.query<
      GetApiV2ReviewablesEditHistoryByRidApiResponse,
      GetApiV2ReviewablesEditHistoryByRidApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v2/Reviewables/edit-history/${queryArg.rid}`,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    postApiReviewsFindingByFid: build.mutation<
      PostApiReviewsFindingByFidApiResponse,
      PostApiReviewsFindingByFidApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Reviews/Finding/${queryArg.fid}`,
        method: 'POST',
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
        params: { type: queryArg['type'], outcome: queryArg.outcome },
      }),
    }),
    postApiReviewsActivityByAid: build.mutation<
      PostApiReviewsActivityByAidApiResponse,
      PostApiReviewsActivityByAidApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Reviews/Activity/${queryArg.aid}`,
        method: 'POST',
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
        params: { type: queryArg['type'], outcome: queryArg.outcome },
      }),
    }),
    postApiReviewsComponentByPcid: build.mutation<
      PostApiReviewsComponentByPcidApiResponse,
      PostApiReviewsComponentByPcidApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Reviews/Component/${queryArg.pcid}`,
        method: 'POST',
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
        params: { type: queryArg['type'], outcome: queryArg.outcome },
      }),
    }),
    postApiReviewsProjectByPid: build.mutation<
      PostApiReviewsProjectByPidApiResponse,
      PostApiReviewsProjectByPidApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Reviews/Project/${queryArg.pid}`,
        method: 'POST',
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
        params: { type: queryArg['type'], outcome: queryArg.outcome },
      }),
    }),
    getApiReviewsByProjectByPid: build.query<
      GetApiReviewsByProjectByPidApiResponse,
      GetApiReviewsByProjectByPidApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Reviews/ByProject/${queryArg.pid}`,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    getApiReviewsByProjectByPidComplete: build.query<
      GetApiReviewsByProjectByPidCompleteApiResponse,
      GetApiReviewsByProjectByPidCompleteApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Reviews/ByProject/${queryArg.pid}/Complete`,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    getApiReviewsByRidByRidComplete: build.query<
      GetApiReviewsByRidByRidCompleteApiResponse,
      GetApiReviewsByRidByRidCompleteApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Reviews/ByRid/${queryArg.rid}/Complete`,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    getApiReviewsByRid: build.query<
      GetApiReviewsByRidApiResponse,
      GetApiReviewsByRidApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Reviews/${queryArg.rid}`,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    getApiV2ReviewsLastReviewByFid: build.query<
      GetApiV2ReviewsLastReviewByFidApiResponse,
      GetApiV2ReviewsLastReviewByFidApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v2/Reviews/last-review/${queryArg.fid}`,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    getApiRoles: build.query<GetApiRolesApiResponse, GetApiRolesApiArg>({
      query: (queryArg) => ({
        url: `/api/Roles`,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    postApiRoles: build.mutation<PostApiRolesApiResponse, PostApiRolesApiArg>({
      query: (queryArg) => ({
        url: `/api/Roles`,
        method: 'POST',
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
        params: { name: queryArg.name, desc: queryArg.desc },
      }),
    }),
    deleteApiRolesByRid: build.mutation<
      DeleteApiRolesByRidApiResponse,
      DeleteApiRolesByRidApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Roles/${queryArg.rid}`,
        method: 'DELETE',
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    putApiRolesByRid: build.mutation<
      PutApiRolesByRidApiResponse,
      PutApiRolesByRidApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Roles/${queryArg.rid}`,
        method: 'PUT',
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
        params: { desc: queryArg.desc },
      }),
    }),
    getApiRulesProjectByPid: build.query<
      GetApiRulesProjectByPidApiResponse,
      GetApiRulesProjectByPidApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Rules/Project/${queryArg.pid}`,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
        params: { final: queryArg.final },
      }),
    }),
    getApiRulesComponentByPcid: build.query<
      GetApiRulesComponentByPcidApiResponse,
      GetApiRulesComponentByPcidApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Rules/Component/${queryArg.pcid}`,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
        params: { AWS: queryArg.aws, final: queryArg.final },
      }),
    }),
    getApiRulesFindingByFid: build.query<
      GetApiRulesFindingByFidApiResponse,
      GetApiRulesFindingByFidApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Rules/Finding/${queryArg.fid}`,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
        params: { AWS: queryArg.aws },
      }),
    }),
    getApiRulesActivityByAid: build.query<
      GetApiRulesActivityByAidApiResponse,
      GetApiRulesActivityByAidApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Rules/Activity/${queryArg.aid}`,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
        params: { final: queryArg.final },
      }),
    }),
    getApiStatisticsFindingsCount: build.query<
      GetApiStatisticsFindingsCountApiResponse,
      GetApiStatisticsFindingsCountApiArg
    >({
      query: (queryArg) => ({
        url: `/api/statistics/findings/count`,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
        params: {
          humanId: queryArg.humanId,
          projectId: queryArg.projectId,
          startDate: queryArg.startDate,
          endDate: queryArg.endDate,
        },
      }),
    }),
    getApiStatusReportByPidAndLastStatus: build.query<
      GetApiStatusReportByPidAndLastStatusApiResponse,
      GetApiStatusReportByPidAndLastStatusApiArg
    >({
      query: (queryArg) => ({
        url: `/api/StatusReport/${queryArg.pid}/${queryArg.lastStatus}`,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    getApiStatusReportByPid: build.query<
      GetApiStatusReportByPidApiResponse,
      GetApiStatusReportByPidApiArg
    >({
      query: (queryArg) => ({
        url: `/api/StatusReport/${queryArg.pid}`,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    postApiStatusReportV2: build.mutation<
      PostApiStatusReportV2ApiResponse,
      PostApiStatusReportV2ApiArg
    >({
      query: (queryArg) => ({
        url: `/api/StatusReport/v2`,
        method: 'POST',
        body: queryArg.krakenModelsDtoStatusReportConfigurableDetails,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
        params: { pid: queryArg.pid, lastStatus: queryArg.lastStatus },
      }),
    }),
    getApiStatusReportEditableInfo: build.query<
      GetApiStatusReportEditableInfoApiResponse,
      GetApiStatusReportEditableInfoApiArg
    >({
      query: (queryArg) => ({
        url: `/api/StatusReport/editable-info`,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
        params: { pid: queryArg.pid },
      }),
    }),
    getApiStereotypes: build.query<
      GetApiStereotypesApiResponse,
      GetApiStereotypesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Stereotypes`,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    postApiStereotypes: build.mutation<
      PostApiStereotypesApiResponse,
      PostApiStereotypesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Stereotypes`,
        method: 'POST',
        body: queryArg.krakenBackendApiModelsStereotypesApiStereotype,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    getApiStereotypesBySid: build.query<
      GetApiStereotypesBySidApiResponse,
      GetApiStereotypesBySidApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Stereotypes/${queryArg.sid}`,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    putApiStereotypesBySid: build.mutation<
      PutApiStereotypesBySidApiResponse,
      PutApiStereotypesBySidApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Stereotypes/${queryArg.sid}`,
        method: 'PUT',
        body: queryArg.krakenBackendApiModelsStereotypesApiStereotype,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    deleteApiStereotypesBySid: build.mutation<
      DeleteApiStereotypesBySidApiResponse,
      DeleteApiStereotypesBySidApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Stereotypes/${queryArg.sid}`,
        method: 'DELETE',
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    getApiStereotypesBySidActivityTemplates: build.query<
      GetApiStereotypesBySidActivityTemplatesApiResponse,
      GetApiStereotypesBySidActivityTemplatesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Stereotypes/${queryArg.sid}/ActivityTemplates`,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    getApiStereotypesByProjectByPid: build.query<
      GetApiStereotypesByProjectByPidApiResponse,
      GetApiStereotypesByProjectByPidApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Stereotypes/ByProject/${queryArg.pid}`,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
        params: { componentId: queryArg.componentId },
      }),
    }),
    getApiSystemVersion: build.query<
      GetApiSystemVersionApiResponse,
      GetApiSystemVersionApiArg
    >({
      query: (queryArg) => ({
        url: `/api/System/version`,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    getApiSystemFindingStatus: build.query<
      GetApiSystemFindingStatusApiResponse,
      GetApiSystemFindingStatusApiArg
    >({
      query: (queryArg) => ({
        url: `/api/System/finding-status`,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    getApiSystemSignalrConfig: build.query<
      GetApiSystemSignalrConfigApiResponse,
      GetApiSystemSignalrConfigApiArg
    >({
      query: (queryArg) => ({
        url: `/api/System/signalr-config`,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    postApiV2Team: build.mutation<
      PostApiV2TeamApiResponse,
      PostApiV2TeamApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v2/Team`,
        method: 'POST',
        body: queryArg.krakenModelsDtoTeamTeamDto,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    getApiV2Team: build.query<GetApiV2TeamApiResponse, GetApiV2TeamApiArg>({
      query: (queryArg) => ({
        url: `/api/v2/Team`,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    putApiV2TeamByTeamId: build.mutation<
      PutApiV2TeamByTeamIdApiResponse,
      PutApiV2TeamByTeamIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v2/Team/${queryArg.teamId}`,
        method: 'PUT',
        body: queryArg.krakenModelsDtoTeamTeamDto,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    getApiV2TeamByTeamId: build.query<
      GetApiV2TeamByTeamIdApiResponse,
      GetApiV2TeamByTeamIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v2/Team/${queryArg.teamId}`,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    deleteApiV2TeamByTeamId: build.mutation<
      DeleteApiV2TeamByTeamIdApiResponse,
      DeleteApiV2TeamByTeamIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v2/Team/${queryArg.teamId}`,
        method: 'DELETE',
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    getApiV2TeamSelect: build.query<
      GetApiV2TeamSelectApiResponse,
      GetApiV2TeamSelectApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v2/Team/select`,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
        params: { isSystem: queryArg.isSystem },
      }),
    }),
    postApiV2TeamByTeamIdMember: build.mutation<
      PostApiV2TeamByTeamIdMemberApiResponse,
      PostApiV2TeamByTeamIdMemberApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v2/Team/${queryArg.teamId}/member`,
        method: 'POST',
        body: queryArg.body,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    deleteApiV2TeamByTeamIdMember: build.mutation<
      DeleteApiV2TeamByTeamIdMemberApiResponse,
      DeleteApiV2TeamByTeamIdMemberApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v2/Team/${queryArg.teamId}/member`,
        method: 'DELETE',
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
        params: { humanId: queryArg.humanId },
      }),
    }),
    getApiUserPreferences: build.query<
      GetApiUserPreferencesApiResponse,
      GetApiUserPreferencesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/UserPreferences`,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    getApiVulnerabilitiesActive: build.query<
      GetApiVulnerabilitiesActiveApiResponse,
      GetApiVulnerabilitiesActiveApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Vulnerabilities/active`,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    getApiVulnerabilitiesInactive: build.query<
      GetApiVulnerabilitiesInactiveApiResponse,
      GetApiVulnerabilitiesInactiveApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Vulnerabilities/inactive`,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    getApiVulnerabilitiesByVid: build.query<
      GetApiVulnerabilitiesByVidApiResponse,
      GetApiVulnerabilitiesByVidApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Vulnerabilities/${queryArg.vid}`,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    deleteApiVulnerabilitiesByVid: build.mutation<
      DeleteApiVulnerabilitiesByVidApiResponse,
      DeleteApiVulnerabilitiesByVidApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Vulnerabilities/${queryArg.vid}`,
        method: 'DELETE',
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    putApiVulnerabilitiesByVidUndelete: build.mutation<
      PutApiVulnerabilitiesByVidUndeleteApiResponse,
      PutApiVulnerabilitiesByVidUndeleteApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Vulnerabilities/${queryArg.vid}/Undelete`,
        method: 'PUT',
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    putApiVulnerabilities: build.mutation<
      PutApiVulnerabilitiesApiResponse,
      PutApiVulnerabilitiesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Vulnerabilities`,
        method: 'PUT',
        body: queryArg.krakenModelsDatabaseTablesVulnerabilities,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    postApiVulnerabilities: build.mutation<
      PostApiVulnerabilitiesApiResponse,
      PostApiVulnerabilitiesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Vulnerabilities`,
        method: 'POST',
        body: queryArg.krakenBackendApiModelsVulnerabilitiesApiVulnerability,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
    putApiVulnerabilitiesByFtid: build.mutation<
      PutApiVulnerabilitiesByFtidApiResponse,
      PutApiVulnerabilitiesByFtidApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Vulnerabilities/${queryArg.ftid}`,
        method: 'PUT',
        body: queryArg.krakenModelsDatabaseTablesVulnerabilities,
        headers: { 'X-CSRF': queryArg['X-CSRF'] },
      }),
    }),
  }),
});
export type GetApiActivitiesComponentByPcidApiResponse =
  /** status 200 Success */ KrakenBackendApiModelsActivitiesApiActivityDetailed[];
export type GetApiActivitiesComponentByPcidApiArg = {
  /** Project component GUID */
  pcid: string;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
};
export type GetApiActivitiesComponentByPcidInactiveApiResponse =
  /** status 200 Success */ KrakenBackendApiModelsActivitiesApiActivityDetailed[];
export type GetApiActivitiesComponentByPcidInactiveApiArg = {
  /** Project component GUID */
  pcid: string;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
};
export type GetApiActivitiesProjectByPidApiResponse =
  /** status 200 Success */ KrakenBackendApiModelsActivitiesApiActivityDetailed[];
export type GetApiActivitiesProjectByPidApiArg = {
  /** Project GUID */
  pid: string;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
};
export type GetApiActivitiesProjectByPidInactiveApiResponse =
  /** status 200 Success */ KrakenBackendApiModelsActivitiesApiActivityDetailed[];
export type GetApiActivitiesProjectByPidInactiveApiArg = {
  /** Project GUID */
  pid: string;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
};
export type GetApiActivitiesByAidApiResponse =
  /** status 200 Success */ KrakenBackendApiModelsActivitiesApiActivityDetailed;
export type GetApiActivitiesByAidApiArg = {
  /** Activity GUID */
  aid: string;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
};
export type PutApiActivitiesByAidApiResponse = unknown;
export type PutApiActivitiesByAidApiArg = {
  /** GUID of the activity to edit */
  aid: string;
  /** New status (optional; if not provided, status does not change) */
  s?: KrakenBackendUtilityConstantsActivityStatus;
  /** If leaving a review at the same time, type of review to leave (optional) */
  reviewType?: KrakenModelsEnumsReviewType;
  /** If leaving a review at the same time, outcome of the review; null if not leaving a review */
  reviewOutcome?: KrakenModelsEnumsReviewOutcome;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
  /** Activity structure to replace the current contents with */
  krakenBackendApiModelsActivitiesApiActivityNew: KrakenBackendApiModelsActivitiesApiActivityNew;
};
export type DeleteApiActivitiesByAidApiResponse = unknown;
export type DeleteApiActivitiesByAidApiArg = {
  /** GUID of the activity to remove */
  aid: string;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
};
export type PostApiActivitiesByAidChecklistApiResponse =
  /** status 200 Success */ string;
export type PostApiActivitiesByAidChecklistApiArg = {
  /** Activity ID */
  aid: string;
  /** Force the activity checklist item to be created with a particular guid */
  forceGuid?: string;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
  /** Text for the checklist item */
  body: string;
};
export type DeleteApiActivitiesChecklistByAcidApiResponse = unknown;
export type DeleteApiActivitiesChecklistByAcidApiArg = {
  /** Checklist item ID */
  acid: string;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
};
export type PutApiActivitiesChecklistByAcidApiResponse = unknown;
export type PutApiActivitiesChecklistByAcidApiArg = {
  /** Checklist item ID */
  acid: string;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
  /** New text */
  body: string;
};
export type PutApiActivitiesChecklistByAcidCheckedApiResponse = unknown;
export type PutApiActivitiesChecklistByAcidCheckedApiArg = {
  /** Checklist item ID */
  acid: string;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
  /** True if checked; false if unchecked */
  krakenModelsEnumsActivityCheckListItemStatus: KrakenModelsEnumsActivityCheckListItemStatus;
};
export type PostApiActivitiesApiResponse = /** status 200 Success */ string;
export type PostApiActivitiesApiArg = {
  /** Force the activity to be created with a particular GUID */
  forceGuid?: string;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
  /** Structure describing the activity and its component */
  krakenBackendApiModelsActivitiesApiActivityNew: KrakenBackendApiModelsActivitiesApiActivityNew;
};
export type PutApiActivitiesByAidStatusApiResponse = unknown;
export type PutApiActivitiesByAidStatusApiArg = {
  /** GUID of the activity to edit */
  aid: string;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
  /** New status */
  krakenBackendUtilityConstantsActivityStatus: KrakenBackendUtilityConstantsActivityStatus;
};
export type PostApiActivitiesByAidBulkCheckListItemsApiResponse = unknown;
export type PostApiActivitiesByAidBulkCheckListItemsApiArg = {
  /** GUID of the activity to edit */
  aid: string;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
  /** New status */
  krakenModelsEnumsActivityCheckListItemStatus: KrakenModelsEnumsActivityCheckListItemStatus;
};
export type DeleteApiActivitiesByAidTemplateLinkApiResponse = unknown;
export type DeleteApiActivitiesByAidTemplateLinkApiArg = {
  /** GUID of the activity to make unassociated */
  aid: string;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
};
export type PutApiActivitiesByAidUndeleteApiResponse = unknown;
export type PutApiActivitiesByAidUndeleteApiArg = {
  /** GUID of the activity to restore */
  aid: string;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
};
export type PutApiActivitiesByAidFindingAndFidApiResponse = unknown;
export type PutApiActivitiesByAidFindingAndFidApiArg = {
  /** GUID of the activity to associate */
  aid: string;
  /** GUID of the finding to associate */
  fid: string;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
};
export type DeleteApiActivitiesByAidFindingAndFidApiResponse = unknown;
export type DeleteApiActivitiesByAidFindingAndFidApiArg = {
  /** GUID of the activity to unassociate */
  aid: string;
  /** GUID of the finding to unassociate */
  fid: string;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
};
export type GetApiActivitiesByAidFindingsApiResponse =
  /** status 200 Success */ string[];
export type GetApiActivitiesByAidFindingsApiArg = {
  /** Activity ID */
  aid: string;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
};
export type GetApiActivityTemplatesApiResponse =
  /** status 200 Success */ KrakenBackendApiModelsActivityTemplatesApiActivityTemplateAssociated[];
export type GetApiActivityTemplatesApiArg = {
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
};
export type PostApiActivityTemplatesApiResponse =
  /** status 200 Success */ string;
export type PostApiActivityTemplatesApiArg = {
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
  krakenBackendApiModelsActivityTemplatesApiActivityTemplateWithChecklist: KrakenBackendApiModelsActivityTemplatesApiActivityTemplateWithChecklist;
};
export type GetApiActivityTemplatesInactiveApiResponse =
  /** status 200 Success */ KrakenBackendApiModelsActivityTemplatesApiActivityTemplateAssociated[];
export type GetApiActivityTemplatesInactiveApiArg = {
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
};
export type GetApiActivityTemplatesByAtidApiResponse =
  /** status 200 Success */ KrakenBackendApiModelsActivityTemplatesApiActivityTemplateAssociated;
export type GetApiActivityTemplatesByAtidApiArg = {
  atid: string;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
};
export type PutApiActivityTemplatesByAtidApiResponse = unknown;
export type PutApiActivityTemplatesByAtidApiArg = {
  atid: string;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
  krakenBackendApiModelsActivityTemplatesApiActivityTemplate: KrakenBackendApiModelsActivityTemplatesApiActivityTemplate;
};
export type DeleteApiActivityTemplatesByAtidApiResponse = unknown;
export type DeleteApiActivityTemplatesByAtidApiArg = {
  atid: string;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
};
export type GetApiActivityTemplatesByAtidChecklistApiResponse =
  /** status 200 Success */ KrakenBackendApiModelsActivityTemplatesApiActivityTemplateChecklistItem[];
export type GetApiActivityTemplatesByAtidChecklistApiArg = {
  atid: string;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
};
export type PostApiActivityTemplatesByAtidChecklistApiResponse =
  /** status 200 Success */ string;
export type PostApiActivityTemplatesByAtidChecklistApiArg = {
  atid: string;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
  body: string;
};
export type PutApiActivityTemplatesByAtidUndeleteApiResponse = unknown;
export type PutApiActivityTemplatesByAtidUndeleteApiArg = {
  atid: string;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
};
export type PutApiActivityTemplatesChecklistByAtcidApiResponse = unknown;
export type PutApiActivityTemplatesChecklistByAtcidApiArg = {
  atcid: string;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
  body: string;
};
export type DeleteApiActivityTemplatesChecklistByAtcidApiResponse = unknown;
export type DeleteApiActivityTemplatesChecklistByAtcidApiArg = {
  atcid: string;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
};
export type PutApiActivityTemplatesByAtidAssociationAndSidApiResponse = unknown;
export type PutApiActivityTemplatesByAtidAssociationAndSidApiArg = {
  atid: string;
  sid: string;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
};
export type DeleteApiActivityTemplatesByAtidAssociationAndSidApiResponse =
  unknown;
export type DeleteApiActivityTemplatesByAtidAssociationAndSidApiArg = {
  atid: string;
  sid: string;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
};
export type PutApiActivityTemplatesBulkAssociationApiResponse = unknown;
export type PutApiActivityTemplatesBulkAssociationApiArg = {
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
  body: KrakenBackendApiModelsActivityTemplatesApiActivityTemplateXStereotype[];
};
export type DeleteApiActivityTemplatesBulkAssociationApiResponse = unknown;
export type DeleteApiActivityTemplatesBulkAssociationApiArg = {
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
  body: KrakenBackendApiModelsActivityTemplatesApiActivityTemplateXStereotype[];
};
export type GetApiActivityTemplatesByAtidAssociationApiResponse =
  /** status 200 Success */ KrakenModelsDatabaseTablesStereotype[];
export type GetApiActivityTemplatesByAtidAssociationApiArg = {
  atid: string;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
};
export type PutApiV2AssigneeFindingByFidApiResponse = unknown;
export type PutApiV2AssigneeFindingByFidApiArg = {
  fid: string;
  id?: string;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
};
export type DeleteApiV2AssigneeFindingByFidApiResponse = unknown;
export type DeleteApiV2AssigneeFindingByFidApiArg = {
  fid: string;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
};
export type GetApiAttachmentsProjectByPidApiResponse =
  /** status 200 Success */ KrakenBackendApiModelsFindingImagesImageHeader[];
export type GetApiAttachmentsProjectByPidApiArg = {
  pid: string;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
};
export type GetApiAttachmentsProjectByPidInactiveApiResponse =
  /** status 200 Success */ KrakenBackendApiModelsFindingImagesImageHeader[];
export type GetApiAttachmentsProjectByPidInactiveApiArg = {
  pid: string;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
};
export type GetApiAttachmentsByAidApiResponse = unknown;
export type GetApiAttachmentsByAidApiArg = {
  aid: string;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
};
export type DeleteApiAttachmentsByAidApiResponse = unknown;
export type DeleteApiAttachmentsByAidApiArg = {
  aid: string;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
};
export type PutApiAttachmentsByAidUndeleteApiResponse = unknown;
export type PutApiAttachmentsByAidUndeleteApiArg = {
  aid: string;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
};
export type PostApiAttachmentsFindingByFidApiResponse = unknown;
export type PostApiAttachmentsFindingByFidApiArg = {
  fid: string;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
  body: {
    files?: Blob[];
  };
};
export type PutApiAttachmentsReportEmitterLogoApiResponse = unknown;
export type PutApiAttachmentsReportEmitterLogoApiArg = {
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
  body: {
    logo?: Blob;
  };
};
export type GetApiAttachmentsReportEmitterLogoApiResponse = unknown;
export type GetApiAttachmentsReportEmitterLogoApiArg = {
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
};
export type GetAuthCheckApiResponse = /** status 200 Success */ boolean;
export type GetAuthCheckApiArg = {
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
};
export type PostAuthSignalrAppendApiResponse = unknown;
export type PostAuthSignalrAppendApiArg = {
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
};
export type GetApiCompaniesApiResponse =
  /** status 200 Success */ KrakenBackendApiModelsCompaniesCompany[];
export type GetApiCompaniesApiArg = {
  search?: string;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
};
export type PostApiCompaniesApiResponse = /** status 200 Success */ string;
export type PostApiCompaniesApiArg = {
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
  body: {
    Name?: string;
    Logo?: Blob;
  };
};
export type GetApiCompaniesTerseApiResponse =
  /** status 200 Success */ KrakenBackendApiModelsCompaniesCompanyTerse[];
export type GetApiCompaniesTerseApiArg = {
  search?: string;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
};
export type GetApiCompaniesAllTerseApiResponse =
  /** status 200 Success */ KrakenBackendApiModelsCompaniesCompanyTerse[];
export type GetApiCompaniesAllTerseApiArg = {
  search?: string;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
};
export type PostApiCompaniesOldApiResponse = /** status 200 Success */ string;
export type PostApiCompaniesOldApiArg = {
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
  /** Name and logo for new company; logo should be empty */
  krakenBackendApiModelsCompaniesApiNewCompany: KrakenBackendApiModelsCompaniesApiNewCompany;
};
export type PostApiCompaniesNameonlyApiResponse =
  /** status 200 Success */ string;
export type PostApiCompaniesNameonlyApiArg = {
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
  /** Name for the new company */
  body: string;
};
export type PutApiCompaniesByCidNameApiResponse = unknown;
export type PutApiCompaniesByCidNameApiArg = {
  /** ID of company to update */
  cid: string;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
  /** Text of the new name */
  body: string;
};
export type PutApiCompaniesByCidLogoApiResponse = unknown;
export type PutApiCompaniesByCidLogoApiArg = {
  /** ID of the company to update */
  cid: string;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
  body: {
    logo?: Blob;
  };
};
export type GetApiCompaniesByCidLogoApiResponse = unknown;
export type GetApiCompaniesByCidLogoApiArg = {
  /** ID of the company to update */
  cid: string;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
};
export type GetApiV2CompaniesByCidApiResponse =
  /** status 200 Success */ KrakenModelsDtoCompanyCompanyDto;
export type GetApiV2CompaniesByCidApiArg = {
  cid: string;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
};
export type PutApiV2CompaniesByCidApiResponse = unknown;
export type PutApiV2CompaniesByCidApiArg = {
  cid: string;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
  krakenModelsDtoCompanyUpdateCompanyDto: KrakenModelsDtoCompanyUpdateCompanyDto;
};
export type GetApiComponentsByPidApiResponse =
  /** status 200 Success */ KrakenBackendApiModelsProjectComponentsV1ProjectComponentsDetailed[];
export type GetApiComponentsByPidApiArg = {
  pid: string;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
};
export type PostApiComponentsByPidApiResponse =
  /** status 200 Success */ string;
export type PostApiComponentsByPidApiArg = {
  pid: string;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
  krakenBackendApiModelsProjectComponentsApiProjectComponent: KrakenBackendApiModelsProjectComponentsApiProjectComponent;
};
export type GetApiComponentsByComponentIdDetailApiResponse =
  /** status 200 Success */ KrakenModelsDatabaseViewsViewProjectComponentsDetailed;
export type GetApiComponentsByComponentIdDetailApiArg = {
  componentId: string;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
};
export type GetApiComponentsByPidInactiveApiResponse =
  /** status 200 Success */ KrakenModelsDatabaseViewsViewProjectComponentsDetailed[];
export type GetApiComponentsByPidInactiveApiArg = {
  pid: string;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
};
export type PutApiComponentsByCidApiResponse = unknown;
export type PutApiComponentsByCidApiArg = {
  /** ID of the component to update */
  cid: string;
  /** If leaving a review at the same time, type of review to leave (optional) */
  reviewType?: KrakenModelsEnumsReviewType;
  /** If leaving a review at the same time, outcome of the review; null if not leaving a review */
  reviewOutcome?: KrakenModelsEnumsReviewOutcome;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
  /** Content of the component to update */
  krakenBackendApiModelsProjectComponentsApiProjectComponent: KrakenBackendApiModelsProjectComponentsApiProjectComponent;
};
export type DeleteApiComponentsByCidApiResponse = unknown;
export type DeleteApiComponentsByCidApiArg = {
  cid: string;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
};
export type PutApiComponentsByCidUndeleteApiResponse = unknown;
export type PutApiComponentsByCidUndeleteApiArg = {
  cid: string;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
};
export type PostApiComponentsMergeBySrcIntoAndDstApiResponse = unknown;
export type PostApiComponentsMergeBySrcIntoAndDstApiArg = {
  src: string;
  dst: string;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
};
export type PutApiComponentsByCidAddActivitiesFromStereotypesApiResponse =
  /** status 200 Success */ string[];
export type PutApiComponentsByCidAddActivitiesFromStereotypesApiArg = {
  /** Project component ID */
  cid: string;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
  /** Stereotype ID list */
  body: string[];
};
export type PutApiComponentsByCidAddActivitiesFromTemplatesApiResponse =
  /** status 200 Success */ string[];
export type PutApiComponentsByCidAddActivitiesFromTemplatesApiArg = {
  /** Project component ID */
  cid: string;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
  /** Activity template ID list */
  body: string[];
};
export type DeleteApiComponentsByCidStereotypeAndSidApiResponse = unknown;
export type DeleteApiComponentsByCidStereotypeAndSidApiArg = {
  /** Project component ID */
  cid: string;
  /** Stereotype ID */
  sid: string;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
};
export type GetApiV2ComponentsByCidApiResponse =
  /** status 200 Success */ KrakenModelsDtoComponentComponentsDto;
export type GetApiV2ComponentsByCidApiArg = {
  cid: string;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
};
export type GetApiConfigByItemApiResponse = /** status 200 Success */ string;
export type GetApiConfigByItemApiArg = {
  /** Configuration key */
  item: string;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
};
export type PutApiConfigByItemApiResponse = unknown;
export type PutApiConfigByItemApiArg = {
  /** Configuration key */
  item: string;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
  /** Item value */
  body: string;
};
export type GetApiConfigApiResponse = /** status 200 Success */ {
  [key: string]: string;
};
export type GetApiConfigApiArg = {
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
};
export type GetApiConfigUserByItemApiResponse =
  /** status 200 Success */ string;
export type GetApiConfigUserByItemApiArg = {
  /** Configuration key */
  item: string;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
};
export type PutApiConfigUserByItemApiResponse = unknown;
export type PutApiConfigUserByItemApiArg = {
  /** Configuration key */
  item: string;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
  /** Item value */
  body: string;
};
export type GetApiConfigUserApiResponse = /** status 200 Success */ {
  [key: string]: string;
};
export type GetApiConfigUserApiArg = {
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
};
export type PutApiConfigUserApiResponse = unknown;
export type PutApiConfigUserApiArg = {
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
  krakenModelsDtoSetUserConfigDto: KrakenModelsDtoSetUserConfigDto;
};
export type GetApiCweApiResponse =
  /** status 200 Success */ KrakenModelsDtoCweCweItemResult[];
export type GetApiCweApiArg = {
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
};
export type GetApiEmailJobDailyEmailApiResponse =
  /** status 200 Success */ KrakenBackendReportingEmailReportDailyReportEmail[];
export type GetApiEmailJobDailyEmailApiArg = {
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
};
export type GetApiEmailJobDailyEmailRenderApiResponse = unknown;
export type GetApiEmailJobDailyEmailRenderApiArg = {
  interim?: boolean;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
};
export type GetApiEmailJobDailyEmailRenderAllApiResponse = unknown;
export type GetApiEmailJobDailyEmailRenderAllApiArg = {
  interim?: boolean;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
};
export type GetApiEmailJobDailyEmailRenderByHidApiResponse = unknown;
export type GetApiEmailJobDailyEmailRenderByHidApiArg = {
  hid: string;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
};
export type PostApiEmailJobDailyEmailSendAllApiResponse = unknown;
export type PostApiEmailJobDailyEmailSendAllApiArg = {
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
};
export type PostApiEmailJobDailyEmailSendAdminInterimApiResponse = unknown;
export type PostApiEmailJobDailyEmailSendAdminInterimApiArg = {
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
};
export type GetApiRouteDebugApiResponse =
  /** status 200 Success */ KrakenBackendControllersEnvironmentControllerRouteModel[];
export type GetApiRouteDebugApiArg = {
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
};
export type GetApiFinalReportByPidApiResponse = unknown;
export type GetApiFinalReportByPidApiArg = {
  pid: string;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
};
export type PostApiFinalReportByPidV2ApiResponse = unknown;
export type PostApiFinalReportByPidV2ApiArg = {
  pid: string;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
  krakenModelsDtoFinalReportConfigurableDetails: KrakenModelsDtoFinalReportConfigurableDetails;
};
export type GetApiFinalReportEditableInfoByPidApiResponse =
  /** status 200 Success */ KrakenModelsDtoFinalReportConfigurableDetails;
export type GetApiFinalReportEditableInfoByPidApiArg = {
  pid: string;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
};
export type PostApiFindingReviewsByFidApiResponse = unknown;
export type PostApiFindingReviewsByFidApiArg = {
  fid: string;
  type?: KrakenModelsEnumsReviewType;
  outcome?: KrakenModelsEnumsReviewOutcome;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
};
export type GetApiFindingReviewsByFidApiResponse =
  /** status 200 Success */ KrakenModelsDatabaseViewsFindingReview[];
export type GetApiFindingReviewsByFidApiArg = {
  fid: string;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
};
export type GetApiFindingReviewsByFidLastApiResponse =
  /** status 200 Success */ KrakenModelsDatabaseViewsFindingReview;
export type GetApiFindingReviewsByFidLastApiArg = {
  fid: string;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
};
export type GetApiFindingReviewsByFidLastSetApiResponse =
  /** status 200 Success */ KrakenModelsDatabaseViewsFindingReview[];
export type GetApiFindingReviewsByFidLastSetApiArg = {
  fid: string;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
};
export type GetApiFindingsProjectByPidApiResponse =
  /** status 200 Success */ KrakenModelsDtoPagedData601KrakenModelsDtoFindingV1FindingReadDto20KrakenModels20Version100020CultureNeutral20PublicKeyTokenNull;
export type GetApiFindingsProjectByPidApiArg = {
  /** ID of the project to get findings for */
  pid: string;
  /** Sorting order */
  sort?: KrakenModelsDtoOrderItem601KrakenModelsDtoFindingColumn20KrakenModels20Version100020CultureNeutral20PublicKeyTokenNull[];
  /** Page No, > 1 */
  page?: number;
  /** Page size, should be more than 1 and less than 50(including) */
  pageSize?: number;
  search?: string;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
};
export type GetApiFindingsProjectByPidFallbackApiResponse =
  /** status 200 Success */ KrakenModelsDtoFindingV1FindingReadDto[];
export type GetApiFindingsProjectByPidFallbackApiArg = {
  pid: string;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
};
export type GetApiFindingsProjectByPidInactiveApiResponse =
  /** status 200 Success */ KrakenBackendModelsViewFindingDetailedWeb[];
export type GetApiFindingsProjectByPidInactiveApiArg = {
  /** ID of the project to get findings for */
  pid: string;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
};
export type GetApiFindingsHumanByHidApiResponse =
  /** status 200 Success */ KrakenModelsDtoFindingGetShortFindingDto[];
export type GetApiFindingsHumanByHidApiArg = {
  hid: string;
  projectId?: string;
  risk?: KrakenModelsFindingHelpersRisk;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
};
export type GetApiFindingsAnnualHumanByHidApiResponse =
  /** status 200 Success */ KrakenBackendModelsViewFindingDetailedWeb[];
export type GetApiFindingsAnnualHumanByHidApiArg = {
  /** ID of the human to get findings for */
  hid: string;
  threshold?: number;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
};
export type GetApiFindingsAnnualByLimitApiResponse =
  /** status 200 Success */ KrakenBackendModelsViewFindingDetailedWeb[];
export type GetApiFindingsAnnualByLimitApiArg = {
  /** Number of findings to get */
  limit: number;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
};
export type GetApiFindingsReviewAllApiResponse =
  /** status 200 Success */ KrakenBackendModelsViewFindingDetailedWeb[];
export type GetApiFindingsReviewAllApiArg = {
  /** Type of review to check for */
  type?: KrakenModelsEnumsReviewType;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
};
export type GetApiFindingsReviewByPidApiResponse =
  /** status 200 Success */ KrakenBackendModelsViewFindingDetailedWeb[];
export type GetApiFindingsReviewByPidApiArg = {
  /** Project ID to get reviewable findings for */
  pid: string;
  /** Type of review to check for */
  type?: KrakenModelsEnumsReviewType;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
};
export type GetApiFindingsNeedsWorkCountApiResponse =
  /** status 200 Success */ number;
export type GetApiFindingsNeedsWorkCountApiArg = {
  /** Count only active findings; if false, inactive findings are returned */
  active?: boolean;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
};
export type GetApiFindingsNeedsWorkApiResponse =
  /** status 200 Success */ KrakenModelsDtoFindingFindingNeedsWorkDto[];
export type GetApiFindingsNeedsWorkApiArg = {
  /** Get only active findings; if false, inactive findings are returned */
  active?: boolean;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
};
export type GetApiFindingsByFidApiResponse =
  /** status 200 Success */ KrakenModelsDatabaseViewsViewFindingDetailed;
export type GetApiFindingsByFidApiArg = {
  /** ID of the finding to get */
  fid: string;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
};
export type DeleteApiFindingsByFidApiResponse = unknown;
export type DeleteApiFindingsByFidApiArg = {
  /** ID of the finding to delete */
  fid: string;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
};
export type PutApiFindingsByFidApiResponse = unknown;
export type PutApiFindingsByFidApiArg = {
  /** Finding ID to modify */
  fid: string;
  /** Whether to write a finding update record, which will cause the finding to appear in status
    updates generated since the time of edit.  If the finding is making a state transition between a reportable and
    non-reportable state or a risk treatment state and a non-risk-treatment state ("updateable transition"), this is forced
    true. */
  materialChange?: boolean;
  /** If leaving a review at the same time, type of review to leave (optional) */
  reviewType?: KrakenModelsEnumsReviewType;
  /** If leaving a review at the same time, outcome of the review; null if not leaving a review */
  reviewOutcome?: KrakenModelsEnumsReviewOutcome;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
  /** Full body of the finding; existing values must be included verbatim if unmodified */
  krakenBackendApiModelsFindingsApiFinding: KrakenBackendApiModelsFindingsApiFinding;
};
export type GetApiFindingsRefByFindingRefApiResponse =
  /** status 200 Success */ KrakenModelsDatabaseViewsViewFindingDetailed;
export type GetApiFindingsRefByFindingRefApiArg = {
  findingRef: number;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
};
export type GetApiFindingsByFidTerseApiResponse =
  /** status 200 Success */ KrakenModelsDatabaseTablesFindings;
export type GetApiFindingsByFidTerseApiArg = {
  /** ID of the finding to get */
  fid: string;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
};
export type PutApiFindingsByFidUndeleteApiResponse = unknown;
export type PutApiFindingsByFidUndeleteApiArg = {
  /** ID of the finding to undelete */
  fid: string;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
};
export type PostApiFindingsApiResponse = /** status 200 Success */ string;
export type PostApiFindingsApiArg = {
  /** Require that the finding be created with a particular GUID */
  forceGuid?: string;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
  /** Body of the finding */
  krakenBackendApiModelsFindingsApiFinding: KrakenBackendApiModelsFindingsApiFinding;
};
export type GetApiFindingsByFidActivitiesApiResponse =
  /** status 200 Success */ string[];
export type GetApiFindingsByFidActivitiesApiArg = {
  /** Finding ID */
  fid: string;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
};
export type GetApiV2FindingsProjectByPidApiResponse =
  /** status 200 Success */ KrakenModelsDtoPagedData601KrakenModelsDtoFindingFindingDto20KrakenModels20Version100020CultureNeutral20PublicKeyTokenNull;
export type GetApiV2FindingsProjectByPidApiArg = {
  /** ID of the project to get findings for */
  pid: string;
  /** Page No, > 1 */
  page?: number;
  /** Page size, should be more than 1 and less than 50(including) */
  pageSize?: number;
  /** Search by summary */
  search?: string;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
};
export type GetApiV2FindingsProjectByPidFallbackApiResponse = unknown;
export type GetApiV2FindingsProjectByPidFallbackApiArg = {
  pid: string;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
};
export type GetApiV2FindingsProjectByPidInactiveApiResponse = unknown;
export type GetApiV2FindingsProjectByPidInactiveApiArg = {
  /** ID of the project to get findings for */
  pid: string;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
};
export type GetApiV2FindingsAnnualHumanByHidApiResponse = unknown;
export type GetApiV2FindingsAnnualHumanByHidApiArg = {
  /** ID of the human to get findings for */
  hid: string;
  threshold?: number;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
};
export type GetApiV2FindingsAnnualByLimitApiResponse = unknown;
export type GetApiV2FindingsAnnualByLimitApiArg = {
  /** Number of findings to get */
  limit: number;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
};
export type GetApiV2FindingsReviewAllApiResponse = unknown;
export type GetApiV2FindingsReviewAllApiArg = {
  /** Type of review to check for */
  type?: KrakenModelsEnumsReviewType;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
};
export type GetApiV2FindingsReviewByPidApiResponse = unknown;
export type GetApiV2FindingsReviewByPidApiArg = {
  /** Project ID to get reviewable findings for */
  pid: string;
  /** Type of review to check for */
  type?: KrakenModelsEnumsReviewType;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
};
export type GetApiV2FindingsByFidApiResponse = unknown;
export type GetApiV2FindingsByFidApiArg = {
  /** ID of the finding to get */
  fid: string;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
};
export type PutApiV2FindingsByFidApiResponse = unknown;
export type PutApiV2FindingsByFidApiArg = {
  /** Finding ID to modify */
  fid: string;
  /** Whether to write a finding update record, which will cause the finding to appear in status
    updates generated since the time of edit.  If the finding is making a state transition between a reportable and
    non-reportable state or a risk treatment state and a non-risk-treatment state ("updateable transition"), this is forced
    true. */
  materialChange?: boolean;
  /** If leaving a review at the same time, type of review to leave (optional) */
  reviewType?: KrakenModelsEnumsReviewType;
  /** If leaving a review at the same time, outcome of the review; null if not leaving a review */
  reviewOutcome?: KrakenModelsEnumsReviewOutcome;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
  /** Full body of the finding; existing values must be included verbatim if unmodified */
  krakenModelsDtoFindingWriteDto: KrakenModelsDtoFindingWriteDto;
};
export type GetApiV2FindingsRefByFindingRefApiResponse = unknown;
export type GetApiV2FindingsRefByFindingRefApiArg = {
  findingRef: number;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
};
export type PostApiV2FindingsApiResponse = /** status 200 Success */ string;
export type PostApiV2FindingsApiArg = {
  /** Require that the finding be created with a particular GUID */
  forceGuid?: string;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
  /** Body of the finding */
  krakenModelsDtoFindingWriteDto: KrakenModelsDtoFindingWriteDto;
};
export type GetApiV2FindingsImagesByFidApiResponse = unknown;
export type GetApiV2FindingsImagesByFidApiArg = {
  fid: string;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
};
export type PostApiV2FindingsImagesByFidApiResponse = unknown;
export type PostApiV2FindingsImagesByFidApiArg = {
  fid: string;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
  body: {
    file?: Blob;
  };
};
export type GetApiHumansEmployeesApiResponse =
  /** status 200 Success */ KrakenBackendApiModelsHumansApiHuman[];
export type GetApiHumansEmployeesApiArg = {
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
};
export type PostApiHumansApiResponse = /** status 200 Success */ string;
export type PostApiHumansApiArg = {
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
  /** Contents of the record to create */
  krakenBackendApiModelsHumansApiNewHuman: KrakenBackendApiModelsHumansApiNewHuman;
};
export type PutApiHumansByHidApiResponse = unknown;
export type PutApiHumansByHidApiArg = {
  /** ID of the human record to update */
  hid: string;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
  /** Contents of the new human record */
  krakenBackendApiModelsHumansApiNewHuman: KrakenBackendApiModelsHumansApiNewHuman;
};
export type DeleteApiHumansByHidEnabledApiResponse = unknown;
export type DeleteApiHumansByHidEnabledApiArg = {
  hid: string;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
};
export type PutApiHumansByHidEnabledApiResponse = unknown;
export type PutApiHumansByHidEnabledApiArg = {
  hid: string;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
};
export type GetApiJiraProjectsEnabledApiResponse =
  /** status 200 Success */ KrakenBackendApiModelsJiraProjectsJiraEnabledResponse;
export type GetApiJiraProjectsEnabledApiArg = {
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
};
export type GetApiJiraProjectsApiResponse =
  /** status 200 Success */ KrakenModelsDtoJiraJiraProject[];
export type GetApiJiraProjectsApiArg = {
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
};
export type GetApiJiraProjectsByKeyApiResponse =
  /** status 200 Success */ KrakenModelsDtoJiraJiraProject;
export type GetApiJiraProjectsByKeyApiArg = {
  key: string;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
};
export type GetApiLeaderboardFindingCountsApiResponse =
  /** status 200 Success */ KrakenBackendApiModelsFindingsApiFindingLeaderboardCount[];
export type GetApiLeaderboardFindingCountsApiArg = {
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
};
export type GetApiLeaderboardAnnualFindingsTopByLimitApiResponse = unknown;
export type GetApiLeaderboardAnnualFindingsTopByLimitApiArg = {
  limit: number;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
};
export type GetApiLeaderboardMyFindingsSinceByWhenApiResponse = unknown;
export type GetApiLeaderboardMyFindingsSinceByWhenApiArg = {
  when: string;
  hid?: string;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
};
export type GetApiLeaderboardFindingsSinceByWhenApiResponse = unknown;
export type GetApiLeaderboardFindingsSinceByWhenApiArg = {
  when: string;
  threshold?: number;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
};
export type GetApiLeaderboardFindingsCustomByFromAndToApiResponse = unknown;
export type GetApiLeaderboardFindingsCustomByFromAndToApiArg = {
  from: string;
  to: string;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
};
export type GetApiLeaderboardHeatmapHumansByDaysAndHidApiResponse =
  /** status 200 Success */ number[];
export type GetApiLeaderboardHeatmapHumansByDaysAndHidApiArg = {
  /** Number of days */
  days: number;
  /** Human ID to get it for (use "me" for the current user) */
  hid: string;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
};
export type GetApiLeaderboardHeatmapHumansByDaysMeApiResponse =
  /** status 200 Success */ number[];
export type GetApiLeaderboardHeatmapHumansByDaysMeApiArg = {
  /** Number of days */
  days: number;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
};
export type GetApiLeaderboardHeatmapHumansByDaysApiResponse =
  /** status 200 Success */ KrakenModelsDtoApiHeatmap[];
export type GetApiLeaderboardHeatmapHumansByDaysApiArg = {
  /** Number of days */
  days: number;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
};
export type GetApiLeaderboardHeatmapProjectsByDaysAndPidApiResponse =
  /** status 200 Success */ number[];
export type GetApiLeaderboardHeatmapProjectsByDaysAndPidApiArg = {
  days: number;
  pid: string;
  hid?: string;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
};
export type GetApiLeaderboardHeatmapProjectsByDaysApiResponse =
  /** status 200 Success */ KrakenModelsDtoApiHeatmap[];
export type GetApiLeaderboardHeatmapProjectsByDaysApiArg = {
  /** Number of days */
  days: number;
  /** Include only projects the user has a role in; forced to true for users not in the all projects
    group */
  mineOnly?: boolean;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
};
export type GetApiLeaderboardEditsByUidAndFromToBareApiResponse = unknown;
export type GetApiLeaderboardEditsByUidAndFromToBareApiArg = {
  uid: string;
  from: string;
  to: string;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
};
export type GetApiLeaderboardEditsAliasByLoginAndFromToBareApiResponse =
  unknown;
export type GetApiLeaderboardEditsAliasByLoginAndFromToBareApiArg = {
  login: string;
  from: string;
  to: string;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
};
export type GetApiLeaderboardProjectEditsByPidBareApiResponse = unknown;
export type GetApiLeaderboardProjectEditsByPidBareApiArg = {
  pid: string;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
};
export type GetApiLeaderboardGoogleRestrictedScopeDigestBySinceApiResponse =
  unknown;
export type GetApiLeaderboardGoogleRestrictedScopeDigestBySinceApiArg = {
  since: string;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
};
export type PostApiProjectfilesByProjectIdApiResponse = unknown;
export type PostApiProjectfilesByProjectIdApiArg = {
  projectId: string;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
  body: {
    file?: Blob;
  };
};
export type GetApiProjectfilesProjectByPidApiResponse =
  /** status 200 Success */ KrakenToolsIoFilesFile[];
export type GetApiProjectfilesProjectByPidApiArg = {
  pid: string;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
};
export type GetApiProjectfilesByIdApiResponse = unknown;
export type GetApiProjectfilesByIdApiArg = {
  id: number;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
};
export type DeleteApiProjectfilesByIdApiResponse = unknown;
export type DeleteApiProjectfilesByIdApiArg = {
  id: number;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
};
export type GetApiProjectsApiResponse =
  /** status 200 Success */ KrakenModelsDtoProjectWithEditInfoQueryResult[];
export type GetApiProjectsApiArg = {
  /** Get only active projects; if false, inactive projects are returned */
  active?: boolean;
  /** Get only projects in which the authenticated user has a role; for users without AllProjectsRole,
    setting false has no effect */
  mine?: boolean;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
};
export type PostApiProjectsApiResponse = /** status 200 Success */ string;
export type PostApiProjectsApiArg = {
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
  /** Fields of the project to create */
  krakenModelsDtoProjectDto: KrakenModelsDtoProjectDto;
};
export type GetApiProjectsV2ApiResponse =
  /** status 200 Success */ KrakenModelsDtoProjectWithEditInfoQueryResult[];
export type GetApiProjectsV2ApiArg = {
  /** Get only active projects; if false, inactive projects are returned */
  active?: boolean;
  /** Get only projects in which the authenticated user has a role; for users without AllProjectsRole,
    setting false has no effect */
  mine?: boolean;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
};
export type GetApiProjectsDetailedApiResponse =
  /** status 200 Success */ KrakenModelsDtoProjectQueryResult[];
export type GetApiProjectsDetailedApiArg = {
  /** Get only active projects; if false, inactive projects are returned */
  active?: boolean;
  /** Get only projects in which the authenticated user has a role; for users without AllProjectsRole,
    setting false has no effect */
  mine?: boolean;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
};
export type GetApiProjectsByPidApiResponse =
  /** status 200 Success */ KrakenModelsDtoProjectQueryResult;
export type GetApiProjectsByPidApiArg = {
  /** Project ID to get */
  pid: string;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
};
export type PutApiProjectsByPidApiResponse = unknown;
export type PutApiProjectsByPidApiArg = {
  /** ID of the project to update */
  pid: string;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
  /** New values for fields of the project.  Unchanged values must be sent verbatim. */
  krakenModelsDtoProjectDto: KrakenModelsDtoProjectDto;
};
export type DeleteApiProjectsByPidApiResponse = unknown;
export type DeleteApiProjectsByPidApiArg = {
  /** ID of the project to mark inactive */
  pid: string;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
};
export type GetApiProjectsByPidDetailedApiResponse =
  /** status 200 Success */ KrakenModelsDtoProjectQueryResult;
export type GetApiProjectsByPidDetailedApiArg = {
  /** Project ID to get */
  pid: string;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
};
export type PutApiProjectsByPidDatesApiResponse = unknown;
export type PutApiProjectsByPidDatesApiArg = {
  /** ID of the project to update */
  pid: string;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
  /** New project start and end date */
  krakenBackendApiModelsProjectsProjectDates: KrakenBackendApiModelsProjectsProjectDates;
};
export type DeleteApiProjectsDeleteByPidApiResponse = unknown;
export type DeleteApiProjectsDeleteByPidApiArg = {
  /** Project Id */
  pid: string;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
};
export type PutApiProjectsByPidUndeleteApiResponse = unknown;
export type PutApiProjectsByPidUndeleteApiArg = {
  /** ID of the project to reactivate */
  pid: string;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
};
export type GetApiProjectsByPidRolesApiResponse =
  /** status 200 Success */ KrakenModelsDtoProjectRole[];
export type GetApiProjectsByPidRolesApiArg = {
  /** Project ID to query */
  pid: string;
  /** Optional: Human ID filter by specific human */
  hid?: string;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
};
export type PutApiProjectsByPidRolesApiResponse = unknown;
export type PutApiProjectsByPidRolesApiArg = {
  /** Project ID to grant access to */
  pid: string;
  /** Human ID of the person to grant access */
  hid?: string;
  /** Role ID for the access to grant */
  rid?: string;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
};
export type DeleteApiProjectsByPidRolesApiResponse = unknown;
export type DeleteApiProjectsByPidRolesApiArg = {
  /** Project ID to act on */
  pid: string;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
  /** Array humanId with roleId */
  body: KrakenModelsDtoDeleteUserRoleDto[];
};
export type DeleteApiProjectsByPidRolesAndHidApiResponse = unknown;
export type DeleteApiProjectsByPidRolesAndHidApiArg = {
  /** Project ID to act on */
  pid: string;
  /** Human ID of the person whose roles to revoke */
  hid: string;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
};
export type DeleteApiProjectsByPidRolesAndHidRidApiResponse = unknown;
export type DeleteApiProjectsByPidRolesAndHidRidApiArg = {
  /** Project ID to act on */
  pid: string;
  /** Human ID of the person whose role to delete */
  hid: string;
  /** ID of the role to delete from that human on that project */
  rid: string;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
};
export type GetApiProjectsByPidTemporaryAccessApiResponse =
  /** status 200 Success */ KrakenBackendApiModelsTemporaryAccessTemporaryAccessGrant[];
export type GetApiProjectsByPidTemporaryAccessApiArg = {
  /** Project ID to get grants for */
  pid: string;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
};
export type DeleteApiProjectsByPidTemporaryAccessApiResponse = unknown;
export type DeleteApiProjectsByPidTemporaryAccessApiArg = {
  /** Project ID to revoke access to */
  pid: string;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
  /** Array individual whose access to revoke */
  body: string[];
};
export type PostApiProjectsByPidTemporaryAccessAndHidApiResponse = unknown;
export type PostApiProjectsByPidTemporaryAccessAndHidApiArg = {
  /** Project ID to grant access to */
  pid: string;
  /** Individual to give access */
  hid: string;
  /** Earliest time to allow access; defaults to the current time */
  notBefore?: string;
  /** Expiration time */
  notAfter?: string;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
};
export type PutApiProjectsByPidTemporaryAccessAndHidApiResponse = unknown;
export type PutApiProjectsByPidTemporaryAccessAndHidApiArg = {
  /** Project ID to update access for */
  pid: string;
  /** Individual to update access for */
  hid: string;
  /** Start time of the grant to update */
  notBefore?: string;
  /** End time of the grant to update */
  notAfter?: string;
  /** New end time for the grant */
  newNotAfter?: string;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
};
export type DeleteApiProjectsByPidTemporaryAccessAndHidApiResponse = unknown;
export type DeleteApiProjectsByPidTemporaryAccessAndHidApiArg = {
  /** Project ID to revoke access to */
  pid: string;
  /** Individual whose access to revoke */
  hid: string;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
};
export type GetApiProjectsByPidFindingActivityBindingApiResponse =
  /** status 200 Success */ KrakenBackendApiModelsActivitiesTerseFindingActivityBinding[];
export type GetApiProjectsByPidFindingActivityBindingApiArg = {
  /** Project ID */
  pid: string;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
};
export type GetApiProjectsByPidLastEditDateApiResponse =
  /** status 200 Success */ string;
export type GetApiProjectsByPidLastEditDateApiArg = {
  /** Project ID to query for */
  pid: string;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
};
export type GetApiProjectsLateEditsApiResponse =
  /** status 200 Success */ KrakenModelsDtoLateProjectRecord[];
export type GetApiProjectsLateEditsApiArg = {
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
};
export type DeleteApiProjectsOldApiResponse = /** status 200 Success */ number;
export type DeleteApiProjectsOldApiArg = {
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
};
export type GetApiProjectsMavenlinkByMidApiResponse =
  /** status 200 Success */ KrakenModelsDtoProjectQueryResult;
export type GetApiProjectsMavenlinkByMidApiArg = {
  /** Mavenlink ID to query */
  mid: number;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
};
export type GetApiProjectsClarizenByClarizenIdApiResponse =
  /** status 200 Success */ KrakenModelsDtoProjectQueryResult;
export type GetApiProjectsClarizenByClarizenIdApiArg = {
  clarizenId: number;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
};
export type GetApiProjectsActiveProjectsForUserByHidApiResponse =
  /** status 200 Success */ KrakenModelsDtoProjectQueryResult[];
export type GetApiProjectsActiveProjectsForUserByHidApiArg = {
  /** User whose projects to get */
  hid: string;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
};
export type PutApiProjectsByPidExecsummaryApiResponse = unknown;
export type PutApiProjectsByPidExecsummaryApiArg = {
  /** Project ID */
  pid: string;
  /** If leaving a review at the same time, type of review to leave (optional) */
  reviewType?: KrakenModelsEnumsReviewType;
  /** If leaving a review at the same time, outcome of the review; null if not leaving a review */
  reviewOutcome?: KrakenModelsEnumsReviewOutcome;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
  /** New executive summary text */
  krakenBackendApiModelsProjectsApiExecutiveSummary: KrakenBackendApiModelsProjectsApiExecutiveSummary;
};
export type GetApiProjectsByPidExecsummaryApiResponse =
  /** status 200 Success */ KrakenBackendApiModelsProjectsApiExecutiveSummary;
export type GetApiProjectsByPidExecsummaryApiArg = {
  pid: string;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
};
export type PatchApiProjectsByPidLogoApiResponse = unknown;
export type PatchApiProjectsByPidLogoApiArg = {
  /** ID of the project to update */
  pid: string;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
  body: {
    logo?: Blob;
  };
};
export type GetApiProjectsByPidLogoApiResponse = unknown;
export type GetApiProjectsByPidLogoApiArg = {
  /** ID of the project */
  pid: string;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
};
export type GetApiV2ProjectsWithInactiveApiResponse =
  /** status 200 Success */ KrakenModelsDtoDeletedDataProjectWithDeletedDataDto[];
export type GetApiV2ProjectsWithInactiveApiArg = {
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
};
export type GetApiV2ProjectsByPidInactiveFindingsApiResponse =
  /** status 200 Success */ KrakenModelsDtoFindingListDto[];
export type GetApiV2ProjectsByPidInactiveFindingsApiArg = {
  pid: string;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
};
export type GetApiV2ProjectsByPidInactiveActivitiesApiResponse =
  /** status 200 Success */ KrakenModelsDtoActivityListDto[];
export type GetApiV2ProjectsByPidInactiveActivitiesApiArg = {
  pid: string;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
};
export type GetApiV2ProjectsByPidInactiveComponentsApiResponse =
  /** status 200 Success */ KrakenModelsDtoProjectComponentListDto[];
export type GetApiV2ProjectsByPidInactiveComponentsApiArg = {
  pid: string;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
};
export type PostApiQuestionsAnswersByQuestionIdAndVersionApiResponse =
  /** status 202 Record created succesfully */ string;
export type PostApiQuestionsAnswersByQuestionIdAndVersionApiArg = {
  /** Question user is answering */
  questionId: string;
  /** Version of question user is answering */
  version: number;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
  /** Users answer. <joke>SHOULD NOT be 42</joke> */
  krakenBackendApiModelsQuestionsAnswer: KrakenBackendApiModelsQuestionsAnswer;
};
export type PutApiQuestionsAnswersByIdApiResponse =
  /** status 202 Record created succesfully */ undefined;
export type PutApiQuestionsAnswersByIdApiArg = {
  /** ID of answer */
  id: string;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
  /** Users answer. <joke>SHOULD NOT be 42</joke> */
  body: string;
};
export type GetApiQuestionsAnswersProjectByPidApiResponse =
  /** status 200 Success */ KrakenBackendApiModelsQuestionsAnswer[];
export type GetApiQuestionsAnswersProjectByPidApiArg = {
  /** ID of project */
  pid: string;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
};
export type GetApiQuestionsTemplatesApplicableByPidApiResponse =
  /** status 200 Success */ KrakenBackendApiModelsQuestionsTemplate[];
export type GetApiQuestionsTemplatesApplicableByPidApiArg = {
  /** Project Id */
  pid: string;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
};
export type GetApiQuestionsTemplatesActiveApiResponse =
  /** status 200 Success */ KrakenBackendApiModelsQuestionsTemplate[];
export type GetApiQuestionsTemplatesActiveApiArg = {
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
};
export type PostApiQuestionsTemplatesApiResponse =
  /** status 202 Record created succesfully */ string;
export type PostApiQuestionsTemplatesApiArg = {
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
  /** Question text and type to ask */
  krakenBackendApiModelsQuestionsBasicTemplate: KrakenBackendApiModelsQuestionsBasicTemplate;
};
export type PutApiQuestionsTemplatesByIdApiResponse = unknown;
export type PutApiQuestionsTemplatesByIdApiArg = {
  /** ID of template to modify */
  id: string;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
  /** Modified template */
  krakenBackendApiModelsQuestionsTemplate: KrakenBackendApiModelsQuestionsTemplate;
};
export type DeleteApiQuestionsTemplatesByIdApiResponse = unknown;
export type DeleteApiQuestionsTemplatesByIdApiArg = {
  /** ID of template to modify */
  id: string;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
};
export type GetApiQuestionsContextActiveApiResponse =
  /** status 200 Success */ KrakenBackendApiModelsQuestionsContext[];
export type GetApiQuestionsContextActiveApiArg = {
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
};
export type PutApiQuestionsContextByIdApiResponse = unknown;
export type PutApiQuestionsContextByIdApiArg = {
  /** Context ID */
  id: string;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
  /** Context data */
  krakenBackendApiModelsQuestionsContext: KrakenBackendApiModelsQuestionsContext;
};
export type DeleteApiQuestionsContextByIdApiResponse = unknown;
export type DeleteApiQuestionsContextByIdApiArg = {
  /** Context ID */
  id: string;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
};
export type PostApiQuestionsContextApiResponse =
  /** status 200 Success */ string;
export type PostApiQuestionsContextApiArg = {
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
  /** New Context */
  krakenBackendApiModelsQuestionsContext: KrakenBackendApiModelsQuestionsContext;
};
export type GetApiQuestionsOutstandingApiResponse =
  /** status 200 Success */ KrakenBackendApiModelsQuestionsProjectUnansweredQuestions[];
export type GetApiQuestionsOutstandingApiArg = {
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
};
export type GetApiRawBugsXmlByPidApiResponse = unknown;
export type GetApiRawBugsXmlByPidApiArg = {
  pid: string;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
};
export type GetApiRawBugsAwsJsonByPidApiResponse = unknown;
export type GetApiRawBugsAwsJsonByPidApiArg = {
  pid: string;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
};
export type GetApiReportsAvailableFontsApiResponse =
  /** status 200 Success */ KrakenModelsReportsFontName[];
export type GetApiReportsAvailableFontsApiArg = {
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
};
export type GetApiReviewablesEditHistoryByRidApiResponse =
  /** status 200 Success */ KrakenModelsDtoEditHistoryDto[];
export type GetApiReviewablesEditHistoryByRidApiArg = {
  /** ID of the object (can be finding, activity, component, or project ID) */
  rid: string;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
};
export type GetApiReviewablesEditHistoryProjectByPidApiResponse =
  /** status 200 Success */ KrakenModelsDtoEditHistoryDto[];
export type GetApiReviewablesEditHistoryProjectByPidApiArg = {
  /** Project ID */
  pid: string;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
};
export type GetApiReviewablesOversightApiResponse =
  /** status 200 Success */ KrakenBackendApiModelsReviewableReviewableReference;
export type GetApiReviewablesOversightApiArg = {
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
};
export type GetApiReviewablesFindByRidApiResponse =
  /** status 200 Success */ KrakenBackendApiModelsReviewableReviewableReference;
export type GetApiReviewablesFindByRidApiArg = {
  /** Reviewable ID to query */
  rid: string;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
};
export type GetApiV2ReviewablesEditHistoryByRidApiResponse =
  /** status 200 Success */ KrakenModelsDtoEditHistoryV2Dto[];
export type GetApiV2ReviewablesEditHistoryByRidApiArg = {
  rid: string;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
};
export type PostApiReviewsFindingByFidApiResponse = unknown;
export type PostApiReviewsFindingByFidApiArg = {
  fid: string;
  type?: KrakenModelsEnumsReviewType;
  outcome?: KrakenModelsEnumsReviewOutcome;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
};
export type PostApiReviewsActivityByAidApiResponse = unknown;
export type PostApiReviewsActivityByAidApiArg = {
  aid: string;
  type?: KrakenModelsEnumsReviewType;
  outcome?: KrakenModelsEnumsReviewOutcome;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
};
export type PostApiReviewsComponentByPcidApiResponse = unknown;
export type PostApiReviewsComponentByPcidApiArg = {
  pcid: string;
  type?: KrakenModelsEnumsReviewType;
  outcome?: KrakenModelsEnumsReviewOutcome;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
};
export type PostApiReviewsProjectByPidApiResponse = unknown;
export type PostApiReviewsProjectByPidApiArg = {
  pid: string;
  type?: KrakenModelsEnumsReviewType;
  outcome?: KrakenModelsEnumsReviewOutcome;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
};
export type GetApiReviewsByProjectByPidApiResponse =
  /** status 200 Success */ KrakenModelsDatabaseViewsViewReviewsAbstract[];
export type GetApiReviewsByProjectByPidApiArg = {
  pid: string;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
};
export type GetApiReviewsByProjectByPidCompleteApiResponse =
  /** status 200 Success */ KrakenBackendApiModelsReviewsReviewRecord[];
export type GetApiReviewsByProjectByPidCompleteApiArg = {
  /** Project ID */
  pid: string;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
};
export type GetApiReviewsByRidByRidCompleteApiResponse =
  /** status 200 Success */ KrakenBackendApiModelsReviewsReviewRecord[];
export type GetApiReviewsByRidByRidCompleteApiArg = {
  rid: string;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
};
export type GetApiReviewsByRidApiResponse =
  /** status 200 Success */ KrakenModelsDatabaseViewsViewReviewsAbstract[];
export type GetApiReviewsByRidApiArg = {
  rid: string;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
};
export type GetApiV2ReviewsLastReviewByFidApiResponse =
  /** status 200 Success */ KrakenModelsDtoFindingFindingReadDto;
export type GetApiV2ReviewsLastReviewByFidApiArg = {
  fid: string;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
};
export type GetApiRolesApiResponse =
  /** status 200 Success */ KrakenModelsDatabaseTablesProjectrole[];
export type GetApiRolesApiArg = {
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
};
export type PostApiRolesApiResponse = /** status 200 Success */ string;
export type PostApiRolesApiArg = {
  name?: string;
  desc?: string;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
};
export type DeleteApiRolesByRidApiResponse = unknown;
export type DeleteApiRolesByRidApiArg = {
  rid: string;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
};
export type PutApiRolesByRidApiResponse = unknown;
export type PutApiRolesByRidApiArg = {
  rid: string;
  desc?: string;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
};
export type GetApiRulesProjectByPidApiResponse =
  /** status 200 Success */ KrakenBackendApiModelsRulesViolation[];
export type GetApiRulesProjectByPidApiArg = {
  /** Project ID */
  pid: string;
  /** Apply final report rules */
  final?: boolean;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
};
export type GetApiRulesComponentByPcidApiResponse =
  /** status 200 Success */ KrakenBackendApiModelsRulesViolation[];
export type GetApiRulesComponentByPcidApiArg = {
  /** Project ID */
  pcid: string;
  /** Apply AWS rules */
  aws?: boolean;
  /** Apply final report rules */
  final?: boolean;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
};
export type GetApiRulesFindingByFidApiResponse =
  /** status 200 Success */ KrakenBackendApiModelsRulesViolation[];
export type GetApiRulesFindingByFidApiArg = {
  /** Finding ID */
  fid: string;
  /** Apply AWS rules */
  aws?: boolean;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
};
export type GetApiRulesActivityByAidApiResponse =
  /** status 200 Success */ KrakenBackendApiModelsRulesViolation[];
export type GetApiRulesActivityByAidApiArg = {
  /** Activity ID */
  aid: string;
  /** Apply final report rules */
  final?: boolean;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
};
export type GetApiStatisticsFindingsCountApiResponse =
  /** status 200 Success */ KrakenModelsDtoFindingFindingCountDto[];
export type GetApiStatisticsFindingsCountApiArg = {
  humanId?: string;
  projectId?: string;
  startDate?: string;
  endDate?: string;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
};
export type GetApiStatusReportByPidAndLastStatusApiResponse = unknown;
export type GetApiStatusReportByPidAndLastStatusApiArg = {
  pid: string;
  lastStatus: string;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
};
export type GetApiStatusReportByPidApiResponse = unknown;
export type GetApiStatusReportByPidApiArg = {
  pid: string;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
};
export type PostApiStatusReportV2ApiResponse = unknown;
export type PostApiStatusReportV2ApiArg = {
  pid?: string;
  lastStatus?: string;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
  krakenModelsDtoStatusReportConfigurableDetails: KrakenModelsDtoStatusReportConfigurableDetails;
};
export type GetApiStatusReportEditableInfoApiResponse =
  /** status 200 Success */ KrakenModelsDtoStatusReportConfigurableDetails;
export type GetApiStatusReportEditableInfoApiArg = {
  pid?: string;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
};
export type GetApiStereotypesApiResponse =
  /** status 200 Success */ KrakenBackendApiModelsStereotypesApiStereotypeAssociated[];
export type GetApiStereotypesApiArg = {
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
};
export type PostApiStereotypesApiResponse = /** status 200 Success */ string;
export type PostApiStereotypesApiArg = {
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
  krakenBackendApiModelsStereotypesApiStereotype: KrakenBackendApiModelsStereotypesApiStereotype;
};
export type GetApiStereotypesBySidApiResponse =
  /** status 200 Success */ KrakenBackendApiModelsStereotypesApiStereotypeAssociated;
export type GetApiStereotypesBySidApiArg = {
  sid: string;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
};
export type PutApiStereotypesBySidApiResponse = unknown;
export type PutApiStereotypesBySidApiArg = {
  sid: string;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
  krakenBackendApiModelsStereotypesApiStereotype: KrakenBackendApiModelsStereotypesApiStereotype;
};
export type DeleteApiStereotypesBySidApiResponse = unknown;
export type DeleteApiStereotypesBySidApiArg = {
  sid: string;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
};
export type GetApiStereotypesBySidActivityTemplatesApiResponse =
  /** status 200 Success */ KrakenModelsDatabaseTablesActivityTemplate[];
export type GetApiStereotypesBySidActivityTemplatesApiArg = {
  sid: string;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
};
export type GetApiStereotypesByProjectByPidApiResponse =
  /** status 200 Success */ KrakenModelsDatabaseViewsViewProjectComponentXStereotypes[];
export type GetApiStereotypesByProjectByPidApiArg = {
  pid: string;
  componentId?: string;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
};
export type GetApiSystemVersionApiResponse = unknown;
export type GetApiSystemVersionApiArg = {
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
};
export type GetApiSystemFindingStatusApiResponse = /** status 200 Success */ {
  [key: string]: string;
};
export type GetApiSystemFindingStatusApiArg = {
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
};
export type GetApiSystemSignalrConfigApiResponse = unknown;
export type GetApiSystemSignalrConfigApiArg = {
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
};
export type PostApiV2TeamApiResponse = /** status 200 Success */ string;
export type PostApiV2TeamApiArg = {
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
  krakenModelsDtoTeamTeamDto: KrakenModelsDtoTeamTeamDto;
};
export type GetApiV2TeamApiResponse =
  /** status 200 Success */ KrakenModelsDtoTeamGetTeamsDto;
export type GetApiV2TeamApiArg = {
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
};
export type PutApiV2TeamByTeamIdApiResponse = unknown;
export type PutApiV2TeamByTeamIdApiArg = {
  teamId: string;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
  krakenModelsDtoTeamTeamDto: KrakenModelsDtoTeamTeamDto;
};
export type GetApiV2TeamByTeamIdApiResponse =
  /** status 200 Success */ KrakenModelsDtoTeamGetTeamDto;
export type GetApiV2TeamByTeamIdApiArg = {
  teamId: string;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
};
export type DeleteApiV2TeamByTeamIdApiResponse = unknown;
export type DeleteApiV2TeamByTeamIdApiArg = {
  teamId: string;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
};
export type GetApiV2TeamSelectApiResponse =
  /** status 200 Success */ KrakenModelsDtoTeamGetSelectTeamDto[];
export type GetApiV2TeamSelectApiArg = {
  isSystem?: boolean;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
};
export type PostApiV2TeamByTeamIdMemberApiResponse = unknown;
export type PostApiV2TeamByTeamIdMemberApiArg = {
  teamId: string;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
  body: string[];
};
export type DeleteApiV2TeamByTeamIdMemberApiResponse = unknown;
export type DeleteApiV2TeamByTeamIdMemberApiArg = {
  teamId: string;
  humanId?: string;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
};
export type GetApiUserPreferencesApiResponse =
  /** status 200 Success */ KrakenBackendControllersUserPreferencesControllerApiUserPreferences;
export type GetApiUserPreferencesApiArg = {
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
};
export type GetApiVulnerabilitiesActiveApiResponse =
  /** status 200 Success */ KrakenModelsDatabaseTablesVulnerabilities[];
export type GetApiVulnerabilitiesActiveApiArg = {
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
};
export type GetApiVulnerabilitiesInactiveApiResponse =
  /** status 200 Success */ KrakenModelsDatabaseTablesVulnerabilities[];
export type GetApiVulnerabilitiesInactiveApiArg = {
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
};
export type GetApiVulnerabilitiesByVidApiResponse =
  /** status 200 Success */ KrakenModelsDatabaseTablesVulnerabilities;
export type GetApiVulnerabilitiesByVidApiArg = {
  vid: string;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
};
export type DeleteApiVulnerabilitiesByVidApiResponse = unknown;
export type DeleteApiVulnerabilitiesByVidApiArg = {
  vid: string;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
};
export type PutApiVulnerabilitiesByVidUndeleteApiResponse = unknown;
export type PutApiVulnerabilitiesByVidUndeleteApiArg = {
  vid: string;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
};
export type PutApiVulnerabilitiesApiResponse = unknown;
export type PutApiVulnerabilitiesApiArg = {
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
  krakenModelsDatabaseTablesVulnerabilities: KrakenModelsDatabaseTablesVulnerabilities;
};
export type PostApiVulnerabilitiesApiResponse =
  /** status 200 Success */ string;
export type PostApiVulnerabilitiesApiArg = {
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
  krakenBackendApiModelsVulnerabilitiesApiVulnerability: KrakenBackendApiModelsVulnerabilitiesApiVulnerability;
};
export type PutApiVulnerabilitiesByFtidApiResponse = unknown;
export type PutApiVulnerabilitiesByFtidApiArg = {
  ftid: string;
  /** Set to any value for mutating requests; here to force preflighting on POST. */
  'X-CSRF'?: string;
  krakenModelsDatabaseTablesVulnerabilities: KrakenModelsDatabaseTablesVulnerabilities;
};
export type KrakenBackendUtilityConstantsActivityStatus =
  | 'DEFAULT'
  | 'PROGRESSING'
  | 'BLOCKED'
  | 'QUALIFIED_DONE'
  | 'DONE'
  | 'IMPOSSIBLE';
export type KrakenModelsEnumsActivityCheckListItemStatus =
  | 'NotStarted'
  | 'Tested'
  | 'NotAvailable';
export type KrakenBackendApiModelsActivitiesApiActivityChecklistItem = {
  acid?: string;
  heading?: string | null;
  status?: KrakenModelsEnumsActivityCheckListItemStatus;
  editor?: string | null;
  editTime?: string | null;
};
export type KrakenBackendApiModelsActivitiesApiActivityDetailed = {
  id?: string;
  Subject?: string | null;
  Description?: string | null;
  Disposition?: string | null;
  CreatedDate?: string | null;
  Active?: boolean;
  ActivityTemplateId?: string | null;
  Notes?: string | null;
  Scope?: string | null;
  FutureWork?: string | null;
  Task?: boolean | null;
  forecast_end_date?: string | null;
  forecast_start_date?: string | null;
  forecast_unblock_date?: string | null;
  PcId?: string;
  PcName?: string | null;
  UpdatedAt?: string | null;
  DeclaredStatus?: KrakenBackendUtilityConstantsActivityStatus;
  InferredStatus?: KrakenBackendUtilityConstantsActivityStatus;
  Checklist?: KrakenBackendApiModelsActivitiesApiActivityChecklistItem[] | null;
};
export type KrakenModelsEnumsReviewType =
  | 'PEER'
  | 'TSLEAD'
  | 'TSPM'
  | 'WRITER'
  | 'OVERSIGHT';
export type KrakenModelsEnumsReviewOutcome = 'OK' | 'NEEDSWORK' | 'EXEMPLAR';
export type KrakenBackendApiModelsActivitiesApiActivityNew = {
  Subject?: string | null;
  Description?: string | null;
  Scope?: string | null;
  Notes?: string | null;
  Disposition?: string | null;
  FutureWork?: string | null;
  PcId?: string;
  Task?: boolean | null;
  forecast_start_date?: string | null;
  forecast_end_date?: string | null;
  forecast_unblock_date?: string | null;
  UpdatedAt?: string | null;
  CreatedAt?: string | null;
};
export type KrakenBackendApiModelsActivityTemplatesApiActivityTemplateChecklistItem =
  {
    atcid?: string;
    heading?: string | null;
  };
export type KrakenBackendApiModelsActivityTemplatesApiActivityTemplateAssociated =
  {
    Subject?: string | null;
    Description?: string | null;
    Scope?: string | null;
    Notes?: string | null;
    Active?: boolean;
    Task?: boolean | null;
    ModifiedDate?: string;
    Id?: string;
    StereotypeId?: string[] | null;
    Checklist?:
      | KrakenBackendApiModelsActivityTemplatesApiActivityTemplateChecklistItem[]
      | null;
  };
export type KrakenBackendApiModelsActivityTemplatesApiActivityTemplateWithChecklist =
  {
    Subject?: string | null;
    Description?: string | null;
    Scope?: string | null;
    Notes?: string | null;
    Task?: boolean | null;
    ChecklistItems?: string[] | null;
  };
export type KrakenBackendApiModelsActivityTemplatesApiActivityTemplate = {
  Subject?: string | null;
  Description?: string | null;
  Scope?: string | null;
  Notes?: string | null;
  Task?: boolean | null;
};
export type KrakenBackendApiModelsActivityTemplatesApiActivityTemplateXStereotype =
  {
    atid?: string;
    sid?: string;
  };
export type KrakenModelsDatabaseTablesFindingStatus = {
  StatusId?: string;
  Name?: string | null;
  Description?: string | null;
  Active?: boolean;
  Number?: number;
  Findings?: KrakenModelsDatabaseTablesFindings[] | null;
};
export type KrakenModelsDatabaseTablesProjectXprojectrole = {
  ProjectId?: string;
  ProjectroleId?: string;
  HumanId?: string;
  Active?: boolean;
  Human?: KrakenModelsDatabaseTablesHumans;
  Project?: KrakenModelsDatabaseTablesProjects;
  Projectrole?: KrakenModelsDatabaseTablesProjectrole;
};
export type KrakenModelsDatabaseTablesProjectrole = {
  Id?: string;
  Name?: string | null;
  Description?: string | null;
  Active?: boolean;
  Rank?: number | null;
  ProjectXprojectrole?: KrakenModelsDatabaseTablesProjectXprojectrole[] | null;
  QuestionTemplateMaps?: KrakenModelsDatabaseTablesQuestionTemplateMap[] | null;
};
export type KrakenModelsDatabaseTablesQuestionTemplate = {
  Id?: string;
  Version?: number;
  Status?: number;
  CreatedDate?: string;
  CreatedBy?: string;
  QuestionText?: string | null;
  QuestionType?: number;
  DeletedDate?: string | null;
  DeletedBy?: string | null;
  QuestionAnswers?: KrakenModelsDatabaseTablesQuestionAnswer[] | null;
};
export type KrakenModelsDatabaseTablesQuestionAnswer = {
  Id?: string;
  CreatedBy?: string;
  CreatedOn?: string;
  QuestionId?: string;
  QuestionVersion?: number;
  Answer?: string | null;
  Status?: number;
  ContextId?: string | null;
  ProjectId?: string;
  Question?: KrakenModelsDatabaseTablesQuestionTemplate;
  QuestionTemplateMap?: KrakenModelsDatabaseTablesQuestionTemplateMap;
};
export type KrakenModelsDatabaseTablesQuestionTemplateMap = {
  QuestionId?: string;
  CompanyId?: string | null;
  StereotypeId?: string | null;
  ActivityTemplateId?: string | null;
  FindingTemplateId?: string | null;
  RoleId?: string | null;
  HumanId?: string | null;
  ProjectId?: string | null;
  ContextId?: string;
  ModifiedBy?: string;
  Modified?: string;
  Status?: number;
  DeletedDate?: string | null;
  DeletedBy?: string | null;
  ActivityTemplate?: KrakenModelsDatabaseTablesActivityTemplate;
  Company?: KrakenModelsDatabaseTablesCompany;
  FindingTemplate?: KrakenModelsDatabaseTablesVulnerabilities;
  Human?: KrakenModelsDatabaseTablesHumans;
  Project?: KrakenModelsDatabaseTablesProjects;
  Role?: KrakenModelsDatabaseTablesProjectrole;
  Stereotype?: KrakenModelsDatabaseTablesStereotype;
  QuestionAnswers?: KrakenModelsDatabaseTablesQuestionAnswer[] | null;
};
export type KrakenModelsDatabaseTablesVulnerabilities = {
  VulnId?: string;
  Name?: string | null;
  Description?: string | null;
  Editor?: string;
  Updated?: string;
  Active?: boolean;
  Reference?: string | null;
  Recommendation?: string | null;
  Observation?: string | null;
  Title?: string | null;
  Impact?: number;
  ImpactRationale?: string | null;
  Difficulty?: number;
  DifficultyRationale?: string | null;
  Notes?: string | null;
  ShippableRecommendation?: boolean;
  EditorNavigation?: KrakenModelsDatabaseTablesHumans;
  Findings?: KrakenModelsDatabaseTablesFindings[] | null;
  QuestionTemplateMaps?: KrakenModelsDatabaseTablesQuestionTemplateMap[] | null;
};
export type KrakenModelsDatabaseTablesVulnTypes = {
  VulnTypeId?: string;
  Name?: string | null;
  Description?: string | null;
  Active?: boolean;
  Findings?: KrakenModelsDatabaseTablesFindings[] | null;
};
export type KrakenModelsDatabaseTablesActivityXfinding = {
  ActivityId?: string;
  FindingId?: string;
  Activity?: KrakenModelsDatabaseTablesActivity;
  Finding?: KrakenModelsDatabaseTablesFindings;
};
export type KrakenModelsDatabaseTablesFindingImages = {
  ImageId?: string;
  FindingId?: string;
  Data?: string | null;
  Type?: string | null;
  Active?: boolean;
  CreatedDate?: string | null;
  Finding?: KrakenModelsDatabaseTablesFindings;
};
export type KrakenModelsDatabaseTablesFindingUpdates = {
  UpdateId?: string;
  Date?: string;
  HumanId?: string;
  FindingId?: string;
  Description?: string | null;
  Active?: boolean;
  Finding?: KrakenModelsDatabaseTablesFindings;
  Human?: KrakenModelsDatabaseTablesHumans;
};
export type KrakenModelsDatabaseViewsActivityReview = {
  ActivityId?: string | null;
  Activity?: KrakenModelsDatabaseTablesActivity;
  FindingReview?: KrakenModelsDatabaseViewsFindingReview;
  ReviewerId?: string;
  ReviewTime?: string;
  ReviewType?: number;
  Outcome?: number;
  PcId?: string | null;
  ProjectId?: string | null;
  Reviewer?: KrakenModelsDatabaseTablesHumans;
  Pc?: KrakenModelsDatabaseTablesProjectComponents;
  Project?: KrakenModelsDatabaseTablesProjects;
};
export type KrakenModelsDatabaseViewsFindingReview = {
  FindingId?: string | null;
  Finding?: KrakenModelsDatabaseTablesFindings;
  ActivityReview?: KrakenModelsDatabaseViewsActivityReview;
  ReviewerId?: string;
  ReviewTime?: string;
  ReviewType?: number;
  Outcome?: number;
  PcId?: string | null;
  ProjectId?: string | null;
  Reviewer?: KrakenModelsDatabaseTablesHumans;
  Pc?: KrakenModelsDatabaseTablesProjectComponents;
  Project?: KrakenModelsDatabaseTablesProjects;
};
export type KrakenModelsDatabaseTablesCweItemStatus =
  | 'Deprecated'
  | 'Draft'
  | 'Incomplete'
  | 'Obsolete'
  | 'Stable'
  | 'Usable';
export type KrakenModelsDatabaseTablesCweItem = {
  Id?: number;
  Name?: string | null;
  Description?: string | null;
  Status?: KrakenModelsDatabaseTablesCweItemStatus;
  CweFindings?: KrakenModelsDatabaseTablesCweFinding[] | null;
};
export type KrakenModelsDatabaseTablesCweFinding = {
  FindingId?: string;
  CweId?: number;
  Finding?: KrakenModelsDatabaseTablesFindings;
  CweItem?: KrakenModelsDatabaseTablesCweItem;
};
export type KrakenModelsDatabaseTablesFindings = {
  FindingId?: string;
  Impact?: number;
  Difficulty?: number;
  Observation?: string | null;
  Recommendation?: string | null;
  Summary?: string | null;
  Mitigation?: string | null;
  VulnId?: string;
  Editor?: string;
  Active?: boolean;
  Location?: string | null;
  ComponentId?: string;
  CreatedDate?: string;
  VulnTypeId?: string;
  Reference?: string | null;
  Description?: string | null;
  FindingRef?: number;
  FindingStatus?: string;
  ParentId?: string | null;
  ImpactRationale?: string | null;
  DifficultyRationale?: string | null;
  Awscategory?: string | null;
  NontrivialRetest?: boolean | null;
  Notes?: string | null;
  OverriddenSeverity?: number | null;
  NeedsWork?: boolean;
  ResidualImpact?: number | null;
  ResidualDifficulty?: number | null;
  CVSSVector?: string | null;
  Assignee?: string | null;
  DeletedDate?: string | null;
  DeletedBy?: string | null;
  DeletedByHuman?: KrakenModelsDatabaseTablesHumans;
  Component?: KrakenModelsDatabaseTablesProjectComponents;
  EditorNavigation?: KrakenModelsDatabaseTablesHumans;
  FindingStatusNavigation?: KrakenModelsDatabaseTablesFindingStatus;
  Vuln?: KrakenModelsDatabaseTablesVulnerabilities;
  VulnType?: KrakenModelsDatabaseTablesVulnTypes;
  ActivityXfinding?: KrakenModelsDatabaseTablesActivityXfinding[] | null;
  FindingImages?: KrakenModelsDatabaseTablesFindingImages[] | null;
  FindingUpdates?: KrakenModelsDatabaseTablesFindingUpdates[] | null;
  FindingReview?: KrakenModelsDatabaseViewsFindingReview[] | null;
  CweFindings?: KrakenModelsDatabaseTablesCweFinding[] | null;
};
export type KrakenModelsDatabaseTablesProjectComponentsXstereotype = {
  Pcid?: string;
  Sid?: string;
  Applydate?: string;
  User?: string;
  Pc?: KrakenModelsDatabaseTablesProjectComponents;
  S?: KrakenModelsDatabaseTablesStereotype;
  UserNavigation?: KrakenModelsDatabaseTablesHumans;
};
export type KrakenModelsDatabaseTablesProjectComponents = {
  PcId?: string;
  ProjectId?: string;
  Name?: string | null;
  Description?: string | null;
  Active?: boolean;
  ThreatAnalysis?: string | null;
  Observations?: string | null;
  Notes?: string | null;
  CreatedAt?: string | null;
  UpdatedAt?: string | null;
  DeletedDate?: string | null;
  DeletedBy?: string | null;
  DeletedByHuman?: KrakenModelsDatabaseTablesHumans;
  Project?: KrakenModelsDatabaseTablesProjects;
  Activity?: KrakenModelsDatabaseTablesActivity[] | null;
  Findings?: KrakenModelsDatabaseTablesFindings[] | null;
  ProjectComponentsXstereotype?:
    | KrakenModelsDatabaseTablesProjectComponentsXstereotype[]
    | null;
};
export type KrakenModelsDatabaseTablesProjectIssues = {
  IssueId?: string;
  ProjectId?: string;
  Description?: string | null;
  Active?: boolean;
  Resolution?: string | null;
  Severity?: number | null;
  HumanId?: string;
  Human?: KrakenModelsDatabaseTablesHumans;
  Project?: KrakenModelsDatabaseTablesProjects;
};
export type KrakenModelsDatabaseTablesProjectMilestones = {
  MilestoneId?: string;
  ProjectId?: string;
  Name?: string | null;
  Description?: string | null;
  Complete?: number | null;
  Active?: boolean;
  StartDate?: string | null;
  EndDate?: string | null;
  Parent?: string | null;
  Project?: KrakenModelsDatabaseTablesProjects;
};
export type KrakenModelsDatabaseTablesTemporaryAccess = {
  NotBefore?: string;
  NotAfter?: string;
  HumanId?: string;
  Human?: KrakenModelsDatabaseTablesHumans;
  Target?: string;
  Project?: KrakenModelsDatabaseTablesProjects;
};
export type KrakenToolsIoFilesFile = {
  Id?: number;
  Name?: string | null;
  Path?: string | null;
  ContentType?: string | null;
  Length?: number;
  CreatedDate?: string;
};
export type KrakenModelsDatabaseTablesProjectFile = {
  FileId?: number;
  ProjectId?: string;
  File?: KrakenToolsIoFilesFile;
  Project?: KrakenModelsDatabaseTablesProjects;
};
export type KrakenModelsDatabaseTablesProjects = {
  ProjectId?: string;
  Name?: string | null;
  StartDate?: string;
  EndDate?: string;
  RetestEndDate?: string | null;
  Editor?: string;
  CompanyId?: string | null;
  Active?: boolean;
  LastStatus?: string | null;
  Notes?: string | null;
  Regression?: boolean | null;
  RatingJustifications?: boolean | null;
  MavenlinkId?: number | null;
  AwsAvpId?: number | null;
  Testproject?: boolean;
  SeverityOverride?: boolean;
  GoogleRestrictedScope?: boolean;
  IsMasa?: boolean;
  ClarizenId?: number | null;
  EsObjectives?: string | null;
  EsScope?: string | null;
  EsObservations?: string | null;
  EsRecommendations?: string | null;
  EsNotes?: string | null;
  ExternalProjectLink?: string | null;
  Logo?: string | null;
  CreatedAt?: string | null;
  UpdatedAt?: string | null;
  Company?: KrakenModelsDatabaseTablesCompany;
  ProjectComponents?: KrakenModelsDatabaseTablesProjectComponents[] | null;
  ProjectIssues?: KrakenModelsDatabaseTablesProjectIssues[] | null;
  ProjectMilestones?: KrakenModelsDatabaseTablesProjectMilestones[] | null;
  ProjectXprojectrole?: KrakenModelsDatabaseTablesProjectXprojectrole[] | null;
  TemporaryAccesses?: KrakenModelsDatabaseTablesTemporaryAccess[] | null;
  ProjectFiles?: KrakenModelsDatabaseTablesProjectFile[] | null;
};
export type KrakenModelsDatabaseTablesCompany = {
  CompanyId?: string;
  Name?: string | null;
  Active?: boolean;
  Logo?: string | null;
  Humans?: KrakenModelsDatabaseTablesHumans[] | null;
  Projects?: KrakenModelsDatabaseTablesProjects[] | null;
  QuestionTemplateMaps?: KrakenModelsDatabaseTablesQuestionTemplateMap[] | null;
};
export type KrakenModelsDatabaseTablesBotConversationInfo = {
  HumanId?: string;
  BotUserId?: string | null;
  ConversationId?: string | null;
  Human?: KrakenModelsDatabaseTablesHumans;
};
export type KrakenModelsDatabaseTablesActivityChecklistItem = {
  CheckboxId?: string;
  ActivityId?: string;
  Heading?: string | null;
  ChangedBy?: string | null;
  ChangedTime?: string | null;
  Status?: KrakenModelsEnumsActivityCheckListItemStatus;
  Activity?: KrakenModelsDatabaseTablesActivity;
  ChangedByNavigation?: KrakenModelsDatabaseTablesHumans;
};
export type KrakenModelsDatabaseTablesUserPreferences = {
  HumanId?: string;
  Name?: string | null;
  Value?: string | null;
  Human?: KrakenModelsDatabaseTablesHumans;
};
export type KrakenModelsDatabaseTablesSignalRSignalRServer = {
  Id?: string;
  DnsName?: string | null;
  CreatedDate?: string;
  Connections?: KrakenModelsDatabaseTablesSignalRSignalRConnection[] | null;
};
export type KrakenModelsDatabaseTablesSignalRFindingEditParticipant = {
  Id?: string;
  FindingId?: string;
  Role?: string | null;
  ConnectionId?: string | null;
  Connection?: KrakenModelsDatabaseTablesSignalRSignalRConnection;
};
export type KrakenModelsDatabaseTablesSignalRSignalRConnection = {
  ConnectionId?: string | null;
  ServerId?: string;
  Server?: KrakenModelsDatabaseTablesSignalRSignalRServer;
  OpenedDate?: string;
  LatestPing?: string | null;
  HumanId?: string;
  Humans?: KrakenModelsDatabaseTablesHumans;
  FindingEditParticipant?: KrakenModelsDatabaseTablesSignalRFindingEditParticipant;
};
export type KrakenModelsDatabaseTablesReportsInProgress = {
  HumanId?: string;
  Count?: number;
  Human?: KrakenModelsDatabaseTablesHumans;
};
export type KrakenModelsDatabaseTablesHumans = {
  HumanId?: string;
  Fname?: string | null;
  Login?: string | null;
  Email?: string | null;
  Phone?: string | null;
  CompanyId?: string;
  Active?: boolean;
  Lname?: string | null;
  LegacyLogin?: string | null;
  Company?: KrakenModelsDatabaseTablesCompany;
  BotConversationInfo?: KrakenModelsDatabaseTablesBotConversationInfo;
  ActivityChecklistItem?:
    | KrakenModelsDatabaseTablesActivityChecklistItem[]
    | null;
  FindingUpdates?: KrakenModelsDatabaseTablesFindingUpdates[] | null;
  Findings?: KrakenModelsDatabaseTablesFindings[] | null;
  ProjectComponentsXstereotype?:
    | KrakenModelsDatabaseTablesProjectComponentsXstereotype[]
    | null;
  ProjectXprojectrole?: KrakenModelsDatabaseTablesProjectXprojectrole[] | null;
  UserPreferences?: KrakenModelsDatabaseTablesUserPreferences[] | null;
  Vulnerabilities?: KrakenModelsDatabaseTablesVulnerabilities[] | null;
  TemporaryAccesses?: KrakenModelsDatabaseTablesTemporaryAccess[] | null;
  SignalrConnections?:
    | KrakenModelsDatabaseTablesSignalRSignalRConnection[]
    | null;
  ProjectIssues?: KrakenModelsDatabaseTablesProjectIssues[] | null;
  ActivityReview?: KrakenModelsDatabaseViewsActivityReview[] | null;
  FindingReview?: KrakenModelsDatabaseViewsFindingReview[] | null;
  QuestionTemplateMaps?: KrakenModelsDatabaseTablesQuestionTemplateMap[] | null;
  Reportees?: KrakenModelsDatabaseTablesHumans[] | null;
  Reports?: KrakenModelsDatabaseTablesHumans[] | null;
  ReportsInProgress?: KrakenModelsDatabaseTablesReportsInProgress[] | null;
  DeletedActivities?: KrakenModelsDatabaseTablesActivity[] | null;
  DeletedFindings?: KrakenModelsDatabaseTablesFindings[] | null;
  DeletedProjectComponents?:
    | KrakenModelsDatabaseTablesProjectComponents[]
    | null;
};
export type KrakenModelsDatabaseTablesActivity = {
  Id?: string;
  Subject?: string | null;
  Description?: string | null;
  Active?: boolean;
  ActivityTemplateId?: string | null;
  Notes?: string | null;
  Status?: number;
  PcId?: string;
  Disposition?: string | null;
  Scope?: string | null;
  FutureWork?: string | null;
  Task?: boolean | null;
  forecast_start_date?: string | null;
  forecast_end_date?: string | null;
  forecast_unblock_date?: string | null;
  CreatedAt?: string | null;
  UpdatedAt?: string | null;
  DeletedDate?: string | null;
  DeletedBy?: string | null;
  DeletedByHuman?: KrakenModelsDatabaseTablesHumans;
  ActivityTemplate?: KrakenModelsDatabaseTablesActivityTemplate;
  Pc?: KrakenModelsDatabaseTablesProjectComponents;
  ActivityChecklistItem?:
    | KrakenModelsDatabaseTablesActivityChecklistItem[]
    | null;
  ActivityXfinding?: KrakenModelsDatabaseTablesActivityXfinding[] | null;
  ActivityReview?: KrakenModelsDatabaseViewsActivityReview[] | null;
};
export type KrakenModelsDatabaseTablesActivityTemplateChecklistItem = {
  Id?: string;
  ActivityTemplateId?: string;
  Heading?: string | null;
  ActivityTemplate?: KrakenModelsDatabaseTablesActivityTemplate;
};
export type KrakenModelsDatabaseTablesActivityTemplate = {
  Id?: string;
  Subject?: string | null;
  Description?: string | null;
  Scope?: string | null;
  ModifiedDate?: string;
  Active?: boolean;
  Notes?: string | null;
  Task?: boolean | null;
  Activity?: KrakenModelsDatabaseTablesActivity[] | null;
  ActivityTemplateChecklistItem?:
    | KrakenModelsDatabaseTablesActivityTemplateChecklistItem[]
    | null;
  ActivityTemplateXstereotype?:
    | KrakenModelsDatabaseTablesActivityTemplateXstereotype[]
    | null;
  QuestionTemplateMaps?: KrakenModelsDatabaseTablesQuestionTemplateMap[] | null;
};
export type KrakenModelsDatabaseTablesActivityTemplateXstereotype = {
  StereotypeId?: string;
  ActivityTemplateId?: string;
  ActivityTemplate?: KrakenModelsDatabaseTablesActivityTemplate;
  Stereotype?: KrakenModelsDatabaseTablesStereotype;
};
export type KrakenModelsDatabaseTablesStereotype = {
  Id?: string;
  Area?: string | null;
  SurfaceName?: string | null;
  Description?: string | null;
  ModifiedDate?: string;
  Active?: boolean;
  ThreatAnalysis?: string | null;
  ActivityTemplateXstereotype?:
    | KrakenModelsDatabaseTablesActivityTemplateXstereotype[]
    | null;
  ProjectComponentsXstereotype?:
    | KrakenModelsDatabaseTablesProjectComponentsXstereotype[]
    | null;
  QuestionTemplateMaps?: KrakenModelsDatabaseTablesQuestionTemplateMap[] | null;
};
export type KrakenBackendApiModelsFindingImagesImageHeader = {
  image_id?: string;
  finding_id?: string;
  type?: string | null;
  created_date?: string | null;
  length?: number;
};
export type KrakenBackendApiModelsCompaniesCompany = {
  CompanyId?: string;
  Name?: string | null;
  Active?: boolean;
  Logo?: string | null;
};
export type KrakenBackendApiModelsCompaniesCompanyTerse = {
  CompanyId?: string;
  Name?: string | null;
  Active?: boolean;
};
export type KrakenBackendApiModelsCompaniesApiNewCompany = {
  Name?: string | null;
  Logo?: string | null;
};
export type KrakenModelsDtoCompanyCompanyDto = {
  CompanyId?: string;
  Name?: string | null;
  Active?: boolean;
  Logo?: string | null;
};
export type KrakenModelsDtoCompanyUpdateCompanyDto = {
  Name?: string | null;
  Active?: boolean | null;
  Logo?: string | null;
};
export type KrakenBackendApiModelsProjectComponentsV1ProjectComponentsDetailed =
  {
    pc_id?: string;
    project_id?: string;
    name?: string | null;
    description?: string | null;
    threat_analysis?: string | null;
    observations?: string | null;
    notes?: string | null;
    active?: boolean;
    deleteable?: boolean;
    weight?: number;
    updated_at?: string | null;
  };
export type KrakenBackendApiModelsProjectComponentsApiProjectComponent = {
  name?: string | null;
  description?: string | null;
  threat_analysis?: string | null;
  observations?: string | null;
  notes?: string | null;
};
export type KrakenModelsDatabaseViewsViewProjectComponentsDetailed = {
  pc_id?: string;
  project_id?: string;
  name?: string | null;
  description?: string | null;
  threat_analysis?: string | null;
  observations?: string | null;
  notes?: string | null;
  active?: boolean;
  deleteable?: boolean;
  weight?: number;
};
export type KrakenModelsDtoComponentComponentsDtoFinding = {
  FindingId?: string;
  FindingRef?: number;
  Summary?: string | null;
};
export type KrakenModelsDtoComponentComponentsDto = {
  Name?: string | null;
  Description?: string | null;
  Notes?: string | null;
  Observations?: string | null;
  ThreatAnalysis?: string | null;
  Findings?: KrakenModelsDtoComponentComponentsDtoFinding[] | null;
};
export type KrakenModelsDtoSetUserConfigDto = {
  Item?: string | null;
  Value?: string | null;
};
export type KrakenModelsDtoCweCweItemResult = {
  Id?: number;
  Name?: string | null;
  Status?: KrakenModelsDatabaseTablesCweItemStatus;
};
export type KrakenBackendReportingEmailReportDailyReportEmail = {
  recipient?: string | null;
  subject?: string | null;
  body?: string | null;
};
export type MicrosoftAspNetCoreMvcActionConstraintsIActionConstraintMetadata =
  object;
export type KrakenBackendControllersEnvironmentControllerRouteModel = {
  Name?: string | null;
  Template?: string | null;
  Constraints?:
    | MicrosoftAspNetCoreMvcActionConstraintsIActionConstraintMetadata[]
    | null;
};
export type KrakenModelsReportsFontName =
  | 'Hauora'
  | 'Manrope'
  | 'IBMPlexMono'
  | 'PTRootUI'
  | 'Montserrat';
export type KrakenModelsDtoFontSettings = {
  MainFont?: KrakenModelsReportsFontName;
  HeaderFooter?: KrakenModelsReportsFontName;
  Classification?: KrakenModelsReportsFontName;
  TableOfContent?: KrakenModelsReportsFontName;
};
export type KrakenModelsDtoFinalReportConfigurableDetails = {
  ReportDate?: string;
  ReportVersion?: string | null;
  AddressAndContactInfo?: string | null;
  Stakeholders?: string | null;
  ObjectivesConclusion?: string | null;
  AddPolicyProcedure?: boolean;
  AddAppendixC?: boolean;
  AppendixC?: string | null;
  FontSettings?: KrakenModelsDtoFontSettings;
};
export type KrakenModelsDtoFindingV1FindingReadDto = {
  active?: boolean;
  finding_id?: string;
  vuln_id?: string;
  editor?: string;
  editor_name?: string | null;
  vulnType_id?: string;
  vulnType_name?: string | null;
  component_id?: string;
  component_name?: string | null;
  impact?: number;
  difficulty?: number;
  computed_severity?: number;
  risk?: number;
  overridden_severity?: number | null;
  needs_work?: boolean;
  residual_impact?: number | null;
  residual_difficulty?: number | null;
  residual_risk?: number;
  AWSCategory?: string | null;
  observation?: string | null;
  description?: string | null;
  recommendation?: string | null;
  impact_rationale?: string | null;
  difficulty_rationale?: string | null;
  reference?: string | null;
  type?: string | null;
  summary?: string | null;
  mitigation?: string | null;
  location?: string | null;
  created_date?: string;
  finding_ref?: number;
  finding_status_id?: string;
  status_name?: string | null;
  status_number?: number;
  last_update?: string;
  nontrivial_retest?: boolean | null;
  notes?: string | null;
  last_edit_date?: string;
  testproject?: boolean;
  project_id?: string;
  project_name?: string | null;
  company_id?: string;
  company_name?: string | null;
  pc_active?: boolean;
  google_restricted_scope?: boolean;
  reportable?: boolean;
  is_in_edit?: boolean;
  realtime_edit_editor_name?: string | null;
  cvss_vector?: string | null;
  cwe_id?: number;
  risk_text?: string | null;
  risk_num?: number;
  residual_risk_text?: string | null;
  residual_risk_num?: number;
};
export type KrakenModelsDtoPagedData601KrakenModelsDtoFindingV1FindingReadDto20KrakenModels20Version100020CultureNeutral20PublicKeyTokenNull =
  {
    Data?: KrakenModelsDtoFindingV1FindingReadDto[] | null;
    TotalCount?: number;
    PageSize?: number;
    CurrentPage?: number;
    TotalPages?: number;
    HasNext?: boolean;
    HasPrevious?: boolean;
  };
export type KrakenModelsDtoOrderDirection = 'Asc' | 'Desc';
export type KrakenModelsDtoFindingColumn =
  | 'FindingRef'
  | 'Severity'
  | 'Title'
  | 'Type'
  | 'Component'
  | 'Editor'
  | 'Status'
  | 'LastModified';
export type KrakenModelsDtoOrderItem601KrakenModelsDtoFindingColumn20KrakenModels20Version100020CultureNeutral20PublicKeyTokenNull =
  {
    Direction?: KrakenModelsDtoOrderDirection;
    Column?: KrakenModelsDtoFindingColumn;
  };
export type KrakenBackendModelsViewFindingDetailedWeb = {
  risk_text?: string | null;
  risk_num?: number;
  residual_risk_text?: string | null;
  residual_risk_num?: number;
  active?: boolean;
  finding_id?: string;
  vuln_id?: string;
  editor?: string;
  editor_name?: string | null;
  vulnType_id?: string;
  vulnType_name?: string | null;
  component_id?: string;
  component_name?: string | null;
  impact?: number;
  difficulty?: number;
  computed_severity?: number;
  risk?: number;
  overridden_severity?: number | null;
  needs_work?: boolean;
  residual_impact?: number | null;
  residual_difficulty?: number | null;
  residual_risk?: number;
  AWSCategory?: string | null;
  observation?: string | null;
  description?: string | null;
  recommendation?: string | null;
  impact_rationale?: string | null;
  difficulty_rationale?: string | null;
  reference?: string | null;
  type?: string | null;
  summary?: string | null;
  mitigation?: string | null;
  location?: string | null;
  created_date?: string;
  finding_ref?: number;
  finding_status_id?: string;
  status_name?: string | null;
  status_number?: number;
  last_update?: string;
  nontrivial_retest?: boolean | null;
  notes?: string | null;
  last_edit_date?: string;
  testproject?: boolean;
  project_id?: string;
  project_name?: string | null;
  company_id?: string;
  company_name?: string | null;
  pc_active?: boolean;
  google_restricted_scope?: boolean;
  reportable?: boolean;
  is_in_edit?: boolean;
  realtime_edit_editor_name?: string | null;
  cvss_vector?: string | null;
  cwe_id?: number;
};
export type KrakenModelsDtoFindingGetShortFindingDto = {
  finding_id?: string;
  risk?: number;
  summary?: string | null;
  type?: string | null;
  component?: string | null;
  editorName?: string | null;
  lastEditDate?: string;
  status?: string;
  statusName?: string | null;
};
export type KrakenModelsFindingHelpersRisk =
  | 'Info'
  | 'Low'
  | 'Medium'
  | 'High'
  | 'Critical';
export type KrakenModelsDtoFindingFindingNeedsWorkDto = {
  finding_id?: string;
  projectID?: string;
  projectName?: string | null;
  risk_text?: string | null;
  summary?: string | null;
  type?: string | null;
  editor_name?: string | null;
  status_name?: string | null;
  assignees?: string[] | null;
  last_update?: string;
  last_edit_date?: string;
  projectLogoUrl?: string | null;
};
export type KrakenModelsDatabaseViewsViewFindingDetailed = {
  active?: boolean;
  finding_id?: string;
  vuln_id?: string;
  editor?: string;
  editor_name?: string | null;
  vulnType_id?: string;
  vulnType_name?: string | null;
  component_id?: string;
  component_name?: string | null;
  impact?: number;
  difficulty?: number;
  computed_severity?: number;
  risk?: number;
  overridden_severity?: number | null;
  needs_work?: boolean;
  residual_impact?: number | null;
  residual_difficulty?: number | null;
  residual_risk?: number;
  AWSCategory?: string | null;
  observation?: string | null;
  description?: string | null;
  recommendation?: string | null;
  impact_rationale?: string | null;
  difficulty_rationale?: string | null;
  reference?: string | null;
  type?: string | null;
  summary?: string | null;
  mitigation?: string | null;
  location?: string | null;
  created_date?: string;
  finding_ref?: number;
  finding_status_id?: string;
  status_name?: string | null;
  status_number?: number;
  last_update?: string;
  nontrivial_retest?: boolean | null;
  notes?: string | null;
  last_edit_date?: string;
  testproject?: boolean;
  project_id?: string;
  project_name?: string | null;
  company_id?: string;
  company_name?: string | null;
  pc_active?: boolean;
  google_restricted_scope?: boolean;
  reportable?: boolean;
  is_in_edit?: boolean;
  realtime_edit_editor_name?: string | null;
  cvss_vector?: string | null;
  cwe_id?: number;
};
export type KrakenBackendApiModelsFindingsApiFinding = {
  impact?: number;
  difficulty?: number;
  residual_impact?: number | null;
  residual_difficulty?: number | null;
  overridden_severity?: number | null;
  vuln_id?: string;
  component_id?: string;
  finding_status_id?: string;
  AWSCategory?: string | null;
  nontrivial_retest?: boolean | null;
  needs_work?: boolean;
  notes?: string | null;
  cwe_id?: number | null;
  associated_activities?: string[] | null;
  description?: string | null;
  observation?: string | null;
  recommendation?: string | null;
  impact_rationale?: string | null;
  difficulty_rationale?: string | null;
  summary?: string | null;
  mitigation?: string | null;
  reference?: string | null;
  location?: string | null;
  cvss_vector?: string | null;
};
export type KrakenModelsDtoFindingFindingDto = {
  FindingId?: string;
  Summary?: string | null;
  Type?: string | null;
  ComponentName?: string | null;
  EditorName?: string | null;
  Impact?: number;
  Difficulty?: number;
  RealtimeEditEditorName?: string | null;
  NeedsWork?: boolean;
  StatusId?: string;
  StatusName?: string | null;
  Assignees?: string[] | null;
  LastEditDate?: string;
};
export type KrakenModelsDtoPagedData601KrakenModelsDtoFindingFindingDto20KrakenModels20Version100020CultureNeutral20PublicKeyTokenNull =
  {
    Data?: KrakenModelsDtoFindingFindingDto[] | null;
    TotalCount?: number;
    PageSize?: number;
    CurrentPage?: number;
    TotalPages?: number;
    HasNext?: boolean;
    HasPrevious?: boolean;
  };
export type KrakenModelsDtoFindingWriteDto = {
  impact?: number;
  difficulty?: number;
  residualImpact?: number | null;
  residualDifficulty?: number | null;
  overriddenSeverity?: number | null;
  templateId?: string;
  componentId?: string;
  statusId?: string;
  awsCategory?: string | null;
  nontrivialRetest?: boolean | null;
  needsWork?: boolean;
  notes?: string | null;
  cweId?: number | null;
  associatedActivities?: string[] | null;
  description?: string | null;
  observation?: string | null;
  recommendation?: string | null;
  impactRationale?: string | null;
  difficultyRationale?: string | null;
  name?: string | null;
  mitigation?: string | null;
  reference?: string | null;
  location?: string | null;
  cvss?: string | null;
};
export type KrakenBackendApiModelsHumansApiHuman = {
  Fname?: string | null;
  Lname?: string | null;
  Email?: string | null;
  Phone?: string | null;
  CompanyId?: string;
  Login?: string | null;
  Active?: boolean;
  HumanId?: string;
};
export type KrakenBackendApiModelsHumansApiNewHuman = {
  Fname?: string | null;
  Lname?: string | null;
  Email?: string | null;
  Phone?: string | null;
  CompanyId?: string;
  Login?: string | null;
};
export type KrakenBackendApiModelsJiraProjectsJiraEnabledResponse = {
  Enabled?: boolean;
};
export type KrakenModelsDtoJiraJiraProject = {
  Id?: string | null;
  Self?: string | null;
  Key?: string | null;
  Name?: string | null;
  ProjectTypeKey?: string | null;
  Simplified?: boolean;
  Type?: string | null;
};
export type KrakenBackendApiModelsFindingsApiFindingLeaderboardCount = {
  humanID?: string;
  humanName?: string | null;
  findings?: number;
};
export type KrakenModelsDtoApiHeatmap = {
  reference?: string;
  heatmap?: number[] | null;
};
export type KrakenModelsDtoProjectWithEditInfoQueryResult = {
  coID?: string;
  coName?: string | null;
  prID?: string;
  prName?: string | null;
  startDate?: string | null;
  endDate?: string | null;
  retestEndDate?: string | null;
  lastStatus?: string | null;
  notes?: string | null;
  regression?: boolean;
  rating_justifications?: boolean;
  active?: boolean;
  mavenlinkID?: number | null;
  testproject?: boolean;
  severity_override?: boolean;
  google_restricted_scope?: boolean;
  clarizen_id?: number | null;
  external_project_link?: string | null;
  readonly_external_project_link?: string | null;
  awsAvpId?: number | null;
  isMasa?: boolean;
  EditorFirstName?: string | null;
  EditorLastName?: string | null;
  UpdatedAt?: string | null;
  CompanyLogoUrl?: string | null;
  LogoUrl?: string | null;
};
export type KrakenModelsDtoProjectDto = {
  prName?: string | null;
  coID?: string;
  startDate?: string;
  endDate?: string;
  retestEndDate?: string | null;
  regression?: boolean;
  rating_justifications?: boolean;
  mavenlinkID?: number | null;
  testproject?: boolean;
  severity_override?: boolean;
  google_restricted_scope?: boolean;
  clarizen_id?: number | null;
  notes?: string | null;
  external_project_link?: string | null;
  awsAvpId?: number | null;
  TeamId?: string | null;
  isMasa?: boolean | null;
};
export type KrakenModelsDtoProjectQueryResult = {
  coID?: string;
  coName?: string | null;
  prID?: string;
  prName?: string | null;
  startDate?: string | null;
  endDate?: string | null;
  retestEndDate?: string | null;
  lastStatus?: string | null;
  notes?: string | null;
  regression?: boolean;
  rating_justifications?: boolean;
  active?: boolean;
  mavenlinkID?: number | null;
  testproject?: boolean;
  severity_override?: boolean;
  google_restricted_scope?: boolean;
  clarizen_id?: number | null;
  external_project_link?: string | null;
  readonly_external_project_link?: string | null;
  awsAvpId?: number | null;
  isMasa?: boolean;
};
export type KrakenBackendApiModelsProjectsProjectDates = {
  start_date?: string;
  end_date?: string;
};
export type KrakenModelsDtoProjectRole = {
  RoleId?: string;
  Role?: string | null;
  HumanId?: string;
  Name?: string | null;
  Email?: string | null;
};
export type KrakenModelsDtoDeleteUserRoleDto = {
  HumanId?: string;
  RoleId?: string;
};
export type KrakenBackendApiModelsTemporaryAccessTemporaryAccessGrant = {
  human_id?: string;
  target?: string;
  not_before?: string;
  not_after?: string;
};
export type KrakenBackendApiModelsActivitiesTerseFindingActivityBinding = {
  fid?: string;
  aid?: string;
};
export type KrakenModelsDtoLateProjectRecord = {
  project_id?: string;
  late_item_count?: number;
};
export type KrakenBackendApiModelsProjectsApiExecutiveSummary = {
  Objectives?: string | null;
  Scope?: string | null;
  Observations?: string | null;
  Recommendations?: string | null;
  Notes?: string | null;
};
export type KrakenModelsDtoDeletedDataProjectWithDeletedDataDto = {
  projectId?: string;
  projectName?: string | null;
  projectLink?: string | null;
};
export type KrakenModelsDtoFindingListDto = {
  findingId?: string;
  summary?: string | null;
  impact?: number;
  difficulty?: number;
  overridenSeverity?: number | null;
  deletedDate?: string | null;
  userDeleted?: string | null;
};
export type KrakenModelsEnumsActivityStatus =
  | 'DEFAULT'
  | 'PROGRESSING'
  | 'BLOCKED'
  | 'QUALIFIED_DONE'
  | 'DONE'
  | 'IMPOSSIBLE';
export type KrakenModelsDtoActivityListDto = {
  activityId?: string;
  subject?: string | null;
  status?: KrakenModelsEnumsActivityStatus;
  deletedDate?: string | null;
  userDeleted?: string | null;
};
export type KrakenModelsDtoProjectComponentListDto = {
  componentId?: string;
  name?: string | null;
  deletedDate?: string | null;
  userDeleted?: string | null;
};
export type KrakenBackendApiModelsQuestionsStatus = 'Deleted' | 'Active';
export type KrakenBackendApiModelsQuestionsAnswer = {
  id?: string;
  created_date?: string;
  created_by?: string;
  question_id?: string;
  version?: number;
  answer?: string | null;
  status?: KrakenBackendApiModelsQuestionsStatus;
  context_id?: string | null;
  project_id?: string;
};
export type MicrosoftAspNetCoreMvcProblemDetails = {
  Type?: string | null;
  Title?: string | null;
  Status?: number | null;
  Detail?: string | null;
  Instance?: string | null;
  [key: string]: any;
};
export type KrakenBackendApiModelsQuestionsQuestionType =
  | 'Text'
  | 'MultipleChoice';
export type KrakenBackendApiModelsQuestionsTemplate = {
  id?: string;
  version?: number;
  status?: KrakenBackendApiModelsQuestionsStatus;
  created_date?: string;
  created_by?: string;
  question_text?: string | null;
  question_type?: KrakenBackendApiModelsQuestionsQuestionType;
  context_id?: string | null;
};
export type KrakenBackendApiModelsQuestionsBasicTemplate = {
  question_text?: string | null;
  question_type?: KrakenBackendApiModelsQuestionsQuestionType;
};
export type KrakenBackendApiModelsQuestionsContext = {
  question_id?: string;
  company_id?: string | null;
  stereotype_id?: string | null;
  activity_template?: string | null;
  finding_template?: string | null;
  role?: string | null;
  human?: string | null;
  project_id?: string | null;
  context_id?: string;
  modified_by?: string;
  modified?: string;
  status?: KrakenBackendApiModelsQuestionsStatus;
};
export type KrakenBackendApiModelsQuestionsProjectUnansweredQuestions = {
  project_id?: string;
  count?: number;
};
export type KrakenModelsDtoEditHistoryDto = {
  referent?: string;
  editor?: string;
  edit_date?: string;
  diff?: string | null;
  initial?: boolean;
  notes_only?: boolean;
  deletion_log?: boolean;
  EditorFirstName?: string | null;
  EditorLastName?: string | null;
};
export type KrakenBackendApiModelsReviewableReviewableReference = {
  rid?: string;
  pid?: string;
  type?: string | null;
};
export type KrakenModelsDtoReviewsReviewType =
  | 'Peer'
  | 'TechLead'
  | 'ProjectManager'
  | 'Editorial'
  | 'Oversight';
export type KrakenModelsDtoReviewsReviewOutcome =
  | 'Ok'
  | 'NeedsWork'
  | 'BugOfYear';
export type KrakenModelsDtoEditHistoryV2Dto = {
  Referent?: string;
  Editor?: string;
  EditDate?: string;
  Diff?: string | null;
  Initial?: boolean;
  NotesOnly?: boolean;
  DeletionLog?: boolean;
  ReviewType?: KrakenModelsDtoReviewsReviewType;
  Outcome?: KrakenModelsDtoReviewsReviewOutcome;
  ReviewableType?: string | null;
  EditorFirstName?: string | null;
  EditorLastName?: string | null;
};
export type KrakenModelsDatabaseViewsViewReviewsAbstract = {
  reviewable_id?: string;
  reviewer_id?: string;
  review_time?: string;
  review_type?: number;
  outcome?: number;
  reviewable_type?: string | null;
};
export type KrakenBackendApiModelsReviewsReviewRecord = {
  rid?: string;
  review?: KrakenModelsEnumsReviewType;
  first_valid_review_time?: string;
};
export type KrakenModelsDtoFindingFindingReadDto = {
  Active?: boolean;
  FindingId?: string;
  TemplateId?: string;
  Editor?: string;
  EditorName?: string | null;
  VulnTypeId?: string;
  VulnTypeName?: string | null;
  ComponentId?: string;
  ComponentName?: string | null;
  Impact?: number;
  Difficulty?: number;
  ComputedSeverity?: number;
  Risk?: number;
  OverriddenSeverity?: number | null;
  NeedsWork?: boolean;
  ResidualImpact?: number | null;
  ResidualDifficulty?: number | null;
  ResidualRisk?: number;
  AwsCategory?: string | null;
  Observation?: string | null;
  Description?: string | null;
  Recommendation?: string | null;
  ImpactRationale?: string | null;
  DifficultyRationale?: string | null;
  Reference?: string | null;
  Type?: string | null;
  Name?: string | null;
  Mitigation?: string | null;
  Location?: string | null;
  CreatedDate?: string;
  FindingRef?: number;
  StatusId?: string;
  StatusName?: string | null;
  StatusNumber?: number;
  LastUpdate?: string;
  NontrivialRetest?: boolean | null;
  Notes?: string | null;
  LastEditDate?: string;
  Testproject?: boolean;
  ProjectId?: string;
  ProjectName?: string | null;
  CompanyId?: string;
  CompanyName?: string | null;
  PcActive?: boolean;
  GoogleRestrictedScope?: boolean;
  Reportable?: boolean;
  IsInEdit?: boolean;
  RealtimeEditEditorName?: string | null;
  Cvss?: string | null;
  CweId?: number | null;
  RiskText?: string | null;
  RiskNum?: number;
  ResidualRiskText?: string | null;
  ResidualRiskNum?: number;
};
export type KrakenBackendUtilityConstantsReferentType =
  | 'MISC'
  | 'FINDING'
  | 'ACTIVITY'
  | 'COMPONENT'
  | 'PROJECT';
export type KrakenBackendApiModelsRulesViolation = {
  ReferentType?: KrakenBackendUtilityConstantsReferentType;
  Referent?: string;
  Issue?: string | null;
};
export type KrakenModelsDtoFindingFindingCountDto = {
  ProjectId?: string;
  ProjectName?: string | null;
  Critical?: number;
  High?: number;
  Medium?: number;
  Low?: number;
  Info?: number;
};
export type KrakenModelsReportsProjectState = 'OnTrack' | 'Completed';
export type KrakenModelsDtoStatusReportConfigurableDetails = {
  ReportDate?: string;
  AddressAndContactInfo?: string | null;
  Stakeholders?: string | null;
  ProjectState?: KrakenModelsReportsProjectState;
  Background?: string | null;
  CurrentStatus?: string | null;
  AddAppendixA?: boolean;
  AppendixAHeader?: string | null;
  AppendixABody?: string | null;
  FontSettings?: KrakenModelsDtoFontSettings;
};
export type KrakenBackendApiModelsStereotypesApiStereotypeAssociated = {
  Area?: string | null;
  SurfaceName?: string | null;
  Description?: string | null;
  Active?: boolean;
  ModifiedDate?: string;
  Id?: string;
  ActivityId?: string[] | null;
  NumActivities?: number;
  ThreatAnalysis?: string | null;
};
export type KrakenBackendApiModelsStereotypesApiStereotype = {
  Area?: string | null;
  SurfaceName?: string | null;
  Description?: string | null;
  ThreatAnalysis?: string | null;
};
export type KrakenModelsDatabaseViewsViewProjectComponentXStereotypes = {
  pc_id?: string;
  sid?: string;
  num_updates?: number;
  implied?: boolean;
  applydate?: string | null;
  user?: string | null;
};
export type KrakenModelsDtoTeamTeamDto = {
  Name?: string | null;
  Description?: string | null;
  Logo?: string | null;
};
export type KrakenModelsDtoTeamGetTeamMemberDto = {
  HumanId?: string;
  Name?: string | null;
  IsOwner?: boolean;
};
export type KrakenModelsDtoTeamGetTeamDto = {
  Id?: string;
  Name?: string | null;
  Description?: string | null;
  Logo?: string | null;
  IsSystem?: boolean;
  TeamMemberCount?: number;
  TeamMembers?: KrakenModelsDtoTeamGetTeamMemberDto[] | null;
};
export type KrakenModelsDtoTeamGetTeamsDto = {
  MyTeamId?: string;
  Teams?: KrakenModelsDtoTeamGetTeamDto[] | null;
};
export type KrakenModelsDtoTeamGetSelectTeamDto = {
  Id?: string;
  Name?: string | null;
  Description?: string | null;
  Logo?: string | null;
};
export type KrakenBackendControllersUserPreferencesControllerApiUserPreferences =
  {
    admin?: boolean;
    templateEditor?: boolean;
    allProjects?: boolean;
    metricsUser?: boolean;
    humanId?: string;
    login?: string | null;
    config?: {
      [key: string]: string | null;
    } | null;
  };
export type KrakenBackendApiModelsVulnerabilitiesApiVulnerability = {
  Name?: string | null;
  Description?: string | null;
  Reference?: string | null;
  Recommendation?: string | null;
  Observation?: string | null;
  Title?: string | null;
  Impact?: number;
  ImpactRationale?: string | null;
  Difficulty?: number;
  DifficultyRationale?: string | null;
  Notes?: string | null;
  ShippableRecommendation?: boolean;
};
export const {
  useGetApiActivitiesComponentByPcidQuery,
  useGetApiActivitiesComponentByPcidInactiveQuery,
  useGetApiActivitiesProjectByPidQuery,
  useGetApiActivitiesProjectByPidInactiveQuery,
  useGetApiActivitiesByAidQuery,
  usePutApiActivitiesByAidMutation,
  useDeleteApiActivitiesByAidMutation,
  usePostApiActivitiesByAidChecklistMutation,
  useDeleteApiActivitiesChecklistByAcidMutation,
  usePutApiActivitiesChecklistByAcidMutation,
  usePutApiActivitiesChecklistByAcidCheckedMutation,
  usePostApiActivitiesMutation,
  usePutApiActivitiesByAidStatusMutation,
  usePostApiActivitiesByAidBulkCheckListItemsMutation,
  useDeleteApiActivitiesByAidTemplateLinkMutation,
  usePutApiActivitiesByAidUndeleteMutation,
  usePutApiActivitiesByAidFindingAndFidMutation,
  useDeleteApiActivitiesByAidFindingAndFidMutation,
  useGetApiActivitiesByAidFindingsQuery,
  useGetApiActivityTemplatesQuery,
  usePostApiActivityTemplatesMutation,
  useGetApiActivityTemplatesInactiveQuery,
  useGetApiActivityTemplatesByAtidQuery,
  usePutApiActivityTemplatesByAtidMutation,
  useDeleteApiActivityTemplatesByAtidMutation,
  useGetApiActivityTemplatesByAtidChecklistQuery,
  usePostApiActivityTemplatesByAtidChecklistMutation,
  usePutApiActivityTemplatesByAtidUndeleteMutation,
  usePutApiActivityTemplatesChecklistByAtcidMutation,
  useDeleteApiActivityTemplatesChecklistByAtcidMutation,
  usePutApiActivityTemplatesByAtidAssociationAndSidMutation,
  useDeleteApiActivityTemplatesByAtidAssociationAndSidMutation,
  usePutApiActivityTemplatesBulkAssociationMutation,
  useDeleteApiActivityTemplatesBulkAssociationMutation,
  useGetApiActivityTemplatesByAtidAssociationQuery,
  usePutApiV2AssigneeFindingByFidMutation,
  useDeleteApiV2AssigneeFindingByFidMutation,
  useGetApiAttachmentsProjectByPidQuery,
  useGetApiAttachmentsProjectByPidInactiveQuery,
  useGetApiAttachmentsByAidQuery,
  useDeleteApiAttachmentsByAidMutation,
  usePutApiAttachmentsByAidUndeleteMutation,
  usePostApiAttachmentsFindingByFidMutation,
  usePutApiAttachmentsReportEmitterLogoMutation,
  useGetApiAttachmentsReportEmitterLogoQuery,
  useGetAuthCheckQuery,
  usePostAuthSignalrAppendMutation,
  useGetApiCompaniesQuery,
  usePostApiCompaniesMutation,
  useGetApiCompaniesTerseQuery,
  useGetApiCompaniesAllTerseQuery,
  usePostApiCompaniesOldMutation,
  usePostApiCompaniesNameonlyMutation,
  usePutApiCompaniesByCidNameMutation,
  usePutApiCompaniesByCidLogoMutation,
  useGetApiCompaniesByCidLogoQuery,
  useGetApiV2CompaniesByCidQuery,
  usePutApiV2CompaniesByCidMutation,
  useGetApiComponentsByPidQuery,
  usePostApiComponentsByPidMutation,
  useGetApiComponentsByComponentIdDetailQuery,
  useGetApiComponentsByPidInactiveQuery,
  usePutApiComponentsByCidMutation,
  useDeleteApiComponentsByCidMutation,
  usePutApiComponentsByCidUndeleteMutation,
  usePostApiComponentsMergeBySrcIntoAndDstMutation,
  usePutApiComponentsByCidAddActivitiesFromStereotypesMutation,
  usePutApiComponentsByCidAddActivitiesFromTemplatesMutation,
  useDeleteApiComponentsByCidStereotypeAndSidMutation,
  useGetApiV2ComponentsByCidQuery,
  useGetApiConfigByItemQuery,
  usePutApiConfigByItemMutation,
  useGetApiConfigQuery,
  useGetApiConfigUserByItemQuery,
  usePutApiConfigUserByItemMutation,
  useGetApiConfigUserQuery,
  usePutApiConfigUserMutation,
  useGetApiCweQuery,
  useGetApiEmailJobDailyEmailQuery,
  useGetApiEmailJobDailyEmailRenderQuery,
  useGetApiEmailJobDailyEmailRenderAllQuery,
  useGetApiEmailJobDailyEmailRenderByHidQuery,
  usePostApiEmailJobDailyEmailSendAllMutation,
  usePostApiEmailJobDailyEmailSendAdminInterimMutation,
  useGetApiRouteDebugQuery,
  useGetApiFinalReportByPidQuery,
  usePostApiFinalReportByPidV2Mutation,
  useGetApiFinalReportEditableInfoByPidQuery,
  usePostApiFindingReviewsByFidMutation,
  useGetApiFindingReviewsByFidQuery,
  useGetApiFindingReviewsByFidLastQuery,
  useGetApiFindingReviewsByFidLastSetQuery,
  useGetApiFindingsProjectByPidQuery,
  useGetApiFindingsProjectByPidFallbackQuery,
  useGetApiFindingsProjectByPidInactiveQuery,
  useGetApiFindingsHumanByHidQuery,
  useGetApiFindingsAnnualHumanByHidQuery,
  useGetApiFindingsAnnualByLimitQuery,
  useGetApiFindingsReviewAllQuery,
  useGetApiFindingsReviewByPidQuery,
  useGetApiFindingsNeedsWorkCountQuery,
  useGetApiFindingsNeedsWorkQuery,
  useGetApiFindingsByFidQuery,
  useDeleteApiFindingsByFidMutation,
  usePutApiFindingsByFidMutation,
  useGetApiFindingsRefByFindingRefQuery,
  useGetApiFindingsByFidTerseQuery,
  usePutApiFindingsByFidUndeleteMutation,
  usePostApiFindingsMutation,
  useGetApiFindingsByFidActivitiesQuery,
  useGetApiV2FindingsProjectByPidQuery,
  useGetApiV2FindingsProjectByPidFallbackQuery,
  useGetApiV2FindingsProjectByPidInactiveQuery,
  useGetApiV2FindingsAnnualHumanByHidQuery,
  useGetApiV2FindingsAnnualByLimitQuery,
  useGetApiV2FindingsReviewAllQuery,
  useGetApiV2FindingsReviewByPidQuery,
  useGetApiV2FindingsByFidQuery,
  usePutApiV2FindingsByFidMutation,
  useGetApiV2FindingsRefByFindingRefQuery,
  usePostApiV2FindingsMutation,
  useGetApiV2FindingsImagesByFidQuery,
  usePostApiV2FindingsImagesByFidMutation,
  useGetApiHumansEmployeesQuery,
  usePostApiHumansMutation,
  usePutApiHumansByHidMutation,
  useDeleteApiHumansByHidEnabledMutation,
  usePutApiHumansByHidEnabledMutation,
  useGetApiJiraProjectsEnabledQuery,
  useGetApiJiraProjectsQuery,
  useGetApiJiraProjectsByKeyQuery,
  useGetApiLeaderboardFindingCountsQuery,
  useGetApiLeaderboardAnnualFindingsTopByLimitQuery,
  useGetApiLeaderboardMyFindingsSinceByWhenQuery,
  useGetApiLeaderboardFindingsSinceByWhenQuery,
  useGetApiLeaderboardFindingsCustomByFromAndToQuery,
  useGetApiLeaderboardHeatmapHumansByDaysAndHidQuery,
  useGetApiLeaderboardHeatmapHumansByDaysMeQuery,
  useGetApiLeaderboardHeatmapHumansByDaysQuery,
  useGetApiLeaderboardHeatmapProjectsByDaysAndPidQuery,
  useGetApiLeaderboardHeatmapProjectsByDaysQuery,
  useGetApiLeaderboardEditsByUidAndFromToBareQuery,
  useGetApiLeaderboardEditsAliasByLoginAndFromToBareQuery,
  useGetApiLeaderboardProjectEditsByPidBareQuery,
  useGetApiLeaderboardGoogleRestrictedScopeDigestBySinceQuery,
  usePostApiProjectfilesByProjectIdMutation,
  useGetApiProjectfilesProjectByPidQuery,
  useGetApiProjectfilesByIdQuery,
  useDeleteApiProjectfilesByIdMutation,
  useGetApiProjectsQuery,
  usePostApiProjectsMutation,
  useGetApiProjectsV2Query,
  useGetApiProjectsDetailedQuery,
  useGetApiProjectsByPidQuery,
  usePutApiProjectsByPidMutation,
  useDeleteApiProjectsByPidMutation,
  useGetApiProjectsByPidDetailedQuery,
  usePutApiProjectsByPidDatesMutation,
  useDeleteApiProjectsDeleteByPidMutation,
  usePutApiProjectsByPidUndeleteMutation,
  useGetApiProjectsByPidRolesQuery,
  usePutApiProjectsByPidRolesMutation,
  useDeleteApiProjectsByPidRolesMutation,
  useDeleteApiProjectsByPidRolesAndHidMutation,
  useDeleteApiProjectsByPidRolesAndHidRidMutation,
  useGetApiProjectsByPidTemporaryAccessQuery,
  useDeleteApiProjectsByPidTemporaryAccessMutation,
  usePostApiProjectsByPidTemporaryAccessAndHidMutation,
  usePutApiProjectsByPidTemporaryAccessAndHidMutation,
  useDeleteApiProjectsByPidTemporaryAccessAndHidMutation,
  useGetApiProjectsByPidFindingActivityBindingQuery,
  useGetApiProjectsByPidLastEditDateQuery,
  useGetApiProjectsLateEditsQuery,
  useDeleteApiProjectsOldMutation,
  useGetApiProjectsMavenlinkByMidQuery,
  useGetApiProjectsClarizenByClarizenIdQuery,
  useGetApiProjectsActiveProjectsForUserByHidQuery,
  usePutApiProjectsByPidExecsummaryMutation,
  useGetApiProjectsByPidExecsummaryQuery,
  usePatchApiProjectsByPidLogoMutation,
  useGetApiProjectsByPidLogoQuery,
  useGetApiV2ProjectsWithInactiveQuery,
  useGetApiV2ProjectsByPidInactiveFindingsQuery,
  useGetApiV2ProjectsByPidInactiveActivitiesQuery,
  useGetApiV2ProjectsByPidInactiveComponentsQuery,
  usePostApiQuestionsAnswersByQuestionIdAndVersionMutation,
  usePutApiQuestionsAnswersByIdMutation,
  useGetApiQuestionsAnswersProjectByPidQuery,
  useGetApiQuestionsTemplatesApplicableByPidQuery,
  useGetApiQuestionsTemplatesActiveQuery,
  usePostApiQuestionsTemplatesMutation,
  usePutApiQuestionsTemplatesByIdMutation,
  useDeleteApiQuestionsTemplatesByIdMutation,
  useGetApiQuestionsContextActiveQuery,
  usePutApiQuestionsContextByIdMutation,
  useDeleteApiQuestionsContextByIdMutation,
  usePostApiQuestionsContextMutation,
  useGetApiQuestionsOutstandingQuery,
  useGetApiRawBugsXmlByPidQuery,
  useGetApiRawBugsAwsJsonByPidQuery,
  useGetApiReportsAvailableFontsQuery,
  useGetApiReviewablesEditHistoryByRidQuery,
  useGetApiReviewablesEditHistoryProjectByPidQuery,
  useGetApiReviewablesOversightQuery,
  useGetApiReviewablesFindByRidQuery,
  useGetApiV2ReviewablesEditHistoryByRidQuery,
  usePostApiReviewsFindingByFidMutation,
  usePostApiReviewsActivityByAidMutation,
  usePostApiReviewsComponentByPcidMutation,
  usePostApiReviewsProjectByPidMutation,
  useGetApiReviewsByProjectByPidQuery,
  useGetApiReviewsByProjectByPidCompleteQuery,
  useGetApiReviewsByRidByRidCompleteQuery,
  useGetApiReviewsByRidQuery,
  useGetApiV2ReviewsLastReviewByFidQuery,
  useGetApiRolesQuery,
  usePostApiRolesMutation,
  useDeleteApiRolesByRidMutation,
  usePutApiRolesByRidMutation,
  useGetApiRulesProjectByPidQuery,
  useGetApiRulesComponentByPcidQuery,
  useGetApiRulesFindingByFidQuery,
  useGetApiRulesActivityByAidQuery,
  useGetApiStatisticsFindingsCountQuery,
  useGetApiStatusReportByPidAndLastStatusQuery,
  useGetApiStatusReportByPidQuery,
  usePostApiStatusReportV2Mutation,
  useGetApiStatusReportEditableInfoQuery,
  useGetApiStereotypesQuery,
  usePostApiStereotypesMutation,
  useGetApiStereotypesBySidQuery,
  usePutApiStereotypesBySidMutation,
  useDeleteApiStereotypesBySidMutation,
  useGetApiStereotypesBySidActivityTemplatesQuery,
  useGetApiStereotypesByProjectByPidQuery,
  useGetApiSystemVersionQuery,
  useGetApiSystemFindingStatusQuery,
  useGetApiSystemSignalrConfigQuery,
  usePostApiV2TeamMutation,
  useGetApiV2TeamQuery,
  usePutApiV2TeamByTeamIdMutation,
  useGetApiV2TeamByTeamIdQuery,
  useDeleteApiV2TeamByTeamIdMutation,
  useGetApiV2TeamSelectQuery,
  usePostApiV2TeamByTeamIdMemberMutation,
  useDeleteApiV2TeamByTeamIdMemberMutation,
  useGetApiUserPreferencesQuery,
  useGetApiVulnerabilitiesActiveQuery,
  useGetApiVulnerabilitiesInactiveQuery,
  useGetApiVulnerabilitiesByVidQuery,
  useDeleteApiVulnerabilitiesByVidMutation,
  usePutApiVulnerabilitiesByVidUndeleteMutation,
  usePutApiVulnerabilitiesMutation,
  usePostApiVulnerabilitiesMutation,
  usePutApiVulnerabilitiesByFtidMutation,
} = api;
