import { Controller } from 'react-hook-form';
import { Control } from 'react-hook-form/dist/types';

import { Input } from '@components/Input/Input';

import { ICompanyFields } from '../type';

interface IProps {
  control: Control<ICompanyFields>;
}

export const CompanyName = ({ control }: IProps) => (
  <Controller
    control={control}
    name="name"
    render={({ field: { value, onChange, ref } }) => (
      <Input
        label="Company name*"
        value={value}
        onChange={onChange}
        inputRef={ref}
      />
    )}
  />
);
