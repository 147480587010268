import { parseISO } from 'date-fns';
import { useParams } from 'react-router-dom';

import { dateWithTimeAmPmFormat } from '@src/constants/constants';
import { useGetApiStereotypesBySidQuery } from '@store/services/query.generated';
import { getDate } from '@utils/dateUtils';

import s from './index.module.sass';

export const STLastChanges = () => {
  const { sid } = useParams();

  const { data: stereotypeTemplate } = useGetApiStereotypesBySidQuery(
    { sid: sid! },
    { skip: !sid },
  );
  return (
    <div className={s.lastChanges}>
      <div className={s.title}>Last changes</div>
      <div className={s.date}>
        {getDate(
          dateWithTimeAmPmFormat,
          stereotypeTemplate?.ModifiedDate
            ? new Date(parseISO(stereotypeTemplate?.ModifiedDate as string))
            : new Date(),
        )}
      </div>
    </div>
  );
};
