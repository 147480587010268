import { useFinalReport } from '@pages/Reporting/GeneralFinalReport/useFinalReport';
import { KrakenLayout } from '@src/layouts/KrakenLayout/KrakenLayout';
import { DownloadFile } from '@src/popups/DownloadPDF/DownloadFile';

import { FRAddPolicyProcedure } from './FRAddPolicyProcedure';
import { FRAddressAndContactInfo } from './FRAddressAndContactInfo';
import { FRAppendixC } from './FRAppendixC';
import { FRChecklist } from './FRChecklist';
import { FRDate } from './FRDate';
import { FRFooter } from './FRFooter';
import { FRHeaderAndFooterFont } from './FRHeaderAndFooterFont';
import { FRMainFont } from './FRMainFont';
import { FRObjectives } from './FRObjectives';
import { FRSecondFont } from './FRSecondFont';
import { FRStakeholders } from './FRStakeholders';
import { FRTocFont } from './FRTocFont';
import { FRVersion } from './FRVersion';

import s from './FinalReport.module.sass';

export const FinalReport = () => {
  const {
    control,
    handleSubmit,
    isLoading,
    onSubmit,
    watch,
    visible,
    fonts,
    fontsIsLoading,
  } = useFinalReport();

  return (
    <KrakenLayout
      checkList={<FRChecklist watch={watch} />}
      footer={<FRFooter />}
      onSubmit={handleSubmit(onSubmit)}
      isLoading={isLoading}
    >
      <div className={s.wrapper}>
        <FRVersion control={control} />
        <FRDate control={control} />
        <FRAddressAndContactInfo control={control} />
        <FRStakeholders control={control} />
        <FRObjectives control={control} />
        <FRAddPolicyProcedure control={control} />
        <FRAppendixC control={control} />
        <FRMainFont
          control={control}
          fonts={fonts}
          fontsIsLoading={fontsIsLoading}
        />
        <FRHeaderAndFooterFont
          control={control}
          fonts={fonts}
          fontsIsLoading={fontsIsLoading}
        />
        <FRSecondFont
          control={control}
          fonts={fonts}
          fontsIsLoading={fontsIsLoading}
        />
        <FRTocFont
          control={control}
          fonts={fonts}
          fontsIsLoading={fontsIsLoading}
        />
        {visible && <DownloadFile />}
      </div>
    </KrakenLayout>
  );
};
