import { useParams } from 'react-router-dom';

import { BreadCrumbs } from '@components/BreadCrumbs/BreadCrumbs';
import { LinkEnum } from '@src/types/types';

const crumbs = [['All projects', LinkEnum.PROJECTS]];

export const ProjectBreadCrumbs = () => {
  const { pid } = useParams();

  return (
    <BreadCrumbs
      crumbs={crumbs}
      currentPage={`${pid ? 'Edit' : 'Add new'} project`}
    />
  );
};
