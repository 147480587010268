import { useEffect, useState } from 'react';
import { UseFormWatch } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { ICheckListLink } from '@components/CheckList/CheckList';

import { EAddEditActivityLinks, IActivityFields } from '../types';

const linksState: ICheckListLink = {
  [EAddEditActivityLinks.SUBJECT]: false,
  [EAddEditActivityLinks.COMPONENT]: false,
  [EAddEditActivityLinks.SCOPE]: false,
  [EAddEditActivityLinks.METHODOLOGY]: false,
  [EAddEditActivityLinks.OBSERVATIONS]: false,
  [EAddEditActivityLinks.FUTURE_WORK]: false,
};

export const useActivityFieldsChecklist = (
  watch: UseFormWatch<IActivityFields>,
) => {
  const [links, setLinks] = useState(linksState);
  const { aid } = useParams();

  useEffect(() => {
    const subscription = watch((value) => {
      setLinks({
        [EAddEditActivityLinks.SUBJECT]: !!value.Subject,
        [EAddEditActivityLinks.COMPONENT]: !!value.PcId,
        [EAddEditActivityLinks.SCOPE]: !!value.Scope,
        ...(aid ? { [EAddEditActivityLinks.CHECKLIST]: true } : {}),
        [EAddEditActivityLinks.METHODOLOGY]: !!value.Description,
        [EAddEditActivityLinks.OBSERVATIONS]: !!value.Disposition,
        [EAddEditActivityLinks.FUTURE_WORK]: !!value.FutureWork,
        ...(aid ? { [EAddEditActivityLinks.FINDINGS]: true } : {}),
        [EAddEditActivityLinks.NOTES]: !!value.Notes,
      });
    });

    return () => subscription.unsubscribe();
  }, [watch]);
  return links;
};
