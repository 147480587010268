import { Controller } from 'react-hook-form';
import { Control } from 'react-hook-form/dist/types';
import { MenuItem } from '@mui/material';

import { KrakenSelect } from '@components/KrakenSelect/KrakenSelect';
import { GetApiReportsAvailableFontsApiResponse } from '@store/services/query.generated';
import { kebabCase } from '@utils/kebabCase';

import { EFRLinks, IFinalReportFields } from '../types';

interface IProps {
  control: Control<IFinalReportFields>;
  fonts?: GetApiReportsAvailableFontsApiResponse;
  fontsIsLoading: boolean;
}

export const FRMainFont = ({ control, fonts, fontsIsLoading }: IProps) => (
  <Controller
    control={control}
    name="MainFont"
    render={({ field: { value, onChange } }) => (
      <KrakenSelect
        id={kebabCase(EFRLinks.FONTS)}
        label="Main Font"
        value={value || 'Manrope'}
        onChange={onChange}
        isLoading={fontsIsLoading}
      >
        {fonts?.map((fontName) => (
          <MenuItem key={fontName} value={fontName}>
            {fontName}
          </MenuItem>
        ))}
      </KrakenSelect>
    )}
  />
);
