import { parseISO } from 'date-fns';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Avatar } from '@mui/material';

import { dateWithTimeFormat } from '@src/constants/constants';
import { useTypedSelector } from '@src/utils';
import { IGraphData } from '@store/reducers/msalSlice';
import { useGetApiReviewablesEditHistoryByRidQuery } from '@store/services/query.generated';
import { getDate } from '@utils/dateUtils';

import s from './FindingLastChanges.module.sass';

const styles = {
  avatar: {
    width: '24px',
    height: '24px',
    display: 'flex',
    fontSize: '14px',
  },
};

export const FindingLastChanges = () => {
  const [initials, setInitials] = useState('');
  const [date, setDate] = useState(getDate());
  const [editor, setEditor] = useState('');
  const { fid } = useParams();

  const { data: editors } = useGetApiReviewablesEditHistoryByRidQuery(
    { rid: fid! },
    { skip: !fid },
  );

  const { displayName, givenName, surname } = useTypedSelector(
    (state) => state.msalReducer.graphData as IGraphData,
  );

  useEffect(() => {
    if (givenName?.length && surname?.length) {
      setInitials(givenName[0] + surname[0]);
    }
  }, [givenName, surname]);

  useEffect(() => {
    if (editors?.length) {
      const {
        EditorFirstName,
        EditorLastName,
        edit_date: editDate,
      } = editors[editors.length - 1];

      if (EditorFirstName && EditorLastName) {
        setInitials(EditorFirstName[0] + EditorLastName[0]);
        setEditor(`${EditorFirstName} ${EditorLastName}`);
      }

      editDate
        && setDate(
          getDate(dateWithTimeFormat, new Date(parseISO(editDate as string))),
        );
    }
  }, [editors]);

  useEffect(() => {
    if (!fid) {
      setEditor(displayName);
    }
  }, [displayName]);

  return (
    <div className={s.lastChanges}>
      <div className={s.left}>
        <div className={s.title}>{fid ? 'Last changes' : 'Creator'}</div>
        <div className={s.editor}>
          <span>{date}</span>
          <Avatar sx={styles.avatar} alt={editor}>
            {initials}
          </Avatar>
          <span>{editor}</span>
        </div>
      </div>
      <div className={s.right} />
    </div>
  );
};
