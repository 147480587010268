import { Controller } from 'react-hook-form';
import { Control } from 'react-hook-form/dist/types';

import { KrakenTextArea } from '@components/KrakenTextArea/KrakenTextArea';
import { kebabCase } from '@utils/kebabCase';
import { textAreaHandler } from '@utils/textAreaHandler';

import { EAddEditComponentLinks, IComponentFields } from '../types';

const placeholder = `What does this attack surface look like?

What are some of the high-level threats to this component?

What is the impact of compromise or a discovered vulnerability?`;

interface IProps {
  control: Control<IComponentFields>;
}

export const ComponentThreatAnalysis = ({ control }: IProps) => (
  <Controller
    control={control}
    name="ThreatAnalysis"
    render={({ field: { value, onChange } }) => (
      <KrakenTextArea
        id={kebabCase(EAddEditComponentLinks.THREAT_ANALYSIS)}
        title="Threat Analysis"
        value={value || ''}
        onChange={(val) => textAreaHandler(val, onChange)}
        placeholder={placeholder}
        height={180}
        hideToolbar
      />
    )}
  />
);
