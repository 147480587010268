import {
  combineReducers,
  configureStore,
  isRejectedWithValue,
  Middleware,
  MiddlewareAPI,
} from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';

import addEditActivityReducer from './reducers/addEditActivitySlice';
import addEditComponentReducer from './reducers/addEditComponentSlice';
import addEditFindingReducer from './reducers/addEditFindingSlice';
import addProjectReducer from './reducers/addProjectSlice';
import componentsReducer from './reducers/componentsSlice';
import downloadFileReducer from './reducers/downloadFileSlice';
import errorReducer, { showError } from './reducers/errorSlice';
import findingsByUserReducer from './reducers/findingsByUserSlice';
import msalReducer from './reducers/msalSlice';
import signalRReducer from './reducers/signalRSlice';
import themeReducer from './reducers/themeSlice';
import { enhancedApi } from './services/enhancedApi';
import { api } from './services/query.generated';

export const reducers = {
  msalReducer,
  errorReducer,
  themeReducer,
  addEditActivityReducer,
  addEditComponentReducer,
  signalRReducer,
  addEditFindingReducer,
  addProjectReducer,
  componentsReducer,
  findingsByUserReducer,
  downloadFileReducer,
  [api.reducerPath]: api.reducer,
};

const rootReducer = combineReducers(reducers);

enhancedApi();

const rtkQueryErrorLogger: Middleware = (store: MiddlewareAPI) => (next) => (action) => {
    // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these matchers!

    if (isRejectedWithValue(action) && action?.payload?.status !== 401) {
      const { method, url } = action.meta.baseQueryMeta.request;
      const { status, data } = action.payload;

      let message = `<b>Request:</b>\n<b>Method:</b> <i>${method}</i>\n<b>Url:</b> <i>${url}</i>\n<b>Status:</b> <i>${status}</i>\n`;
      data && typeof data !== 'string'
        ? Object.entries(data).forEach(([key, value]) => {
            message += `<b>${key}:</b> <i>${JSON.stringify(value)}</i>\n`;
          })
        : (message += `<b>Message:</b> ${data}`);

      store.dispatch(showError({ message }));
    }

    return next(action);
  };

const setupStore = () => configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false,
      }).concat(rtkQueryErrorLogger, api.middleware),
  });

export const store = setupStore();

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];
