import { useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';

import { ICompanyFields } from '@pages/Admin/Companies/AddEditCompanies/type';
import { LinkEnum } from '@src/types/types';
import {
  KrakenModelsDtoCompanyUpdateCompanyDto,
  useGetApiV2CompaniesByCidQuery,
  usePostApiCompaniesOldMutation,
  usePutApiV2CompaniesByCidMutation,
} from '@store/services/query.generated';

const schema = yup.object().shape({
  name: yup.string().required().min(3),
  logo: yup.string(),
});

export const useAddEditCompany = () => {
  const { cid } = useParams();
  const navigate = useNavigate();
  const {
 handleSubmit, control, formState, setFocus, reset,
} = useForm<ICompanyFields>({
      mode: 'onChange',
      resolver: yupResolver(schema),
    });

  const { data: company, isLoading: companyLoading } = useGetApiV2CompaniesByCidQuery({ cid: cid! }, { skip: !cid });
  const [putCompanyByCid, { isLoading: putLoading }] = usePutApiV2CompaniesByCidMutation();
  const [createCompany, { isLoading: postLoading }] = usePostApiCompaniesOldMutation();

  const onSubmit: SubmitHandler<ICompanyFields> = (data) => {
    if (cid) {
      const krakenModelsDtoCompanyUpdateCompanyDto: KrakenModelsDtoCompanyUpdateCompanyDto = {
          Name: data.name,
        };

      if (data.logo) {
        krakenModelsDtoCompanyUpdateCompanyDto.Logo = data.logo.replace(
          /.+base64,/g,
          '',
        ); // it's possible like this: -> .split(',')[1]
      }

      putCompanyByCid({
        cid,
        krakenModelsDtoCompanyUpdateCompanyDto,
      }).then(() => {
        reset({
          name: data.name,
          logo: data.logo || '',
        });
      });
    } else {
      createCompany({
        krakenBackendApiModelsCompaniesApiNewCompany: {
          Name: data.name,
          ...(data.logo ? { Logo: data.logo.split(',')[1] } : {}),
        },
      }).then((resp: any) => {
        resp?.data
          && navigate(LinkEnum.EDIT_COMPANY.replace(':cid', resp.data));
      });
    }
  };

  useEffect(() => {
    if (company) {
      reset({
        name: company?.Name || '',
        logo: company?.Logo ? `data:image/png;base64,${company?.Logo}` : '',
      });
    }
  }, [company]);

  useEffect(() => {
    setFocus('name');
  }, [setFocus]);

  return {
    companyLoading,
    putLoading,
    postLoading,
    cid,
    handleSubmit,
    onSubmit,
    control,
    formState,
  };
};
