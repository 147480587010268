export enum EATList {
  SUBJECT = 'Subject',
  DESCRIPTION = 'Methodology',
  SCOPE = 'Scope',
  NOTES = 'Notes',
  CHECKLIST = 'Checklist',
}

export interface IActivityTemplateFields {
  Subject: string | null;
  Description: string | null;
  Scope: string | null;
  Notes: string | null;
}
