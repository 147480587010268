import cn from 'classnames';
import { MutableRefObject } from 'react';
import SunEditorCore from 'suneditor/src/lib/core';
import SunEditor from 'suneditor-react';
import { FormControl } from '@mui/material';

import Icon from '@components/Icon/Icon';

import s from './KrakenTextArea.module.sass';

interface IProps {
  title: string;
  value: string;
  onChange: (e: string) => void;
  onInput?: (event: InputEvent) => void;
  placeholder?: string;
  radioBtns?: JSX.Element;
  id?: string;
  info?: string;
  hideToolbar?: boolean;
  getRef?: MutableRefObject<SunEditorCore | null> | null;
  prefix?: string;
  // All others properties
  [x: string]: any;
}

const setOptions = {
  buttonList: [
    // the list of all button names is in the same folder in the type.ts file
    ['undo', 'redo'],
    ['font', 'fontSize', 'formatBlock'],
    ['bold', 'underline', 'italic', 'strike'],
    ['subscript', 'superscript'],
    ['fontColor', 'hiliteColor', 'textStyle'],
    ['removeFormat'],
    ['align', 'lineHeight'],
    ['horizontalRule', 'list', 'table'],
    ['link', 'image'],
    ['fullScreen', 'preview', 'print', 'codeView', 'showBlocks'],
  ],
};

export const KrakenTextArea = ({
  title,
  value,
  onChange,
  placeholder = '',
  radioBtns,
  id = '',
  info = '',
  hideToolbar = false,
  getRef = null,
  prefix = '',
  ...other
}: IProps) => {
  const getInstance = (sunEditor: SunEditorCore) => {
    if (getRef?.current) {
      getRef.current = sunEditor;
    }
  };

  return (
    <FormControl id={id} variant="standard" fullWidth>
      <h3
        className={cn(s.header, {
          [s.mb8]: !radioBtns,
          [s.m0]: radioBtns,
        })}
      >
        {title}
      </h3>
      {prefix && <span className={s.prefix}>{prefix}</span>}
      {radioBtns && radioBtns}
      <SunEditor
        onChange={onChange}
        placeholder={placeholder}
        setContents={value}
        hideToolbar={hideToolbar}
        getSunEditorInstance={getInstance}
        {...(hideToolbar
          ? {
              defaultTag: '',
              setOptions: {
                showPathLabel: false,
              },
            }
          : {
              setOptions,
            })}
        {...other}
      />
      {info && (
        <span className={s.info}>
          <Icon iconName="info" />
          {info}
        </span>
      )}
    </FormControl>
  );
};
