import { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';

import { ICompanyFields } from '@pages/Admin/Companies/AddEditCompanies/type';
import { usePostApiCompaniesOldMutation } from '@store/services/query.generated';

const schema = yup.object().shape({
  name: yup.string().required().min(2),
  logo: yup.string(),
});

export const useAddCompany = (closeCompaniesPopup: () => void) => {
  const [readySubmit, setReadySubmit] = useState(false);
  const {
    control,
    handleSubmit,
    formState: { isDirty, isValid },
    setFocus,
  } = useForm<ICompanyFields>({
    mode: 'onChange',
    resolver: yupResolver(schema),
  });

  const [createCompany] = usePostApiCompaniesOldMutation();

  const onSubmit: SubmitHandler<ICompanyFields> = (data) => {
    createCompany({
      krakenBackendApiModelsCompaniesApiNewCompany: {
        Name: data.name,
        ...(data.logo ? { Logo: data.logo.split(',')[1] } : {}),
      },
    }).then(() => {
      closeCompaniesPopup();
    });
  };

  useEffect(() => {
    setFocus('name');
  }, [setFocus]);

  useEffect(() => {
    setReadySubmit(isDirty && isValid);
  }, [isDirty, isValid]);

  return {
    handleSubmit,
    onSubmit,
    control,
    readySubmit,
  };
};
