import { Controller } from 'react-hook-form';
import { Control } from 'react-hook-form/dist/types';

import { KrakenTextArea } from '@components/KrakenTextArea/KrakenTextArea';
import { kebabCase } from '@utils/kebabCase';
import { textAreaHandler } from '@utils/textAreaHandler';

import { EESLinks, IExecutiveSummaryFields } from '../types';

interface IProps {
  control: Control<IExecutiveSummaryFields>;
}

export const ESNotes = ({ control }: IProps) => (
  <Controller
    control={control}
    name="Notes"
    render={({ field: { value, onChange } }) => (
      <KrakenTextArea
        id={kebabCase(EESLinks.INTERNAL_NOTES)}
        title="Internal Notes*"
        height={180}
        hideToolbar
        value={value || ''}
        onChange={(val) => textAreaHandler(val, onChange)}
      />
    )}
  />
);
