import * as yup from 'yup';

import { EChecklistBtn, EReviewRoles } from '@src/types/types';

export enum ERoles {
  admin = '3ed7ebcc-8f59-4e3c-9b79-736914603716',
  user = '22e8f125-7c82-4982-b245-3d0fcfde1310',
  allProjects = '5ed6e614-384a-4c8e-9312-7d24e014a2a9',
  metrics = 'cc88721a-b7ed-47e1-ae0e-e70bd21a410e',
  templateEditor = '67d488e8-2c54-4e59-b829-fa9bd0dd54db',
  automation = 'eccf976d-6b20-4783-982a-81d07a854900',
}

export const Roles = {
  'Security Advisor': EReviewRoles.SECURITY_ADVISOR,
  'Technical Reviewer': EReviewRoles.TECHNICAL_REVIEWER,
  'Executive Sponsor': EReviewRoles.EXECUTIVE_SPONSOR,
  Consultant: EReviewRoles.CONSULTANT,
  'Technical Lead': EReviewRoles.TECHNICAL_LEAD,
  'Project Manager': EReviewRoles.PROJECT_MANAGER,
};

export const fetchOptions = {
  headers: {
    'Access-Control-Allow-Origin': '*',
    Authorization: `Bearer ${localStorage.getItem('idToken')}`,
    'X-CSRF': '1',
    redirect: 'error',
    mode: 'cors',
  },
};

export const xcsrf = { 'X-CSRF': '1' };
export const dateWithTimeFormat = 'dd.MM.yyyy, HH:mm:ss';
export const dateWithTimeAmPmFormat = 'yyyy, MMM dd, p';
export const datepickerDateFormat = 'dd/MM/yyyy';
export const dateFormat = 'dd MMM yyyy';
export const reverseDateFormat = 'yyyy-MM-dd';

export const buttonsList = {
  [EChecklistBtn.INCOMPLETE]: 'NotStarted',
  [EChecklistBtn.TESTED]: 'Tested',
  [EChecklistBtn.N_A]: 'NotAvailable',
};

// YUP
yup.addMethod(
  yup.mixed,
  'defined',
  function defined(msg = '$path must be defined') {
    return this.test('defined', msg, (value) => value !== undefined);
  },
);
yup.addMethod(
  yup.object,
  'atLeastOneOf',
  function atLeastOneOf(list: string[]) {
    return this.test({
      name: 'atLeastOneOf',
      // eslint-disable-next-line no-template-curly-in-string
      message: '${path} must have at least one of these keys: ${keys}',
      exclusive: true,
      params: { keys: list.join(', ') },
      test: (value) => value == null || list.some((f: any) => !!value[f]),
    });
  },
);

export const yupRequiredString = yup
  .string()
  .trim()
  .required('required string min 2')
  .min(2);
export const yupString = yup.string().notRequired();
export const yupBoolean = yup.bool().default(false).notRequired();
export const yupRequiredDate = yup.date().required();
export const yupDateRange = yup.array().of(yup.date().default(null));
export const yupRequiredDateRange = yup
  .array()
  .of(yup.date().required('required date'))
  .defined()
  .nullable();
export const yupRequiredStringArray = yup.array().of(yupString).required();
export const yupStringArray = yup.array().of(yupString);
