import { ChangeEvent } from 'react';
import { v4 } from 'uuid';
import { FormControl, InputBase, InputLabel } from '@mui/material';

import Icon from '../Icon/Icon';

import s from './Input.module.sass';

interface IProps {
  id?: string;
  label?: string;
  value?: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  info?: string;
  placeholder?: string;
  // All others properties
  [x: string]: any;
}

export const Input = ({
  id = '',
  label,
  value = '',
  onChange = () => {},
  placeholder = '',
  info,
  ...other
}: IProps) => {
  const inputId = v4();

  return (
    <FormControl id={id} variant="standard" fullWidth>
      {label && (
        <InputLabel shrink htmlFor={inputId}>
          {label}
        </InputLabel>
      )}
      <InputBase
        value={value}
        id={inputId}
        onChange={onChange}
        placeholder={placeholder}
        {...other}
      />
      {info && (
        <span className={s.info}>
          <Icon iconName="info" />
          {info}
        </span>
      )}
    </FormControl>
  );
};
