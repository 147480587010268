import { Buffer } from 'buffer';

import { graphConfig } from '@src/config/authConfig';

export const getMsPhoto = async (accessToken: string) => {
  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;

  headers.append('Authorization', bearer);

  const options = {
    method: 'GET',
    headers,
    'Content-Type': 'image/jpg',
  };

  const response = await fetch(
    `${graphConfig.graphMeEndpoint}/photos/240x240/$value`,
    options,
  );

  if (!response.ok) {
    return null;
  }

  const imageBuffer = await response.arrayBuffer();

  return imageBuffer
    ? `data:image/png;base64,${Buffer.from(imageBuffer).toString('base64')}`
    : null;
};
