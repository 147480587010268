import { useEffect, useState } from 'react';
import { Avatar } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';

import { useTypedSelector } from '@src/utils';
import { IGraphData } from '@store/reducers/msalSlice';

import Icon from '../Icon/Icon';

import './UserBar.sass';

export function UserBar() {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [initials, setInitials] = useState('');

  const { graphData, userPhoto } = useTypedSelector(
    (state) => state.msalReducer,
  );

  const {
 displayName, jobTitle, givenName, surname,
} = graphData as IGraphData;

  const handleSnackbarClick = () => {
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = (reason: any) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbarOpen(false);
  };

  useEffect(() => {
    if (givenName?.length && surname?.length) {
      setInitials(givenName[0] + surname[0]);
    }
  }, [givenName, surname]);

  return (
    <div className="user-bar">
      <div className="user-bar__controls">
        <button
          type="button"
          className="user-bar__notification active"
          onClick={handleSnackbarClick}
          disabled
          hidden
        >
          <Icon className="user-bar__notification-icon" iconName="bell" />
          <span className="user-bar__notification-indicator" />
        </button>
      </div>
      <div className="user-bar__card">
        <div className="user-bar__card-content">
          <div className="user-bar__card-title">{displayName}</div>
          <div className="user-bar__card-label">{jobTitle}</div>
        </div>
        <Avatar
          sx={{ bgcolor: '#A5A6F6' }}
          alt={displayName}
          src={userPhoto || ''}
        >
          {initials}
        </Avatar>
      </div>

      <Snackbar
        open={snackbarOpen}
        onClose={handleSnackbarClose}
        action={(
          <button
            type="button"
            className="snackbar-alert__close"
            onClick={handleSnackbarClose}
          >
            <Icon className="snackbar-alert__close-icon" iconName="close" />
          </button>
        )}
        message="This is a Snackbar message!"
        className="snackbar-alert"
      />
    </div>
  );
}

export default UserBar;
