import { useState } from 'react';
import { Autocomplete, LinearProgress, TextField } from '@mui/material';

import { xcsrf } from '@src/constants/constants';
import {
  GetApiActivityTemplatesApiResponse,
  KrakenBackendApiModelsActivityTemplatesApiActivityTemplateAssociated,
  useGetApiActivityTemplatesQuery,
  usePutApiComponentsByCidAddActivitiesFromTemplatesMutation,
} from '@store/services/query.generated';

import s from './ComponentActivities.module.sass';

interface IProps {
  cid: string | undefined;
}

export const ComponentActivities = ({ cid }: IProps) => {
  const [value, setValue] = useState<KrakenBackendApiModelsActivityTemplatesApiActivityTemplateAssociated | null>(
      null,
    );

  const { data } = useGetApiActivityTemplatesQuery(xcsrf);
  const [putActivities, { isLoading }] = usePutApiComponentsByCidAddActivitiesFromTemplatesMutation();

  const onChange = (e: any, newValue: any) => {
    e.stopPropagation();
    setValue(newValue);
    putActivities({ cid: cid!, body: [newValue.Id] }).finally(() => setValue(null));
  };

  const onClick = (e: any) => {
    e.stopPropagation();
  };

  return (
    <div className={s.wrapper}>
      {data && (
        <Autocomplete
          value={value}
          disablePortal
          options={data as GetApiActivityTemplatesApiResponse}
          renderOption={(props, option) => (
            <li {...props} style={{ fontSize: '12px' }}>
              {option.Subject}
            </li>
          )}
          getOptionLabel={(option) => `Added ${option.Subject!}`}
          isOptionEqualToValue={(option, val) => option?.Subject === val?.Subject}
          selectOnFocus
          clearOnBlur
          handleHomeEndKeys
          freeSolo
          renderInput={(params) => (
            <TextField
              {...params}
              inputProps={{
                ...params.inputProps,
                onClick,
                placeholder: 'Select activity template',
                sx: {
                  color: '#a2a2a2',
                },
              }}
            />
          )}
          onChange={onChange}
        />
      )}
      {isLoading && (
        <div className={s.loading}>
          <LinearProgress />
        </div>
      )}
    </div>
  );
};
