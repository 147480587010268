import { parseISO } from 'date-fns';
import { IconButton, TextareaAutosize, Zoom } from '@mui/material';

import Icon from '@components/Icon/Icon';
import { RadioButtons } from '@components/RadioButtons/RadioButtons';
import { useListItem } from '@pages/Project/Activities/AddEditActivity/ActivityChecklist/useListItem';
import { buttonsList, dateWithTimeFormat } from '@src/constants/constants';
import { KrakenBackendApiModelsActivitiesApiActivityChecklistItem } from '@store/services/query.generated';
import { getDate } from '@utils/dateUtils';

import s from './ActivityChecklist.module.sass';

interface IProps {
  data: KrakenBackendApiModelsActivitiesApiActivityChecklistItem;
}

export const ListItem = ({ data }: IProps) => {
  const {
    textareaValue,
    readonly,
    textareaRef,
    onEdit,
    onDelete,
    apply,
    btnChecked,
    onTextarea,
    onChange,
  } = useListItem(data);

  return (
    <div className={s.listItemWrapper}>
      <div className={s.title}>
        Last Modified:&nbsp;
        {data.editTime
          && getDate(
            dateWithTimeFormat,
            new Date(parseISO(data.editTime as string)),
          )}
      </div>
      <div className={s.heading}>
        <TextareaAutosize
          aria-label="header of checklist"
          value={textareaValue}
          readOnly={readonly}
          minRows={1}
          className={s.textarea}
          ref={textareaRef}
          onChange={onTextarea}
        />
        <div className={s.btns}>
          <Zoom in={readonly}>
            <IconButton size="small" aria-label="edit" onClick={onEdit}>
              <Icon iconName="edit" />
            </IconButton>
          </Zoom>
          <IconButton size="small" aria-label="delete" onClick={onDelete}>
            <Icon iconName="delete" />
          </IconButton>
        </div>
        <Zoom in={!readonly} className={s.apply}>
          <IconButton size="small" aria-label="apply" onClick={apply}>
            <Icon iconName="apply" />
          </IconButton>
        </Zoom>
      </div>
      <div className={s.btnWrapper}>
        <RadioButtons
          list={Object.keys(buttonsList)}
          checked={btnChecked}
          onChange={onChange}
        />
      </div>
    </div>
  );
};
