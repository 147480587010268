import { parseISO } from 'date-fns';
import { useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { xcsrf } from '@src/constants/constants';
import { useTypedSelector } from '@src/utils';
import {
  useGetApiReportsAvailableFontsQuery,
  useGetApiStatusReportEditableInfoQuery,
} from '@store/services/query.generated';
import { useActions } from '@utils/hooks/useActions';

import { IStatusReportFields } from './types';

export const useStatusReport = () => {
  const { pid } = useParams();
  const { showDownloadFilePopup } = useActions();
  const {
 control, watch, handleSubmit, reset,
} = useForm<IStatusReportFields>({
    mode: 'onChange',
  });

  const { visible } = useTypedSelector((state) => state.downloadFileReducer);
  const { data: statusReport, isLoading: reportIsLoading } = useGetApiStatusReportEditableInfoQuery({ pid: pid! });
  const { data: fonts, isLoading: fontsIsLoading } = useGetApiReportsAvailableFontsQuery(xcsrf);

  const onSubmit: SubmitHandler<IStatusReportFields> = (data) => {
    const payload = {
      ReportDate: data.ReportDate,
      AddressAndContactInfo: data.AddressAndContactInfo,
      Stakeholders: data.Stakeholders,
      ProjectState: data.ProjectState,
      Background: data.Background,
      CurrentStatus: data.CurrentStatus,
      AddAppendixA: data.AddAppendixA,
      AppendixAHeader: data.AppendixAHeader,
      AppendixABody: data.AppendixABody,
      FontSettings: {
        MainFont: data.MainFont,
        HeaderFooter: data.HeaderFooter,
        Classification: data.Classification,
        TableOfContent: null,
      },
    };

    showDownloadFilePopup({
      uri: `/api/StatusReport/v2?pid=${pid}${
        data.lastStatusDate ? `&lastStatus=${data.lastStatusDate}` : ''
      }`,
      payload,
      fileName: 'StatusReport.pdf',
    });
  };

  useEffect(() => {
    if (statusReport) {
      reset({
        lastStatusDate: null,
        ReportDate: parseISO(statusReport.ReportDate as string) || null,
        AddressAndContactInfo: statusReport.AddressAndContactInfo || null,
        Stakeholders: statusReport.Stakeholders || null,
        ProjectState: statusReport.ProjectState || null,
        Background: statusReport.Background || null,
        CurrentStatus: statusReport.CurrentStatus || null,
        AddAppendixA: statusReport.AddAppendixA || true,
        AppendixAHeader: statusReport.AppendixAHeader || null,
        AppendixABody: statusReport.AppendixABody || null,
        MainFont: statusReport.FontSettings?.MainFont || 'Manrope',
        HeaderFooter: statusReport.FontSettings?.HeaderFooter || 'IBMPlexMono',
        Classification: statusReport.FontSettings?.Classification || 'PTRootUI',
      });
    }
  }, [statusReport]);

  return {
    control,
    visible,
    watch,
    handleSubmit,
    onSubmit,
    isLoading: reportIsLoading || fontsIsLoading,
    fonts,
    fontsIsLoading,
  };
};
