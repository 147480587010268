const defaultStyles = {
  styleOverrides: {
    root: {
      fontWeight: 600,
      fontSize: '12px',
      lineHeight: '10px',
      color: '#6A7380',
      '&.Mui-selected': {
        color: '#090C15',
      },
    },
  },
};

export const MuiMenuItem = {
  ...defaultStyles,
};
