import { Controller } from 'react-hook-form';
import { Control } from 'react-hook-form/dist/types';

import { KrakenTextArea } from '@components/KrakenTextArea/KrakenTextArea';
import { RadioButtons } from '@components/RadioButtons/RadioButtons';
import { kebabCase } from '@utils/kebabCase';
import { textAreaHandler } from '@utils/textAreaHandler';

import { impactList } from '../constants';
import { EAddEditFindingLinks, IFindingFields } from '../types';

const impactPlaceholder = `Impact Rationale: Point of view: Third person. Don’t use first person (e.g., I, we, our) or second person (e.g., you, your).

Content: Explain how the customer’s business or operations are impacted by the following: compromised confidentiality, integrity, or availability (CIA); compliance issue (e.g., HIPAA); potential financial or reputational loss; or difficulty of recovery. In other words, answer the questions, “So what? Why should we care about this?”`;

interface IProps {
  control: Control<IFindingFields>;
}

export const FindingImpact = ({ control }: IProps) => (
  <Controller
    control={control}
    name="impactRationale"
    render={({ field: { value, onChange } }) => (
      <KrakenTextArea
        id={kebabCase(EAddEditFindingLinks.IMPACT)}
        title="Impact"
        value={value || ''}
        onChange={(val) => textAreaHandler(val, onChange)}
        placeholder={impactPlaceholder}
        height={180}
        radioBtns={(
          <Controller
            control={control}
            name="impact"
            render={({ field: { value: checked, onChange: handler } }) => (
              <RadioButtons
                list={Object.keys(impactList)}
                checked={checked || 'Info'}
                onChange={(e) => handler(e.target.value)}
              />
            )}
          />
        )}
        hideToolbar
      />
    )}
  />
);
