import { UseFormWatch } from 'react-hook-form';

import { CheckList } from '@components/CheckList/CheckList';
import { useFieldChecklist } from '@pages/Admin/ActivityTemplates/AddEditActivityTemplate/FieldChecklist/useFieldChecklist';

import { IActivityTemplateFields } from '../types';

interface IProps {
  watch: UseFormWatch<IActivityTemplateFields>;
}

export const FieldChecklist = ({ watch }: IProps) => {
  const links = useFieldChecklist(watch);

  return <CheckList asideLinks={links} />;
};
