import { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import { TabBox } from '@components/TabBox/TabBox';
import { Activities } from '@pages/Project/Activities/Activities';
import { Users } from '@pages/Project/Users/Users';
import { LinkEnum } from '@src/types/types';
import { useGetApiProjectsByPidQuery } from '@store/services/query.generated';
import { kebabCase } from '@utils/kebabCase';

import { ExecutiveSummary } from '../Reporting/ExecutiveSummary/ExecutiveSummary';

import { Components } from './Components/Components';
import { Findings } from './Findings/Findings';
import { Reporting } from './Reporting/Reporting';
import { EProjectTab } from './types';

const tabs = [
  {
    tabName: EProjectTab.PROGRESS,
    iconName: 'progress',
    link: LinkEnum.PROGRESS,
    component: <>Progress</>,
  },
  {
    tabName: EProjectTab.FINDINGS,
    iconName: 'findings',
    link: LinkEnum.FINDINGS,
    component: <Findings />,
  },
  {
    tabName: EProjectTab.ACTIVITIES,
    iconName: 'activities',
    link: LinkEnum.ACTIVITIES,
    component: <Activities />,
  },
  {
    tabName: EProjectTab.EXECUTIVE_SUMMARY,
    iconName: 'executiveSummary',
    link: LinkEnum.EXECUTIVE_SUMMARY,
    component: <ExecutiveSummary />,
  },
  {
    tabName: EProjectTab.COMPONENTS,
    iconName: 'components',
    link: LinkEnum.COMPONENTS,
    component: <Components />,
  },
  {
    tabName: EProjectTab.USERS,
    iconName: 'users',
    link: LinkEnum.USERS,
    component: <Users />,
  },
  {
    tabName: EProjectTab.REPORTING,
    iconName: 'report',
    link: LinkEnum.REPORTING,
    component: <Reporting />,
  },
];

export const Project = () => {
  const { pid } = useParams();
  const [activeTab, setActiveTab] = useState<number | null>(null);
  const { pathname } = useLocation();
  const { data: prData = {} } = useGetApiProjectsByPidQuery(
    { pid: pid! },
    { skip: !pid },
  );

  useEffect(() => {
    const index = tabs.findIndex((t) => pathname.includes(kebabCase(t.tabName)));
    index >= 0 && setActiveTab(index);
  }, [pathname]);

  return (
    <div>
      {activeTab && activeTab >= 0 && (
        <TabBox
          headerName={prData?.prName ? prData.prName : 'Project'}
          tabs={tabs}
          activeTab={activeTab}
          urlNavigate
          orientation="vertical"
        />
      )}
    </div>
  );
};
