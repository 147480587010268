import cn from 'classnames';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from '@mui/material';

import { Datepicker } from '@components/Datepicker/Datepicker';
import Icon from '@components/Icon/Icon';
import { KrakenListItem } from '@components/KrakenListItem/KrakenListItem';
import { reverseDateFormat } from '@src/constants/constants';
import { DownloadFile } from '@src/popups/DownloadPDF/DownloadFile';
import { LinkEnum } from '@src/types/types';
import { useTypedSelector } from '@src/utils';
import { getDate } from '@utils/dateUtils';
import { useActions } from '@utils/hooks/useActions';
import { useAWS } from '@utils/hooks/useAWS';
import { kebabCase } from '@utils/kebabCase';

import s from '../Reporting.module.sass';

export const ReportingList = () => {
  const [lastStatus, setLastStatus] = useState<Date | null>(null);
  const { pid } = useParams();
  const navigate = useNavigate();
  const isAWS = useAWS();
  const { showDownloadFilePopup } = useActions();

  const { visible } = useTypedSelector((state) => state.downloadFileReducer);

  const handler = (key: string) => {
    navigate(LinkEnum[key as keyof typeof LinkEnum].replace(':pid', pid!), {
      state: { linkTemplate: LinkEnum[key as keyof typeof LinkEnum] },
    });
  };

  const onChange = (date: (Date | null) | [Date | null, Date | null]) => {
    if (date instanceof Date) {
      setLastStatus(date);
    }
  };

  const reportFromTime = (e: any) => {
    e.stopPropagation();

    showDownloadFilePopup({
      uri: `/api/StatusReport/${pid}/${getDate(
        reverseDateFormat,
        lastStatus!,
      )}T00:00:00.000Z`,
      method: 'GET',
      fileName: 'StatusReport.docx',
    });
  };

  const AWSList = [
    {
      name: 'Download Findings.json',
      onClick: () => {
        showDownloadFilePopup({
          uri: `/api/RawBugs/AWSJson/${pid}`,
          method: 'GET',
          fileName: 'Findings.json',
        });
      },
    },
    {
      name: 'Final Report',
      onClick: () => handler('FINAL_REPORT'),
    },
    {
      name: 'Status Report',
      onClick: () => handler('STATUS_REPORT'),
    },
  ];

  const list = [
    {
      name: 'Final Report',
      onClick: () => {
        showDownloadFilePopup({
          uri: `/api/FinalReport/${pid}`,
          method: 'GET',
          fileName: 'FinalReport.docx',
        });
      },
    },
    {
      name: 'Status Report',
      onClick: (e: any) => {
        e.stopPropagation();

        showDownloadFilePopup({
          uri: `/api/StatusReport/${pid}`,
          method: 'GET',
          fileName: 'StatusReport.docx',
        });
      },
    },
    {
      name: 'Download Findings.xml',
      onClick: () => {
        showDownloadFilePopup({
          uri: `/api/RawBugs/XML/${pid}`,
          method: 'GET',
          fileName: 'Findings.xml',
        });
      },
    },
  ];

  return (
    <div className={s.Reporting}>
      {isAWS
        ? AWSList.map(({ name, onClick }) => {
            const isDFJ = name === 'Download Findings.json';
            return (
              <KrakenListItem
                key={name}
                classname={cn(s.item, { [s.disabled]: isDFJ })}
                handler={isDFJ ? () => {} : onClick}
              >
                <div className={cn(s.icon, s[kebabCase(name)])}>
                  <Icon iconName="file" />
                </div>
                <span>{name}</span>
                {isDFJ && (
                  <Button size="large" onClick={onClick}>
                    Download
                  </Button>
                )}
              </KrakenListItem>
            );
          })
        : list.map(({ name, onClick }) => {
            const isStatusReport = name === 'Status Report';

            return (
              <KrakenListItem
                key={name}
                classname={cn(s.item, s.disabled, s[kebabCase(name)])}
                handler={() => {}}
              >
                <div className={cn(s.icon, s[kebabCase(name)])}>
                  <Icon iconName="file" />
                </div>
                <span>{name}</span>
                <Button size="large" onClick={onClick}>
                  {name === 'Download Findings.xml'
                    ? 'Download'
                    : 'Generate Report'}
                </Button>
                {isStatusReport && (
                  <>
                    <div />
                    <Datepicker
                      className={s.lastStatus}
                      startDate={lastStatus}
                      onChange={onChange}
                    />
                    <Button
                      size="large"
                      onClick={reportFromTime}
                      disabled={!lastStatus}
                    >
                      Report from time
                    </Button>
                  </>
                )}
              </KrakenListItem>
            );
          })}
      {visible && <DownloadFile />}
    </div>
  );
};
