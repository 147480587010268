import { ReactElement, SyntheticEvent, useState } from 'react';
import { Link } from 'react-router-dom';
import {
 Button, Divider, LinearProgress, Menu, MenuItem,
} from '@mui/material';

import { EmptyList } from '@components/EmptyList/EmptyList';
import { Footer } from '@components/Footer/Footer';
import Icon from '@components/Icon/Icon';
import { ERoles } from '@src/constants/constants';
import { LinkEnum } from '@src/types/types';
import { useIsInRole } from '@src/utils/hooks/useIsInRole';
import { useGetApiProjectsV2Query } from '@store/services/query.generated';

import ProjectsList from './ProjectsList/ProjectsList';

import styles from './Projects.module.sass';

enum EFilterName {
  ALL_PROJECTS = 'All projects',
  ONLY_MY_PROJECTS = 'Only my projects',
  ONLY_ACTIVE = 'Only active',
  ONLY_INACTIVE = 'Only inactive',
}

function ProjectsPage(): ReactElement {
  const [anchorEl, setAnchorEl] = useState<null | Element>(null);
  const [active, setActive] = useState(true);
  const [mine, setMine] = useState(true);
  const { isInRole: isAdmin } = useIsInRole(ERoles.admin);
  const open = Boolean(anchorEl);

  const { data, isLoading } = useGetApiProjectsV2Query({ active, mine });

  const handleClick = (event: SyntheticEvent) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleActive = ({ target: { innerText } }: any) => {
    setActive(innerText === EFilterName.ONLY_ACTIVE);
    handleClose();
  };

  const handleMine = ({ target: { innerText } }: any) => {
    setMine(innerText !== EFilterName.ALL_PROJECTS);
    handleClose();
  };

  const btn = (
    <Button
      component={Link}
      color="primary"
      size="small"
      to={LinkEnum.ADD_PROJECT}
    >
      Add new project
    </Button>
  );

  return (
    <div className={styles.projectsWrapper}>
      <div className={styles.header}>
        <span className={styles.title}>Projects</span>
        <Button
          id="filter-projects-button"
          aria-controls={open ? 'filter-projects-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
          variant="text"
          startIcon={<Icon iconName="filter" />}
        >
          Filter
        </Button>
        <Menu
          autoFocus
          id="filter-projects-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          MenuListProps={{
            'aria-labelledby': 'filter-projects-button',
          }}
        >
          {isAdmin && (
            <div>
              <MenuItem
                sx={{ width: 167 }}
                onClick={handleMine}
                selected={!mine}
              >
                {EFilterName.ALL_PROJECTS}
              </MenuItem>
              <MenuItem
                sx={{ width: 167 }}
                onClick={handleMine}
                selected={mine}
              >
                {EFilterName.ONLY_MY_PROJECTS}
              </MenuItem>
              <Divider />
            </div>
          )}
          <MenuItem
            sx={{ width: 167 }}
            onClick={handleActive}
            selected={active}
          >
            {EFilterName.ONLY_ACTIVE}
          </MenuItem>
          <MenuItem
            sx={{ width: 167 }}
            onClick={handleActive}
            selected={!active}
          >
            {EFilterName.ONLY_INACTIVE}
          </MenuItem>
        </Menu>
      </div>
      {isLoading && <LinearProgress />}
      {!isLoading && !data?.length && (
        <EmptyList to={LinkEnum.ADD_PROJECT} listName="project" />
      )}
      {!isLoading && data && !!data.length && (
        <>
          <ProjectsList projectsData={data} />
          <Footer>{btn}</Footer>
        </>
      )}
    </div>
  );
}

export default ProjectsPage;
