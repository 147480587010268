import { useEffect, useState } from 'react';
import { UseFormWatch } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { ICheckListLink } from '@components/CheckList/CheckList';

import { EATList, IActivityTemplateFields } from '../types';

const linkState: ICheckListLink = {
  [EATList.SUBJECT]: false,
  [EATList.SCOPE]: false,
  [EATList.DESCRIPTION]: false,
  [EATList.NOTES]: false,
};

export const useFieldChecklist = (
  watch: UseFormWatch<IActivityTemplateFields>,
) => {
  const [links, setLinks] = useState(linkState);
  const { atid } = useParams();

  useEffect(() => {
    const subscription = watch((value) => {
      setLinks({
        [EATList.SUBJECT]: Boolean(value.Subject),
        [EATList.SCOPE]: Boolean(value.Scope),
        [EATList.DESCRIPTION]: Boolean(value.Description),
        ...(atid ? { [EATList.CHECKLIST]: true } : {}),
        [EATList.NOTES]: Boolean(value.Notes),
      });
    });
    return () => subscription.unsubscribe();
  }, [watch, atid]);

  return links;
};
