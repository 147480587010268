import { Controller } from 'react-hook-form';
import { Control } from 'react-hook-form/dist/types';

import { Datepicker } from '@components/Datepicker/Datepicker';
import { kebabCase } from '@utils/kebabCase';

import { EESLinks, IExecutiveSummaryFields } from '../types';

interface IProps {
  control: Control<IExecutiveSummaryFields>;
}

const getValue = (
  dates: (Date | null) | [Date | null, Date | null],
  type: string,
) => {
  if (Array.isArray(dates)) {
    const [start, end] = dates;
    return type === 'startDate' ? start : end;
  }

  return null;
};

export const ESDate = ({ control }: IProps) => (
  <div id={kebabCase(EESLinks.DATE)}>
    <Controller
      control={control}
      name="Dates"
      render={({ field: { value, onChange } }) => (
        <Datepicker
          startDate={getValue(value, 'startDate')}
          endDate={getValue(value, 'endDate')}
          selectsRange
          onChange={onChange}
        />
      )}
    />
  </div>
);
