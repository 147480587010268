import { ChangeEvent, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button } from '@mui/material';

import { Input } from '@components/Input/Input';
import { KrakenDialog } from '@components/KrakenDialog/KrakenDialog';
import { usePostApiComponentsByPidMutation } from '@store/services/query.generated';
import { useActions } from '@utils/hooks/useActions';

interface IAddComponent {
  visible: boolean;
  onClose: () => void;
}

export const AddComponent = ({ visible, onClose }: IAddComponent) => {
  const [componentName, setComponentName] = useState('');
  const { pid } = useParams();
  const { rerenderComponents } = useActions();
  const onChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
    setComponentName(value);
  };

  const [addComponent] = usePostApiComponentsByPidMutation();

  const apply = () => {
    addComponent({
      pid: pid!,
      krakenBackendApiModelsProjectComponentsApiProjectComponent: {
        name: componentName,
      },
    }).then(() => {
      rerenderComponents();
      onClose();
    });
  };

  useEffect(
    () => () => {
      setComponentName('');
    },
    [],
  );

  return (
    <KrakenDialog
      title="Add Component"
      onClose={onClose}
      visible={visible}
      footer={(
        <Button onClick={apply} color="primary" disabled={!componentName}>
          Add Component
        </Button>
      )}
    >
      <Input label="Name" value={componentName} onChange={onChange} />
    </KrakenDialog>
  );
};
