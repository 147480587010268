import { ActivityBreadCrumbs } from '@pages/Project/Activities/AddEditActivity/ActivityBreadCrumbs/ActivityBreadCrumbs';
import { ActivityFieldsChecklist } from '@pages/Project/Activities/AddEditActivity/ActivityFieldsChecklist/ActivityFieldsChecklist';
import { KrakenLayout } from '@src/layouts/KrakenLayout/KrakenLayout';

import { ActivityChecklist } from './ActivityChecklist';
import { ActivityComponent } from './ActivityComponent';
import { ActivityFindings } from './ActivityFindings';
import { ActivityFooter } from './ActivityFooter';
import { ActivityForecasted } from './ActivityForecasted';
import { ActivityFutureWork } from './ActivityFutureWork';
import { ActivityHistory } from './ActivityHistory';
import { ActivityMethodology } from './ActivityMethodology';
import { ActivityName } from './ActivityName';
import { ActivityNotes } from './ActivityNotes';
import { ActivityObservations } from './ActivityObservations';
import { ActivityScope } from './ActivityScope';
import { ActivityStatusBar } from './ActivityStatusBar';
import { useAddEditActivity } from './useAddEditActivity';

import s from './AddEditActivity.module.sass';

export const AddEditActivity = () => {
  const {
    aid,
    control,
    watch,
    handleSubmit,
    onSubmit,
    readyToSubmit,
    isLoading,
    status,
  } = useAddEditActivity();

  return (
    <KrakenLayout
      wrapperClass={s.addEditActivity}
      breadCrumbs={<ActivityBreadCrumbs watch={watch} />}
      checkList={<ActivityFieldsChecklist watch={watch} />}
      footer={<ActivityFooter readyToSubmit={readyToSubmit} />}
      onSubmit={handleSubmit(onSubmit)}
      isLoading={isLoading}
    >
      <div className={s.wrapper}>
        <ActivityName control={control} />
        <ActivityComponent control={control} />
        <ActivityScope control={control} />
        {aid && <ActivityChecklist />}
        <div className={s.twoColumns}>
          <ActivityMethodology control={control} />
          <ActivityObservations control={control} />
        </div>
        <ActivityFutureWork control={control} />
        {aid && <ActivityFindings />}
        {aid && <ActivityStatusBar status={status} />}
        <ActivityForecasted control={control} />
        <ActivityNotes control={control} />
        {aid && <ActivityHistory aid={aid} />}
      </div>
    </KrakenLayout>
  );
};
