import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';

import { Footer } from '@components/Footer/Footer';
import { LinkEnum } from '@src/types/types';

export const STFooter = () => {
  const navigate = useNavigate();

  const onClick = () => {
    navigate(LinkEnum.ADD_STEREOTYPE_TEMPLATE);
  };

  return (
    <Footer>
      <Button size="small" onClick={onClick}>
        Add Stereotype Template
      </Button>
    </Footer>
  );
};
