import { callMsGraph } from '@src/utils';
import {
  IGraphData,
  updateGraphData,
  updateTokenData,
  updateUserPhoto,
} from '@store/reducers/msalSlice';
import { store } from '@store/store';
import { getMsPhoto } from '@utils/getMsPhoto';

export const tokenHandler = (response: any) => {
  const now: any = new Date()?.getTime();
  const ttl = response.extExpiresOn ? response.extExpiresOn.getTime() - now : 0;

  store.dispatch(updateTokenData(response));

  if (ttl > 0) {
    sessionStorage.setItem('tokensTime', response.extExpiresOn);
    localStorage.setItem('idToken', response.idToken);
  } else if (localStorage.getItem('idToken')) {
    localStorage.removeItem('idToken');
    sessionStorage.removeItem('tokensTime');
  }

  callMsGraph(response.accessToken).then((resp: IGraphData) => store.dispatch(updateGraphData(resp)));
  getMsPhoto(response.accessToken).then(
    (resp) => resp && store.dispatch(updateUserPhoto(resp)),
  );
};
