import { useEffect, useState } from 'react';
import { FormState } from 'react-hook-form/dist/types/form';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from '@mui/material';

import { Footer } from '@components/Footer/Footer';
import Icon from '@components/Icon/Icon';
import { ICompanyFields } from '@pages/Admin/Companies/AddEditCompanies/type';
import { LinkEnum } from '@src/types/types';

interface IProps {
  formState: FormState<ICompanyFields>;
}

export const CompanyFooter = ({ formState: { isDirty, isValid } }: IProps) => {
  const [readySubmit, setReadySubmit] = useState(false);
  const navigate = useNavigate();
  const { cid } = useParams();

  const onClose = () => navigate(LinkEnum.COMPANIES);

  useEffect(() => {
    setReadySubmit(isDirty && isValid);
  }, [isDirty, isValid]);

  return (
    <Footer>
      <Button color="secondary" size="small" onClick={onClose}>
        Back
      </Button>
      <Button
        color="primary"
        size="small"
        startIcon={<Icon iconName="save" />}
        type="submit"
        disabled={!readySubmit}
      >
        {`${cid ? 'Update' : 'Add'} company`}
      </Button>
    </Footer>
  );
};
