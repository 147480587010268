import { IOptions } from './types';

export const options: IOptions = {
  regression: {
    name: 'Regression',
    keyName: 'regression',
  },
  rating_justifications: {
    name: 'Rating Justifications',
    keyName: 'rating_justifications',
  },
  severity_override: {
    name: 'Severity override',
    keyName: 'severity_override',
  },
  google_restricted_scope: {
    name: 'Google OAV',
    keyName: 'google_restricted_scope',
  },
  testproject: {
    name: 'Test project',
    keyName: 'testproject',
  },
  isMasa: {
    name: 'MASA',
    keyName: 'isMasa',
  },
};
