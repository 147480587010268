import cn from 'classnames';
import { parseISO } from 'date-fns';
import { v4 } from 'uuid';
import {
  Avatar,
  Button,
  Checkbox,
  LinearProgress,
  Menu,
  MenuItem,
} from '@mui/material';

import { KrakenDialog } from '@components/KrakenDialog/KrakenDialog';
import style from '@components/RadioButtons/RadioButtons.module.sass';
import { UseUsersList } from '@pages/Project/Users/UsersList/UseUsersList';
import { dateFormat } from '@src/constants/constants';
import { getDate } from '@utils/dateUtils';

import { UsersListMenu } from './UsersListMenu';

import s from './UsersList.module.sass';

type TChecked = 'rows' | 'cells';

interface IUser {
  name: string;
  checked: TChecked;
}

const User = ({ name, checked }: IUser) => (
  <div className={s.user}>
    <Avatar
      sx={{
        bgcolor: '#A5A6F6',
        width: checked === 'rows' ? '44px' : '80px',
        height: checked === 'rows' ? '44px' : '80px',
      }}
      alt={name || ''}
    >
      {name
        ?.split(' ')
        .map((word) => word[0])
        .join('')
        .slice(0, 2)}
    </Avatar>
    {name}
  </div>
);

const Email = ({ email }: { email: string }) => (
  <a href={`mailto:${email}`} className={s.email}>
    {email}
  </a>
);

export const UsersList = () => {
  const {
    checked,
    contextMenu,
    tempSelected,
    visible,
    setSelected,
    setVisible,
    roles,
    tempRoles,
    employees,
    isChecked,
    onChange,
    onCheckbox,
    onTempCheckbox,
    onContextMenu,
    handleClose,
    onDelete,
    onYes,
    closePopup,
    rolesIsLoading,
    tempRolesIsLoading,
  } = UseUsersList();

  return (
    <div className={cn(s.twoColumns, { [s.viewCells]: checked === 'cells' })}>
      {(rolesIsLoading || tempRolesIsLoading) && (
        <div className={s.progress}>
          <LinearProgress />
        </div>
      )}
      {!rolesIsLoading
        && !tempRolesIsLoading
        && (!!roles.length || !!tempRoles.length) && (
          <>
            <div>
              <h3>Full access</h3>
              <div className={s.listWrapper}>
                {roles?.map(({
 HumanId, Name, Role, RoleId, Email: email,
}) => (
  <div
    key={HumanId! + RoleId!}
    className={s.wrapper}
    onContextMenu={(e: any) => {
                      e.preventDefault();
                      onContextMenu(
                        contextMenu === null
                          ? {
                              mouseX: e.clientX - 2,
                              mouseY: e.clientY - 4,
                            }
                          : null,
                        HumanId!,
                        RoleId!,
                      );
                    }}
    role="link"
    tabIndex={0}
  >
    <div className={s.checkWithUser}>
      <Checkbox
        onChange={onCheckbox}
        value={JSON.stringify({ hid: HumanId, rid: RoleId })}
        className={s.checkbox}
        checked={isChecked(HumanId!, RoleId!)}
      />
      <User
        name={Name || 'No name'}
        checked={checked as TChecked}
      />
    </div>
    <div className={s.role}>{Role}</div>
    <div className={s.emailAndMenu}>
      <Email email={email || ''} />
      <UsersListMenu
        hid={HumanId!}
        rid={RoleId!}
        setSelected={setSelected}
        showPopup={setVisible}
      />
    </div>
  </div>
                ))}
              </div>
            </div>
            <div>
              <h3>Temporary access</h3>
              <div className={s.listWrapper}>
                {tempRoles?.map(({ human_id, not_before, not_after }) => {
                  const human = employees?.find((h) => h.HumanId === human_id) || {};
                  return (
                    <div
                      key={v4()}
                      className={s.wrapper}
                      onContextMenu={(e: any) => {
                        e.preventDefault();
                        onContextMenu(
                          contextMenu === null
                            ? {
                                mouseX: e.clientX - 2,
                                mouseY: e.clientY - 4,
                              }
                            : null,
                          human_id!,
                        );
                      }}
                    >
                      <Checkbox
                        onChange={onTempCheckbox}
                        className={s.checkbox}
                        value={human_id}
                        checked={tempSelected.includes(human_id!)}
                      />
                      <User
                        name={`${human.Fname} ${human.Lname}`}
                        checked={checked as TChecked}
                      />
                      <Email email={human.Email || ''} />
                      <div className={s.date}>
                        <div className={s.dateJoined}>
                          {getDate(
                            dateFormat,
                            new Date(parseISO(not_before as string)),
                          )}
                        </div>
                        <div className={s.dateEnd}>
                          {getDate(
                            dateFormat,
                            new Date(parseISO(not_after as string)),
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className={s.view}>
              <fieldset className={style.radioBtns}>
                <legend className={style.legend}>Scheme</legend>
                <input
                  className={cn(style.btn, s.rows)}
                  type="radio"
                  name="view"
                  value=""
                  data-type="rows"
                  aria-label="rows"
                  onChange={onChange}
                  checked={checked === 'rows'}
                />
                <input
                  className={cn(style.btn, s.cells)}
                  type="radio"
                  name="view"
                  value=""
                  data-type="cells"
                  aria-label="cells"
                  onChange={onChange}
                  checked={checked === 'cells'}
                />
              </fieldset>
            </div>
            <Menu
              open={contextMenu !== null}
              onClose={handleClose}
              anchorReference="anchorPosition"
              anchorPosition={
                contextMenu !== null
                  ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                  : undefined
              }
            >
              <MenuItem onClick={onDelete}>Delete</MenuItem>
            </Menu>
            {visible && (
              <KrakenDialog
                maxWidth="xs"
                onClose={closePopup}
                visible={visible}
                title="Are you Sure?"
                footer={(
                  <footer className={s.gap}>
                    <Button onClick={onYes}>Yes</Button>
                    <Button color="error" onClick={closePopup}>
                      No
                    </Button>
                  </footer>
                )}
              >
                <span>Are you Sure?</span>
              </KrakenDialog>
            )}
          </>
        )}
    </div>
  );
};
