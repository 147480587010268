import { HubConnectionState } from '@microsoft/signalr';

import { notificationQueue } from './constants';
import { notifications } from './notifications';

export const queueConnection = (fn: Function) => {
  if (notifications && notifications.state === HubConnectionState.Connected) {
    fn();
  } else {
    notificationQueue.push(fn);
  }
};
