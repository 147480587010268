import {
 FormEvent, useEffect, useRef, useState,
} from 'react';

interface IProps {
  value?: string;
  handler?: (state: string) => void;
  JustDnDHandler?: (file: File) => void;
}

export const useDragAndDrop = ({
  handler,
  value = '',
  JustDnDHandler,
}: IProps) => {
  const [inDropZone, setInDropZone] = useState(false);
  const [filename, setFilename] = useState('');
  const [image, setImage] = useState<string>(value);
  const [visible, setVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const inputRef = useRef<HTMLInputElement>(null);

  const onClickContainer = () => {
    inputRef.current?.click();
  };

  const isFileValid = (file?: File) => {
    const validTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/webp'];

    if (!file) return false;

    return validTypes.includes(file.type);
  };

  const handleDragEnter = (e: any) => {
    e.preventDefault();

    setInDropZone(true);
  };

  const handleDragLeave = (e: any) => {
    e.preventDefault();

    setInDropZone(false);
  };

  const handleDragOver = (e: any) => {
    e.preventDefault();

    setInDropZone(true);
  };

  const updateStatesOnChange = (file?: File) => {
    setErrorMessage('');

    if (file && isFileValid(file)) {
      if (JustDnDHandler) {
        JustDnDHandler(file);
      } else {
        const reader = new FileReader();

        reader.onload = (e: any) => {
          setImage(e.target.result);
          setVisible(true);
          handler && handler(e.target.result);
          setFilename(file.name);
        };
        // Read the file as Data URL (since we accept only images)
        reader.readAsDataURL(file);
      }
    } else {
      setErrorMessage(
        'Oh! Something went wrong with the upload. Please try again.',
      );
    }
  };

  const onApply = (img: string) => {
    handler && handler(img);
    setImage(img);
  };

  const onFileUpload = (event: FormEvent<HTMLInputElement>) => {
    const selectedFile = (event.target as HTMLInputElement).files![0];

    updateStatesOnChange(selectedFile);
  };

  const handleDrop = (e: any) => {
    e.preventDefault();
    const file = e.dataTransfer?.files[0];

    setInDropZone(false);

    updateStatesOnChange(file);
  };

  useEffect(() => {
    if (errorMessage) {
      setTimeout(() => {
        setErrorMessage('');
      }, 10000);
    }
  }, [errorMessage]);

  useEffect(() => {
    if (value) {
      setImage(value);
    }
  }, [value]);

  return {
    inputRef,
    onFileUpload,
    inDropZone,
    handleDrop,
    handleDragOver,
    handleDragEnter,
    handleDragLeave,
    onClickContainer,
    filename,
    image,
    errorMessage,
    visible,
    onApply,
    setVisible,
  };
};
