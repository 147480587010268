import { format } from 'date-fns';
import preval from 'preval.macro';
import { CSSProperties } from 'react';

import { dateWithTimeFormat } from '@src/constants/constants';
import { BUILD_HASH, BUILD_VERSION } from '@src/constants/environment';

const trimmedCommitHash = BUILD_HASH.slice(0, 8);
const buildTimestamp = format(
  preval`module.exports = new Date().getTime();`,
  dateWithTimeFormat,
);

const styles = {
  tac: {
    textAlign: 'center',
    fontSize: '12px',
  },
};

export const VersionNumber = () => (
  <div style={styles.tac as CSSProperties}>
    {`Kraken v:${BUILD_VERSION}-${trimmedCommitHash}`}
    <br />
    {buildTimestamp}
  </div>
);
