import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';

import { Footer } from '@components/Footer/Footer';
import { LinkEnum } from '@src/types/types';

interface IProps {
  readyToSubmit: boolean;
}

export const FTFooter = ({ readyToSubmit }: IProps) => {
  const navigate = useNavigate();

  const onBack = () => {
    navigate(LinkEnum.FINDING_TEMPLATE);
  };

  return (
    <Footer>
      <Button size="small" color="secondary" onClick={onBack}>
        Back
      </Button>
      <Button size="small" type="submit" disabled={!readyToSubmit}>
        Save
      </Button>
    </Footer>
  );
};
