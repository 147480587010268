import { parseISO } from 'date-fns';

import { dateWithTimeAmPmFormat } from '@src/constants/constants';
import { getDate } from '@utils/dateUtils';

import s from '../ArchiveProject.module.sass';

interface IProps {
  deletedDate: string;
}

export const DeletedDate = ({ deletedDate }: IProps) => (
  <div className={s.date}>
    {deletedDate
      ? getDate(dateWithTimeAmPmFormat, new Date(parseISO(deletedDate)))
      : ''}
  </div>
);
