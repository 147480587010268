import { Controller } from 'react-hook-form';
import { Control } from 'react-hook-form/dist/types';
import { MenuItem } from '@mui/material';

import { KrakenSelect } from '@components/KrakenSelect/KrakenSelect';

import { statusList } from '../constants';
import { IFindingFields } from '../types';

interface IProps {
  control: Control<IFindingFields>;
}

export const FindingStatus = ({ control }: IProps) => (
  <Controller
    control={control}
    name="statusId"
    render={({ field: { value, onChange } }) => (
      <KrakenSelect
        label="Status"
        value={
          value && !Object.keys(statusList)?.length
            ? ''
            : value?.toUpperCase() || ''
        }
        onChange={(e) => onChange(e.target.value)}
      >
        {Object.entries(statusList).map(([key, val]) => (
          <MenuItem key={val} value={val}>
            {key}
          </MenuItem>
        ))}
      </KrakenSelect>
    )}
  />
);
