import {
  Autocomplete,
  Checkbox,
  FormControlLabel,
  Skeleton,
  TextField,
} from '@mui/material';

import { GetApiStereotypesApiResponse } from '@store/services/query.generated';
import { kebabCase } from '@utils/kebabCase';

import { EAddEditComponentLinks } from '../types';

import { useComponentStereotypes } from './useComponentStereotypes';

const skeletonStyle = { flexGrow: 1, height: 44, marginTop: '20px' };

interface IProps {
  componentId?: string | undefined;
}

const styles = {
  mt32: {
    marginTop: '32px',
  },
};

export const ComponentStereotypes = ({ componentId }: IProps) => {
  const {
 isLoadingAllStereotypes, allStereotypes, value, onChange, onClick,
} = useComponentStereotypes(componentId);

  return (
    <>
      {isLoadingAllStereotypes && !allStereotypes.length && (
        <div>
          <Skeleton animation="wave" variant="rectangular" sx={skeletonStyle} />
        </div>
      )}
      {allStereotypes.length ? (
        <div>
          <Autocomplete
            value={value as any}
            multiple
            limitTags={3}
            id={kebabCase(EAddEditComponentLinks.STEREOTYPES)}
            options={allStereotypes as GetApiStereotypesApiResponse}
            getOptionLabel={(option) => option.SurfaceName!}
            isOptionEqualToValue={(option, val) => option?.SurfaceName === val?.SurfaceName}
            renderOption={(props, option, { selected }) => (
              <li {...props} style={{ fontSize: '12px' }}>
                <FormControlLabel
                  control={<Checkbox checked={selected} />}
                  label={option?.SurfaceName || 'Unknown'}
                />
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                onClick={onClick}
                {...(!componentId && { label: 'Stereotypes' })}
              />
            )}
            onChange={onChange}
          />
        </div>
      ) : (
        <div style={styles.mt32}>There are no stereotypes here.</div>
      )}
    </>
  );
};
