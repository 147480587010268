import { Button, Menu, MenuItem } from '@mui/material';

import { KrakenDialog } from '@components/KrakenDialog/KrakenDialog';
import { KrakenTable } from '@components/KrakenTable/KrakenTable';
import { useProjectsList } from '@pages/Projects/ProjectsList/useProjectsList';
import {
  GetApiProjectsV2ApiResponse,
  KrakenModelsDtoProjectWithEditInfoQueryResult,
} from '@store/services/query.generated';

import s from './ProjectsList.module.sass';

interface IProps {
  projectsData: GetApiProjectsV2ApiResponse;
}

export function ProjectsList({ projectsData }: IProps) {
  const {
    columns,
    rowTemplate,
    contextMenu,
    onEdit,
    isActive,
    onDeactivateHandler,
    onActivateHandler,
    isAdmin,
    onDeleteHandler,
    pid,
    showPopup,
    closePopup,
    onYes,
    close,
  } = useProjectsList();

  return (
    <div className={s.projectsList}>
      <KrakenTable<KrakenModelsDtoProjectWithEditInfoQueryResult>
        tableData={projectsData}
        columns={columns}
        gridTemplateColumns="minmax(200px, 400px) minmax(100px, 300px) 132px 230px 32px"
        rowTemplate={rowTemplate}
      />
      <Menu
        open={contextMenu !== null}
        onClose={close}
        anchorReference="anchorPosition"
        anchorPosition={
          contextMenu !== null
            ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
            : undefined
        }
      >
        <MenuItem onClick={onEdit}>Edit</MenuItem>
        {isActive ? (
          <MenuItem onClick={onDeactivateHandler}>Deactivate</MenuItem>
        ) : (
          <MenuItem onClick={onActivateHandler}>Activate</MenuItem>
        )}
        {isAdmin && <MenuItem onClick={onDeleteHandler}>Delete</MenuItem>}
      </Menu>
      {pid && showPopup && (
        <KrakenDialog
          maxWidth="xs"
          onClose={closePopup}
          visible={showPopup}
          title="Are you Sure?"
          footer={(
            <footer className={s.gap}>
              <Button onClick={onYes}>Yes</Button>
              <Button color="error" onClick={closePopup}>
                No
              </Button>
            </footer>
          )}
        >
          <span>
            The project will be completely deleted. Are you sure you want to
            proceed?
          </span>
        </KrakenDialog>
      )}
    </div>
  );
}

export default ProjectsList;
