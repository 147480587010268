const defaultMuiAlert = {
  styleOverrides: {
    root: {
      height: '48px',
      minHeight: '48px',
      alignItems: 'center',
      fontSize: '14px',
      lineHeight: 1,
      marginTop: '12px',
      '& .MuiAlert-icon': {
        fontSize: '17px',
        color: '#FF4646',
        opacity: 1,
      },
      '& .MuiAlert-message': {
        fontWeight: 400,
        fontSize: '14px',
        color: '#090C15',
      },
    },
  },
  variants: [
    {
      props: { severity: 'error' },
      style: {
        background: '#FFF1F1',
      },
    },
    {
      props: { severity: 'success' },
      style: {
        background: '#E8FBF5',
        '& .MuiAlert-icon': {
          color: '#48DEAD',
        },
      },
    },
  ],
};

export const MuiAlertLight = {
  ...defaultMuiAlert,
  variants: [
    ...defaultMuiAlert.variants,
    {
      props: { severity: 'info' },
      style: {
        background: '#F8F8F8',
        '& .MuiAlert-icon': {
          color: '#090C15',
        },
      },
    },
  ],
};

export const MuiAlertDark = {
  ...defaultMuiAlert,
  variants: [
    ...defaultMuiAlert.variants,
    {
      props: { severity: 'info' },
      style: {
        background: '#384455',
        '& .MuiAlert-icon': {
          color: '#FFFFFF',
        },
      },
    },
  ],
};
