import { useNavigate } from 'react-router-dom';
import { Avatar } from '@mui/material';

import { KrakenListItem } from '@components/KrakenListItem/KrakenListItem';
import { xcsrf } from '@src/constants/constants';
import { LinkEnum } from '@src/types/types';
import { useGetApiV2ProjectsWithInactiveQuery } from '@store/services/query.generated';

import s from './ArchiveProjectsList.module.sass';

export const ArchiveProjectsList = () => {
  const navigate = useNavigate();

  const onClick = (id: string | undefined) => {
    id && navigate(LinkEnum.ARCHIVE_PROJECT.replace(':pid', id));
  };

  const { data = [] } = useGetApiV2ProjectsWithInactiveQuery(xcsrf);

  return (
    <div className={s.wrapper}>
      <h2>All Projects</h2>
      <div className={s.list}>
        {data?.map(({ projectId, projectName, projectLink }) => (
          <KrakenListItem
            key={projectId}
            fid={projectId}
            className={s.listItem}
            handler={onClick}
          >
            {!!projectName?.length && (
              <Avatar
                sx={{
                  bgcolor: 'transparent',
                  width: '80px',
                  height: '80px',
                  display: 'flex',
                  border: '1px solid #EAEAEE',
                  color: 'black',
                }}
                alt={projectName || ''}
              >
                {projectName[0]!}
              </Avatar>
            )}
            <div className={s.projectCell}>
              <span className={s.projectName}>{projectName}</span>
              <span className={s.projectLink}>{projectLink}</span>
            </div>
          </KrakenListItem>
        ))}
      </div>
    </div>
  );
};
