import { FindingsByUser } from '@pages/Dashboard/Widgets/FindingsByUser/FindingsByUser';
import { ERoles } from '@src/constants/constants';
import { useIsInRole } from '@src/utils/hooks/useIsInRole';

import { NeedsWork } from './Widgets/NeedsWork/NeedsWork';

import s from './Dashboard.module.sass';

export const Dashboard = () => {
  const { isInRole: isMetricsUser } = useIsInRole(ERoles.metrics);

  return (
    <div className={s.wrapper}>
      <NeedsWork />
      {isMetricsUser && <FindingsByUser />}
    </div>
  );
};
