import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IAddProject {
  showConfirmationPopup: boolean;
  showCompaniesPopup: boolean;
}

const initialState: IAddProject = {
  showConfirmationPopup: false,
  showCompaniesPopup: false,
};

const addProjectSlice = createSlice({
  name: 'addProject',
  initialState,
  reducers: {
    setConfirmationPopup(
      state: IAddProject,
      { payload }: PayloadAction<boolean>,
    ) {
      state.showConfirmationPopup = payload;
    },
    setCompaniesPopup(state: IAddProject, { payload }: PayloadAction<boolean>) {
      state.showCompaniesPopup = payload;
    },
    resetAddProject: () => initialState,
  },
});

const { reducer, actions } = addProjectSlice;

export default reducer;
export const { setConfirmationPopup, setCompaniesPopup, resetAddProject } = actions;
