import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { GetApiStereotypesByProjectByPidApiResponse } from '@store/services/query.generated';

interface IAddEditComponent {
  componentName: string;
  description: string;
  threatAnalysis: string;
  observations: string;
  findings: string[];
  internalNotes: string;
  stereotypes: GetApiStereotypesByProjectByPidApiResponse;
  dataFromServer: any;
}

const initialState: IAddEditComponent = {
  componentName: '',
  description: '',
  threatAnalysis: '',
  observations: '',
  findings: [],
  internalNotes: '',
  stereotypes: [],
  dataFromServer: null,
};

const addEditComponent = createSlice({
  name: 'addEditComponent',
  initialState,
  reducers: {
    updateAddEditComponentField(
      state: IAddEditComponent,
      action: PayloadAction<{ field: string; value: any }>,
    ) {
      switch (action.payload.field) {
        case 'componentName':
          state.componentName = action.payload.value;
          break;
        case 'description':
          state.description = action.payload.value;
          break;
        case 'threatAnalysis':
          state.threatAnalysis = action.payload.value;
          break;
        case 'observations':
          state.observations = action.payload.value;
          break;
        case 'findings':
          state.findings = action.payload.value;
          break;
        case 'internalNotes':
          state.internalNotes = action.payload.value;
          break;
        case 'stereotypes':
          state.stereotypes = action.payload.value;
          break;
        default:
          return state;
      }
    },
    resetAddEditComponent: () => initialState,
  },
});

const { reducer, actions } = addEditComponent;

export default reducer;
export const { updateAddEditComponentField, resetAddEditComponent } = actions;
