import { Controller } from 'react-hook-form';
import { Control } from 'react-hook-form/dist/types';

import { KrakenTextArea } from '@components/KrakenTextArea/KrakenTextArea';
import { textAreaHandler } from '@utils/textAreaHandler';

import { IFindingFields } from '../types';

const placeholder = 'Who at the customer accepted the risk, and why are they accepting?';

interface IProps {
  control: Control<IFindingFields>;
}

export const FindingAccepted = ({ control }: IProps) => (
  <Controller
    control={control}
    name="mitigation"
    render={({ field: { value, onChange } }) => (
      <KrakenTextArea
        title="Acceptance Reason and Authority*"
        value={value || ''}
        onChange={(val) => textAreaHandler(val, onChange)}
        placeholder={placeholder}
        height={180}
        hideToolbar
      />
    )}
  />
);
