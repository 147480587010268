import { NavLink } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import { Tooltip } from '@mui/material';

import { CustomNavLink } from '@components/Sidebar/CustomNavLink/CustomNavLink';
import { Settings } from '@components/Sidebar/Settings/Settings';
import { ThemeSwitcher } from '@components/Sidebar/ThemeSwitcher/ThemeSwitcher';
import { VersionNumber } from '@components/VersionNumber';
import LOGO from '@src/assets/Logo.svg';
import { LinkEnum } from '@src/types/types';
import { handleLogout } from '@src/utils';

import Icon from '../Icon/Icon';

import { routes } from './routes';

import styles from './Sidebar.module.sass';

const btnStyles = {
  width: '100%',
  background: 'transparent',
  border: 'none',
  cursor: 'pointer',
};

function Sidebar() {
  const { instance } = useMsal();

  return (
    <div className={styles.sidebar}>
      <div className={styles.sidebarContent}>
        <div className={styles.sidebarLogo}>
          <Tooltip title={<VersionNumber />} followCursor>
            <NavLink to={LinkEnum.MAIN_PAGE}>
              <img className={styles.logo} src={LOGO} alt="" />
            </NavLink>
          </Tooltip>
        </div>
        <ul className={styles.sidebarMenu}>
          {routes?.map(({ to, iconName, itemName }) => (
            <CustomNavLink
              to={to}
              iconName={iconName}
              itemName={itemName}
              key={itemName}
            />
          ))}
        </ul>
      </div>
      <div className={styles.bottomBtns}>
        <Settings />
        <ThemeSwitcher />
        <Tooltip title="Logout" placement="right">
          <button
            type="button"
            className={styles.sidebarLogoutLink}
            onClick={() => handleLogout(instance)}
            style={btnStyles}
          >
            <Icon className={styles.sidebarLogoutIcon} iconName="logout" />
          </button>
        </Tooltip>
      </div>
    </div>
  );
}

export default Sidebar;
