import { useDispatch } from 'react-redux';
import { bindActionCreators } from '@reduxjs/toolkit';

import {
  resetActivity,
  updateActivity,
  updateActivityFields,
} from '@store/reducers/addEditActivitySlice';
import {
  resetAddEditComponent,
  updateAddEditComponentField,
} from '@store/reducers/addEditComponentSlice';
import {
  resetAddEditFinding,
  resetFindingNotification,
  setFindingNotificationName,
  setFindingNotificationRequesterConnectionId,
  setFindingNotificationType,
  setHasObserver,
  setIsEditor,
} from '@store/reducers/addEditFindingSlice';
import {
  resetAddProject,
  setCompaniesPopup,
  setConfirmationPopup,
} from '@store/reducers/addProjectSlice';
import { rerenderComponents } from '@store/reducers/componentsSlice';
import {
  hideDownloadFilePopup,
  showDownloadFilePopup,
} from '@store/reducers/downloadFileSlice';
import { hideError, showError } from '@store/reducers/errorSlice';
import { resetFBU, updateFBU } from '@store/reducers/findingsByUserSlice';
import {
  resetMsal,
  updateGraphData,
  updateTokenData,
  updateUserParams,
  updateUserPhoto,
} from '@store/reducers/msalSlice';
import {
  resetSignalR,
  updateConnectionState,
} from '@store/reducers/signalRSlice';
import { updateTheme } from '@store/reducers/themeSlice';
import { AppDispatch } from '@store/store';

const actions = {
  setIsEditor,
  setHasObserver,
  resetAddEditFinding,
  updateConnectionState,
  resetSignalR,
  updateActivityFields,
  updateActivity,
  resetActivity,
  updateAddEditComponentField,
  resetAddEditComponent,
  resetFindingNotification,
  setFindingNotificationType,
  setFindingNotificationName,
  setFindingNotificationRequesterConnectionId,
  setConfirmationPopup,
  setCompaniesPopup,
  resetAddProject,
  rerenderComponents,
  showDownloadFilePopup,
  hideDownloadFilePopup,
  showError,
  hideError,
  updateFBU,
  resetFBU,
  updateTokenData,
  updateGraphData,
  updateUserParams,
  updateUserPhoto,
  resetMsal,
  updateTheme,
};

export const useActions = () => {
  const dispatch = useDispatch<AppDispatch>();
  return bindActionCreators(actions, dispatch);
};
