import { Link } from 'react-router-dom';
import { Breadcrumbs, Typography } from '@mui/material';

import s from './BreadCrumbs.module.sass';

interface IProps {
  crumbs: string[][];
  currentPage: string | null;
}

export const BreadCrumbs = ({ crumbs, currentPage }: IProps) => (
  <header className={s.breadCrumbs}>
    <Breadcrumbs aria-label="breadcrumb">
      {crumbs.map(([crumbName, href]) => (
        <Link key={href} color="inherit" to={href}>
          {crumbName}
        </Link>
      ))}
      <Typography color="text.primary">{currentPage || ''}</Typography>
    </Breadcrumbs>
  </header>
);
