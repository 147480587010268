import { useEffect } from 'react';

import { useTypedSelector } from '@src/utils';

export function ReAuthPage() {
  const { is401 } = useTypedSelector((state) => state.errorReducer);

  useEffect(() => {
    if (is401) {
      localStorage.setItem('authSuccess', 'false');
      window.close();
    }
  }, [is401]);

  return <div>Re-auth page</div>;
}
