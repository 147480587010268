import cn from 'classnames';

import { buttonsList } from '@pages/Project/Activities/AddEditActivity/ActivityStatusBar/ActivityStatusBar';
import { getKeyByValue } from '@utils/getKeyByValue';

import s from '../ArchiveProject.module.sass';

interface IProps {
  status: string;
}

export const DeletedActivityStatus = ({ status }: IProps) => (
  <div className={cn(s.status, s[status])}>
    {getKeyByValue(buttonsList, status)}
  </div>
);
