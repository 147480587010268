export enum EFRLinks {
  DISPLAY_DATE = 'Display Date',
  ADDRESS_AND_CONTACT_INFO = 'Address and Contact Info',
  STAKEHOLDERS = 'Stakeholders',
  OBJECTIVES_CONCLUSION = 'Objectives conclusion',
  FONTS = 'Fonts',
}

export interface IFinalReportFields {
  ReportVersion: string | null;
  ReportDate: Date | null;
  AddressAndContactInfo: string | null;
  Stakeholders: string | null;
  ObjectivesConclusion: string | null;
  AddPolicyProcedure: boolean;
  AddAppendixC: boolean;
  AppendixC: string | null;
  MainFont: string;
  HeaderFooter: string;
  Classification: string;
  TableOfContent: string;
}
