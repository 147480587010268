import { Controller } from 'react-hook-form';
import { Control } from 'react-hook-form/dist/types';

import { Input } from '@components/Input/Input';
import { kebabCase } from '@utils/kebabCase';

import { EFTLinks, IFindingTemplateFields } from '../types';

interface IProps {
  control: Control<IFindingTemplateFields>;
}

export const FTTitle = ({ control }: IProps) => (
  <Controller
    control={control}
    name="Title"
    render={({ field: { value, onChange, ref } }) => (
      <Input
        id={kebabCase(EFTLinks.TITLE)}
        label="Title*"
        value={value || ''}
        onChange={onChange}
        inputRef={ref}
      />
    )}
  />
);
