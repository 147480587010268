import { AccountInfo, IPublicClientApplication } from '@azure/msal-browser';

import { loginRequest } from '@src/config/authConfig';
import { showError } from '@store/reducers/errorSlice';

import { tokenHandler } from './tokenHandler';

export async function requestProfileData(
  instance: IPublicClientApplication,
  accounts: AccountInfo[],
) {
  // Silently acquires an access token which is then attached to a request for MS Graph data
  await instance
    .acquireTokenSilent({
      ...loginRequest,
      account: accounts[0],
    })
    .then(tokenHandler)
    .catch(() => {
      instance
        .acquireTokenPopup({
          ...loginRequest,
          account: accounts[0],
        })
        .then(tokenHandler, (err) => {
          showError(err);
        });
    });
}
