import { useState } from 'react';

import { Datepicker } from '@components/Datepicker/Datepicker';
import { reverseDateFormat } from '@src/constants/constants';
import { getDate } from '@utils/dateUtils';
import { useActions } from '@utils/hooks/useActions';

export const FBUDates = () => {
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const { updateFBU } = useActions();

  const onStartDate = (date: (Date | null) | [Date | null, Date | null]) => {
    if (date instanceof Date) {
      setStartDate(date);
      date
        && updateFBU({
          field: 'startDate',
          value: getDate(reverseDateFormat, date),
        });
    }
  };

  const onEndDate = (date: (Date | null) | [Date | null, Date | null]) => {
    if (date instanceof Date) {
      setEndDate(date);
      date
        && updateFBU({
          field: 'endDate',
          value: getDate(reverseDateFormat, date),
        });
    }
  };

  return (
    <>
      <Datepicker
        label="From"
        startDate={startDate}
        onChange={onStartDate}
        placeholderText="Select Date"
      />
      <Datepicker
        label="To"
        startDate={endDate}
        onChange={onEndDate}
        placeholderText="Select Date"
      />
    </>
  );
};
