import { parseISO } from 'date-fns';
import Parser from 'html-react-parser';
import { useState } from 'react';
import { Avatar, Button } from '@mui/material';

import { FullScreenDialog } from '@components/FullScreenDialog/FullScreenDialog';
import { dateWithTimeAmPmFormat } from '@src/constants/constants';
import { GetApiReviewablesEditHistoryByRidApiResponse } from '@store/services/query.generated';
import { getDate } from '@utils/dateUtils';

import s from './EditsHistory.module.sass';

interface IProps {
  data: GetApiReviewablesEditHistoryByRidApiResponse;
}

const sx = {
  avatar: {
    width: '24px',
    height: '24px',
    fontSize: '14px',
  },
};

export const EditsHistory = ({ data }: IProps) => {
  const [showPopup, setShowPopup] = useState('');

  const showEdits = (value: string) => {
    setShowPopup(value);
  };
  const closePopup = () => {
    setShowPopup('');
  };

  return (
    <div>
      {data?.map((item) => (
        <div key={item.edit_date} className={s.historyWrapper}>
          <div className={s.editor}>
            <Avatar sx={sx.avatar}>
              {item.EditorFirstName && item.EditorLastName
                ? `${item.EditorFirstName[0] + item.EditorLastName[0]}`
                : ''}
            </Avatar>
            {`${item.EditorFirstName} ${item.EditorLastName}`}
          </div>
          <div className={s.time}>
            {getDate(
              dateWithTimeAmPmFormat,
              new Date(parseISO(item.edit_date as string)),
            )}
          </div>
          <div className={s.buttonWrapper}>
            <Button size="small" onClick={() => showEdits(item.diff!)}>
              Show edits
            </Button>
          </div>
        </div>
      ))}
      {!!showPopup && (
        <FullScreenDialog
          open={!!showPopup}
          onClose={closePopup}
          title="Show edits"
        >
          {Parser(showPopup)}
        </FullScreenDialog>
      )}
    </div>
  );
};
