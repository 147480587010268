import { Controller } from 'react-hook-form';
import { Control } from 'react-hook-form/dist/types';

import { KrakenTextArea } from '@components/KrakenTextArea/KrakenTextArea';
import { kebabCase } from '@utils/kebabCase';
import { textAreaHandler } from '@utils/textAreaHandler';

import { EAddEditFindingLinks, IFindingFields } from '../types';

const placeholder = `Point of view: Third person. Don’t use first person (e.g., I, we, our) or second person (e.g., you, your).

Content: Provide a general description of the type or class of vulnerability.`;

interface IProps {
  control: Control<IFindingFields>;
}

export const FindingDescription = ({ control }: IProps) => (
  <Controller
    control={control}
    name="description"
    render={({ field: { value, onChange } }) => (
      <KrakenTextArea
        id={kebabCase(EAddEditFindingLinks.DESCRIPTION)}
        title="Description"
        value={value || ''}
        onChange={(val) => textAreaHandler(val, onChange)}
        height={180}
        placeholder={placeholder}
        hideToolbar
      />
    )}
  />
);
