import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type TFindingNotificationType =
  | 'askForControl'
  | 'accepted'
  | 'declined'
  | 'requesting'
  | 'waiting'
  | 'askControlBlocked'
  | null;

export interface IAddEditFinding {
  isEditor: boolean;
  hasObserver: boolean;
  findingNotificationType: TFindingNotificationType;
  notificationName: string | null;
  requesterConnectionId: string | null;
}

const initialState: IAddEditFinding = {
  isEditor: true,
  hasObserver: false,
  findingNotificationType: null,
  notificationName: null,
  requesterConnectionId: null,
};

const addEditFinding = createSlice({
  name: 'addEditFinding',
  initialState,
  reducers: {
    setIsEditor(state: IAddEditFinding, { payload }: PayloadAction<boolean>) {
      state.isEditor = payload;
    },
    setHasObserver(
      state: IAddEditFinding,
      { payload }: PayloadAction<boolean>,
    ) {
      state.hasObserver = payload;
    },
    setFindingNotificationType(
      state: IAddEditFinding,
      { payload }: PayloadAction<TFindingNotificationType>,
    ) {
      state.findingNotificationType = payload;
    },
    setFindingNotificationName(
      state: IAddEditFinding,
      { payload }: PayloadAction<string>,
    ) {
      state.notificationName = payload;
    },
    setFindingNotificationRequesterConnectionId(
      state: IAddEditFinding,
      { payload }: PayloadAction<string>,
    ) {
      state.requesterConnectionId = payload;
    },
    resetFindingNotification(state: IAddEditFinding) {
      state.findingNotificationType = null;
      state.notificationName = null;
      state.requesterConnectionId = null;
    },
    resetAddEditFinding: () => initialState,
  },
});

const { reducer, actions } = addEditFinding;

export default reducer;
export const {
  setIsEditor,
  setHasObserver,
  setFindingNotificationType,
  resetFindingNotification,
  setFindingNotificationName,
  setFindingNotificationRequesterConnectionId,
  resetAddEditFinding,
} = actions;
