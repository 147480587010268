import styles from './Logo.module.sass';

export function Logo() {
  return (
    <svg
      className={styles.logoImage}
      width="111"
      height="22"
      viewBox="0 0 111 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1011_6617)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21.5341 10.7493C21.5341 11.0647 21.5057 11.3801 21.474 11.6889C21.2704 14.0673 20.3215 16.3079 18.7756 18.0601C16.5611 20.6066 13.5589 21.8649 10.8667 21.762C5.40647 21.5595 1.67667 16.0481 1.92975 10.6364C1.96842 9.8184 2.10881 9.0092 2.34734 8.22937C2.56624 7.50986 2.85678 6.81656 3.21414 6.16096C3.59018 5.47373 4.02766 4.82561 4.52068 4.22536C5.99805 2.42256 8.23783 0.789084 10.4713 0.241272C9.59815 1.01817 7.85505 2.72469 7.10845 3.82363C3.11608 9.68688 4.5017 16.5827 10.93 16.8782C14.2169 17.0309 16.9565 14.1391 17.0324 10.7593C17.0736 8.98303 16.2764 7.61183 15.0647 6.40333C12.7332 4.07928 8.85472 4.45112 6.63393 6.99762C6.2287 7.46277 5.89177 7.98869 5.63425 8.55806C6.46309 3.36878 11.452 0.307674 11.4931 0.281114C13.9252 0.322457 16.2654 1.26301 18.1017 2.93717C20.3668 5.00226 21.6101 7.51555 21.5341 10.7759V10.7493ZM16.4314 18.4618C16.4314 18.4618 21.9486 16.0681 19.7531 8.01357C19.7531 8.01357 21.376 14.6537 16.4314 18.4618ZM2.4549 16.7819C2.98413 17.6129 3.62323 18.3608 4.35302 19.003C4.78631 19.3821 5.2487 19.723 5.73548 20.0223C4.84685 19.1538 4.05222 18.1851 3.366 17.1338C2.11957 15.1882 1.38563 12.997 1.49319 10.6265C1.53424 9.7702 1.68098 8.92315 1.92975 8.10653C2.15908 7.3625 2.46234 6.64585 2.83452 5.9684C3.22494 5.26021 3.67942 4.59312 4.19168 3.97635C4.96298 3.03558 5.85148 2.20808 6.83322 1.51618C7.15169 1.29484 7.46804 1.09232 7.78228 0.908607L7.25397 1.10781C5.39696 1.90983 3.79599 3.2503 2.63838 4.97238C1.50028 6.67042 0.866277 8.68388 0.816193 10.7593C0.78873 11.832 0.914557 12.903 1.18949 13.9366C1.4672 14.9506 1.89415 15.9128 2.4549 16.7885V16.7819Z"
          fill="#877CFF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.59612 1.2207C6.31773 1.2871 6.18802 1.2207 6.44743 1.01485C6.56387 0.925813 6.68775 0.848025 6.81756 0.782447C7.42812 0.47036 7.50405 0.613123 7.34587 0.809008C7.34587 0.809008 7.18137 1.02149 7.23199 1.14102L6.79226 1.36678C6.73605 1.3083 6.6694 1.26204 6.59612 1.23066V1.2207ZM6.91247 1.01153C6.71949 1.10117 6.56765 1.09785 6.70684 0.984971C6.75234 0.950011 6.80109 0.919979 6.85236 0.89533C7.20351 0.712726 7.09595 0.928531 6.91247 1.01153Z"
          fill="#877CFF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.72035 3.22598C3.24582 3.42186 2.9959 3.34882 3.40399 2.89397C3.58636 2.70118 3.78547 2.52667 3.99874 2.37272C5.00158 1.64562 5.17241 1.87471 4.9478 2.26647C4.9478 2.26647 4.71686 2.68812 4.83391 2.87073L4.11579 3.3953C3.99772 3.31015 3.86587 3.24827 3.72667 3.21269L3.72035 3.22598ZM4.24233 2.76116C3.92597 2.97365 3.65075 3.01349 3.86903 2.76116C3.93946 2.68583 4.01681 2.61799 4.09997 2.55864C4.68206 2.13699 4.54603 2.55864 4.24233 2.74789V2.76116Z"
          fill="#877CFF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.528233 10.5932C0.363729 11.1444 0.129627 11.3203 0.0378848 10.6696C-0.000294095 10.3826 -0.00983518 10.0922 0.00941423 9.80307C0.0885025 8.43852 0.404853 8.4352 0.575684 8.89337C0.575684 8.89337 0.771821 9.37478 1.00909 9.38806L0.952145 10.3841C0.799083 10.4321 0.655493 10.5085 0.528233 10.6099V10.5932ZM0.496596 9.82631C0.458633 10.248 0.300457 10.5169 0.249841 10.1583C0.238918 10.0479 0.238918 9.93669 0.249841 9.82631C0.29413 9.03614 0.544048 9.42459 0.506086 9.82631H0.496596Z"
          fill="#877CFF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.53103 6.30043C1.1261 6.69884 0.838216 6.73204 1.07232 6.11783C1.1801 5.84448 1.31363 5.58311 1.47092 5.33761C2.19853 4.17891 2.4706 4.34159 2.39467 4.82632C2.39467 4.82632 2.32824 5.35089 2.52438 5.49033L2.0024 6.32367C1.84655 6.29398 1.68688 6.29398 1.53103 6.32367V6.30043ZM1.87585 5.63642C1.63859 5.98503 1.37285 6.14107 1.49939 5.80906C1.54098 5.703 1.59293 5.60175 1.65441 5.50694C2.07516 4.84292 2.10363 5.30441 1.87585 5.63642Z"
          fill="#877CFF"
        />
      </g>
      <path
        d="M26.5074 18.6064V1.57581H30.309V8.26639H32.8434L37.6587 1.57581H41.9671L36.1381 9.72616L42.3473 18.6064H37.9121L32.9701 11.6725H30.309V18.6064H26.5074Z"
        fill="#00040F"
      />
      <path
        d="M43.6036 18.6064V6.19839H47.1517V8.0231H47.2784C47.4305 7.63383 47.6586 7.29322 47.9627 7.00126C48.6554 6.30382 49.5255 5.9551 50.5731 5.9551H52.0937V9.23957H50.1929C49.2299 9.23957 48.478 9.49097 47.9373 9.99378C47.4136 10.4804 47.1517 11.1616 47.1517 12.0374V18.6064H43.6036Z"
        fill="#00040F"
      />
      <path
        d="M54.9391 17.171C53.8071 15.9545 53.2411 14.365 53.2411 12.4024C53.2411 10.4398 53.8071 8.85841 54.9391 7.65816C56.088 6.44169 57.5073 5.83345 59.1969 5.83345C60.3289 5.83345 61.3511 6.15784 62.2635 6.80663C62.6521 7.1148 62.9815 7.43919 63.2519 7.7798H63.3786V6.19839H66.9267V18.6064H63.3786V17.025H63.2519C62.9815 17.3656 62.6521 17.69 62.2635 17.9982C61.3511 18.6469 60.3289 18.9713 59.1969 18.9713C57.5073 18.9713 56.088 18.3712 54.9391 17.171ZM57.803 9.72616C57.2116 10.3749 56.9159 11.267 56.9159 12.4024C56.9159 13.5378 57.2116 14.4298 57.803 15.0786C58.3943 15.7274 59.1546 16.0518 60.0839 16.0518C61.0639 16.0518 61.858 15.7355 62.4662 15.103C63.0745 14.4542 63.3786 13.554 63.3786 12.4024C63.3786 11.2508 63.0745 10.3587 62.4662 9.72616C61.858 9.07737 61.0639 8.75298 60.0839 8.75298C59.1546 8.75298 58.3943 9.07737 57.803 9.72616Z"
        fill="#00040F"
      />
      <path
        d="M69.7145 18.6064V1.57581H73.2626V10.821H74.7832L78.2046 6.19839H82.1329L77.9512 11.9158L82.5131 18.6064H78.4581L75.1634 13.7405H73.2626V18.6064H69.7145Z"
        fill="#00040F"
      />
      <path
        d="M84.2469 17.0736C82.9122 15.7923 82.2448 14.2352 82.2448 12.4024C82.2448 10.5696 82.9122 9.0206 84.2469 7.75547C85.5986 6.47413 87.2544 5.83345 89.2143 5.83345C91.208 5.83345 92.8469 6.46602 94.131 7.73114C95.4151 8.99627 96.0571 10.5534 96.0571 12.4024V13.1323H85.9196C86.0548 13.9433 86.4349 14.6326 87.0601 15.2003C87.6852 15.768 88.4033 16.0518 89.2143 16.0518C90.1098 16.0518 90.8785 15.8085 91.5206 15.3219C91.774 15.1111 91.9768 14.8678 92.1288 14.592H95.9304C95.6432 15.4192 95.2208 16.1491 94.6632 16.7817C93.3115 18.2414 91.4952 18.9713 89.2143 18.9713C87.2544 18.9713 85.5986 18.3388 84.2469 17.0736ZM86.0463 11.0643H92.2555C92.1204 10.4155 91.774 9.87213 91.2165 9.4342C90.6758 8.98005 90.0084 8.75298 89.2143 8.75298C88.4202 8.75298 87.7444 8.97194 87.1868 9.40987C86.6292 9.8478 86.2491 10.3993 86.0463 11.0643Z"
        fill="#00040F"
      />
      <path
        d="M98.2014 18.6064V6.19839H101.75V7.90145H101.876C102.096 7.5284 102.408 7.18779 102.814 6.87962C103.743 6.18217 104.782 5.83345 105.931 5.83345C107.536 5.83345 108.778 6.27949 109.657 7.17157C110.552 8.06365 111 9.36122 111 11.0643V18.6064H107.452V11.5509C107.452 10.6426 107.215 9.95323 106.742 9.48286C106.286 8.99627 105.636 8.75298 104.791 8.75298C103.946 8.75298 103.228 9.06115 102.637 9.6775C102.045 10.2938 101.75 11.0805 101.75 12.0374V18.6064H98.2014Z"
        fill="#00040F"
      />
      <defs>
        <clipPath id="clip0_1011_6617">
          <rect
            width="21.5373"
            height="21.5373"
            fill="white"
            transform="translate(0 0.231354)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
