import { FormGroup } from '@mui/material';

import { kebabCase } from '@utils/kebabCase';

import Icon from '../Icon/Icon';

import s from './CheckList.module.sass';

export interface ICheckListLink {
  [key: string]: boolean;
}

interface IProps {
  asideLinks: ICheckListLink;
}

export const CheckList = ({ asideLinks }: IProps) => {
  const onClick = (linkName: string) => {
    const element = document.getElementById(kebabCase(linkName));

    if (element) {
      element.scrollIntoView({
        block: 'center',
        behavior: 'smooth',
      });
    }
  };

  return (
    <aside className={s.aside}>
      <FormGroup>
        {Object.entries(asideLinks).map(([linkName, isChecked]) => (
          <div
            aria-label="Row"
            role="link"
            tabIndex={0}
            onClick={() => onClick(linkName)}
            onKeyDown={() => onClick(linkName)}
            className={s.check}
            key={linkName}
          >
            {isChecked ? (
              <Icon iconName="checkboxCheckedIcon" />
            ) : (
              <Icon iconName="checkboxIcon" />
            )}
            <span>{linkName}</span>
          </div>
        ))}
      </FormGroup>
    </aside>
  );
};
