import classNames from 'classnames';
import { Checkbox } from '@mui/material';

import Icon from '@components/Icon/Icon';
import { Promotion } from '@components/Promotion/Promotion';

import styles from './Notifications.module.sass';

function Row({ text }: { text: string }) {
  return (
    <div className={styles.notificationsRow}>
      <Checkbox
        size="small"
        sx={{
          '&.Mui-checked': {
            color: '#877CFF',
          },
        }}
      />
      <Checkbox
        size="small"
        sx={{
          '&.Mui-checked': {
            color: '#877CFF',
          },
        }}
      />
      <span className={styles.checkText}>{text}</span>
    </div>
  );
}

export function Notifications() {
  return (
    <div className={styles.notifications}>
      <Promotion
        iconName="slack-colored"
        text="To start using Slack for personal notifications, you should first connect Slack"
        btnText="Connect Slack"
        onClick={() => {}}
      />
      <div
        className={classNames(
          styles.notificationsRow,
          styles.notificationsFirstRow,
        )}
      >
        <Icon iconName="mail" />
        <Icon iconName="slack" />
        <span className={styles.notificationsHeader}>
          Notification for email & Slack
        </span>
      </div>
      <div className={styles.title}>Board activity & conversation</div>
      <Row text="When someone comments in finding I’m create" />
      <Row text="When a project is shared with me" />
      <Row text="When someone @mentions me in any comments" />
      <Row text="Summary of changes on my finding" />
      <div className={`${styles.title} ${styles.secondTitle}`}>
        Board activity & conversation
      </div>
      <Row text="When someone adds me to a project" />
    </div>
  );
}
