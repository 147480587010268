import { useParams } from 'react-router-dom';
import { Button } from '@mui/material';

import { KrakenListItem } from '@components/KrakenListItem/KrakenListItem';
import { KrakenNavBar } from '@components/KrakenNavBar/KrakenNavBar';
import { DeletedActivityStatus } from '@pages/Admin/Archive/ArchiveProject/components/DeletedActivityStatus';
import {
  useGetApiV2ProjectsByPidInactiveActivitiesQuery,
  usePutApiActivitiesByAidUndeleteMutation,
} from '@store/services/query.generated';

import { gridTemplate } from '../../constants';
import { DeletedDate } from '../components/DeletedDate';

import s from '../ArchiveProject.module.sass';

const list = ['ACTIVITY', 'STATUS', 'DELETED BY', ''];

export const DeletedActivities = () => {
  const { pid } = useParams();
  const { data = [] } = useGetApiV2ProjectsByPidInactiveActivitiesQuery(
    { pid: pid! },
    { skip: !pid },
  );
  const [undelete] = usePutApiActivitiesByAidUndeleteMutation();

  return (
    <div className={s.deletedActivities}>
      <h2>Deleted Activities</h2>
      <KrakenNavBar list={list} gridTemplateColumns={gridTemplate} />
      {data.map(({
 activityId, subject, status, deletedDate, userDeleted,
}) => (
  <KrakenListItem
    key={activityId}
    classname={s.listItem}
    handler={() => {}}
  >
    <div className={s.subject}>{subject}</div>
    <DeletedActivityStatus status={status as string} />
    <div>
      <div className={s.userDeleted}>
        {userDeleted ? `@${userDeleted}` : ''}
      </div>
      <DeletedDate deletedDate={deletedDate as string} />
    </div>
    <Button size="large" onClick={() => undelete({ aid: activityId! })}>
      Undelete Activity
    </Button>
  </KrakenListItem>
      ))}
    </div>
  );
};
