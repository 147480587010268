import { Promotion } from '@components/Promotion/Promotion';

import styles from './Integrations.module.sass';

export function Integrations() {
  return (
    <div className={styles.integrations}>
      <Promotion
        iconName="slack-colored"
        text="To start using Slack for personal notifications, you should first connect Slack"
        btnText="Connect Slack"
        onClick={() => {}}
      />
      <Promotion
        iconName="jira"
        text="To start using Slack for personal notifications, you should first connect Slack"
        btnText="Connect Jira"
        onClick={() => {}}
      />
    </div>
  );
}
