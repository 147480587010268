import { UseFormWatch } from 'react-hook-form';

import { CheckList } from '@components/CheckList/CheckList';
import { IComponentFields } from '@pages/Project/Components/AddEditComponent/types';

import { useComponentCheckList } from './useComponentCheckList';

interface IProps {
  watch: UseFormWatch<IComponentFields>;
}

export const ComponentCheckList = ({ watch }: IProps) => {
  const links = useComponentCheckList(watch);
  return <CheckList asideLinks={links} />;
};
