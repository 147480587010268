import { useEffect, useState } from 'react';
import { UseFormWatch } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { BreadCrumbs } from '@components/BreadCrumbs/BreadCrumbs';
import { IActivityFields } from '@pages/Project/Activities/AddEditActivity/types';
import { LinkEnum } from '@src/types/types';

const initialCrumb = [['All projects', LinkEnum.PROJECTS]];

interface IProps {
  watch: UseFormWatch<IActivityFields>;
}

export const ActivityBreadCrumbs = ({ watch }: IProps) => {
  const [crumbs, setCrumbs] = useState(initialCrumb);
  const { pid } = useParams();
  const activityName = watch('Subject');

  useEffect(() => {
    if (pid) {
      setCrumbs([
        ...initialCrumb,
        ['All Activities', LinkEnum.ACTIVITIES.replace(':pid', pid!)],
      ]);
    }
  }, [pid]);

  return <BreadCrumbs crumbs={crumbs} currentPage={activityName} />;
};
