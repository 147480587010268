import { Controller } from 'react-hook-form';
import { Control } from 'react-hook-form/dist/types';
import { useParams } from 'react-router-dom';
import { MenuItem, Skeleton } from '@mui/material';

import { KrakenSelect } from '@components/KrakenSelect/KrakenSelect';
import { useGetApiComponentsByPidQuery } from '@store/services/query.generated';
import { kebabCase } from '@utils/kebabCase';

import { EAddEditActivityLinks, IActivityFields } from '../types';

const skeletonStyle = { flexGrow: 1, height: 44, marginTop: '20px' };

interface IProps {
  control: Control<IActivityFields>;
}

export const ActivityComponent = ({ control }: IProps) => {
  const { pid } = useParams();

  const { data: components = [], isLoading } = useGetApiComponentsByPidQuery({
    pid: pid!,
  });

  return (
    <>
      {isLoading && !components.length && (
        <Skeleton animation="wave" variant="rectangular" sx={skeletonStyle} />
      )}
      {components.length ? (
        <Controller
          control={control}
          name="PcId"
          render={({ field: { value, onChange } }) => (
            <KrakenSelect
              id={kebabCase(EAddEditActivityLinks.COMPONENT)}
              label="Component*"
              value={value && !components.length ? '' : value || ''}
              onChange={onChange}
            >
              {components.map(({ pc_id, name }) => (
                <MenuItem key={pc_id} value={pc_id}>
                  {name}
                </MenuItem>
              ))}
            </KrakenSelect>
          )}
        />
      ) : null}
    </>
  );
};
