import { fetcher } from '@src/utils/fetcher';

export const endpoints = {
  company: {
    putLogo: (cid: string, logo: Blob) => {
      const formData = new FormData();
      formData.append('logo', logo);
      return fetcher(
        `/api/Companies/${cid}/Logo`,
        { body: formData, method: 'PUT' },
        undefined,
        false,
      );
    },
  },
  finding: {
    postImg: (
      fid: string,
      img: Blob,
    ): Promise<{ id: string; link: string }> => {
      const formData = new FormData();
      formData.append('file', img);
      return fetcher(`/api/v2/Findings/images/${fid}`, {
        body: formData,
        method: 'POST',
      });
    },
  },
  project: {
    getLogo: (pid: string) => fetcher(`/api/Projects/${pid}/logo`, {}, undefined, false),
    patchLogo: (pid: string, logo: Blob) => {
      const formData = new FormData();
      formData.append('logo', logo);
      return fetcher(
        `/api/Projects/${pid}/logo`,
        { body: formData, method: 'PATCH' },
        undefined,
        false,
      );
    },
  },
};
