import { UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import { Button } from '@mui/material';

import Icon from '@components/Icon/Icon';
import { Logo } from '@components/Logo/Logo';
import LOGO from '@src/assets/Logo.svg';
import { handleLogin } from '@src/utils';

import s from './Unauthenticated.module.sass';

export function Unauthenticated() {
  const { instance } = useMsal();

  return (
    <UnauthenticatedTemplate>
      <div className={s.unauthenticated}>
        <header className={s.unauthenticatedHeader}>
          <Logo />
        </header>
        <div className={s.main}>
          <div className={s.container}>
            <img src={LOGO} alt="Logo" />
            <h2>Sign in</h2>
            <Button
              size="large"
              color="primary"
              startIcon={<Icon iconName="me" />}
              onClick={() => handleLogin(instance)}
              sx={{
                background: '#877cff',
                color: '#fff',
                '& path:not(.colored)': {
                  fill: '#fff',
                },
                '&:hover': {
                  background: '#544da9',
                },
                '&:active': {
                  background: '#443E89',
                },
                '&:focus': {
                  background: '#544da9',
                  outline: '3px solid #b5aeff',
                },
              }}
            >
              Sign in with Microsoft
            </Button>
          </div>
        </div>
      </div>
    </UnauthenticatedTemplate>
  );
}
