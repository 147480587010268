import cn from 'classnames';
import { CSSProperties, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTheme } from '@mui/material';

import { KrakenListItem } from '@components/KrakenListItem/KrakenListItem';
import { KrakenTable } from '@components/KrakenTable/KrakenTable';
import { IColumn } from '@components/KrakenTable/type';
import { EStatusList } from '@pages/Project/Findings/AddEditFinding/types';
import { LinkEnum } from '@src/types/types';
import { useTypedSelector } from '@src/utils';
import {
  GetApiFindingsProjectByPidFallbackApiResponse,
  KrakenModelsDtoFindingV1FindingReadDto,
} from '@store/services/query.generated';
import { getKeyByValue } from '@utils/getKeyByValue';

import {
  severityColorScheme,
  statusColorScheme,
  statusList,
} from '../AddEditFinding/constants';
import { getSeverity } from '../AddEditFinding/helper';

import s from './FindingList.module.sass';

interface IProps {
  findings: GetApiFindingsProjectByPidFallbackApiResponse;
}

const columns: IColumn<KrakenModelsDtoFindingV1FindingReadDto>[] = [
  {
    headerName: 'SEVERITY',
    field: 'risk_text',
    sortable: true,
  },
  {
    headerName: 'FINDINGS',
    field: 'summary',
    sortable: true,
  },
  {
    headerName: 'TYPE',
    field: 'type',
    sortable: true,
  },
  {
    headerName: 'COMPONENT',
    field: 'component_name',
    sortable: true,
  },
  {
    headerName: 'STATUS',
  },
];

export const FindingList = ({ findings }: IProps) => {
  const { pid } = useParams();
  const navigate = useNavigate();
  const {
    palette: { mode },
  } = useTheme();

  const { hasSignalRConnection } = useTypedSelector(
    (state) => state.signalRReducer,
  );

  const onClick = (fid: string | undefined) => {
    fid
      && navigate(
        LinkEnum.EDIT_FINDING.replace(':pid', pid!).replace(':fid', fid!),
        { state: { linkTemplate: LinkEnum.EDIT_FINDING } },
      );
  };

  const rowTemplate = useCallback(
    ({
      summary,
      finding_id,
      type,
      component_name,
      impact,
      difficulty,
      finding_status_id,
      status_name,
    }) => {
      const severity = getSeverity(impact || 0, difficulty || 0);
      const status = getKeyByValue(
        statusList,
        finding_status_id!.toUpperCase(),
      );

      return (
        <KrakenListItem
          key={finding_id}
          classname={cn(s.wrapper, {
            [s.retracted]: status_name === 'Retracted',
            [s.disabled]: !hasSignalRConnection,
          })}
          handler={onClick}
          fid={finding_id}
          style={{
            gridTemplateColumns:
              '88px minmax(160px, 250px) minmax(100px, 240px) 132px 167px',
          }}
        >
          <div
            className={s.severity}
            style={{
              backgroundColor:
                severityColorScheme[mode || 'light'][severity || 'Info'].bg,
              color:
                severityColorScheme[mode || 'light'][severity || 'Info'].color,
            }}
          >
            <span>{severity}</span>
          </div>
          <div className={s.findings}>{summary}</div>
          <div className={s.type}>{type}</div>
          <div className={s.component}>{component_name}</div>
          <div
            className={s.status}
            style={
              {
                background:
                  statusColorScheme[
                    (status as keyof typeof statusColorScheme)
                      || EStatusList.UNDER_INVESTIGATION
                  ].bg,
                color:
                  statusColorScheme[
                    (status as keyof typeof statusColorScheme)
                      || EStatusList.UNDER_INVESTIGATION
                  ].color,
              } as CSSProperties
            }
          >
            <span>{status}</span>
          </div>
        </KrakenListItem>
      );
    },
    [hasSignalRConnection],
  );

  return (
    <div className={s.findingList}>
      <KrakenTable<KrakenModelsDtoFindingV1FindingReadDto>
        tableData={findings}
        columns={columns}
        gridTemplateColumns="88px minmax(160px, 250px) minmax(100px, 240px) 132px 167px"
        rowTemplate={rowTemplate}
      />
    </div>
  );
};
