import { SnackbarProvider } from 'notistack';
import { ReactElement, ReactNode } from 'react';

import s from './KrakenNotifications.module.sass';

interface IProps {
  children: ReactNode | ReactElement | ReactElement[];
}

const MAX_NOTIFICATIONS = 3;
const AUTO_HIDE_DURATION = 5000;

export const KrakenNotificationProvider = ({ children }: IProps) => (
  <SnackbarProvider
    maxSnack={MAX_NOTIFICATIONS}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    autoHideDuration={AUTO_HIDE_DURATION}
    className={s.wrapper}
    classes={{
      root: s.snackbarRoot,
      containerRoot: s.snackbarContainerRoot,
    }}
  >
    {children}
  </SnackbarProvider>
);
