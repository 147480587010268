import { useRef, useState } from 'react';
import { Menu, MenuItem } from '@mui/material';
import IconButton from '@mui/material/IconButton';

import Icon from '@components/Icon/Icon';
import { AddEditUser } from '@pages/Project/Users/AddEditUser';
import s from '@pages/Project/Users/UsersList/UsersList.module.sass';

interface IProps {
  hid: string;
  rid: string;
  showPopup: any;
  setSelected: any;
}

export const UsersListMenu = ({
 setSelected, showPopup, hid, rid,
}: IProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [visible, setVisible] = useState(false);
  const open = Boolean(anchorEl);
  const menuRef = useRef(null);

  const handleClick = () => {
    setAnchorEl(menuRef.current);
  };

  const onClose = () => {
    setAnchorEl(null);
  };

  const select = () => setSelected([{ hid, rid }]);

  const onEdit = () => {
    setVisible(true);
    select();
  };

  const deleteItem = () => {
    showPopup(true);
    select();
  };

  const closePopup = () => setVisible(false);

  return (
    <div className={s.menu}>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        ref={menuRef}
        onClick={handleClick}
      >
        <Icon iconName="more" />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={onClose}
        transformOrigin={{ horizontal: 'center', vertical: 'top' }}
      >
        <MenuItem onClick={onEdit}>Edit</MenuItem>
        <MenuItem onClick={deleteItem}>Delete</MenuItem>
      </Menu>
      <AddEditUser
        visible={visible}
        onClose={closePopup}
        edit="full"
        hid={hid}
        rid={rid}
      />
    </div>
  );
};
