import { KrakenTable } from '@components/KrakenTable/KrakenTable';
import { GetApiStereotypesApiResponse } from '@store/services/query.generated';

import { useSTList } from './useSTList';

interface ISTList {
  templates: GetApiStereotypesApiResponse;
}

export const STList = ({ templates }: ISTList) => {
  const { columns, rowTemplate } = useSTList();

  return (
    <KrakenTable
      tableData={templates}
      columns={columns}
      gridTemplateColumns="242px 170px 1fr 74px"
      rowTemplate={rowTemplate}
    />
  );
};
