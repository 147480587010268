import { useNavigate, useParams } from 'react-router-dom';
import { Button } from '@mui/material';

import { Footer } from '@components/Footer/Footer';
import Icon from '@components/Icon/Icon';
import { LinkEnum } from '@src/types/types';

interface IProps {
  readyToSubmit: boolean;
}

export const ESFooter = ({ readyToSubmit }: IProps) => {
  const navigate = useNavigate();
  const { pid } = useParams();

  const onBack = () => {
    navigate(LinkEnum.REPORTING.replace(':pid', pid!));
  };

  return (
    <Footer>
      <Button size="small" color="secondary" onClick={onBack} disabled={!pid}>
        Back
      </Button>
      <Button
        color="primary"
        size="small"
        startIcon={<Icon iconName="save" />}
        type="submit"
        disabled={!readyToSubmit}
      >
        Save
      </Button>
    </Footer>
  );
};
