import { EStatusList } from '@pages/Project/Findings/AddEditFinding/types';

export const impactList: { [key: string]: number } = {
  Info: 0,
  Low: 1,
  Medium: 2,
  High: 3,
  Critical: 4,
};

export const difficultyList: { [key: string]: number } = {
  Simple: 3,
  Moderate: 2,
  Advanced: 1,
};

export const underInvestigation = '1585E85B-B415-4001-95D1-46BD52AD6466';
export const vulnerable = '198A894E-0B31-4FB0-B977-8FEF242C50B4';
export const accepted = '7E7368BD-AC54-4CDB-B007-17386EA50036';
export const mitigated = '70D262FC-0A40-4693-A5CF-085D6878CDC4';
export const remedied = '979A41E6-0435-42D0-8780-DFFBC015BDDA';
export const retracted = '3C028D83-3DA2-4E81-9FEF-153CB8196089';

export const requiredStatusId = [accepted, mitigated, remedied];

export const statusList: { [key: string]: string } = {
  [EStatusList.UNDER_INVESTIGATION]: underInvestigation,
  [EStatusList.VULNERABLE]: vulnerable,
  [EStatusList.ACCEPTED]: accepted,
  [EStatusList.MITIGATED]: mitigated,
  [EStatusList.REMEDIED]: remedied,
  [EStatusList.RETRACTED]: retracted,
};

interface ISeverity {
  [key: string]: { bg: string; color: string };
}
interface ISeverityColorScheme {
  light: ISeverity;
  dark: ISeverity;
}
export const severityColorScheme: ISeverityColorScheme = {
  light: {
    Info: {
      bg: '#E9F0FF',
      color: '#0090FF',
    },
    Low: {
      bg: '#E5FCF4',
      color: '#12B17E',
    },
    Medium: {
      bg: '#FEF8F0',
      color: '#CB740E',
    },
    High: {
      bg: '#FFF1F1',
      color: '#CC3838',
    },
    Critical: {
      bg: '#FFD4D4',
      color: '#872424',
    },
    Simple: {
      bg: '#FFF1F1',
      color: '#CC3838',
    },
    Moderate: {
      bg: '#FEF8F0',
      color: '#CB740E',
    },
    Advanced: {
      bg: '#FFD4D4',
      color: '#872424',
    },
  },
  dark: {
    Info: {
      bg: '#043F6F',
      color: '#fff',
    },
    Low: {
      bg: '#0B4C3B',
      color: '#fff',
    },
    Medium: {
      bg: '#42290E',
      color: '#fff',
    },
    High: {
      bg: 'rgba(204, 56, 56, 0.3)',
      color: '#fff',
    },
    Critical: {
      bg: '#561B1F',
      color: '#fff',
    },
    Simple: {
      bg: 'rgba(204, 56, 56, 0.3)',
      color: '#fff',
    },
    Moderate: {
      bg: '#42290E',
      color: '#fff',
    },
    Advanced: {
      bg: '#561B1F',
      color: '#fff',
    },
  },
};

export const statusColorScheme: {
  [key: string]: { bg: string; color: string };
} = {
  [EStatusList.UNDER_INVESTIGATION]: {
    bg: '#FEF8F0',
    color: '#CB740E',
  },
  [EStatusList.VULNERABLE]: {
    bg: '#FFF1F1',
    color: '#CC3838',
  },
  [EStatusList.ACCEPTED]: {
    bg: '#F0EFFF',
    color: '#544DA9',
  },
  [EStatusList.MITIGATED]: {
    bg: '#E8FBF5',
    color: '#12B17E',
  },
  [EStatusList.REMEDIED]: {
    bg: '#E8FBF5',
    color: '#12B17E',
  },
  [EStatusList.RETRACTED]: {
    bg: '#EAEAEE',
    color: '#717786',
  },
};

export const cvssRegexp = /^CVSS:\d\.\d\/AV:[NALP]\/AC:[LH]\/PR:[NLH]\/UI:[NR]\/S:[UC]\/C:[NLH]\/I:[NLH]\/A:[NLH].+/;
