import { Controller } from 'react-hook-form';
import { Control } from 'react-hook-form/dist/types';
import { FormControl, InputLabel } from '@mui/material';

import { DragAndDrop } from '@components/DragAndDrop/DragAndDrop';

import { IProjectFields } from '../types';

import s from '../AddProject.module.sass';

interface IProps {
  control: Control<IProjectFields>;
}

export const ProjectLogo = ({ control }: IProps) => (
  <FormControl fullWidth className={s.addLogo}>
    <InputLabel shrink id="status-text-label" className={s.addLogoLabel}>
      Add logo
    </InputLabel>
    <Controller
      control={control}
      name="logo"
      render={({ field: { value, onChange } }) => (
        <DragAndDrop handler={onChange} value={value || ''} />
      )}
    />
  </FormControl>
);
