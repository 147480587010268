import { ChangeEvent, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { xcsrf } from '@src/constants/constants';
import { ICord } from '@src/types/types';
import {
  useDeleteApiProjectsByPidRolesAndHidRidMutation,
  useDeleteApiProjectsByPidTemporaryAccessAndHidMutation,
  useGetApiHumansEmployeesQuery,
  useGetApiProjectsByPidRolesQuery,
  useGetApiProjectsByPidTemporaryAccessQuery,
} from '@store/services/query.generated';

interface ISelected {
  hid: string;
  rid: string;
}

export const UseUsersList = () => {
  const [checked, setChecked] = useState('rows');
  const [contextMenu, setContextMenu] = useState<ICord | null>(null);
  const [selected, setSelected] = useState<ISelected[] | []>([]);
  const [tempSelected, setTempSelected] = useState<string[]>([]);
  const [visible, setVisible] = useState(false);
  const { pid } = useParams();

  const { data: roles = [], isLoading: rolesIsLoading } = useGetApiProjectsByPidRolesQuery({ pid: pid! });
  const { data: tempRoles = [], isLoading: tempRolesIsLoading } = useGetApiProjectsByPidTemporaryAccessQuery({
      pid: pid!,
    });
  const { data: employees } = useGetApiHumansEmployeesQuery(xcsrf);
  const [deleteUser] = useDeleteApiProjectsByPidRolesAndHidRidMutation();
  const [deleteTempUser] = useDeleteApiProjectsByPidTemporaryAccessAndHidMutation();

  const onChange = ({
    target,
  }: ChangeEvent<
    HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
  >) => {
    setChecked(target.dataset.type!);
  };

  const isChecked = (hid: string, rid: string) => Boolean(selected?.find((sel) => sel.hid === hid && sel.rid === rid));

  const onCheckbox = (
    { target: { value } }: ChangeEvent<HTMLInputElement>,
    isCheck: boolean,
  ) => {
    const val = JSON.parse(value);
    isCheck
      ? setSelected([...selected, val])
      : setSelected(
          selected.filter(
            (item) => item.hid !== val.hid && item.rid !== val.rid,
          ),
        );
  };

  const onTempCheckbox = (
    { target: { value } }: ChangeEvent<HTMLInputElement>,
    isCheck: boolean,
  ) => {
    isCheck
      ? setTempSelected([...tempSelected, value])
      : setTempSelected(tempSelected.filter((hid) => hid !== value));
  };

  const onContextMenu = (
    cord: ICord | null,
    hid: string,
    rid?: string | undefined,
  ) => {
    setContextMenu(cord);
    if (rid) {
      !isChecked(hid, rid) && setSelected([...selected, { hid, rid }]);
    } else {
      !tempSelected.includes(hid) && setTempSelected([...tempSelected, hid]);
    }
  };

  const handleClose = () => {
    setContextMenu(null);
  };

  const onDelete = () => {
    setVisible(true);
    setContextMenu(null);
  };

  const onYes = () => {
    selected?.forEach(({ hid, rid }) => deleteUser({
        pid: pid!,
        hid,
        rid,
      }));

    tempSelected?.forEach((hid) => deleteTempUser({
        pid: pid!,
        hid,
      }));

    setVisible(false);
  };

  const closePopup = () => setVisible(false);

  useEffect(() => {
    if (checked === 'cells') {
      setSelected([]);
      setTempSelected([]);
    }
  }, [checked]);

  return {
    checked,
    contextMenu,
    tempSelected,
    visible,
    setSelected,
    setVisible,
    roles,
    tempRoles,
    employees,
    isChecked,
    onChange,
    onCheckbox,
    onTempCheckbox,
    onContextMenu,
    handleClose,
    onDelete,
    onYes,
    closePopup,
    rolesIsLoading,
    tempRolesIsLoading,
  };
};
