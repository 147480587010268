import { UseFormWatch } from 'react-hook-form';

import { CheckList } from '@components/CheckList/CheckList';

import { IExecutiveSummaryFields } from '../types';

import { useESChecklist } from './useESChecklist';

interface IProps {
  watch: UseFormWatch<IExecutiveSummaryFields>;
}

export const ESChecklist = ({ watch }: IProps) => {
  const links = useESChecklist(watch);

  return <CheckList asideLinks={links} />;
};
