import { updateConnectionState } from '@store/reducers/signalRSlice';
import { store } from '@store/store';
import { notifications } from '@utils/signalR';

export const shutdownConnection = async () => {
  store.dispatch(updateConnectionState(false));

  if (!notifications || notifications.state === 'Disconnected') return;

  await notifications.stop();

  window.console.log('=== Device connection closed ===');
};
